import { Pipe, PipeTransform } from '@angular/core';
import { TreatmentCaseAppointmentEventDateModel } from '../models/treatment-case.model';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { isDateInRange } from '../utils/date.utils';

@Pipe({
  name: 'filterTreatmentCaseEventDates',
  standalone: true,
})
export class FilterTreatmentCaseEventDatesPipe implements PipeTransform {
  transform(
    treatmentCaseEventDates: TreatmentCaseAppointmentEventDateModel[],
    filter: Filter[]
  ): TreatmentCaseAppointmentEventDateModel[] {
    if (!treatmentCaseEventDates || !filter) {
      return treatmentCaseEventDates;
    }

    return treatmentCaseEventDates.filter(instituteEvent => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              instituteEvent.startDate,
              instituteEvent.endDate,
              filter.value as FilterDateRange
            );
          default:
            return true;
        }
      });
    });
  }
}
