import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { InitialEducationStockModel } from 'src/app/models/initial-education-stock.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InitialEducationStockService } from 'src/app/services/initial-education-stock.service';
import { getPracticalWorkTypeLabel } from 'src/app/utils/practical-work.utils';

@Component({
  selector: 'app-initial-education-stock-details',
  templateUrl: './initial-education-stock-details.component.html',
  styleUrl: './initial-education-stock-details.component.scss',
})
export class InitialEducationStockDetailsComponent
  implements OnInit, OnDestroy
{
  public initialEducationStock?: InitialEducationStockModel;
  public isLoading: boolean = true;

  public getPracticalWorkTypeLabel = getPracticalWorkTypeLabel;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initialEducationStockService: InitialEducationStockService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadInitialEducationStock();
  }

  /**
   * Loads the initial education stock if it exists and sets the form values
   * @returns Promise<void>
   */
  private loadInitialEducationStock(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const userId = await this.resolveUserId();

      if (!userId) {
        resolve();
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Anfangsbestand konnte nicht geladen werden.'
        );
        this.isLoading = false;
        return;
      }

      this.initialEducationStockService
        .getInitialEducationStockByUserId(userId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            if (!response.body) {
              resolve();
              this.isLoading = false;
              return;
            }

            this.initialEducationStock =
              await this.initialEducationStockService.parseBackendInitialEducationStock(
                response.body
              );

            resolve();
            this.isLoading = false;
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Der Anfangsbestand konnte nicht geladen werden.'
            );
            reject();
            this.isLoading = false;
          },
        });
    });
  }

  /**
   * Resolves the user ID
   * @returns A promise that resolves to the user ID.
   */
  public resolveUserId(): Promise<number | null> {
    return new Promise(resolve => {
      this.activatedRoute.parent?.paramMap
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          let userId: number;
          if (params.get('userId')) {
            userId = +atob(params.get('userId'));
          }
          resolve(userId);
        });
    });
  }

  /**
   * Opens the edit view for initial education stock
   * @returns void
   */
  public onOpenInitialEducationStockEdit(): void {
    this.router.navigate(['initial-education-stock'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.initialEducationStockService.openFile(
      this.initialEducationStock?.id,
      file.id
    );
  }

  /**
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.initialEducationStockService.downloadFile(
      this.initialEducationStock?.id,
      file.id
    );
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
