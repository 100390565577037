import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { FilterStudentPipe } from 'src/app/pipes/filter-student.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { getTotalEducationalProgressInPercent } from 'src/app/utils/educational-progress.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'userIdentifier',
    'educationalProgress',
    'entryDate',
    'label',
    'actions',
  ];
  public dataSource: MatTableDataSource<UserModel> = new MatTableDataSource();
  public allStudents: UserModel[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  public studentFilter: Filter[] = [
    {
      type: FilterType.LABEL,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  public searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
  });

  // import from utils
  public getTotalEducationalProgressInPercent =
    getTotalEducationalProgressInPercent;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private navigationService: NavigationService
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the students of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name?.lastName;
        case 'educationalProgress':
          return getTotalEducationalProgressInPercent(item);
        case 'vintage':
          return item.entryDate;
        case 'label':
          return item.label?.name;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      const firstName = data.name.firstName
        ? String(data.name.firstName).toLowerCase()
        : '';
      const lastName = data.name.lastName
        ? String(data.name.lastName).toLowerCase()
        : '';

      const dataStr = firstName + ' ' + lastName;
      return dataStr.indexOf(filter.toLowerCase()) !== -1;
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getInstituteStudents();
  }

  /**
   * retrieves all students of the current institute and initializes the table data
   * @returns Promise<void>
   */
  private async getInstituteStudents(): Promise<void> {
    this.userService
      .getInstituteUsersByRole(Role.STUDENT, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const promises = response.body?.map(
            async (userData: any): Promise<UserModel> => {
              const user = await this.userService.parseBackendUser(userData);
              return user;
            }
          );

          if (!promises) {
            this.isLoading = false;
            return;
          }

          this.allStudents = await Promise.all(promises);
          this.dataSource.data = this.allStudents;

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kandidaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * studentFilterChanged
   * gets called when the student filter changed
   * @param student Filter[]
   * @returns void
   */
  public studentFilterChanged(studentFilter: Filter[]): void {
    this.studentFilter = studentFilter;

    this.applyStudentFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applyStudentFilter
   * applies the student filter
   * @returns void
   */
  public applyStudentFilter(): void {
    this.dataSource.data = FilterStudentPipe.prototype.transform(
      this.allStudents,
      this.studentFilter
    );
  }

  /**
   * search the student name by search text
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   * openStudentDetail
   * navigate to student detail page
   * @param userId
   * @returns void
   */
  public openStudentDetail(userId: number) {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate(['detail', btoa(String(userId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditStudent
   * navigate to edit page
   * @param userId
   */
  public onEditStudent(userId: number) {
    this.router.navigate(['edit', btoa(String(userId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * exportDataToExcel
   * exports the student data to excel
   * @returns void
   */
  public exportDataToExcel(): void {
    const users = this.allStudents.map(student => {
      return {
        name: student.name.firstName + ' ' + student.name.lastName,
        email: student.email,
      };
    });

    try {
      // create a worksheet
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(users);

      // rename columns
      worksheet['A1'].v = 'Name';
      worksheet['B1'].v = 'E-Mail';

      // change column width to fit content
      const wscols = [{ wch: 20 }, { wch: 30 }];
      worksheet['!cols'] = wscols;

      // create a workbook
      const woorkbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(woorkbook, worksheet, 'Kandidat_innen');

      // save to file
      const date = moment().format('DD_MM_YYYY-HH_mm');
      const fileName = `Kandidat_innen_${date}.xlsx`;

      // write workbook and export
      XLSX.writeFile(woorkbook, fileName);

      this.alertService.showSuccessAlert(
        'Liste exportiert',
        `Die Liste ${fileName} wurde erfolgreich exportiert.`
      );
    } catch (error) {
      this.alertService.showErrorAlert(
        'Fehler beim Exportieren der Daten',
        'Beim Exportieren der Daten ist ein Fehler aufgetreten.'
      );
    }
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: UserModel): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: user.profilePicture,
        viewOnly: true,
      },
    });
  }

  /**
   * navigate to the invite members page
   * @returns void
   */
  public onInviteUser(): void {
    this.router.navigate(['/eleguide/institute/members/invite']);
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
