import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CourseModel, CourseType } from 'src/app/models/course.model';
import { InstituteEvent } from 'src/app/models/event.model';
import { Filter, FilterType, FilterUser } from 'src/app/models/filter.model';
import { Label } from 'src/app/models/label.model';
import { Role } from 'src/app/models/permission.model';
import { RoomModel } from 'src/app/models/room.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { LabelService } from 'src/app/services/label.service';
import { RoomOrganizationService } from 'src/app/services/room-organization.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() filters: Filter[];
  @Output() filterChanged: EventEmitter<Filter[]> = new EventEmitter();
  @Input() courses?: CourseModel[];
  @Input() instituteEvents?: InstituteEvent[];
  @Input() labelFilter?: boolean;
  @Input() eLogUserFilter?: boolean;

  public filterForm: FormGroup = new FormGroup({});
  public filterType = FilterType;

  public courseTypes: CourseType[] = [];
  public instituteLecturer: FilterUser[] = [];
  public rooms: RoomModel[] = [];
  public labels: Label[] = [];
  public Role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private roomOrganizationService: RoomOrganizationService,
    private userService: UserService,
    private labelService: LabelService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.initializeFilterValues();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      this.initializeForm();
    }
  }

  /**
   * initializeForm
   * Creates the form controls for each filter
   * @returns void
   */
  private initializeForm(): void {
    this.filterForm = new FormGroup({});

    this.filters?.forEach((filter: Filter) => {
      if (filter.type !== FilterType.DATE_RANGE) {
        this.filterForm.addControl(filter.type, new FormControl(filter.value));
      }
    });

    this.filterForm
      .get(FilterType.REGISTERED_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.OPEN_COURSES, value);
      });

    this.filterForm
      .get(FilterType.FINISHED_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.OPEN_COURSES, value);
        this.updateControlState(FilterType.PENDING_ELOGS, value);
      });

    this.filterForm
      .get(FilterType.OPEN_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.REGISTERED_COURSES, value);
        this.updateControlState(FilterType.FINISHED_COURSES, value);
        this.updateControlState(FilterType.PENDING_ELOGS, value);
      });

    this.filterForm
      .get(FilterType.PENDING_ELOGS)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.FINISHED_COURSES, value);
        this.updateControlState(FilterType.OPEN_COURSES, value);
      });
  }

  /**
   * initializeFilterValues
   * Initializes the filter values for the course types, lecturers and rooms
   * @returns void
   */
  public initializeFilterValues(): void {
    if (this.courses) {
      this.courseTypes = this.courseService.filterUniqueCourseTypes(
        this.courses
      );
      this.rooms = this.courseService.getAllCourseRooms(this.courses);

      // get all institute lecturers
      this.userService
        .getInstituteUsersByRole(Role.LECTURER, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            const instituteLecturer = result.body
              ? await Promise.all(
                  result.body.map(
                    async (userData: any): Promise<FilterUser> => {
                      const decryptedUserData =
                        await this.userService.parseBackendUser(userData);
                      const name =
                        (decryptedUserData.name.academicTitle
                          ? decryptedUserData.name.academicTitle + ' '
                          : '') +
                        decryptedUserData.name.firstName +
                        ' ' +
                        decryptedUserData.name.lastName;
                      return {
                        id: decryptedUserData.id,
                        name: name,
                      };
                    }
                  )
                )
              : [];

            this.instituteLecturer = instituteLecturer;
          },
        });
    }

    if (this.instituteEvents) {
      this.rooms = this.roomOrganizationService.getAllInstituteEventRooms(
        this.instituteEvents
      );
    }

    if (this.labelFilter) {
      this.labelService
        .getAllLabels()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: result => {
            this.labels = result.body;
          },
          error: error => {},
        });
    }
  }

  /**
   * applyFilter
   * Emits the filterChanged event with the current filter values
   * @returns void
   */
  public applyFilter(): void {
    const filters: Filter[] = [];

    Object.keys(this.filterForm.controls).forEach((key: string) => {
      const filter: Filter = {
        type: key as FilterType,
        value: this.filterForm.get(key).value,
      };
      // if value is false set to null
      if (filter.value === false) {
        filter.value = null;
      }

      filters.push(filter);
    });

    this.filterChanged.emit(filters);
  }

  /**
   * clearFilter
   * Emits the filterChanged event with empty filter values
   * @returns void
   */
  public clearFilter(): void {
    const filters: Filter[] = [];

    Object.keys(this.filterForm.controls).forEach((key: string) => {
      const filter: Filter = {
        type: key as FilterType,
        value: null,
      };

      filters.push(filter);
    });

    this.filterChanged.emit(filters);
  }

  /**
   * updateControlState
   * Helper function to safely update control state without triggering infinite loops
   * @param controlName
   * @param shouldBeDisabled
   * @returns void
   */
  private updateControlState(
    controlName: string,
    shouldBeDisabled: boolean
  ): void {
    const control = this.filterForm.get(controlName);
    if (control) {
      if (shouldBeDisabled && control.value) {
        control.setValue(null, { emitEvent: false });
      }
    }
  }

  /**
   * formGroupHasControls
   * Checks if the form group has any controls
   * @returns boolean
   */
  public formGroupHasControls(): boolean {
    return (
      this.filterForm.controls &&
      Object.keys(this.filterForm.controls).length > 0
    );
  }

  /**
   * compareLecturer
   * Compares two lecturers by their id
   * @param lecturer1: FilterUser
   * @param lecturer2: FilterUser
   * @returns boolean
   */
  public compareLecturer(
    lecturer1: FilterUser,
    lecturer2: FilterUser
  ): boolean {
    return lecturer1 && lecturer2
      ? lecturer1.id === lecturer2.id
      : lecturer1 === lecturer2;
  }

  /**
   * compareLabel
   * Compares two labels by their name
   * @param label1 Label
   * @param label2 Label
   * @returns
   */
  public compareLabel(label1: Label, label2: Label): boolean {
    return label1 && label2 ? label1.name === label2.name : label1 === label2;
  }

  /**
   * returns true if any advanced filter is in the form
   * @returns boolean
   */
  public showAdvancedFilters(): boolean {
    return !!(
      this.filterForm.get(this.filterType.REGISTERED_COURSES) ||
      this.filterForm.get(this.filterType.FINISHED_COURSES) ||
      this.filterForm.get(this.filterType.OPEN_COURSES) ||
      this.filterForm.get(this.filterType.PENDING_ELOGS) ||
      this.filterForm.get(this.filterType.ROOM_ACTIVE) ||
      this.filterForm.get(this.filterType.OPEN_ROOMPLANNING)
    );
  }

  /**
   * ngOnDestroy
   * Cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
