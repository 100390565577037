<div class="component-content">
  <div class="header">
    <div class="header-title">
      <div *ngIf="!isLoading" class="user-container-header">
        <img
          (click)="openProfilePictureDialog()"
          src="{{
            user?.profilePicture ?? '../../../../../assets/img/user.jpg'
          }}"
          alt="Profilbild"
          class="user-icon-image cursor-pointer" />

        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ getFullName(user) }}
          </h1>
          <div class="flex-r gap-16 align-center">
            <h3 class="hasomed-text-h3">
              {{ user?.userIdentifier ?? '-' }}
            </h3>
            <div
              *ngIf="user?.label"
              [style.background]="user?.label?.color"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ user?.label?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onBackToOverview()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text">Zurück zur Übersicht</span>
      </button>

      <!-- Edit button -->
      <button
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div class="main-content">
    <app-user-master-data></app-user-master-data>
  </div>
</div>
