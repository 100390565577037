<form
  [formGroup]="selfAwarenessForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Eintrag bearbeiten' : 'Neuen Eintrag vornehmen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie diesen Eintrag.'
            : 'Erstellen Sie einen neuen Eintrag.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content-form">
    <div class="formular-row">
      <label class="formular-row-label" for="selfAwarenessType">
        <span class="label-title">
          Art der Lehranalyse, Lehrtherapie oder Supervision
          <span *ngIf="isRequired(selfAwarenessForm.get('selfAwarenessType'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die Art der Lehranalyse, Lehrtherapie oder Supervision.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="hasomed-toggle-button">
          <button
            [ngClass]="{
              'hasomed-button-orange':
                selfAwarenessForm.get('selfAwarenessType').value === 'Einzel',
              'hasomed-button-white-grey':
                selfAwarenessForm.get('selfAwarenessType').value !== 'Einzel',
              'border-red':
                selfAwarenessForm.get('selfAwarenessType').invalid &&
                selfAwarenessForm.get('selfAwarenessType').touched,
            }"
            (click)="
              selfAwarenessForm.get('selfAwarenessType').setValue('Einzel')
            "
            type="button"
            class="hasomed-text-button button-full-width btn-left">
            <span class="hasomed-button-text">Einzel</span>
          </button>
          <button
            [ngClass]="{
              'hasomed-button-orange':
                selfAwarenessForm.get('selfAwarenessType').value === 'Gruppe',
              'hasomed-button-white-grey':
                selfAwarenessForm.get('selfAwarenessType').value !== 'Gruppe',
              'border-red':
                selfAwarenessForm.get('selfAwarenessType').invalid &&
                selfAwarenessForm.get('selfAwarenessType').touched,
            }"
            (click)="
              selfAwarenessForm.get('selfAwarenessType').setValue('Gruppe')
            "
            type="button"
            class="hasomed-text-button button-full-width btn-right">
            <span class="hasomed-button-text">Gruppe</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            selfAwarenessForm.get('selfAwarenessType')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="selfAwarenessTrainerId">
        <span class="label-title">
          Selbsterfahrungsleitung
          <span
            *ngIf="isRequired(selfAwarenessForm.get('selfAwarenessTrainerId'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie eine Selbsterfahrungsleitung.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-32">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                #selfAwarenessTrainerInput
                id="selfAwarenessTrainerSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="selfAwarenessTrainerSearch" />
            </div>
            <div
              *ngIf="availableSelfAwarenessTrainers"
              [ngClass]="{
                'error-border':
                  selfAwarenessForm.get('selfAwarenessTrainerId').invalid &&
                  selfAwarenessForm.get('selfAwarenessTrainerId').touched,
              }"
              id="selfAwarenessTrainerId"
              class="flex-c vertical-overflow gap-8 users">
              <div
                *ngFor="
                  let selfAwarenessTrainer of availableSelfAwarenessTrainers
                    | searchFilter: selfAwarenessTrainerInput.value
                "
                [ngClass]="{
                  active:
                    selfAwarenessForm.get('selfAwarenessTrainerId').value ===
                    selfAwarenessTrainer.id,
                }"
                (click)="onSelfAwarenessTrainerSelected(selfAwarenessTrainer)"
                class="flex-r user-container">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        selfAwarenessTrainer.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt
                      class="user-icon-image" />
                  </div>
                  <div class="flex-c justify-center">
                    <span class="hasomed-text-body">
                      {{ getFullName(selfAwarenessTrainer) }}
                    </span>
                    <span class="hasomed-text-small">
                      {{ getAllAdditionalQualifications(selfAwarenessTrainer) }}
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    selfAwarenessForm.get('selfAwarenessTrainerId').value ===
                    selfAwarenessTrainer.id
                  ">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
            <span
              *ngIf="availableSelfAwarenessTrainers?.length === 0"
              class="hasomed-text-small">
              Keine Selbsterfahrungsleitung gefunden.
            </span>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            selfAwarenessForm.get('selfAwarenessTrainerId')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer
          <span *ngIf="isRequired(selfAwarenessForm.get('duration'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie die Dauer des Eintrags in Stunden
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="duration"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="duration"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Datum der Sitzung
          <span *ngIf="isRequired(selfAwarenessForm.get('date'))">*</span>
        </span>
        <span class="label-description"> Datum der Selbsterfahrung. </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field
            [class.hasomed-date-field-empty]="
              !selfAwarenessForm.get('date').value
            "
            class="hasomed-date-field">
            <input
              [matDatepicker]="picker"
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              placeholder="Wählen Sie ein Datum"
              formControlName="date" />
            <mat-datepicker-toggle
              [for]="picker"
              class="hasomed-date-toggle"
              matIconPrefix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('date')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(selfAwarenessForm.get('description'))"
            >*</span
          >
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"
            placeholder="Beschreibung"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            selfAwarenessForm.get('description')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumentenupload
          <span *ngIf="isRequired(selfAwarenessForm.get('documentUpload'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Hier können Sie Dokumente für diesen Eintrag hochladen.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [sensitiveDataAlert]="false"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [allowedFileTypes]="allowedFileTypesDocuments"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="selfAwarenessDocumentsUpload"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
