import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ambulatory-part',
  templateUrl: './ambulatory-part.component.html',
  styleUrls: ['./ambulatory-part.component.scss'],
})
export class AmbulatoryPartComponent implements OnInit {
  public searchForm: FormGroup;
  public isLoading = true;

  public selectedTreatmentCase: any;
  public treatmentCases: any[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });
  }

  public onCreateAppointment() {
    this.router.navigate(['create-appointment'], {
      relativeTo: this.activatedRoute,
    });
  }

  public onCreateTreatmentCase() {
    this.router.navigate(['create-treatment-case'], {
      relativeTo: this.activatedRoute,
    });
  }
}
