import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard-my-space',
  templateUrl: './dsb-my-space.component.html',
  styleUrls: ['./dsb-my-space.component.scss'],
})
export class DSBMySpaceComponent {
  public showRoomUtilizationCalendar = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    // check if the user has selected the room utilization calendar
    const selectedCalendar = localStorage.getItem('selectedCalendar');

    if (selectedCalendar === 'room') {
      this.showRoomUtilizationCalendar = true;
    }
  }

  onManageAppointments() {
    const isStudent = this.userService.currentUser.roleId === Role.STUDENT;

    if (isStudent) {
      this.router.navigate(['../../education/ambulatory-part'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['../../education/course-administration'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * getDashboardHeaderTitle
   * returns the title for the dashboard header based on the user role
   * @returns string
   */
  public getDashboardHeaderTitle(): string {
    if (this.userService.currentUserIsStudent()) {
      return 'Ausbildungsüberblick';
    }

    return 'Institutsüberblick';
  }

  /**
   * getDashboardHeaderSubTitle
   * returns the subtitle for the dashboard header based on the user role
   * @returns string
   */
  public getDashboardHeaderSubTitle(): string {
    if (this.userService.currentUserIsStudent()) {
      return 'Die wichtigsten Informationen zu Ihrer Ausbildung.';
    }

    return 'Die wichtigsten Informationen zu Ihrem Institut.';
  }

  /**
   * changes the calendar to show the room utilization or the normal calendar
   * @returns void
   */
  public onChangeCalendar(): void {
    this.showRoomUtilizationCalendar = !this.showRoomUtilizationCalendar;

    // store the current state in the local storage
    localStorage.setItem(
      'selectedCalendar',
      this.showRoomUtilizationCalendar ? 'room' : 'normal'
    );
  }
}
