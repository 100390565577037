<div class="dialog flex-c">
  <div class="header flex-r gap-16 align-center">
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h1">
        {{ getEventDateTitle() }}
      </span>
      <div *ngIf="data.calendarEventDate.isCanceled" class="label label-red">
        <i class="fa-light fa-ban label-icon"></i>
        <span class="hasomed-text-small label-text"> Abgesagt </span>
      </div>
    </div>
    <button
      type="button"
      class="close-button"
      matTooltip="Schließen"
      (click)="onClose()">
      <i class="fa-light fa-x close-button-icon"></i>
    </button>
  </div>

  <form class="dialog-form" [formGroup]="eventForm">
    <!-- Date -->
    <div class="flex-c gap-24 scrollable-content">
      <div class="formular-row">
        <label for="date" class="formular-row-label">
          <div class="label-title">Datum</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="date"
              class="hasomed-text-input flex-grow"
              formControlName="date"
              readonly />
          </div>
        </div>
      </div>

      <!-- room -->
      <div class="formular-row">
        <label for="room" class="formular-row-label">
          <div class="label-title">Raum</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="room"
              class="hasomed-text-input flex-grow"
              formControlName="room"
              readonly />
          </div>
        </div>
      </div>

      <div
        class="flex-r align-center justify-space-between"
        *ngIf="data.calendarEventDate.videoMeetingLink">
        <div class="hasomed-text-body link-container">
          Video-Meeting: {{ data.calendarEventDate.videoMeetingLink }}
        </div>
        <app-copy-to-clipboard
          type="Link"
          [textToCopy]="
            data.calendarEventDate.videoMeetingLink
          "></app-copy-to-clipboard>
      </div>

      <!-- chiffre -->
      <div class="formular-row" *ngIf="eventForm.get('chiffre').value">
        <label for="chiffre" class="formular-row-label">
          <div class="label-title">Chiffre</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="chiffre"
              class="hasomed-text-input flex-grow"
              formControlName="chiffre"
              readonly />
          </div>
        </div>
      </div>

      <!-- supervisor -->
      <div class="formular-row" *ngIf="eventForm.get('supervisor').value">
        <label for="supervisor" class="formular-row-label">
          <div class="label-title">Supervisor*in</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="supervisor"
              class="hasomed-text-input flex-grow"
              formControlName="supervisor"
              readonly />
          </div>
        </div>
      </div>

      <!-- lecturer -->
      <div class="formular-row" *ngIf="eventForm.get('lecturer').value">
        <label for="lecturer" class="formular-row-label">
          <div class="label-title">Lehrpersonal</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="lecturer"
              class="hasomed-text-input flex-grow"
              formControlName="lecturer"
              readonly />
          </div>
        </div>
      </div>

      <!-- treatmentCaseStudent -->
      <div
        class="formular-row"
        *ngIf="eventForm.get('treatmentCaseStudent').value">
        <label for="treatmentCaseStudent" class="formular-row-label">
          <div class="label-title">Kandidat*in</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="treatmentCaseStudent"
              class="hasomed-text-input flex-grow"
              formControlName="treatmentCaseStudent"
              readonly />
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->

    <div class="formular-buttons flex-r justify-space-between">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onClose()">
        <i class="hasomed-button-icon fa-light fa-x"></i>
        <span class="hasomed-button-text">Schließen</span>
      </button>
      <button
        *ngIf="data.calendarEventDate.eventDateType === eventType.COURSE"
        type="button"
        class="hasomed-text-button hasomed-button-orange"
        (click)="onOpenCourseDetail()">
        <i
          class="hasomed-button-icon fa-light fa-arrow-up-right-from-square"></i>
        <span class="hasomed-button-text"> Kurs Detailansicht </span>
      </button>

      <button
        *ngIf="
          (data.calendarEventDate.eventDateType === eventType.PATIENT_SESSION ||
            data.calendarEventDate.eventDateType === eventType.SUPERVISION ||
            data.calendarEventDate.eventDateType ===
              eventType.GROUP_SUPERVISION) &&
          userService.currentUserIsStudent()
        "
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onManageAppointment()">
        <i class="hasomed-button-icon fa-light fa-calendar-plus"></i>
        <span class="hasomed-button-text"> Verwalten </span>
      </button>

      <button
        *ngIf="
          (data.calendarEventDate.eventDateType === eventType.PATIENT_SESSION ||
            data.calendarEventDate.eventDateType === eventType.SUPERVISION ||
            data.calendarEventDate.eventDateType ===
              eventType.GROUP_SUPERVISION) &&
          userService.currentUserIsStudent()
        "
        [disabled]="data.calendarEventDate.isCanceled"
        type="button"
        class="hasomed-text-button hasomed-button-red"
        (click)="onCancelAppointment()">
        <i class="hasomed-button-icon fa-light fa-ban"></i>
        <span class="hasomed-button-text"> Absagen </span>
      </button>
    </div>
  </form>
</div>
