import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Feature, Permission } from 'src/app/models/permission.model';
import { SelfAwarenessEntryModel } from 'src/app/models/self-awareness-entry.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-self-awareness',
  templateUrl: './self-awareness.component.html',
  styleUrls: ['./self-awareness.component.scss'],
})
export class SelfAwarenessComponent implements OnInit, OnDestroy {
  public selfAwarenessEntries: SelfAwarenessEntryModel[] = [];
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });
  public filterOpened: boolean = false;
  public filterEmpty: boolean = true;

  public displayedColumns = [
    'selfAwarenessTrainer',
    'date',
    'duration',
    'confirmation',
    'actions',
  ];
  public dataSource: MatTableDataSource<SelfAwarenessEntryModel> =
    new MatTableDataSource();
  public isLoading: boolean = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  public getFullName = getFullName;

  constructor(
    private selfAwarenessService: SelfAwarenessService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        let userId = +atob(params.get('id'));

        if (!userId) {
          userId = this.userService.currentUser.id;
        }

        this.getSelfAwarenessEntriesFromStudent(userId);
      });
  }

  /**
   * Gets all self awareness entries from the student
   * @param studentId The student id
   * @returns void
   */
  private getSelfAwarenessEntriesFromStudent(studentId: number): void {
    this.selfAwarenessService
      .getAllSelfAwarenessEntriesByStudentId(studentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.selfAwarenessEntries = response.body
            ? await Promise.all(
                response.body.map(
                  async (
                    selfAwarenessEntry: SelfAwarenessEntryModel
                  ): Promise<SelfAwarenessEntryModel> =>
                    await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                      selfAwarenessEntry
                    )
                )
              )
            : [];

          this.dataSource.data = this.selfAwarenessEntries;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Selbsterfahrungseinträge konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );

          this.isLoading = false;
        },
      });
  }

  /**
   * Applies the search filter
   * @param event The event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Navigate to the self awareness entry view
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public navigateToViewSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    this.router.navigate(['./', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Navigate to the create self awareness entry page
   * @returns void
   */
  public navigateToCreateSelfAwareness(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  /**
   * Navigate to the edit self awareness entry page
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public navigateToEditSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    this.router.navigate(['./', 'edit', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Deletes the self awareness entry
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public deleteSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Eintrag löschen',
        message: `Möchten Sie den Eintrag wirklich löschen? Der Eintrag kann nicht wiedehergestellt werden.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.selfAwarenessService
            .deleteSelfAwarenessEntry(selfAwarenessEntry.id)
            .pipe(first())
            .subscribe({
              next: result => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Eintrag wurde gelöscht!`
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(selfAwarenessEntry);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== selfAwarenessEntry
                  );
                }
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat nicht geklappt!',
                  'Der Eintrag konnte nicht gelöscht werden.'
                );
              },
            });
        }
      });
  }

  /**
   * onDownload
   * download the file
   * @param selfAwarenessId
   * @param fileId
   * @returns void
   */
  public onDownload(selfAwarenessId: number, fileId: number): void {
    this.selfAwarenessService.downloadFile(selfAwarenessId, fileId);
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
