import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { VNode, createElement } from '@fullcalendar/core/preact';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import {
  CalendarEventDateModel,
  FullCalendarEventModel,
} from 'src/app/models/calendar-event.model';
import { EventDateType } from 'src/app/models/event.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCalendarEventColor,
  getCalendarEventRoomName,
  getCalendarEventTitle,
} from 'src/app/utils/calendar.utils';
import { CalendarEventDetailComponent } from './calendar-event-detail/calendar-event-detail.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  public calendarOptions: CalendarOptions;
  public isTodayInView = true;
  private changeViewBreakpoint: number = 1200;
  private scrollTopPosition: number = 0;
  public calendar: Calendar;
  public onlyDayView = false;
  public selectedCalendarView: string;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  @HostListener('window:resize', ['$event'])
  public resize($event: any) {
    // change view
    this.changeViewBreakpoint = this.sidenavService.collapsed ? 1200 : 1350;
    if (window.innerWidth < this.changeViewBreakpoint) {
      this.calendar.changeView('timeGridDay');
      this.onlyDayView = true;
    } else {
      const previousView = this.calendar.view.type;

      if (previousView === this.selectedCalendarView) {
        this.onlyDayView = false;
        return;
      }

      this.onlyDayView = false;
    }

    this.selectedCalendarView = this.calendar.view.type;
    // keep scroll position on resize
    this.keepScrollPosition();
    this.updateTitle();
  }

  constructor(
    private eventService: EventService,
    private sidenavService: SidenavService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit() {
    this.initializeCalendarOptions();
    // await this.initializeCalendarEvents();
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    // get scroll position
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    this.scrollTopPosition = fcScroller.scrollTop;

    fcScroller.addEventListener('scroll', () => {
      this.scrollTopPosition = fcScroller.scrollTop;
    });

    this.sidenavService.sidenavChangedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((collapsed: boolean) => {
        this.changeViewBreakpoint = collapsed ? 1200 : 1350;

        if (this.calendar) {
          if (window.innerWidth < this.changeViewBreakpoint) {
            this.onViewChange('timeGridDay');
            this.onlyDayView = true;
          } else {
            this.onViewChange('timeGridSevenDay');
            this.onlyDayView = false;
          }
          this.selectedCalendarView = this.calendar.view.type;
          this.keepScrollPosition();
        }
      });

    if (window.innerWidth < this.changeViewBreakpoint) {
      this.onViewChange('timeGridDay');
      this.onlyDayView = true;
    } else {
      this.onlyDayView = false;
    }

    this.selectedCalendarView = this.calendar.view.type;
    localStorage.setItem('calendarView', this.selectedCalendarView);

    // call detectChanges because calendar.getApi() is called in ngAfterViewInit
    this.cdr.detectChanges();
  }

  /**
   * onViewChange
   * triggerd when the view of the calendar changes
   * @returns void
   */
  public onViewChange(view: string, day?: string): void {
    this.selectedCalendarView = view;

    if (day) {
      this.calendar.changeView(this.selectedCalendarView, day);
    } else {
      this.calendar.changeView(this.selectedCalendarView);
    }

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }

    localStorage.setItem('calendarView', this.selectedCalendarView);
    this.updateTitle();
    this.checkIsTodayInView();
    this.initializeCalendarEventDatesForView();
  }

  /**
   * initializeCalendarOptions
   * initialize the calendar options
   * @returns void
   */
  private initializeCalendarOptions(): void {
    this.changeViewBreakpoint = this.sidenavService.collapsed ? 1200 : 1350;

    // get calendarView from localStorage
    this.selectedCalendarView =
      localStorage.getItem('calendarView') ?? 'timeGridSevenDay';

    this.calendarOptions = {
      locale: 'de',
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
      initialView: this.selectedCalendarView,
      views: {
        timeGridSevenDay: {
          type: 'timeGrid',
          duration: { days: 7 },
          slotMinTime: '05:00:00',
          slotMaxTime: '23:59:59',
          slotDuration: '1:00',
          expandRows: true,
          allDaySlot: false,
          nowIndicator: true,
          // start 1.5 hours before now,
          scrollTime: moment()
            .minute(0)
            .second(0)
            .subtract(1.5, 'h')
            .format('HH:mm:ss'),
          eventMaxStack: 1,
        },
        timeGridDay: {
          type: 'timeGrid',
          duration: { days: 1 },
          slotMinTime: '05:00:00',
          slotMaxTime: '23:59:59',
          slotDuration: '1:00',
          expandRows: true,
          allDaySlot: false,
          nowIndicator: true,
          // start 1.5 hours before now
          scrollTime: moment()
            .minute(0)
            .second(0)
            .subtract(1.5, 'h')
            .format('HH:mm:ss'),
          eventMaxStack: 3,
        },
        dayGridMonth: {
          type: 'dayGridMonth',
          dayMaxEventRows: 3,
        },
      },
      firstDay: 1, // start month view with monday
      moreLinkText: 'mehr',
      moreLinkContent(renderProps, createElement) {
        return createElement(
          'span',
          { class: 'hasomed-text-small' },
          renderProps.num +
            ' weitere' +
            (renderProps.num === 1 ? 'r ' : ' ') +
            ' Termin' +
            (renderProps.num === 1 ? '' : 'e')
        );
      },
      moreLinkDidMount: function (info) {
        info.el.title = 'Termine anzeigen'; // Custom tooltip text
      },
      dayHeaderFormat: {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        omitCommas: true,
      },
      nowIndicatorContent: args => {
        // create the nor indicator line
        if (args.isAxis) {
          return createElement(
            'span',
            { class: 'hasomed-text-small font-white' },
            moment(args.date).format('HH:mm')
          );
        } else {
          return null;
        }
      },
      dateClick: info => {
        if (this.selectedCalendarView === 'dayGridMonth') {
          this.onViewChange('timeGridDay', info.dateStr);
          this.updateTitle();
        }
      },
      dayHeaderContent: args => {
        // create the custom header div (weekday, day)
        const date = new Date(args.date);
        const weekday = date.toLocaleDateString('de-DE', { weekday: 'long' });
        const day = date.getDate();

        const weekdayDiv = createElement(
          'div',
          { class: 'fc-custom-header-weekday' },
          weekday
        );

        const dayDiv = createElement(
          'div',
          { class: 'fc-custom-header-day', id: 'day' },
          day
        );

        return createElement('div', { class: 'fc-custom-header-cell' }, [
          weekdayDiv,
          this.calendar?.view.type !== 'dayGridMonth' ? dayDiv : null,
        ]);
      },
      dayHeaderDidMount: ({ el, view }) => {
        const today = new Date();
        const headerDate = new Date(el.getAttribute('data-date'));

        // check, if the header date is todays date
        if (moment(today).isSame(headerDate, 'day')) {
          // find the div where the content is the day of today
          const dayDiv = el.querySelector('.fc-custom-header-day');
          if (dayDiv.innerHTML === today.getDate().toString()) {
            dayDiv.classList.add('fc-custom-today');
          }
        }
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: 'numeric',
        omitZeroMinute: false,
      },
      dayCellDidMount: info => {
        info.el.title = moment(info.date).format('DD.MM.YYYY') + ' anzeigen';
      },
      slotLabelContent: args => {
        // create the slot label (time to the left) div to display in calendar
        const slotLabelText = args.text;
        let slotLabelDiv = createElement(
          'div',
          { class: 'fc-custom-slot-label' },
          slotLabelText !== '05:00' ? slotLabelText : ''
        );

        return slotLabelDiv;
      },
      eventContent: args => {
        // create the event div to display in calendar
        const event = args.event;
        const time = args.timeText;
        const eventWrapperDiv = this.createEventDiv(event, time);
        return eventWrapperDiv;
      },
      eventDidMount: info => {
        // create tooltip
        const title = info.event.title;
        const room = info.event.extendedProps['calendarEventDate'].room
          ? info.event.extendedProps['calendarEventDate'].room.name
          : 'Kein Raum';
        const date =
          moment(info.event.start).format('DD.MM.YYYY') +
          ', ' +
          moment(info.event.start).format('HH:mm') +
          ' - ' +
          moment(info.event.end).format('HH:mm') +
          ' Uhr';
        const tooltipText = title + ' - ' + room + ' - ' + date;

        info.el.title = tooltipText;
      },
      eventClick: info => {
        // open event detail dialog
        const dialogRef = this.dialog.open(CalendarEventDetailComponent, {
          data: {
            calendarEventDate: info.event.extendedProps['calendarEventDate'],
          },
          width: '500px',
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (result?.eventDateHasBeenCanceled) {
              this.initializeCalendarEventDatesForView();
            }
          });
      },
      // remove header toolbar
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      buttonText: {
        today: 'Heute',
      },
    };
  }

  /**
   * Initalizes the even dates for the current view
   * @returns voidq
   */
  private initializeCalendarEventDatesForView(): void {
    const startDate = this.calendar.view.activeStart;
    const endDate = this.calendar.view.activeEnd;

    let getAllEventDates = this.eventService.getAllEventDatesByInstituteId(
      startDate,
      endDate
    );

    if (this.userService.currentUserIsStudent()) {
      getAllEventDates = this.eventService.getAllEventDatesByStudentId(
        startDate,
        endDate
      );
    }

    if (this.userService.currentUserIsLecturer()) {
      getAllEventDates = this.eventService.getAllEventDatesByLecturerId(
        startDate,
        endDate
      );
    }

    getAllEventDates.pipe(takeUntil(this.destroy$)).subscribe({
      next: async response => {
        const calendarEventDates = response.body
          ? await Promise.all(
              response.body.map(async (eventDate: CalendarEventDateModel) => {
                return this.eventService.parseCalendarEventDate(eventDate);
              })
            )
          : [];

        const calendarEvents: FullCalendarEventModel[] = calendarEventDates.map(
          (
            calendarEventDate: CalendarEventDateModel
          ): FullCalendarEventModel => {
            const title = getCalendarEventTitle(calendarEventDate);
            const color = getCalendarEventColor(calendarEventDate);

            return {
              title: title,
              start: calendarEventDate.startDate,
              end: calendarEventDate.endDate,
              backgroundColor: color.backgroundColor,
              borderColor: color.borderColor,
              textColor: color.textColor,
              extendedProps: {
                calendarEventDate: calendarEventDate,
              },
            };
          }
        );

        // update calendar
        this.calendar.setOption('events', calendarEvents);
      },
      error: error => {},
    });
  }

  /**
   * onClickNext
   * trigger calenderApi.next() when clicking on next button, update isTodayInView and title
   * @returns void
   */
  public onClickNext(): void {
    this.calendar.next();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * onClickPrev
   * trigger calenderApi.prev() when clicking on prev button, update isTodayInView and title
   * @returns void
   */
  public onClickPrev(): void {
    this.calendar.prev();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * onClickToday
   * trigger calenderApi.today() when clicking on today button,  update isTodayInView and title
   * @returns void
   */
  public onClickToday() {
    this.calendar.today();
    this.isTodayInView = true;
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * onChangeCalendarView
   * change calendar view to day or seven day view
   * @returns void
   */
  public onChangeCalendarView(): void {
    // check which view is active and change to the other
    if (this.calendar.view.type === 'timeGridDay') {
      this.calendar.changeView('timeGridSevenDay');
    } else {
      this.calendar.changeView('timeGridDay');
    }
    this.keepScrollPosition();
  }

  /**
   * checkIsTodayInView
   * Update isTodayInView to true, when todays date is in current view, else false
   * @returns void
   */
  private checkIsTodayInView(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const viewEnd = calendarApiView.activeEnd;
    const today = new Date();

    if (today >= viewStart && today <= viewEnd) {
      this.isTodayInView = true;
    } else {
      this.isTodayInView = false;
    }
  }

  /**
   * updateTitle
   * updates the title (date-range) of current view
   * @returns void
   */
  private updateTitle(): void {
    const calendarApiView = this.calendar.view;
    const titleSpan = document.getElementById('fcCustomTitle');
    titleSpan.innerHTML = calendarApiView.title;
  }

  /**
   * getEventsInView
   * returns all events in current view
   * @returns EventImpl[]
   */
  public getEventsInView(): EventImpl[] {
    const viewStart = this.calendar.view.activeStart;
    const viewEnd = this.calendar.view.activeEnd;

    const allEvents = this.calendar.getEvents();
    const eventsInView = [];

    allEvents.forEach(event => {
      if (
        (event.start >= viewStart && event.start <= viewEnd) ||
        (event.end >= viewStart && event.end <= viewEnd)
      ) {
        eventsInView.push(event);
      }
    });

    eventsInView.sort((a, b) => a.start - b.start);

    return eventsInView;
  }

  /**
   * keepScrollPosition
   * keeps the scroll position of the calendar
   * @returns void
   */
  private keepScrollPosition(): void {
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    fcScroller.scrollTop = this.scrollTopPosition;
  }

  /**
   * createEventDiv
   * creates the event div
   * @param event
   * @param time
   * @returns VNode<any>
   */
  private createEventDiv(event: EventImpl, time: string): VNode<any> {
    const calendarEventDate: CalendarEventDateModel =
      event.extendedProps['calendarEventDate'];

    let title = event.title;

    if (calendarEventDate.isCanceled) {
      title = 'Abgesagt - ' + title;
    }

    // get view
    const view = this.calendar.view.type;
    if (view === 'dayGridMonth') {
      return createElement(
        'div',
        {
          class: 'fc-custom-event-month',
          id: 'event',
          style:
            'background-color: ' +
            event.backgroundColor +
            ';' +
            'border-color: ' +
            event.borderColor +
            ';' +
            'color: ' +
            event.textColor +
            ';',
        },
        [title]
      );
    }

    let titleDiv = createElement(
      'div',
      { class: 'fc-custom-event-title' },
      title
    );

    let additionalInfoDiv = null;
    if (calendarEventDate.eventDateType === EventDateType.PATIENT_SESSION) {
      additionalInfoDiv = createElement(
        'div',
        { class: 'fc-custom-event-title' },
        calendarEventDate.patientChiffre
      );
    }

    const roomDiv = createElement(
      'div',
      { class: 'fc-custom-event-room' },
      'Raum: ' + getCalendarEventRoomName(calendarEventDate)
    );

    let titleInfoRoomDiv = createElement(
      'div',
      { class: 'fc-custom-event-title-room' },
      [titleDiv, additionalInfoDiv, roomDiv]
    );

    const timeDiv = createElement('div', { class: 'fc-custom-event-time' }, [
      time,
    ]);

    let eventWrapperDiv = createElement(
      'div',
      { class: 'fc-custom-event', id: 'event' },
      [titleInfoRoomDiv, timeDiv]
    );

    return eventWrapperDiv;
  }

  /**
   * Calculate the margin left for the now indicator line
   * @returns void
   */
  private calculateMarginLeftForNowIndicatorLine(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const now = new Date();

    const diffInDays = moment(now).diff(viewStart, 'days', false);
    const offsetToNowIndicatorArrow = -diffInDays - 6;
    const marginLeft = diffInDays * -100 + offsetToNowIndicatorArrow;

    const nowIndicatorLine = document.getElementsByClassName(
      'fc-timegrid-now-indicator-line'
    )[0] as HTMLElement;

    if (!nowIndicatorLine) return;

    nowIndicatorLine.style.marginLeft = marginLeft + '%';
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
