import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  CourseCreateModel,
  CourseEvent,
  CourseModel,
  CourseUpdateModel,
} from '../models/course.model';
import { eLog } from '../models/elog.model';
import { UserModel } from '../models/user.model';
import { CancellationService } from './cancellation.service';
import { FileService } from './file.service';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private roomService: RoomService,
    private fileService: FileService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllCourseTypes
   * @returns Observable<HttpResponse<any>>
   */
  public getAllCourseTypes(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/types`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses of an institute
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl + `/api/institutes/${this.instituteId}/courses`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses of the current lecturer
   * @returns Observable<HttpResponse<any>>
   */
  public getCurrentLecturersCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/lecturer`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses for the current student
   * @returns Observable<HttpResponse<any>>
   */
  public getCurrentStudentsCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/student`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses the student is registered to
   * @param userId
   * @returns Observable<HttpResponse<any>>
   */
  public getStudentsCourses(userId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/student/${userId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses the lecturer is assigned to
   * @param userId
   * @returns Observable<HttpResponse<any>>
   */
  public getLecturersCourses(userId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/lecturer/${userId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNumberOfStudentsCourses
   * get the number of courses of a student
   * @returns
   */
  public getNumberOfStudentsCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/count/student`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNumberOfLecturersCourses
   * get the number of courses of a lecturer
   * @returns
   */
  public getNumberOfLecturersCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/count/lecturer`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNumberOfInsitutesCourses
   * get the number of courses of an institute
   * @returns Observable<HttpResponse<any>>
   */
  public getNumberOfInsitutesCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/count`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseById
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public getCourseById(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseDetails
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public getCourseDetails(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/details`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseLecturers
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public getCourseLecturers(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/lecturers`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseStudents
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public getCourseStudents(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/students`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseEventDates
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public getCourseEventDates(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/event-dates`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /* returns all unique types from all courses */
  public filterUniqueCourseTypes(courses: CourseModel[]) {
    const uniqueTypes = [
      ...new Map(
        courses?.map(course => [course.courseType.id, course.courseType])
      ).values(),
    ];
    return uniqueTypes;
  }

  /* returns all unique lecturers */
  public getAllCourseLecturer(courses: CourseModel[]) {
    const uniqueLecturers = [
      ...new Map(
        courses?.flatMap(course =>
          course.lecturers.map(lecturer => [lecturer.id, lecturer])
        )
      ).values(),
    ];
    return uniqueLecturers;
  }

  /**
   * getAllCourseRooms
   * @param courses - Courses to get rooms from
   * @returns all unique rooms from the given courses
   */
  public getAllCourseRooms(courses: CourseModel[]) {
    const uniqueRooms = [
      ...new Map(
        courses
          ?.filter(course => course.room)
          .map(course => [course.room?.id, course.room])
      ).values(),
    ];
    return uniqueRooms;
  }

  /**
   * registerToCourse
   * @description registering the current student to a course
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public registerToCourse(courseId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/courses/${courseId}/register`,
      null,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * deregisterFromCourse
   * @description deregistering a student from a course
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public deregisterFromCourse(courseId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/courses/${courseId}/unregister`,
      null,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * createCourse
   * @description create a new course
   * @param courseCreateModel
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createCourse(
    courseCreateModel: CourseCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl + `/api/institutes/${this.instituteId}/courses`,
      courseCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /* Update an existing course */
  public async updateCourse(
    courseId: number,
    updatedCourseData: CourseUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/courses/${courseId}`,
      updatedCourseData,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * deleteCourse
   * @param courseId
   * @returns Observable<HttpResponse<any>>
   */
  public deleteCourse(courseId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/courses/${courseId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * getCourseFiles
   * @param courseId
   * @returns HttpResponse<any>
   */
  public getAllCourseFiles(courseId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/files`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseFiles
   * get file from backend
   * @param courseId
   * @param fileId
   * @returns HttpResponse<any>
   */
  public getCourseFileByUd(
    courseId: number,
    fileId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/files/${fileId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param courseId
   * @param fileId
   * @returns void
   */
  public openFile(courseId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/courses/${courseId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param courseId
   * @param fileId
   * @returns void
   */
  public downloadFile(courseId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/courses/${courseId}/files/${fileId}`
    );
  }

  /**
   * deleteCourseFileById
   * delete file from backend
   * @param courseId
   * @param fileId
   * @returns HttpResponse<any>
   */
  public deleteCourseFileById(
    courseId: number,
    fileId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/courses/${courseId}/files/${fileId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * enableOrDisableCourseLogon
   * @description enable or disable course logon
   * @param courseId
   * @param logonEnabled
   * @returns Observable<HttpResponse<any>>
   */
  public enableOrDisableCourseLogon(
    courseId: number,
    logonEnabled: boolean
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/courses/${courseId}`,
      { logonEnabled: logonEnabled },
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parseBackendCourse
   * @param courseData
   * @returns Promise<Course>
   */
  public async parseBackendCourse(
    courseData: CourseModel
  ): Promise<CourseModel> {
    let titlePicture: string;
    if (courseData.defaultTitlePicture) {
      switch (courseData.defaultTitlePicture) {
        case 1:
          titlePicture = '/assets/img/course-img-1.jpg';
          break;
        case 2:
          titlePicture = '/assets/img/course-img-2.jpg';
          break;
        case 3:
          titlePicture = '/assets/img/course-img-3.jpg';
          break;
      }
    }
    const course: CourseModel = {
      id: courseData.id,
      title: courseData.title,
      description: courseData.description,
      educationCourses: courseData.educationCourses,
      mandatory: Boolean(courseData.mandatory),
      titlePicture: courseData.titlePicture
        ? courseData.titlePicture
        : titlePicture,
      defaultTitlePicture: courseData.defaultTitlePicture,
      courseType: courseData.courseType,
      duration: courseData.duration,
      maxParticipants: courseData.maxParticipants,
      room: courseData.room
        ? await this.roomService.parseBackendRoom(courseData.room)
        : null,
      differentRooms: Boolean(courseData.differentRooms),
      lecturers: courseData.lecturers
        ? await Promise.all(
            courseData.lecturers?.map(
              async (userData: UserModel): Promise<UserModel> => {
                return await this.userService.parseBackendUser(userData);
              }
            )
          )
        : null,
      logonEnabled: Boolean(courseData.logonEnabled),
      closed: Boolean(courseData.closed),
      startDate: moment(courseData.startDate).tz('Europe/Berlin').toDate(),
      endDate: moment(courseData.endDate).tz('Europe/Berlin').toDate(),
      eventsStartDate:
        courseData.eventsStartDate &&
        moment(courseData.eventsStartDate).tz('Europe/Berlin').toDate(),
      eventsEndDate:
        courseData.eventsEndDate &&
        moment(courseData.eventsEndDate).tz('Europe/Berlin').toDate(),
      allDayEvent: Boolean(courseData.allDayEvent),
      recurrencePattern: courseData.recurrencePattern,
      learningContents: courseData.learningContents,
      examTypes: courseData.examTypes,
      location: courseData.location,
      link: courseData.link,
      courseEventDates: courseData.courseEventDates
        ? await Promise.all(
            courseData.courseEventDates?.map(
              async (event: any): Promise<CourseEvent> => {
                return {
                  id: event.id,
                  startDate: moment(event.startDate)
                    .tz('Europe/Berlin')
                    .toDate(),
                  endDate: moment(event.endDate).tz('Europe/Berlin').toDate(),
                  room: event.room
                    ? await this.roomService.parseBackendRoom(event.room)
                    : null,
                  elogStatusCounts: event.eLogStatusCounts ?? null,
                  eLogs: event.eLogs
                    ? await Promise.all(
                        event.eLogs?.map(async (elog: any): Promise<eLog> => {
                          return {
                            id: elog.id,
                            user: await this.userService.parseBackendUser(
                              elog.student
                            ),
                            status: elog.eLogStatus,
                          };
                        })
                      )
                    : [],
                };
              }
            )
          )
        : [],
      isRegistered: Boolean(courseData.isRegistered),
      registeredStudents: courseData.registeredStudents,
      students: courseData.students
        ? await Promise.all(
            courseData.students?.map(
              async (userData: UserModel): Promise<UserModel> => {
                return await this.userService.parseBackendUser(userData);
              }
            )
          )
        : null,
      elogStatusCounts: courseData.elogStatusCounts,
      internalNotes: courseData.internalNotes,
      files: await this.fileService.parseBackendFiles(courseData.files),
    };
    return course;
  }
}
