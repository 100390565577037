import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../models/user.model';

/**
 * getFullName
 * returns the full name with academicTitle of a user
 * @param user
 * @returns string
 */
export function getFullName(user: UserModel): string | null {
  if (!user || !user.name) {
    return null;
  }
  let fullName = '';
  if (user.name.academicTitle) {
    fullName += user.name.academicTitle + ' ';
  }
  if (user.name.firstName) {
    fullName += user.name.firstName + ' ';
  }
  if (user.name.lastName) {
    fullName += user.name.lastName;
  }
  if (fullName.trim() === '') {
    return null;
  }
  return fullName.trim();
}

/**
 * getFullNames
 * returns the full names of a list of users
 * @param users
 * @returns string
 */
export function getFullNames(users: UserModel[]): string {
  return users
    .map((user, index) => {
      return getFullName(user) + (index < users.length - 1 ? ', ' : '');
    })
    .join('');
}

/**
 * prettyPrintIban
 * pretty prints an iban with whitespaces
 * @param input
 * @returns string
 */
export function prettyPrintIban(input: string): string {
  const whitespace = ' ';
  const interval = 4;

  if (!input) {
    return input;
  }

  let result = '';
  let i = 0;
  while (i < input.length) {
    if (i + interval < input.length) {
      result += input.substring(i, i + interval) + whitespace;
    } else {
      result += input.substring(i);
    }
    i += interval;
  }

  return result;
}

/**
 * maskIban
 * mask iban with asterisks
 * @param input
 * @returns
 */
export function maskIban(input: string): string {
  const replacement = '*';
  const start = 4;
  const end = 20;

  if (!input) {
    return input;
  }

  if (start >= end || start < 0 || end > input.length) {
    return input; // Handle invalid input
  }

  const prefix = input.substring(0, start);
  const suffix = input.substring(end);
  const asterisks = replacement.repeat(end - start);

  return prefix + asterisks + suffix;
}

/**
 * getAllAdditionalQualifications
 * returns all additional qualifications of a user as a string
 * @param user
 * @returns string
 */
export function getAllAdditionalQualifications(user: UserModel): string | null {
  if (
    !user ||
    !user.additionalQualifications ||
    user.additionalQualifications?.length === 0
  ) {
    return null;
  }

  return user.additionalQualifications.map(aq => aq.name).join(', ');
}

/**
 * getAllProcedures
 * returns all procedures of a user as a string
 * @param user
 * @returns string
 */
export function getAllProcedures(user: UserModel): string | null {
  if (!user || !user.procedures || user.procedures?.length === 0) {
    return null;
  }

  return user.procedures.map(p => p.name).join(', ');
}

/**
 * getAllExpertises
 * returns all expertises of a user as a string
 * @param user
 * @returns string
 */
export function getAllExpertises(user: UserModel): string | null {
  if (!user || !user.expertises || user.expertises?.length === 0) {
    return null;
  }

  return user.expertises.map(e => e.name).join(', ');
}

/**
 * gets the user id from the route params
 * @param route
 * @returns the user id from the route params or null
 */
export function getUserIdFromParams(route: ActivatedRoute): number | null {
  const encodedUserId = route.snapshot?.params?.userId;
  return encodedUserId ? +atob(encodedUserId) : null;
}
