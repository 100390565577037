import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  PatientAppointmentCreateModel,
  PatientAppointmentModel,
  PatientAppointmentUpdateModel,
} from '../models/patient-appointment.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PatientAppointmentService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private roomService: RoomService
  ) {}

  /**
   * Get a patient appointment by id
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @returns Observable<HttpResponse<any>>
   */
  public getPatientAppointmentById(
    treatmentCaseId: number,
    patientAppointmentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments/${patientAppointmentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Create a patient appointment
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentCreateModel The patient appointment create model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createPatientAppointment(
    treatmentCaseId: number,
    patientAppointmentCreateModel: PatientAppointmentCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    patientAppointmentCreateModel.videoMeetingLink =
      patientAppointmentCreateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        patientAppointmentCreateModel.videoMeetingLink
      ));

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments`,
      patientAppointmentCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Update a patient appointment
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @param patientAppointmentUpdateModel The patient appointment update model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updatePatientAppointment(
    treatmentCaseId: number,
    patientAppointmentId: number,
    patientAppointmentUpdateModel: PatientAppointmentUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    patientAppointmentUpdateModel.videoMeetingLink =
      patientAppointmentUpdateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        patientAppointmentUpdateModel.videoMeetingLink
      ));

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments/${patientAppointmentId}`,
      patientAppointmentUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Cancel a patient appointment event date
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @returns Observable<HttpResponse<any>>
   */
  public cancelPatientAppointmentEventDate(
    treatmentCaseId: number,
    appointmentId: number,
    eventDateId: number
  ) {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments/${appointmentId}/event-dates/${eventDateId}/cancel`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Confirm a patient appointment event date
   * @param treatmentCaseId The treatment case id
   * @param appointmentId The appointment id
   * @param eventDateId The event date id
   * @returns Observable<any>
   */
  public confirmPatientAppointmentEventDate(
    treatmentCaseId: number,
    appointmentId: number,
    eventDateId: number
  ): Observable<any> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments/${appointmentId}/event-dates/${eventDateId}/confirm`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Delete a patient appointment event date
   * @param treatmentCaseId The treatment case id
   * @param appointmentId The appointment id
   * @param eventDateId The event date id
   * @returns Observable<HttpResponse<any>>
   */
  public deletePatientAppointmentEventDate(
    treatmentCaseId: number,
    appointmentId: number,
    eventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/patient-appointments/${appointmentId}/event-dates/${eventDateId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parses the backend patient appointment
   * @param patientAppointment the patient appointment from the backend
   * @returns PatientAppointmentModel
   */
  public async parseBackendPatientAppointment(
    patientAppointment: PatientAppointmentModel
  ): Promise<PatientAppointmentModel> {
    const patientAppointmentModel: PatientAppointmentModel = {
      id: patientAppointment.id,
      treatmentCase: {
        id: patientAppointment.treatmentCase.id,
        patientChiffre: await this.decryptionService.decryptString(
          patientAppointment.treatmentCase.patientChiffre
        ),
      },
      accompanyingPersons: patientAppointment.accompanyingPersons,
      durationInTimeUnits: patientAppointment.durationInTimeUnits,
      eventDates: patientAppointment.eventDates
        ? await Promise.all(
            patientAppointment.eventDates.map(async eventDate => {
              const room = eventDate.room
                ? await this.roomService.parseBackendRoom(eventDate.room)
                : null;
              return {
                id: eventDate.id,
                startDate: moment(eventDate.startDate)
                  .tz('Europe/Berlin')
                  .toDate(),
                endDate: moment(eventDate.endDate).tz('Europe/Berlin').toDate(),
                room: room,
              };
            })
          )
        : [],
      location: patientAppointment.location,
      videoMeetingLink: await this.decryptionService.decryptString(
        patientAppointment.videoMeetingLink
      ),
      recurrencePattern: patientAppointment.recurrencePattern,
    };

    return patientAppointmentModel;
  }
}
