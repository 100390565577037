import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EducationCourse } from 'src/app/models/education-course.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-education-courses',
  templateUrl: './education-courses.component.html',
  styleUrl: './education-courses.component.scss',
})
export class EducationCoursesComponent {
  public displayedColumns: string[] = [
    'title',
    'accreditation',
    'regulation',
    'expertise',
    'procedure',
    'actions',
  ];

  public dataSource: MatTableDataSource<EducationCourse> =
    new MatTableDataSource<EducationCourse>();
  public isLoading = true;
  public searchForm = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator),
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private educationCourseService: EducationCourseService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the education courses of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'education':
          return item.title;
        case 'accreditation':
          return item.accreditationYear;
        case 'regulation':
          return item.regulation;
        case 'expertise':
          return item.expertise?.name;
        case 'procedure':
          return item.procedure?.name;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getEducationCourses();
  }

  /**
   * retrieves all education courses of the current institute and initializes the table data
   * @returns Promise<void>
   */
  private async getEducationCourses(): Promise<void> {
    this.educationCourseService
      .getInstituteEducationCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const educationCourses: EducationCourse[] = response.body
            ? await Promise.all(
                response.body.map(
                  async (
                    educationCourseData: EducationCourse
                  ): Promise<EducationCourse> =>
                    this.educationCourseService.parseBackendEducationCourse(
                      educationCourseData
                    )
                )
              )
            : [];
          this.dataSource.data = educationCourses;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Bildungsgänge konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onOpen
   * navigate to education course details page
   * @param educationCourse the educationCourse
   * @returns void
   */
  public onOpen(educationCourse: EducationCourse): void {
    this.router.navigate([btoa(String(educationCourse.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreate
   * navigate to create education course page
   * @returns void
   */
  public onCreate(): void {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEdit
   * navigate to edit education course
   * @param educationCourse the educationCourse
   * @returns void
   */
  public onEdit(educationCourse: EducationCourse): void {
    this.router.navigate(['edit', btoa(String(educationCourse.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDelete
   * delete education course
   * @param educationCourse the educationCourse
   * @returns void
   */
  public onDelete(educationCourse: EducationCourse): void {
    // open confirm dialog
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Bildungsgang löschen',
        message: `Möchten Sie den Bildungsgang '${educationCourse.title}' wirklich löschen? Der Bildungsgang kann nicht wiederhergestellt werden.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.educationCourseService
            .deleteEducationCourse(educationCourse.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Bildungsgang wurde gelöscht!`
                );
                this.dataSource.data = this.dataSource.data.filter(
                  ec => ec.id !== educationCourse.id
                );
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  'Der Bildungsgang konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.'
                );
              },
            });
        }
      });
  }

  /**
   * applySearch
   * apply search filter
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
