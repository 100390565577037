import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CryptoRestService, CryptoService } from '@healthycloud/lib-ngx-crypto';
import { firstValueFrom } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { AuthService } from '../services/auth.service';
import { KeyManagementService } from '../services/key-management.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class CryptoKeyResolver implements Resolve<boolean> {
  constructor(
    private cryptoService: CryptoService,
    private cryptoRestService: CryptoRestService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private userService: UserService,
    private authService: AuthService,
    private keyManagementService: KeyManagementService
  ) {
    this.cryptoRestService.backendUrl.set(this.config.backendUrl);
  }

  async resolve(): Promise<boolean> {
    if (!this.userService.currentUser) {
      await this.loginUser();
    }

    // check if the user has the necessary data to fetch the key material
    if (
      !this.userService.currentUser.currentInstituteId ||
      !this.userService.currentUser.confirmingAdminIdpId
    ) {
      return false;
    }

    if (this.keyManagementService.keysInitialized) {
      return true;
    }

    const tenantId = this.userService.currentUser.currentInstituteId;
    const confirmingAdminIdpId =
      this.userService.currentUser.confirmingAdminIdpId;

    // Convert Observable to Promise and set the keysInitialized flag
    const importKeysResponse = firstValueFrom(
      this.cryptoService.fetchAndImportKeyMaterial(
        tenantId,
        confirmingAdminIdpId
      )
    );

    this.keyManagementService.keysInitialized = await importKeysResponse;

    return importKeysResponse;
  }

  /**
   * Logs the user in and sets the current user in the user service
   * @returns Promise<void>
   */
  private async loginUser(): Promise<void> {
    try {
      // check if the user is logged in via TGP vor XSRF Token bug
      await firstValueFrom(this.authService.isLoggedIn());

      // Convert Observable to Promise and await the response
      const response = await firstValueFrom(this.authService.loginUser());

      // Process the response
      this.userService.setEncryptedCurrentUser(response.body);
    } catch (error) {
      // Handle any errors that occur during the login process
    }
  }
}
