import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CourseModel } from 'src/app/models/course.model';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseDateRange,
  getCourseEndDate,
  getCourseLogoffTooltip,
  getCourseLogonTooltip,
  getCourseRoom,
  getCourseStartDate,
  getEducationCourseTitles,
  isCourseLogoffDisabled,
  isCourseLogonDisabled,
} from 'src/app/utils/course.utils';
import { getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrl: './course-details.component.scss',
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  @Input() public course: CourseModel;
  public navLinks: NavLink[];
  public isLoading = true;
  public isStudent = this.userService.currentUserIsStudent();
  public isMobile = false;
  public isTablet = false;

  public Feature = Feature;
  public Permission = Permission;

  // Helper functions from utils
  public getFullNames = getFullNames;
  public getEducationCourseTitles = getEducationCourseTitles;
  public getCourseRoom = getCourseRoom;
  public getCourseStartDate = getCourseStartDate;
  public getCourseEndDate = getCourseEndDate;
  public getCourseDateRange = getCourseDateRange;
  public getCourseLogonTooltip = getCourseLogonTooltip;
  public getCourseLogoffTooltip = getCourseLogoffTooltip;
  public isCourseLogonDisabled = isCourseLogonDisabled;
  public isCourseLogoffDisabled = isCourseLogoffDisabled;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService
  ) {
    this.navLinks = [
      {
        label: 'Termine und Anwesenheiten',
        link: 'events',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Beschreibung',
        link: 'description',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Lehrpersonal',
        link: 'lecturers',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Teilnehmende',
        link: 'participants',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Dokumente',
        link: 'documents',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
    ];
  }

  public ngOnInit(): void {
    const courseId = +atob(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getData(courseId);

    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';
      });
  }

  /**
   * get course data by id
   * @param courseId
   * @returns void
   */
  private getData(courseId: number): void {
    this.courseService
      .getCourseById(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.course = await this.courseService.parseBackendCourse(
            response.body
          );
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kursdetails konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * navigate back to overview page from navigation service
   * if no overview url is set, navigate to parent route
   * @returns void
   */
  public onBackToOverview(): void {
    this.navigationService.getOverviewUrl()
      ? this.router.navigateByUrl(this.navigationService.getOverviewUrl())
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * navigate to edit course
   * @returns void
   */
  public onEditCourse(): void {
    this.router.navigate(['../edit/', btoa(this.course.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * either register or deregister from course
   * @param course
   * @returns void
   */
  public onCourseStudentChange(course: CourseModel): void {
    const dialogTitle = course.isRegistered ? 'Abmelden' : 'Anmelden';
    const dialogMessage = course.isRegistered
      ? `Möchten Sie sich wirklich vom Kurs '${course.title}' abmelden?`
      : `Möchten Sie sich wirklich am Kurs '${course.title}' anmelden?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: dialogTitle,
        message: dialogMessage,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (!dialogResult) {
          return;
        }
        if (course.isRegistered) {
          this.deregisterFromCourse(course);
        } else {
          this.registerToCourse(course);
        }
      });
  }

  /**
   * register the current user to the course and reload the course data
   * @param course
   * @returns void
   */
  private registerToCourse(course: CourseModel): void {
    this.courseService
      .registerToCourse(course.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Sie haben sich am Kurs '${course.title}' angemeldet!`
          );
          this.getData(course.id);
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        },
      });
  }

  /**
   * deregister the current user from the course and reload the course data
   * @param course
   * @returns void
   */
  private deregisterFromCourse(course: CourseModel): void {
    this.courseService
      .deregisterFromCourse(course.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Sie haben sich vom Kurs '${course.title}' abgemeldet!`
          );
          this.getData(course.id);
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        },
      });
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
