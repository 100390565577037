import { KeyValue } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { TherapyForm } from 'src/app/enums/therapy-form.enum';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { AccompanyingPerson } from 'src/app/models/accompanying-person.model';
import { FileModel } from 'src/app/models/file.model';
import { ProcedureModel } from 'src/app/models/procedure.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { TherapyPhase } from 'src/app/models/therapy-phase.model';
import {
  TreatmentCaseCreateModel,
  TreatmentCaseModel,
  TreatmentCaseUpdateModel,
} from 'src/app/models/treatment-case.model';
import {
  AdditionalQualificationEnum,
  UserModel,
} from 'src/app/models/user.model';
import { AccompanyingPersonService } from 'src/app/services/accompanying-person.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { TherapyPhaseService } from 'src/app/services/therapy-phase.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';
import { chiffreValidator } from 'src/app/validators/chiffre.validator';

@Component({
  selector: 'app-create-edit-treatment-case',
  templateUrl: './create-edit-treatment-case.component.html',
  styleUrls: ['./create-edit-treatment-case.component.scss'],
})
export class CreateEditTreatmentCaseComponent implements OnInit, OnDestroy {
  public currentTreatmentCase?: TreatmentCaseModel;
  public treatmentCaseForm: FormGroup;
  public supervisors: UserModel[];
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public initialFormValues: {};
  public availableTherapyPhases: SelectOption[] = [];
  public availableTreatmentProcedures: SelectOption[] = [];
  public availableAccompanyingPersons: SelectOption[] = [];
  public availableSupervisors: UserModel[] = [];
  public TherapyForm = TherapyForm;
  public selectedTherapyType: string;
  public selectedDuration = '45';
  public isLoading = true;
  public editMode = false;

  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.treatmentCaseForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private userService: UserService,
    private procedureService: ProcedureService,
    private accompaningPersonService: AccompanyingPersonService,
    private therapyPhaseService: TherapyPhaseService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public ngOnInit() {
    this.createForm();
    this.initAvailableTherapyPhases();
    this.initAvailableTreatmentProcedures();
    this.initAvailableSupervisors();
    this.initAvailableAccompanyingPersons();

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const treatmentCaseId = +atob(this.activatedRoute.snapshot.params['id']);
      this.initCurrentTreatmentCase(treatmentCaseId);
    }

    if (!this.editMode) {
      this.isLoading = false;
      this.initialFormValues = this.treatmentCaseForm.value;
    }

    // subscribe to uploadedFiles changes and convert them to base64
    this.treatmentCaseForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.treatmentCaseForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.treatmentCaseForm.get('documents'),
          false,
          true
        );
      });
  }

  /**
   * create the treatment case form
   * @returns void
   */
  private createForm(): void {
    this.treatmentCaseForm = this.formBuilder.group({
      patientChiffre: new FormControl('', [
        Validators.required,
        chiffreValidator(),
        Validators.maxLength(100),
      ]),
      patientAge: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(120),
      ]),
      therapyForm: new FormControl('', Validators.required),
      therapyPhaseId: new FormControl(null, Validators.required),
      treatmentProceduresIds: new FormControl(null, Validators.required),
      supervisorSearch: new FormControl(null),
      supervisorIds: new FormControl([], Validators.required),
      accompanyingPersonsIds: this.formBuilder.array([new FormControl(null)]),
      description: new FormControl(null, Validators.maxLength(6000)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * Returns the therapy phase form control.
   * @returns The therapy phase form control.
   */
  get therpapyPhaseControl(): FormControl {
    return this.treatmentCaseForm.get('therapyPhaseId') as FormControl;
  }

  /**
   * Returns the treatment procedures form control.
   * @returns The treatment procedures form control.
   */
  get treatmentProceduresControl(): FormControl {
    return this.treatmentCaseForm.get('treatmentProceduresIds') as FormControl;
  }

  /**
   * initialize available therapy phases
   * @returns void
   */
  private initAvailableTherapyPhases(): void {
    this.therapyPhaseService
      .getAllTherapyPhases()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.availableTherapyPhases = response.body.map(
            (therapyPhase: TherapyPhase): SelectOption => {
              return {
                value: therapyPhase.id,
                label: therapyPhase.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die verfügbaren Arten der Therapie konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * initialize available treatment procedures
   * @returns void
   */
  private initAvailableTreatmentProcedures(): void {
    this.procedureService
      .getAllProcedures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.availableTreatmentProcedures = response.body.map(
            (treatmentProcedure: ProcedureModel): SelectOption => {
              return {
                value: treatmentProcedure.id,
                label: treatmentProcedure.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Behandlungsverfahren konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * initialize available supervisors
   * @returns void
   */
  private initAvailableSupervisors(): void {
    this.userService
      .getInstituteUsersByAdditionalQualification(
        AdditionalQualificationEnum.SUPERVISOR
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.availableSupervisors = response.body
            ? await Promise.all(
                response.body.map(
                  async (userData: any) =>
                    await this.userService.parseBackendUser(userData)
                )
              )
            : [];
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervisoren konnten nicht geladen werden.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * initialize available accompanying persons
   * @returns void
   */
  private initAvailableAccompanyingPersons(): void {
    this.accompaningPersonService
      .getAllAccompanyingPersons()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          if (!response.body) return;

          this.availableAccompanyingPersons = response.body.map(
            (accompanyingPerson: AccompanyingPerson): SelectOption => {
              return {
                value: accompanyingPerson.id,
                label: accompanyingPerson.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Begleitpersonen konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * initialize current treatment case
   * @param treatmentCaseId id of the treatment case
   * @returns void
   */
  private initCurrentTreatmentCase(treatmentCaseId: number): void {
    this.treatmentCaseService
      .getTreatmentCaseById(treatmentCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentTreatmentCase =
            await this.treatmentCaseService.parseBackendTreatmentCase(
              response.body
            );

          this.currentTreatmentCase.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.treatmentCaseForm.patchValue({
            patientChiffre: this.currentTreatmentCase.patientChiffre,
            patientAge: this.currentTreatmentCase.patientAge,
            therapyForm: this.currentTreatmentCase.therapyForm,
            therapyPhaseId: this.currentTreatmentCase.therapyPhase.id,
            treatmentProceduresIds:
              this.currentTreatmentCase.treatmentProcedures.map(it => it.id),
            supervisorIds: this.currentTreatmentCase.supervisors.map(
              it => it.id
            ),
            description: this.currentTreatmentCase.description,
          });

          this.treatmentCaseForm.setControl(
            'accompanyingPersonsIds',
            this.formBuilder.array(
              this.currentTreatmentCase.accompanyingPersons.map(
                it => new FormControl(it.id)
              )
            )
          );

          // if no accompanying persons are selected, add an empty form control
          if (this.currentTreatmentCase.accompanyingPersons.length === 0) {
            this.accompanyingPersonsFormArray.push(new FormControl(null));
          }

          this.isLoading = false;
          this.initialFormValues = this.treatmentCaseForm.value;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Behandlungsfall konnte nicht geladen werden.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * getter for accompanyingPersons form array
   * @returns FormArray
   */
  get accompanyingPersonsFormArray(): FormArray {
    return this.treatmentCaseForm.get('accompanyingPersonsIds') as FormArray;
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the treatment case is created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.treatmentCaseForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.treatmentCaseForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateTreatmentCase() : this.createTreatmentCase();
  }

  /**
   * create a new treatment case
   * @returns Promise<void>
   */
  private async createTreatmentCase(): Promise<void> {
    const files: FileModel[] = this.treatmentCaseForm.value.documents
      ? this.existingFiles.concat(this.treatmentCaseForm.value.documents)
      : this.existingFiles;

    const treatmentCaseCreateModel: TreatmentCaseCreateModel = {
      patientChiffre: this.treatmentCaseForm.get('patientChiffre').value,
      patientAge: this.treatmentCaseForm.get('patientAge').value,
      therapyForm: this.treatmentCaseForm.get('therapyForm').value,
      therapyPhaseId: this.treatmentCaseForm.get('therapyPhaseId').value,
      treatmentProceduresIds: this.treatmentCaseForm.get(
        'treatmentProceduresIds'
      ).value,
      supervisorIds: this.treatmentCaseForm.get('supervisorIds').value,
      accompanyingPersonsIds: this.treatmentCaseForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      description: this.treatmentCaseForm.get('description').value ?? null,
      files: files,
    };

    const createTreatmentCaseObservable =
      await this.treatmentCaseService.createTreatmentCase(
        treatmentCaseCreateModel
      );

    createTreatmentCaseObservable.pipe(first()).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Behandlungsfall wurde erstellt.'
        );
        this.initialFormValues = this.treatmentCaseForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Behandlungsfall konnte nicht erstellt werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * update the current treatment case
   * @returns Promise<void>
   */
  private async updateTreatmentCase(): Promise<void> {
    const files: FileModel[] = this.treatmentCaseForm.value.documents
      ? this.existingFiles.concat(this.treatmentCaseForm.value.documents)
      : this.existingFiles;

    const treatmentCaseId = this.currentTreatmentCase?.id;
    const treatmentCaseUpdateModel: TreatmentCaseUpdateModel = {
      patientChiffre: this.treatmentCaseForm.get('patientChiffre').value,
      patientAge: this.treatmentCaseForm.get('patientAge').value,
      therapyForm: this.treatmentCaseForm.get('therapyForm').value,
      therapyPhaseId: this.treatmentCaseForm.get('therapyPhaseId').value,
      treatmentProceduresIds: this.treatmentCaseForm.get(
        'treatmentProceduresIds'
      ).value,
      supervisorIds: this.treatmentCaseForm.get('supervisorIds').value,
      accompanyingPersonsIds: this.treatmentCaseForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      description: this.treatmentCaseForm.get('description').value ?? null,
      files: files,
    };

    const updateTreatmentCaseObservable =
      await this.treatmentCaseService.updateTreatmentCase(
        treatmentCaseId,
        treatmentCaseUpdateModel
      );

    updateTreatmentCaseObservable.pipe(first()).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Behandlungsfall '${this.treatmentCaseForm.value.patientChiffre}' wurde aktualisiert.`
        );
        this.initialFormValues = this.treatmentCaseForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Behandlungsfall konnte nicht aktualisiert werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * set TherapyForm
   * @param therapyForm
   * @returns void
   */
  public setTherapyForm(therapyForm: TherapyForm): void {
    this.treatmentCaseForm.get('therapyForm').setValue(therapyForm);
  }

  /**
   * adds or removes the clicked supervisor to the selected supervisors control
   * @param supervisor
   * @returns void
   */
  public onSupervisorSelected(supervisor: UserModel): void {
    if (
      this.treatmentCaseForm.get('supervisorIds').value.includes(supervisor.id)
    ) {
      // remove supervisor
      this.treatmentCaseForm
        .get('supervisorIds')
        .setValue(
          this.treatmentCaseForm
            .get('supervisorIds')
            .value.filter((id: number) => id !== supervisor.id)
        );
    } else {
      // add supervisor
      this.treatmentCaseForm
        .get('supervisorIds')
        .setValue(
          this.treatmentCaseForm
            .get('supervisorIds')
            .value.concat(supervisor.id)
        );
    }
  }

  /**
   * on cancel
   * @returns void
   */
  public onCancel(): void {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.treatmentCaseForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalTherapyFormOrder = (
    a: KeyValue<string, TherapyForm>,
    b: KeyValue<string, TherapyForm>
  ): number => {
    return 0;
  };

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.treatmentCaseService.openFile(this.currentTreatmentCase?.id, file.id);
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.treatmentCaseService.downloadFile(
      this.currentTreatmentCase?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.treatmentCaseForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * ngOnDestroy
   * Cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
