<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kooperationspartner</h1>
      <h3 class="hasomed-text-h3">
        Sehen Sie sich den Kooperationspartner ein.
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.COOPERATION_PARTNER,
          permission: permission.ADMIN,
        }"
        [disabled]="isLoading"
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Name</span>
        <span class="label-description"> Name des Kooperationspartners. </span>
      </div>
      <div class="detail-value">
        {{ cooperationPartner?.name }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Ansprechpartner</span>
        <span class="label-description">
          Titel, Vor- und Nachname des Ansprechpartners.
        </span>
      </div>
      <div class="detail-value">
        {{ getFullContactPersonName(cooperationPartner) }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">E-Mail Adresse</span>
          <span class="label-description">
            E-Mail als Kontaktmöglichkeit.
          </span>
        </div>
        <div class="detail-value">
          <i class="fa-light fa-envelope"></i>
          <span>
            {{ cooperationPartner?.email ?? 'Nicht hinterlegt.' }}
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- phone number -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Telefonnummer (Festnetz) </span>
          <span class="label-description">
            Festnetznummer als Kontaktmöglichkeit.
          </span>
        </div>
        <div class="detail-value">
          <i class="fa-light fa-phone"></i>
          <span>
            {{ cooperationPartner?.phone ?? 'Nicht hinterlegt.' }}
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- mobile number -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Telefonnummer (Mobile) </span>
          <span class="label-description">
            Mobilfunknummer als Kontaktmöglichkeit.
          </span>
        </div>
        <div class="detail-value">
          <i class="fa-light fa-mobile-screen-button"></i>
          <span>
            {{ cooperationPartner?.mobile ?? 'Nicht hinterlegt.' }}
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          Anschrift des Kooperationspartners.
        </span>
      </div>
      <div class="flex-c">
        <span
          *ngIf="
            !cooperationPartner?.address?.street &&
            !cooperationPartner?.address?.houseNumber &&
            !cooperationPartner?.address?.addressAddition &&
            !cooperationPartner?.address?.zipCode &&
            !cooperationPartner?.address?.city &&
            !cooperationPartner?.address?.country
          "
          class="detail-value">
          Nicht hinterlegt.
        </span>
        <span
          *ngIf="
            cooperationPartner?.address?.street ||
            cooperationPartner?.address?.houseNumber
          "
          class="detail-value">
          {{ cooperationPartner?.address?.street }}
          {{ cooperationPartner?.address?.houseNumber }}
        </span>
        <span
          *ngIf="cooperationPartner?.address?.addressAddition"
          class="detail-value">
          {{ cooperationPartner?.address?.addressAddition }}
        </span>
        <span
          *ngIf="
            cooperationPartner?.address?.zipCode ||
            cooperationPartner?.address?.city
          "
          class="detail-value">
          {{ cooperationPartner?.address?.zipCode }}
          {{ cooperationPartner?.address?.city }}
        </span>
        <span *ngIf="cooperationPartner?.address?.country" class="detail-value">
          {{ cooperationPartner?.address?.country }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- expertise -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Fachkunde</span>
        <span class="label-description">
          Fachkunde des Kooperationspartners.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllExpertises(cooperationPartner) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- supplySector -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Versorgungsbereich</span>
        <span class="label-description">
          Versorgungsbereich des Kooperationspartners.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ cooperationPartner?.supplySector?.name ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- procedure -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Richtlinienverfahren </span>
        <span class="label-description">
          Richtlinienverfahren des Kooperationspartners.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllProcedures(cooperationPartner) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }"></mat-divider>

    <!-- cooperationAgreement -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Kooperationsvertrag</span>
          <span class="label-description">
            Kooperationsvertrag liegt vor.
          </span>
        </div>
        <div class="detail-value">
          <span *ngIf="!cooperationPartner?.cooperationAgreementAvailable"
            >Liegt nicht vor</span
          >
          <span *ngIf="cooperationPartner?.cooperationAgreementAvailable">
            Liegt vor seit dem:
            {{
              cooperationPartner?.cooperationAgreementDate | date: 'dd.MM.yyyy'
            }}
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- surveyForm -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Erhebungsbogen</span>
          <span class="label-description"> Erhebungsbogen liegt vor. </span>
        </div>
        <div class="detail-value">
          <span *ngIf="!cooperationPartner?.surveyFormAvailable"
            >Liegt nicht vor</span
          >
          <span *ngIf="cooperationPartner?.surveyFormAvailable">
            Liegt vor seit dem:
            {{ cooperationPartner?.surveyFormDate | date: 'dd.MM.yyyy' }}
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- isAdvancedTrainingApproved -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Weiterbildungsbefugt </span>
          <span class="label-description">
            Weiterbildungsbefugnis liegt vor.
          </span>
        </div>
        <div class="detail-value">
          <span *ngIf="!cooperationPartner?.isAdvancedTrainingApproved"
            >Liegt nicht vor</span
          >
          <span *ngIf="cooperationPartner?.isAdvancedTrainingApproved"
            >Liegt vor</span
          >
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- isAdvancedTrainingApproved -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Umfang der Weiterbildungsbefugnis </span>
          <span class="label-description">
            Gibt den Umfang der Weiterbildungsbefugnis an.
          </span>
        </div>
        <div class="detail-value">
          {{
            cooperationPartner?.scopeOfAdvancedTrainingApproval ??
              'Nicht hinterlegt.'
          }}
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- Document -->
    <ng-container
      *appCheckPermissions="{
        feature: feature.COOPERATION_PARTNER,
        permission: permission.ADMIN,
      }">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Dokumentenupload</span>
          <span class="label-description"> Alle relevanten Dokumente. </span>
        </div>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of cooperationPartner?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
          <span
            *ngIf="
              !cooperationPartner?.files ||
              cooperationPartner?.files?.length == 0
            "
            class="detail-value">
            Keine Dokumente hochgeladen.
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
