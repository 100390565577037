import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';

import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllAccompanyingPersons,
  getAllSupervisors,
  getAllTreatmentProcedures,
} from 'src/app/utils/treatment-case.utils';

@Component({
  selector: 'app-treatment-case-overview',
  templateUrl: './treatment-case-overview.component.html',
  styleUrls: ['./treatment-case-overview.component.scss'],
})
export class TreatmentCaseOverviewComponent implements OnInit, OnDestroy {
  public treatmentCase: TreatmentCaseModel;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getAllTreatmentProcedures = getAllTreatmentProcedures;
  public getAllSupervisors = getAllSupervisors;
  public getAllAccompanyingPersons = getAllAccompanyingPersons;

  constructor(
    private treatmentCaseService: TreatmentCaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public userService: UserService,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params.id) {
          return;
        }

        const treatmentCaseId = +atob(params.id);
        this.initTreatmentCase(treatmentCaseId);
      });
  }

  /**
   * initialize treatment case to view
   * @param treatmentCaseId id of the treatment case
   * @returns void
   */
  private initTreatmentCase(treatmentCaseId: number): void {
    this.treatmentCaseService
      .getTreatmentCaseById(treatmentCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.treatmentCase =
            await this.treatmentCaseService.parseBackendTreatmentCase(
              response.body
            );

          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Behandlungsfall konnte nicht geladen werden!'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * onEditTreatmentCase
   * open the edit treatment case page
   * @returns void
   */
  public onEditTreatmentCase() {
    if (this.treatmentCase) {
      this.router.navigate(
        ['../edit-treatment-case', btoa(String(this.treatmentCase.id))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * navigate back to the previous page
   * @returns void
   */
  public onGoBack(): void {
    this.location.back();
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.treatmentCaseService.openFile(this.treatmentCase.id, file.id);
  }

  /**
   * onDownloadFile
   * download the file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.treatmentCaseService.downloadFile(this.treatmentCase.id, file.id);
  }

  /**
   * ngOnDestroy
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
