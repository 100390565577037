import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import {
  EventDate,
  EventDateType,
  InstituteEvent,
} from 'src/app/models/event.model';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { FilterRoomOrganizationPipe } from 'src/app/pipes/filter-room-organization.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { DecryptionService } from 'src/app/services/decryption.service';
import { EventService } from 'src/app/services/event.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-room-organization',
  templateUrl: './room-organization.component.html',
  styleUrls: ['./room-organization.component.scss'],
})
export class RoomOrganizationComponent implements OnInit, OnDestroy {
  public instituteEvents: InstituteEvent[] = [];
  public searchForm: FormGroup;
  public showCourseRoomPlanning: boolean = false;
  public selectedEvent: InstituteEvent;
  public isLoading: boolean = true;
  public eventType = EventDateType;

  public displayedColumns: string[] = [
    'event_title',
    'organizer',
    'currentRoom',
    'dates',
    'roomplanning',
  ];
  public dataSource: MatTableDataSource<InstituteEvent> =
    new MatTableDataSource<InstituteEvent>();
  private tableData: Subject<InstituteEvent[]> = new Subject();

  // Because of the *ngIf in the html, the paginator and sort are not available at the time of initialization
  // Therefore, we have to use the setter to set the paginator and sort
  public paginator: MatPaginator;
  public sort: MatSort;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    this.setDataSourceAttributes();
  }

  permission = Permission;
  feature = Feature;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public roomOrganizationFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.ROOM_NAME,
      value: null,
    },
    {
      type: FilterType.OPEN_ROOMPLANNING,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  private setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  constructor(
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private alertService: AlertService,
    private userService: UserService,
    private roomService: RoomService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
      dateRange: new FormGroup({
        start: new FormControl(null, Validators.required),
        end: new FormControl(null, Validators.required),
      }),
    });

    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }

        // update value inside courseFilter
        this.dateRangeFilter.value = dateRange;
        this.applyRoomOrganizationFilter();
      },
    });

    this.initTableData();

    this.tableData
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (instituteEvents: InstituteEvent[]) => {
        this.dataSource = new MatTableDataSource<InstituteEvent>(
          instituteEvents
        );
        this.instituteEvents = instituteEvents;
        /* set sortingDataAccessor */
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'event_title':
              return item.name;
            case 'organizer':
              return item.organizer.name.firstName;
            case 'currentRoom':
              return item.currentRoom?.name;
            case 'dates':
              return item.startDate;
            default:
              return item[property];
          }
        };

        this.isLoading = false;
      });
  }

  /**
   * initTableData
   * @description initializes the table data
   */
  public async initTableData(): Promise<void> {
    this.eventService
      .getAllInstituteEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          if (!response.success) {
            return;
          }
          this.tableData.next(
            response.data
              ? await Promise.all(
                  response.data?.map(async (instituteEvent: InstituteEvent) => {
                    instituteEvent.name =
                      instituteEvent.name &&
                      (await this.decryptionService.decryptString(
                        instituteEvent.name
                      ));
                    instituteEvent.organizer =
                      await this.userService.parseBackendUser(
                        instituteEvent.organizer
                      );
                    instituteEvent.currentRoom = instituteEvent.currentRoom
                      ? await this.roomService.parseBackendRoom(
                          instituteEvent.currentRoom
                        )
                      : null;
                    instituteEvent.startDate = moment(
                      instituteEvent.startDate,
                      'YYYY-MM-DD HH:mm:ss'
                    ).toDate();
                    instituteEvent.endDate = moment(
                      instituteEvent.endDate,
                      'YYYY-MM-DD HH:mm:ss'
                    ).toDate();
                    return instituteEvent;
                  })
                )
              : []
          );
        },
        error: error => {},
      });
  }

  /**
   * applyRoomOrganizationFilter
   * applies the room organization filter
   * @returns void
   */
  public applyRoomOrganizationFilter(): void {
    this.dataSource.data = FilterRoomOrganizationPipe.prototype.transform(
      this.instituteEvents,
      this.roomOrganizationFilter
    );
  }

  /**
   * applyFilter
   * @description filters the table
   * @param event
   */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * openRoomPlanning
   * @description opens the room planning for the event
   * @param event
   */
  public openRoomPlanning(event: InstituteEvent) {
    this.selectedEvent = event;
    this.showCourseRoomPlanning = !this.showCourseRoomPlanning;
  }

  openFilterDialog() {}

  /**
   * openRoomOverview
   * @description opens the room overview of the institute
   */
  public openRoomOverview() {
    this.router.navigate(['/eleguide/institute/rooms']);
  }

  public closeRoomPlanning(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (result) {
            this.showCourseRoomPlanning = !this.showCourseRoomPlanning;
            return;
          }
        });
    } else {
      // only update the events in backend when the events have changed
      if (
        JSON.stringify(this.selectedEvent.eventDates) !==
        JSON.stringify(eventDates)
      ) {
        // check if the event dates have changed
        const changedEventDates = eventDates.filter(
          eventDate =>
            !this.selectedEvent.eventDates.find(
              selectedEventDate =>
                selectedEventDate.id === eventDate.id &&
                selectedEventDate.room?.id === eventDate.room?.id
            )
        );

        this.eventService
          .updateEventDates(changedEventDates)
          .pipe(first())
          .subscribe({
            next: response => {
              this.alertService.showSuccessAlert(
                'Raumplanung aktualisiert!',
                `Der Raum für ${
                  response.data?.updatedEventDates === 1
                    ? '1 Termin'
                    : response.data?.updatedEventDates + ' Termine'
                } wurde erfolgreich aktualisiert.`
              );

              // reload page
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([
                    'eleguide/education/room-organization',
                  ]);
                });
              this.showCourseRoomPlanning = !this.showCourseRoomPlanning;
            },
            error: error => {
              this.alertService.showErrorAlert(
                'Das hat leider nicht geklappt!',
                'Raumplanung konnte nicht aktualisiert werden.'
              );
            },
          });
        this.selectedEvent.eventDates = eventDates;
      } else {
        this.showCourseRoomPlanning = !this.showCourseRoomPlanning;
      }
    }
  }

  public getSubTitleForRoomPlanning(): string {
    switch (this.selectedEvent.type) {
      case EventDateType.COURSE:
        return 'Planen Sie die Räume des Kurses';
      case EventDateType.EXAM:
        return 'Planen Sie die Räume der Prüfung';
      case EventDateType.PATIENT_SESSION:
        return 'Planen Sie die Räume des Patienten Termins';
      case EventDateType.SUPERVISION:
        return 'Planen Sie die Räume der Supervision';
      case EventDateType.GROUP_SUPERVISION:
        return 'Planen Sie die Räume der Gruppensupervision';
    }
  }

  public applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * roomOrganizationFilterChanged
   * gets called when the roomOrganization filter changed
   * @param courseFilter Filter[]
   * @returns void
   */
  public roomOrganizationFilterChanged(roomOrganizationFilter: Filter[]): void {
    this.roomOrganizationFilter = roomOrganizationFilter;

    if (!roomOrganizationFilter.includes(this.dateRangeFilter)) {
      roomOrganizationFilter.push(this.dateRangeFilter);
    }

    this.applyRoomOrganizationFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range subject
    const dateRangeFilter = roomOrganizationFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
