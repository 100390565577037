<div class="flex-c flex-grow">
  <div
    [class.table-bicolor]="dataSource?.filteredData?.length > 1"
    class="flex-c table course-details-main">
    <table
      [dataSource]="dataSource"
      mat-table
      class="course-details-table"
      matSort>
      <!-- lecturer column / using firstName for sorting -->
      <ng-container matColumnDef="file">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          Dateien
          <app-custom-table-sort-icon [sort]="sort" columnName="file">
          </app-custom-table-sort-icon>
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          <div class="flex-r gap-8 align-center">
            <i class="fa-light fa-file"></i>
            <span>
              {{ getFileNameOnly(row) }}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- owner column -->
      <ng-container matColumnDef="creator">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <span>Eigentümer</span>
          <app-custom-table-sort-icon [sort]="sort" columnName="creator">
          </app-custom-table-sort-icon>
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          {{ getFullName(row.creator) ?? 'nicht gespeichert' }}
        </td>
      </ng-container>

      <!-- mofiedDate column -->
      <ng-container matColumnDef="modifiedDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          Änderungsdatum
          <app-custom-table-sort-icon [sort]="sort" columnName="modifiedDate">
          </app-custom-table-sort-icon>
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          {{
            (row.timeModified | date: 'dd.MM.yyyy') ??
              (row.timeCreated | date: 'dd.MM.yyyy')
          }}
        </td>
      </ng-container>

      <!-- fileSize column -->
      <ng-container matColumnDef="fileSize">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          Dateigröße
          <app-custom-table-sort-icon [sort]="sort" columnName="fileSize">
          </app-custom-table-sort-icon>
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          {{ formatBytes(row.size, 2) }}
        </td>
      </ng-container>

      <!-- fileType column -->
      <ng-container matColumnDef="fileType">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          Dateityp
          <app-custom-table-sort-icon [sort]="sort" columnName="fileType">
          </app-custom-table-sort-icon>
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          {{ row.type.toUpperCase() + '-' + getFileType(row) }}
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
          Aktionen
        </th>
        <td *matCellDef="let row; dataSource: dataSource" mat-cell>
          <div class="table-button-icons">
            <button
              #t="matMenuTrigger"
              [matMenuTriggerFor]="actionsMenu"
              type="button"
              class="table-button-icon"
              matTooltip="Datei Aktionen">
              <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
            </button>

            <mat-menu #actionsMenu="matMenu" class="action-menu">
              <button (click)="onOpen(row)" mat-menu-item type="button">
                <div class="action-menu-item">
                  <i class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                  <span class="hasomed-text-body gray-600">Öffnen</span>
                </div>
              </button>

              <mat-divider class="menu-divider"></mat-divider>
              <button (click)="onDownload(row)" mat-menu-item type="button">
                <div class="action-menu-item">
                  <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
                  <span class="hasomed-text-body gray-600">Herunterladen</span>
                </div>
              </button>
              <mat-divider
                *ngIf="isAdministrator"
                class="menu-divider"></mat-divider>
              <button
                *ngIf="isAdministrator"
                (click)="onDelete(row)"
                type="button"
                mat-menu-item>
                <div class="action-menu-item">
                  <i class="fa-light fa-trash-can icon-sm"></i>
                  <span class="hasomed-text-body gray-600">Löschen</span>
                </div>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tbody>
        <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
          <td [attr.colspan]="columnsToDisplay.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <app-lottie-loading-spinner
                size="45px"></app-lottie-loading-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurden keine Dateien gefunden.
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <mat-divider></mat-divider>
    <mat-paginator
      [appCustomLength]="dataSource.filteredData.length"
      [pageSize]="5"
      [renderButtonsNumber]="1"
      custom-paginator
      aria-label="Select page"></mat-paginator>
  </div>
  <div class="vertical-spacer"></div>
</div>
