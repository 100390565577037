<form
  [formGroup]="learningContentForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Lehrinhalt</h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie den Lehrinhalt.'
            : 'Erstellen Sie einen neuen Lehrinhalt.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- name -->
    <div class="formular-row">
      <label class="formular-row-label" for="name">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(learningContentForm.get('name'))">*</span>
        </span>
        <span class="label-description">
          Geben Sie dem Lehrinhalt einen Titel.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="off"
            formControlName="name"
            placeholder="Lehrinhalt" />
        </div>
        <app-error-message
          [formField]="learningContentForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- shortName -->
    <div class="formular-row">
      <label class="formular-row-label" for="shortName">
        <span class="label-title">
          Kürzel
          <span *ngIf="isRequired(learningContentForm.get('shortName'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie das Kürzel des Lehrinhalts an.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="shortName"
            class="hasomed-text-input"
            type="text"
            formControlName="shortName"
            placeholder="LIH" />
        </div>
        <app-error-message
          [formField]="
            learningContentForm.get('shortName')
          "></app-error-message>
      </div>
    </div>
  </div>
</form>
