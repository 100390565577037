import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllAdditionalQualifications,
  getAllExpertises,
  getAllProcedures,
  getUserIdFromParams,
} from 'src/app/utils/user.utils';
import { ViewProfilePictureDialogComponent } from '../view-profile-picture-dialog/view-profile-picture-dialog.component';

@Component({
  selector: 'app-user-master-data',
  templateUrl: './user-master-data.component.html',
  styleUrl: './user-master-data.component.scss',
})
export class UserMasterDataComponent {
  public user: UserModel;
  public isLoading = true;
  public existingFiles: FileModel[] = [];
  public roleBasedLabel: string;

  private destroy$: Subject<void> = new Subject<void>();

  /* for permission */
  public feature = Feature;
  public permission = Permission;
  public Role = Role;

  // import from utils
  public getAllAdditionalQualifications = getAllAdditionalQualifications;
  public getAllExpertises = getAllExpertises;
  public getAllProcedures = getAllProcedures;

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    const userId =
      getUserIdFromParams(this.activatedRoute.parent) ??
      getUserIdFromParams(this.activatedRoute);
    this.getUser(userId);
  }

  /**
   * get user data of student
   * @param userId
   * @returns void
   */
  private getUser(userId: number): void {
    this.userService
      .getInstituteUserById(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          switch (this.user.roleId) {
            case Role.STUDENT:
              this.roleBasedLabel = ' des Kandidaten';
              break;
            case Role.LECTURER:
              this.roleBasedLabel = ' des Lehrpersonals';
              break;
            case Role.ADMINISTRATOR:
              this.roleBasedLabel = ' des Verwalters';
              break;
            default:
              this.roleBasedLabel = '';
          }

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Stammdaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onEdit
   * navigate to edit page
   * @returns void
   */
  public onEdit(): void {
    this.router.navigate(['edit'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    this.dialog.open(ViewProfilePictureDialogComponent, {
      data: {
        image: this.user.profilePicture,
      },
    });
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.userService.openFile(this.user?.id, file.id);
  }

  /**
   * onDownloadFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.userService.downloadFile(this.user?.id, file.id);
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
