import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  TreatmentCaseCreateModel,
  TreatmentCaseModel,
} from '../models/treatment-case.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { FileService } from './file.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TreatmentCaseService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private userService: UserService,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private fileService: FileService
  ) {}

  /**
   * Get a treatment case by id
   * @param treatmentCaseId The id of the treatment case
   * @returns Observable<HttpResponse<any>>
   */
  public getTreatmentCaseById(
    treatmentCaseId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Get all treatment cases by institute id
   * @returns Observable<HttpResponse<any>>
   */
  public getAllTreatmentCasesByInstituteId(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Get all treatment cases of a student
   * @param studentId The id of the student
   * @param includePatientAppointments Whether to include patient appointments
   * @returns Observable<HttpResponse<any>>
   */
  public getAllTreatmentCasesByInstituteIdAndStudentId(
    studentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases/student/${studentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Get all appointment event dates of a treatment case
   * @param treatmentCaseId The id of the treatment case
   * @returns Observable<HttpResponse<any>>
   */
  public getAllAppointmentEventDatesByTreatmentCaseId(
    treatmentCaseId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/appointment-event-dates`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Create a treatment case
   * @param treatmentCaseCreateModel The treatment case create model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createTreatmentCase(
    treatmentCaseCreateModel: TreatmentCaseCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Encrypt the patient chiffre, patient age, description and files
    treatmentCaseCreateModel.patientChiffre =
      treatmentCaseCreateModel.patientChiffre &&
      (await this.cryptoService.encrypt(
        treatmentCaseCreateModel.patientChiffre
      ));
    treatmentCaseCreateModel.patientAge =
      treatmentCaseCreateModel.patientAge &&
      (await this.cryptoService.encrypt(
        String(treatmentCaseCreateModel.patientAge)
      ));
    treatmentCaseCreateModel.description =
      treatmentCaseCreateModel.description &&
      (await this.cryptoService.encrypt(treatmentCaseCreateModel.description));

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases`,
      treatmentCaseCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Update a treatment case
   * @param treatmentCaseId The id of the treatment case
   * @param treatmentCaseUpdateModel The treatment case update model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateTreatmentCase(
    treatmentCaseId: number,
    treatmentCaseUpdateModel: TreatmentCaseCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Encrypt the patient chiffre, patient age, description and files
    treatmentCaseUpdateModel.patientChiffre =
      treatmentCaseUpdateModel.patientChiffre &&
      (await this.cryptoService.encrypt(
        treatmentCaseUpdateModel.patientChiffre
      ));
    treatmentCaseUpdateModel.patientAge =
      treatmentCaseUpdateModel.patientAge &&
      (await this.cryptoService.encrypt(
        String(treatmentCaseUpdateModel.patientAge)
      ));
    treatmentCaseUpdateModel.description =
      treatmentCaseUpdateModel.description &&
      (await this.cryptoService.encrypt(treatmentCaseUpdateModel.description));

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}`,
      treatmentCaseUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Delete a treatment case
   * @param treatmentCaseId The id of the treatment case
   * @returns Observable<HttpResponse<any>>
   */
  public deleteTreatmentCase(
    treatmentCaseId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param treatmentCaseId
   * @param fileId
   * @returns void
   */
  public openFile(treatmentCaseId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param treatmentCaseId
   * @param fileId
   * @returns void
   */
  public downloadFile(treatmentCaseId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/treatment-cases/${treatmentCaseId}/files/${fileId}`
    );
  }

  /**
   * Parse the backend treatment case
   * @param treatmentCase The treatment case
   * @returns Promise<TreatmentCaseModel>
   */
  public async parseBackendTreatmentCase(
    treatmentCase: TreatmentCaseModel
  ): Promise<TreatmentCaseModel> {
    const parsedTreatmentCase: TreatmentCaseModel = {
      id: treatmentCase.id,
      student: treatmentCase.student,
      patientChiffre: await this.decryptionService.decryptString(
        treatmentCase.patientChiffre
      ),
      patientAge: Number(
        await this.decryptionService.decryptString(
          String(treatmentCase.patientAge)
        )
      ),
      therapyForm: treatmentCase.therapyForm,
      therapyPhase: treatmentCase.therapyPhase,
      treatmentProcedures: treatmentCase.treatmentProcedures,
      supervisors: treatmentCase.supervisors,
      accompanyingPersons: treatmentCase.accompanyingPersons,
      description: await this.decryptionService.decryptString(
        treatmentCase.description
      ),
      startDate: treatmentCase.startDate,
      endDate: treatmentCase.endDate,
      patientAppointments: treatmentCase.patientAppointments,
      files: await this.fileService.parseBackendFiles(treatmentCase.files),
    };

    return parsedTreatmentCase;
  }
}
