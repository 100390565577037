import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { CourseType } from 'src/app/models/course.model';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import { Role } from 'src/app/models/permission.model';
import { RoomModel } from 'src/app/models/room.model';
import { UserModel } from 'src/app/models/user.model';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
})
export class FilterTagsComponent {
  @Input() filters: Filter[] = [];
  @Output() filterChanged: EventEmitter<Filter[]> = new EventEmitter();

  @Input() eLogUserFilter?: boolean;

  public hasActiveFilterValue = hasActiveFilterValue;

  /**
   * validFilters
   * Returns all filters that have a value
   * @returns Filter[]
   */
  public get validFilters(): Filter[] {
    return this.filters.filter(filter => filter.value !== null);
  }

  /**
   * clearFilter
   * Emits the filterChanged event with the current filter values
   * @param filter: Filter
   * @returns void
   */
  public clearFilter(filter: Filter): void {
    filter.value = null;
    this.filterChanged.emit(this.filters);
  }

  /**
   * getFilterDisplayName
   * Returns the display name for a filter
   * @param filter: Filter
   * @returns string
   */
  public getFilterDisplayName(filter: Filter): string {
    const displayNames = {
      [FilterType.ROOM_NAME]: 'Raum',
      [FilterType.ROOM_FLOOR]: 'Etage',
      [FilterType.ROOM_CAPACITY]: 'Mindestanzahl an Plätzen',
      [FilterType.ROOM_ACTIVE]: 'Nur aktive Räume',
      [FilterType.LECTURER]: 'Lehrpersonal',
      [FilterType.COURSE_TYPE]: 'Kurstyp',
      [FilterType.DATE_RANGE]: 'Zeitraum',
      [FilterType.REGISTERED_COURSES]: 'Nur angemeldete Kurse',
      [FilterType.FINISHED_COURSES]: 'Nur abgeschlossene Kurse',
      [FilterType.OPEN_COURSES]: 'Nur offene Kurse',
      [FilterType.PENDING_ELOGS]: this.eLogUserFilter
        ? 'Nur Lehrpersonal mit ausstehenden Anwesenheiten in Kursen'
        : 'Nur Kurse mit ausstehenden Anwesenheiten',
      [FilterType.OPEN_ROOMPLANNING]: 'Nur offene Raumplanungen',
      [FilterType.LABEL]: 'Etikett',
      [FilterType.ROLE]: 'Rolle',
      [FilterType.LOGON_STATE]: 'Anmeldestatus',
    };

    if (filter.value === true) {
      return displayNames[filter.type] + ': Ja';
    } else if (filter.value === false) {
      return displayNames[filter.type] + ': Nein';
    } else if (this.isUser(filter.value)) {
      return (
        displayNames[filter.type] +
        ': ' +
        (filter.value.name.academicTitle
          ? filter.value.name.academicTitle + ' '
          : '') +
        filter.value.name.firstName +
        ' ' +
        filter.value.name.lastName
      );
    } else if (this.isCourseType(filter.value)) {
      return displayNames[filter.type] + ': ' + filter.value.name;
    } else if (this.isRoom(filter.value)) {
      return displayNames[filter.type] + ': ' + filter.value.name;
    } else if (this.isDateRange(filter.value)) {
      return (
        displayNames[filter.type] +
        ': ' +
        moment(filter.value.start).format('DD.MM.YYYY') +
        ' - ' +
        moment(filter.value.end).format('DD.MM.YYYY')
      );
    } else if (filter.type === FilterType.ROLE) {
      return (
        displayNames[filter.type] +
        ': ' +
        this.processRoleFilterValue(filter.value)
      );
    } else if (filter.type === FilterType.LOGON_STATE) {
      return (
        displayNames[filter.type] +
        ': ' +
        this.processLogonStateFilterValue(filter.value)
      );
    } else {
      return displayNames[filter.type] + ': ' + filter.value;
    }
  }

  /**
   * clearAllFilters
   * Clears all filters
   * @returns void
   */
  public clearAllFilters(): void {
    this.filters.forEach(filter => (filter.value = null));
    this.filterChanged.emit(this.filters);
  }

  private isCourseType(obj: any): obj is CourseType {
    return obj && typeof obj === 'object' && 'name' in obj;
  }

  private isUser(obj: any): obj is UserModel {
    return obj && typeof obj === 'object' && 'email' in obj;
  }

  private isRoom(obj: any): obj is RoomModel {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }

  private isDateRange(obj: any): obj is FilterDateRange {
    return obj && typeof obj === 'object' && 'start' in obj && 'end' in obj;
  }

  /**
   * returns the display value for the role filter value
   * @param value
   * @returns
   */
  private processRoleFilterValue(value: any): string {
    switch (value) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return value;
    }
  }

  /**
   * returns the display value for the logon state filter value
   * @param value
   * @returns
   */
  private processLogonStateFilterValue(value: any): string {
    switch (value) {
      case 'invited':
        return 'Eingeladen';
      case 'registered':
        return 'Registriert';
      case 'confirmed':
        return 'Beigetreten';
      default:
        return value;
    }
  }
}
