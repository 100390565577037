<div class="filter-tags" *ngIf="hasActiveFilterValue(filters, false)">
  <i class="fa-light fa-filter-list hasomed-icon"></i>
  <span class="hasomed-text-h3"> Filter: </span>
  <div *ngIf="hasActiveFilterValue(filters, false)" class="tags flex-r gap-8">
    <div
      *ngIf="hasActiveFilterValue(filters, false)"
      class="label label-primary">
      <span class="label-text">Alle Filter löschen</span>
      <button
        type="button"
        class="label-button-icon"
        (click)="clearAllFilters()"
        matTooltip="Alle Filter löschen">
        <i class="fa-light fa-times-circle label-icon remove-filter-icon"> </i>
      </button>
    </div>

    <div class="hasomed-text-body" *ngFor="let filter of validFilters">
      <div *ngIf="filter.value !== null" class="label label-primary">
        <span class="label-text">
          {{ getFilterDisplayName(filter) }}
        </span>
        <button
          type="button"
          class="label-button-icon"
          (click)="clearFilter(filter)"
          matTooltip="Filter entfernen">
          <i class="fa-light fa-times-circle label-icon remove-filter-icon">
          </i>
        </button>
      </div>
    </div>
  </div>
  <span *ngIf="!hasActiveFilterValue(filters, false)" class="hasomed-text-h3">
    Keine
  </span>
</div>
