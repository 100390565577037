import * as moment from 'moment';
import { WeekdayEnum } from '../enums/weekday.enum';
import { FilterDateRange } from '../models/filter.model';

/**
 * Translates a weekday number to a german weekday string.
 * @param dayNumber The weekday number
 * @returns The german weekday as a string
 * @throws An error if the day number is not between 0 and 6
 */
export function getGermanWeekday(dayNumber: number): string {
  const germanWeekdays = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ];
  if (dayNumber < 0 || dayNumber > 6) {
    throw new Error('Day number must be between 0 (Sunday) and 6 (Saturday).');
  }
  return germanWeekdays[dayNumber];
}

export function getEnglishWeekday(dayNumber: number): string {
  const englishWeekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (dayNumber < 0 || dayNumber > 6) {
    throw new Error('Day number must be between 0 (Sunday) and 6 (Saturday).');
  }
  return englishWeekdays[dayNumber];
}

export function getWeekDayAbbreviation(weekday: WeekdayEnum): string {
  switch (weekday) {
    case WeekdayEnum.SUNDAY:
      return 'So';
    case WeekdayEnum.MONDAY:
      return 'Mo';
    case WeekdayEnum.TUESDAY:
      return 'Di';
    case WeekdayEnum.WEDNESDAY:
      return 'Mi';
    case WeekdayEnum.THURSDAY:
      return 'Do';
    case WeekdayEnum.FRIDAY:
      return 'Fr';
    case WeekdayEnum.SATURDAY:
      return 'Sa';
  }
}

export function isDateInRange(
  startDate: Date,
  endDate: Date,
  dateRange: FilterDateRange
) {
  const start = moment(dateRange.start).startOf('day').toDate();
  const end = moment(dateRange.end).endOf('day').toDate();
  return (
    moment(startDate).isSameOrAfter(start) &&
    moment(endDate).isSameOrBefore(end)
  );
}

/**
 * Converts a date to a string in the format 'YYYY-MM-DD'.
 * @param date The date to convert
 * @returns The date as a string in the format 'YYYY-MM-DD'
 */
export function convertDateToDateOnly(date: Date): string | null {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
}
