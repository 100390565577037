import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  InstituteCreateModel,
  InstituteModel,
  InstituteUpdateModel,
} from 'src/app/models/institute.model';
import { OpeningHourModel } from 'src/app/models/opening-hour.model';
import { AddressService } from 'src/app/services/address.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { LoadingService } from 'src/app/services/loading.service';
import { hasErrors, isRequired } from 'src/app/utils/form.utils';
import { createOpeningHoursFromFormArray } from 'src/app/utils/opening-hour.utils';
import { emailValidator } from 'src/app/validators/email.validator';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { phoneNumberValidator } from 'src/app/validators/phone-number.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-institute',
  templateUrl: './create-edit-institute.component.html',
  styleUrl: './create-edit-institute.component.scss',
})
export class CreateEditInstituteComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public institute: InstituteModel;
  public instituteForm: FormGroup;
  public initialFormValues: {};
  public editMode = false;
  public openingHours: OpeningHourModel[] = [];

  // import from utils
  public isRequired = isRequired;
  public hasErrors = hasErrors;

  private destroy$: Subject<void> = new Subject<void>();

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.instituteForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private instituteService: InstituteService,
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private formSubmitValidationService: FormSubmitValidationService,
    private dialog: MatDialog,
    private location: Location,
    private formDeactivateService: FormDeactivateService,
    public addressService: AddressService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.editMode = true;
          this.getData(+atob(params.id));
        }
      });
    this.createForm();

    if (!this.editMode) {
      this.isLoading = false;
      this.initialFormValues = this.instituteForm.value;
    }
  }

  /**
   * createForm
   * create the institute form
   * @returns void
   */
  private createForm(): void {
    this.instituteForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl(null, [Validators.maxLength(1000)]),
      email: new FormControl(null, [
        Validators.maxLength(255),
        emailValidator(),
      ]),
      phone: new FormControl(null, [
        Validators.maxLength(20),
        phoneNumberValidator(),
      ]),
      website: new FormControl(null, [Validators.maxLength(500)]),
      street: new FormControl(null, [Validators.maxLength(255)]),
      houseNumber: new FormControl(null, [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl(null, [Validators.maxLength(100)]),
      zipCode: new FormControl(null, [
        minNumberLength(5),
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl(null, [Validators.maxLength(255)]),
      country: new FormControl(null, [Validators.maxLength(100)]),
      logo: new FormControl(null),
      openingHoursFormGroup: new FormGroup({}),
    });

    this.initialFormValues = this.instituteForm.value;
  }

  /**
   * Returns the FormControl for the country field in the instituteForm.
   * @returns The FormControl for the country field.
   */
  get countryControl(): FormControl {
    return this.instituteForm.get('country') as FormControl;
  }

  /**
   * getData
   * get the institute data
   * @param instituteId
   * @returns Promise<void>
   */
  private async getData(instituteId: number): Promise<void> {
    this.instituteService
      .getInstitute(instituteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.institute = await this.instituteService.parseBackendInstitute(
            response.body
          );

          this.instituteForm.patchValue({
            name: this.institute.name,
            description: this.institute.description,
            email: this.institute.email,
            phone: this.institute.phone,
            website: this.institute.website,
            street: this.institute.address?.street,
            houseNumber: this.institute.address?.houseNumber,
            addressAddition: this.institute.address?.addressAddition,
            zipCode: this.institute.address?.zipCode,
            city: this.institute.address?.city,
            country: this.institute.address?.country,
            logo: this.institute.logo,
          });

          this.openingHours = this.institute.openingHours;
          this.initialFormValues = this.instituteForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte erneut versuchen.'
          );
          this.location.back();
        },
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the institute will be created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.instituteForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.instituteForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();

    this.editMode ? this.updateInstitute() : this.createInstitute();
  }

  /**
   * createInstitute
   * create the institute
   * @returns void
   */
  private createInstitute(): void {
    const institute: InstituteCreateModel = {
      name: this.instituteForm.get('name').value,
      description: this.instituteForm.get('description').value,
      email: this.instituteForm.get('email').value,
      phone: this.instituteForm.get('phone').value,
      website: this.instituteForm.get('website').value,
      address: {
        street: this.instituteForm.get('street').value,
        houseNumber: this.instituteForm.get('houseNumber').value,
        addressAddition: this.instituteForm.get('addressAddition').value,
        zipCode: this.instituteForm.get('zipCode').value,
        city: this.instituteForm.get('city').value,
        country: this.instituteForm.get('country').value,
      },
      openingHours: createOpeningHoursFromFormArray(
        this.instituteForm
          .get('openingHoursFormGroup')
          .get('openingHours') as FormArray
      ),
      logo: this.instituteForm.get('logo').value,
    };

    this.instituteService
      .createInstitute(institute)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Das Institut wurde erfolgreich erstellt.'
          );
          this.initialFormValues = this.instituteForm.value;
          this.location.back();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt',
            'Das Institut konnte nicht erstellt werden. Bitte versuchen Sie es erneut.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * updateInstitute
   * update the institute
   * @returns void
   */
  private updateInstitute(): void {
    const institute: InstituteUpdateModel = {
      name: this.instituteForm.get('name').value,
      description: this.instituteForm.get('description').value,
      email: this.instituteForm.get('email').value,
      phone: this.instituteForm.get('phone').value,
      website: this.instituteForm.get('website').value,
      address: {
        street: this.instituteForm.get('street').value,
        houseNumber: this.instituteForm.get('houseNumber').value,
        addressAddition: this.instituteForm.get('addressAddition').value,
        zipCode: this.instituteForm.get('zipCode').value,
        city: this.instituteForm.get('city').value,
        country: this.instituteForm.get('country').value,
      },
      openingHours: createOpeningHoursFromFormArray(
        this.instituteForm
          .get('openingHoursFormGroup')
          .get('openingHours') as FormArray
      ),
      logo: this.instituteForm.get('logo').value,
    };

    this.instituteService
      .updateInstitute(this.institute.id, institute)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Das Institut wurde erfolgreich aktualisiert.'
          );
          this.initialFormValues = this.instituteForm.value;
          this.onCancel();

          // update current institute if it is the same
          if (
            this.instituteService.currentInstitute?.id === this.institute.id
          ) {
            this.instituteService.currentInstitute = {
              ...this.institute,
              ...institute,
            };
          }
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Institut konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * imageChangeEvent
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Institutlogo zuschneiden',
        round: false,
        height: 300,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result) {
          this.instituteForm.get('logo').setValue(result);
        }
      });
  }

  /**
   * Navigates to the previous page based on the edit mode
   * @returns void
   */
  public onCancel() {
    this.editMode
      ? this.router.navigate(['../../'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * Sets the opening hours to the opening hours that are passed from the opening hours component
   * @param openingHours The opening hours that are passed from the opening hours component
   * @returns void
   */
  public onOpeningHoursChange(openingHours: OpeningHourModel[]): void {
    this.openingHours = openingHours;
  }

  /**
   * Sets the initial form values after the opening hours have been initialized
   * @returns void
   */
  public onOpeningHoursInitialized(): void {
    this.initialFormValues = this.instituteForm.value;
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.instituteForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
