import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  InitialEducationStockBackendModel,
  InitialEducationStockCreateModel,
  InitialEducationStockModel,
  InitialEducationStockUpdateModel,
  InitialPracticalWorkStockBackendModel,
  InitialPracticalWorkStockModel,
} from '../models/initial-education-stock.model';
import { CancellationService } from './cancellation.service';
import { FileService } from './file.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InitialEducationStockService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cancellationService: CancellationService,
    private userService: UserService,
    private fileService: FileService
  ) {}

  /**
   * Calls the backend to get the initial education stock by user id
   * @param userId The user id
   * @returns Observable<HttpResponse<any>>
   */
  public getInitialEducationStockByUserId(
    userId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/initial-education-stocks/students/${userId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Calls the backend to create the initial education stock
   * @param initialEducationStock The initial education stock to create
   * @param studentId The student id
   * @returns Observable<HttpResponse<any>>
   */
  public createInitialEducationStock(
    initialEducationStock: InitialEducationStockCreateModel,
    studentId?: number
  ): Observable<HttpResponse<any>> {
    let url =
      this.config.backendUrl +
      `/api/institutes/${this.instituteId}/initial-education-stocks`;

    if (studentId) {
      // add as query parameter
      url += `?studentId=${studentId}`;
    }

    return this.http
      .post(url, initialEducationStock, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Calls the backend to update the initial education stock
   * @param initialEducationStock The initial education stock to update
   * @returns Observable<HttpResponse<any>>
   */
  public updateInitialEducationStock(
    initialEducationStock: InitialEducationStockUpdateModel
  ): Observable<HttpResponse<any>> {
    return this.http
      .put(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/initial-education-stocks/${initialEducationStock.id}`,
        initialEducationStock,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Calls the backend to mark the initial education stock of the current user as set
   * @returns Observable<HttpResponse<any>>
   */
  public markInitialEducationStockAsSet(): Observable<HttpResponse<any>> {
    const userId: number = this.userService.currentUser.id;
    return this.http
      .patch(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/initial-education-stocks/students/${userId}/mark-as-set`,
        null,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get file from backend and open it in a new tab
   * @param initialEducationStockId The id of the initial education stock
   * @param fileId The id of the file
   * @returns void
   */
  public openFile(initialEducationStockId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/initial-education-stocks/${initialEducationStockId}/files/${fileId}`
    );
  }

  /**
   * get file from backend and download it
   * @param initialEducationStockId The id of the initial education stock
   * @param fileId The id of the file
   * @returns void
   */
  public downloadFile(initialEducationStockId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/initial-education-stocks/${initialEducationStockId}/files/${fileId}`
    );
  }

  /**
   * Parses the backend initial education stock data to the frontend model
   * @param initialEducationStockData The backend initial education stock data
   * @returns Promise<InitialEducationStockModel>
   */
  public async parseBackendInitialEducationStock(
    initialEducationStockData: InitialEducationStockBackendModel
  ): Promise<InitialEducationStockModel> {
    const initialEducationStock: InitialEducationStockModel = {
      id: initialEducationStockData.id,
      theoreticalEducationDurationHours:
        initialEducationStockData.theoreticalEducationDurationHours,
      theoreticalEducationStartDate:
        initialEducationStockData.theoreticalEducationStartDate
          ? moment(
              initialEducationStockData.theoreticalEducationStartDate,
              'YYYY-MM-DD'
            ).toDate()
          : null,
      theoreticalEducationEndDate:
        initialEducationStockData.theoreticalEducationEndDate
          ? moment(
              initialEducationStockData.theoreticalEducationEndDate,
              'YYYY-MM-DD'
            ).toDate()
          : null,
      initialPracticalWorkStocks:
        initialEducationStockData.initialPracticalWorkStocks?.map(
          (
            initialPracticalWorkStock: InitialPracticalWorkStockBackendModel
          ): InitialPracticalWorkStockModel => {
            return {
              id: initialPracticalWorkStock.id,
              type: initialPracticalWorkStock.type,
              cooperationPartner: initialPracticalWorkStock.cooperationPartner,
              durationHours: initialPracticalWorkStock.durationHours,
              startDate: initialPracticalWorkStock.startDate
                ? moment(
                    initialPracticalWorkStock.startDate,
                    'YYYY-MM-DD'
                  ).toDate()
                : null,
              endDate: initialPracticalWorkStock.endDate
                ? moment(
                    initialPracticalWorkStock.endDate,
                    'YYYY-MM-DD'
                  ).toDate()
                : null,
            };
          }
        ),
      patientTreatmentDurationHours:
        initialEducationStockData.patientTreatmentDurationHours,
      completedPatientTreatments:
        initialEducationStockData.completedPatientTreatments,
      patientTreatmentStartDate:
        initialEducationStockData.patientTreatmentStartDate
          ? moment(
              initialEducationStockData.patientTreatmentStartDate,
              'YYYY-MM-DD'
            ).toDate()
          : null,
      patientTreatmentEndDate: initialEducationStockData.patientTreatmentEndDate
        ? moment(
            initialEducationStockData.patientTreatmentEndDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      supervisionDurationHours:
        initialEducationStockData.supervisionDurationHours,
      supervisionStartDate: initialEducationStockData.supervisionStartDate
        ? moment(
            initialEducationStockData.supervisionStartDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      supervisionEndDate: initialEducationStockData.supervisionEndDate
        ? moment(
            initialEducationStockData.supervisionEndDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      selfAwarenessDurationHours:
        initialEducationStockData.selfAwarenessDurationHours,
      selfAwarenessStartDate: initialEducationStockData.selfAwarenessStartDate
        ? moment(
            initialEducationStockData.selfAwarenessStartDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      selfAwarenessEndDate: initialEducationStockData.selfAwarenessEndDate
        ? moment(
            initialEducationStockData.selfAwarenessEndDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      files: await this.fileService.parseBackendFiles(
        initialEducationStockData.files
      ),
    };
    return initialEducationStock;
  }
}
