import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  SupervisionAppointmentCreateModel,
  SupervisionAppointmentModel,
} from '../models/supervision-appointment.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { FileService } from './file.service';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SupervisionAppointmentService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private roomService: RoomService,
    private fileService: FileService
  ) {}

  /**
   * Get a supervision appointment by id
   * @param supervisionAppointmentId The supervision appointment id
   * @returns Observable<HttpResponse<any>>
   */
  public getSupervisionAppointmentById(
    supervisionAppointmentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Create a supervision appointment
   * @param supervisionAppointmentCreateModel The suervision appointment create model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createSupervisionAppointment(
    supervisionAppointmentCreateModel: SupervisionAppointmentCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    supervisionAppointmentCreateModel.videoMeetingLink =
      supervisionAppointmentCreateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        supervisionAppointmentCreateModel.videoMeetingLink
      ));

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments`,
      supervisionAppointmentCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Update a supervision appointment
   * @param supervisionAppointmentId The supervision appointment id
   * @param supervisionAppointmentUpdateModel The supervision appointment update model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateSupervisionAppointment(
    supervisionAppointmentId: number,
    supervisionAppointmentUpdateModel: SupervisionAppointmentCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    supervisionAppointmentUpdateModel.videoMeetingLink =
      supervisionAppointmentUpdateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        supervisionAppointmentUpdateModel.videoMeetingLink
      ));

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}`,
      supervisionAppointmentUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Cancel a supervision appointment
   * @param supervisionAppointmentId Supervision appointment id
   * @param eventDateId Event date id
   * @returns Observable<any
   */
  public cancelSupervisionAppointmentEventDate(
    supervisionAppointmentId: number,
    eventDateId: number
  ): Observable<any> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}/event-dates/${eventDateId}/cancel`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Confirm a supervision appointment
   * @param supervisionAppointmentId Supervision appointment id
   * @param eventDateId Event date id
   * @returns Observable<any>
   */
  public confirmSupervisionAppointmentEventDate(
    supervisionAppointmentId: number,
    eventDateId: number
  ): Observable<any> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}/event-dates/${eventDateId}/confirm`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Delete a supervision appointment event date
   * @param supervisionAppointmentId The supervision appointment id
   * @param eventDateId The event date id
   * @returns Observable<HttpResponse<any>>
   */
  public deleteSupervisionAppointmentEventDate(
    supervisionAppointmentId: number,
    eventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}/event-dates/${eventDateId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param supervisionAppointmentId
   * @param fileId
   * @returns void
   */
  public openFile(supervisionAppointmentId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param supervisionAppointmentId
   * @param fileId
   * @returns void
   */
  public downloadFile(supervisionAppointmentId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}/files/${fileId}`
    );
  }

  /**
   * parses the backend supervision appointment
   * @param supervisionAppointment the backend data of the supervision appointment
   * @returns Promise<SupervisionAppointmentModel>
   */
  public async parseBackendSupervisionAppointment(
    supervisionAppointment: SupervisionAppointmentModel
  ): Promise<SupervisionAppointmentModel> {
    const supervisionAppointmentModel: SupervisionAppointmentModel = {
      id: supervisionAppointment.id,
      treatmentCases: supervisionAppointment.treatmentCases
        ? await Promise.all(
            supervisionAppointment.treatmentCases?.map(async treatmentCase => {
              return {
                id: treatmentCase.id,
                patientChiffre: await this.decryptionService.decryptString(
                  treatmentCase.patientChiffre
                ),
              };
            })
          )
        : [],
      type: supervisionAppointment.type,
      patientAppointmentEventDates:
        supervisionAppointment.patientAppointmentEventDates,
      durationInTimeUnits: supervisionAppointment.durationInTimeUnits,
      recurrencePattern: supervisionAppointment.recurrencePattern,
      eventDates: supervisionAppointment.eventDates
        ? await Promise.all(
            supervisionAppointment.eventDates.map(async eventDate => {
              const room = eventDate.room
                ? await this.roomService.parseBackendRoom(eventDate.room)
                : null;
              return {
                id: eventDate.id,
                startDate: moment(eventDate.startDate)
                  .tz('Europe/Berlin')
                  .toDate(),
                endDate: moment(eventDate.endDate).tz('Europe/Berlin').toDate(),
                room: room,
              };
            })
          )
        : [],
      location: supervisionAppointment.location,
      videoMeetingLink: await this.decryptionService.decryptString(
        supervisionAppointment.videoMeetingLink
      ),
      supervisors: supervisionAppointment.supervisors
        ? await Promise.all(
            supervisionAppointment.supervisors.map(async supervisor => {
              return await this.userService.parseBackendUser(supervisor);
            })
          )
        : [],
      description: supervisionAppointment.description,
      files: await this.fileService.parseBackendFiles(
        supervisionAppointment.files
      ),
    };

    return supervisionAppointmentModel;
  }
}
