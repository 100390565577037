import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CooperationPartnerModel } from 'src/app/models/cooperation-partner.model';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CooperationPartnerService } from 'src/app/services/cooperation-partner.service';
import {
  getAllExpertises,
  getAllProcedures,
  getFullContactPersonName,
} from 'src/app/utils/cooperation-partner-utils';

@Component({
  selector: 'app-cooperation-partner-details',
  templateUrl: './cooperation-partner-details.component.html',
  styleUrl: './cooperation-partner-details.component.scss',
})
export class CooperationPartnerDetailsComponent implements OnInit, OnDestroy {
  public cooperationPartner: CooperationPartnerModel;
  public isLoading = true;
  public existingFiles: FileModel[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getAllExpertises = getAllExpertises;
  public getAllProcedures = getAllProcedures;
  public getFullContactPersonName = getFullContactPersonName;

  constructor(
    private cooperationPartnerService: CooperationPartnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private location: Location
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.getData(+atob(params.id));
        }
      });
  }

  /**
   * getData
   * get the cooperation partner data
   * @param cooperationPartnerId
   * @returns void
   */
  private getData(cooperationPartnerId: number): void {
    this.cooperationPartnerService
      .getCooperationPartnerById(cooperationPartnerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.cooperationPartner =
            await this.cooperationPartnerService.parseBackendCooperationPartner(
              response.body
            );

          this.cooperationPartner.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten vom Kooperationspartner konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.cooperationPartnerService.openFile(
      this.cooperationPartner.id,
      file.id
    );
  }

  /**
   * onDownloadFile
   * download the file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.cooperationPartnerService.downloadFile(
      this.cooperationPartner.id,
      file.id
    );
  }

  /**
   * onEdit
   * navigate to edit page
   * @returns void
   */
  public onEdit(): void {
    this.router.navigate(
      ['../edit', btoa(String(this.cooperationPartner.id))],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * onGoBack
   * navigate back to previous page
   * @returns void
   */
  public onGoBack(): void {
    this.location.back();
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
