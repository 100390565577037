import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { Label } from '../models/label.model';
import { UserModel } from '../models/user.model';
import { isDateInRange } from '../utils/date.utils';

@Pipe({
  name: 'filterStudent',
})
export class FilterStudentPipe implements PipeTransform {
  transform(students: UserModel[], filter: Filter[]): UserModel[] {
    if (!students || !filter) {
      return students;
    }

    return students.filter(student => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              student.entryDate,
              student.entryDate,
              filter.value as FilterDateRange
            );
          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return student.label?.id === filter.value.id;
            }
            return false;
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
