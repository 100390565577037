<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Infozentrum</h1>
      <h3 class="hasomed-text-h3">Durchsuchen Sie die Wissenssammlung.</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
      <ng-container *ngIf="selectedPage">
        <button
          *appCheckPermissions="{
            feature: feature.DB_WIKI,
            permission: permission.ADMIN,
          }"
          type="button"
          (click)="onEdit(selectedPage)"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="hasomed-button-icon fa-light fa-pen"></i>
          <span class="hasomed-button-text">Bearbeiten</span>
        </button>
      </ng-container>

      <button
        *appCheckPermissions="{
          feature: feature.DB_WIKI,
          permission: permission.ADMIN,
        }"
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onCreate()">
        <i class="hasomed-button-icon fa-light fa-file-plus"></i>
        <span class="hasomed-button-text">Neuer Artikel</span>
      </button>
    </form>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div class="main-content" *ngIf="!isLoading">
    <div class="articles-container" #articleContainer>
      <div class="article-list-container">
        <div *ngIf="filteredWikiPages.length > 0" class="flex-c article-list">
          <ng-container *ngFor="let page of filteredWikiPages">
            <div
              class="article-list-item"
              [ngClass]="{ 'article-list-item-active': selectedPage === page }"
              (click)="onSelectPage(page)">
              {{ page.title }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="wikiPages?.length === 0">
          <span class="hasomed-text-body">
            Es wurde noch kein Artikel angelegt.
          </span>
        </div>
        <div *ngIf="filteredWikiPages.length === 0 && wikiPages?.length > 0">
          Es wurden keine Artikel gefunden.
        </div>
      </div>
      <div class="page flex-c flex-1" *ngIf="selectedPage">
        <div class="flex-c gap-32">
          <div class="flex-r justify-space-between align-center">
            <div class="flex-c">
              <h1 class="hasomed-text-h1">{{ selectedPage?.title }}</h1>
              <h3 class="hasomed-text-h3">{{ selectedPage?.subtitle }}</h3>
            </div>
          </div>
          <div [innerHTML]="getSanitizedArticleContent()"></div>
        </div>
        <div *ngIf="selectedPage?.image">
          <img
            [attr.src]="selectedPage?.image"
            alt="Titelbild"
            class="title-picture" />
        </div>
        <div class="flex-r" *ngIf="selectedPage?.link">
          <a
            [href]="selectedPage?.link"
            class="hasomed-link-button hasomed-button-primary">
            <i class="fa-light fa-link hasomed-button-icon"></i>
            <span class="hasomed-button-text">
              {{ selectedPage?.link }}
            </span>
          </a>
        </div>
        <div class="user-container" *ngIf="selectedPage.modifier">
          <div class="user-icon">
            <img
              src="{{
                selectedPage.modifier.profilePicture ?? '../assets/img/user.jpg'
              }}"
              alt=""
              class="user-icon-image" />
          </div>
          <div class="user-info">
            <span class="user-name hasomed-text-small">
              {{ getFullName(selectedPage.modifier) }}
            </span>
            <span class="hasomed-text-x-small">
              am {{ selectedPage.timeModified | date: 'dd.MM.yyyy' }} um
              {{ selectedPage.timeModified | date: 'HH:mm' }} Uhr
            </span>
          </div>
        </div>
        <div
          class="user-container"
          *ngIf="selectedPage.creator && !selectedPage.modifier">
          <div class="user-icon">
            <img
              src="{{
                selectedPage.creator.profilePicture ?? '../assets/img/user.jpg'
              }}"
              alt=""
              class="user-icon-image" />
          </div>
          <div class="user-info">
            <span class="user-name hasomed-text-small">
              {{ getFullName(selectedPage.creator) }}
            </span>
            <span class="hasomed-text-x-small">
              am {{ selectedPage.timeCreated | date: 'dd.MM.yyyy' }} um
              {{ selectedPage.timeCreated | date: 'HH:mm' }} Uhr
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
