import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CourseModel, CourseType } from 'src/app/models/course.model';
import { EducationCourse } from 'src/app/models/education-course.model';
import { InstituteEvent } from 'src/app/models/event.model';
import { Filter, FilterType, FilterUser } from 'src/app/models/filter.model';
import { Label } from 'src/app/models/label.model';
import { Role } from 'src/app/models/permission.model';
import { RoomModel } from 'src/app/models/room.model';
import { AdditionalQualificationEnum } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { LabelService } from 'src/app/services/label.service';
import { RoomOrganizationService } from 'src/app/services/room-organization.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() filters: Filter[];
  @Output() filterChanged: EventEmitter<Filter[]> = new EventEmitter();
  @Input() courses?: CourseModel[];
  @Input() instituteEvents?: InstituteEvent[];
  @Input() eLogUserFilter?: boolean;

  public filterForm: FormGroup = new FormGroup({});
  public filterType = FilterType;
  public isLoading = true;

  public courseTypes: CourseType[] = [];
  public instituteLecturer: FilterUser[] = [];
  public instituteStudents: FilterUser[] = [];
  public rooms: RoomModel[] = [];
  public labels: Label[] = [];
  public educationCourses: EducationCourse[] = [];
  public Role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private roomOrganizationService: RoomOrganizationService,
    private userService: UserService,
    private labelService: LabelService,
    private educationCourseService: EducationCourseService,
    private cancellationService: CancellationService
  ) {}

  public async ngOnInit() {
    await this.initializeFilterValues();
    this.isLoading = false;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      this.initializeForm();
    }
  }

  /**
   * Initializes the filter form by creating a new FormGroup and adding controls
   * for each filter that is not of type DATE_RANGE. It also sets up value change
   * handlers for registered courses, finished courses, open courses, and pending
   * eLogs.
   *
   * @private
   * @returns {void}
   */
  private initializeForm(): void {
    this.filterForm = new FormGroup({});

    this.filters?.forEach((filter: Filter) => {
      if (filter.type !== FilterType.DATE_RANGE) {
        this.filterForm.addControl(filter.type, new FormControl(filter.value));
      }
    });

    this.handleRegisteredCoursesValueChanges();
    this.handleFinishedCoursesValueChanges();
    this.handleOpenCoursesValueChanges();
    this.handlePendingElogsValueChanges();
  }

  /**
   * Handles the value changes for the PENDING_ELOGS filter.
   * Subscribes to the value changes of the PENDING_ELOGS control in the filter form.
   * When the value changes, it updates the control states of FINISHED_COURSES and OPEN_COURSES
   * based on the new value.
   * @returns {void}
   */
  private handlePendingElogsValueChanges(): void {
    this.filterForm
      .get(FilterType.PENDING_ELOGS)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.FINISHED_COURSES, value);
        this.updateControlState(FilterType.OPEN_COURSES, value);
      });
  }

  /**
   * Handles the value changes for the "Open Courses" filter.
   * This method subscribes to the value changes of the "Open Courses" filter form control.
   * When the value changes, it updates the state of the "Registered Courses", "Finished Courses",
   * and "Pending ELogs" filter controls based on the new value.
   * @returns {void}
   */
  private handleOpenCoursesValueChanges(): void {
    this.filterForm
      .get(FilterType.OPEN_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.REGISTERED_COURSES, value);
        this.updateControlState(FilterType.FINISHED_COURSES, value);
        this.updateControlState(FilterType.PENDING_ELOGS, value);
      });
  }

  /**
   * Handles the value changes for the 'Finished Courses' filter.
   * Subscribes to the value changes of the 'Finished Courses' filter form control,
   * and updates the state of the 'Open Courses' and 'Pending ELogs' filter controls
   * based on the new value.
   * @returns {void}
   */
  private handleFinishedCoursesValueChanges(): void {
    this.filterForm
      .get(FilterType.FINISHED_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.OPEN_COURSES, value);
        this.updateControlState(FilterType.PENDING_ELOGS, value);
      });
  }

  /**
   * Handles the value changes for the 'Registered Courses' filter.
   * Subscribes to the value changes of the 'Registered Courses' filter form control,
   * and updates the state of the 'Open Courses' filter control based on the new value.
   * @returns {void}
   */
  private handleRegisteredCoursesValueChanges(): void {
    this.filterForm
      .get(FilterType.REGISTERED_COURSES)
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.updateControlState(FilterType.OPEN_COURSES, value);
      });
  }

  /**
   * Initializes the filter values based on the input data.
   * If courses are provided, it initializes the course filter.
   * If institute events are provided, it initializes the room filter.
   * If the label filter is enabled, it fetches the labels.
   * @returns {void}
   */
  private async initializeFilterValues(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.courses) {
        await this.initializeCourseFilter();
      }

      if (this.instituteEvents) {
        this.rooms = this.roomOrganizationService.getAllInstituteEventRooms(
          this.instituteEvents
        );
      }

      if (this.shouldFetchLabels()) {
        await this.fetchLabels();
      }

      if (this.shouldFetchEducationCourses()) {
        await this.fetchEducationCourses();
      }

      if (this.shouldFetchSupervisors()) {
        await this.fetchInstituteSupervisors();
      }

      if (this.shouldFetchStudents()) {
        await this.fetchInstituteStudents();
      }

      resolve();
    });
  }

  /**
   * Determines whether the label filter is enabled based on the current filters.
   * @returns {boolean} `true` if the filters contain at least one filter of type `LABEL`, otherwise `false`.
   */
  private shouldFetchLabels(): boolean {
    return (
      this.filters &&
      this.filters.some(filter => filter.type === FilterType.LABEL)
    );
  }

  /**
   * Fetches all labels from the backend and sets the labels property.
   * @returns {Promise<void>}
   */
  private fetchLabels(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.labelService
        .getAllLabels()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: result => {
            this.labels = result.body;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /**
   * Determines whether the education courses should be fetched based on the current filters.
   * @returns {boolean} `true` if the filters contain at least one filter of type `EDUCATION_COURSE`, otherwise `false`.
   */
  private shouldFetchEducationCourses(): boolean {
    return (
      this.filters &&
      this.filters.some(filter => filter.type === FilterType.EDUCATION_COURSE)
    );
  }

  /**
   * Determines whether the supervisors should be fetched based on the current filters.
   * @returns {boolean} `true` if the filters contain at least one filter of type `SUPERVISION_SUPERVISOR`, otherwise `false`.
   */
  private shouldFetchSupervisors(): boolean {
    return (
      this.filters &&
      this.filters.some(
        filter => filter.type === FilterType.SUPERVISION_SUPERVISOR
      )
    );
  }

  /**
   * Determines whether the students should be fetched based on the current filters.
   * @returns {boolean} `true` if the filters contain at least one filter of type `SUPERVISION_STUDENT`, otherwise `false`.
   */
  private shouldFetchStudents(): boolean {
    return (
      this.filters &&
      this.filters.some(
        filter => filter.type === FilterType.SUPERVISION_STUDENT
      )
    );
  }

  /**
   * Fetches all education courses from the backend and sets the educationCourses property.
   * @returns {Promise<void>}
   */
  private fetchEducationCourses(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.educationCourseService
        .getInstituteEducationCourses()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.educationCourses = response.body?.map(
              (educationCourseData: any): EducationCourse => {
                return {
                  id: educationCourseData.id,
                  title: educationCourseData.title,
                };
              }
            );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /**
   * Initializes the course filter by filtering unique course types and retrieving all course rooms.
   * Fetches all institute lecturers and processes their data to be used in the filter.
   * @returns {Promise<void>}
   */
  private initializeCourseFilter(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.courseTypes = this.courseService.filterUniqueCourseTypes(
        this.courses
      );
      this.rooms = this.courseService.getAllCourseRooms(this.courses);

      // get all institute lecturers
      this.fetchInstituteLecturers();
    });
  }

  /**
   * Fetches all institute lecturers and processes their data to be used in the filter.
   * @returns {Promise<void>}
   */
  private fetchInstituteLecturers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByRole(Role.LECTURER, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            const instituteLecturer = result.body
              ? await Promise.all(
                  result.body.map(
                    async (userData: any): Promise<FilterUser> => {
                      const decryptedUserData =
                        await this.userService.parseBackendUser(userData);
                      const name = getFullName(decryptedUserData);
                      return {
                        id: decryptedUserData.id,
                        name: name,
                      };
                    }
                  )
                )
              : [];

            this.instituteLecturer = instituteLecturer;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /**
   * Fetches all institute supervisors and processes their data to be used in the filter.
   * @returns {Promise<void>}
   */
  private fetchInstituteSupervisors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByAdditionalQualification(
          AdditionalQualificationEnum.SUPERVISOR
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.instituteLecturer = response.body
              ? await Promise.all(
                  response.body.map(
                    async (userData: any): Promise<FilterUser> => {
                      const decryptedUserData =
                        await this.userService.parseBackendUser(userData);
                      const name = getFullName(decryptedUserData);
                      return {
                        id: decryptedUserData.id,
                        name: name,
                      };
                    }
                  )
                )
              : [];
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /**
   * Fetches all institute students and processes their data to be used in the filter.
   * @returns {Promise<void>}
   */
  private fetchInstituteStudents(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByRole(Role.STUDENT, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            const instituteStudents = result.body
              ? await Promise.all(
                  result.body.map(
                    async (userData: any): Promise<FilterUser> => {
                      const decryptedUserData =
                        await this.userService.parseBackendUser(userData);
                      const name = getFullName(decryptedUserData);
                      return {
                        id: decryptedUserData.id,
                        name: name,
                      };
                    }
                  )
                )
              : [];

            this.instituteStudents = instituteStudents;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /**
   * Applies the current filters from the filter form and emits the `filterChanged` event.
   * @returns {void}
   */
  public applyFilter(): void {
    const filters: Filter[] = [];

    Object.keys(this.filterForm.controls).forEach((key: string) => {
      const filter: Filter = {
        type: key as FilterType,
        value: this.filterForm.get(key).value,
      };
      // if value is false set to null
      if (filter.value === false) {
        filter.value = null;
      }

      filters.push(filter);
    });

    this.filterChanged.emit(filters);
  }

  /**
   * Clears all filters by setting all filter values to null and emitting the `filterChanged` event.
   * @returns {void}
   */
  public clearFilter(): void {
    const filters: Filter[] = [];

    Object.keys(this.filterForm.controls).forEach((key: string) => {
      const filter: Filter = {
        type: key as FilterType,
        value: null,
      };

      filters.push(filter);
    });

    this.filterChanged.emit(filters);
  }

  /**
   * Helper function to safely update control state without triggering infinite loops
   * @param controlName
   * @param shouldBeDisabled
   * @returns {void}
   */
  private updateControlState(
    controlName: string,
    shouldBeDisabled: boolean
  ): void {
    const control = this.filterForm.get(controlName);
    if (control) {
      if (shouldBeDisabled && control.value) {
        control.setValue(null, { emitEvent: false });
      }
    }
  }

  /**
   * Checks if the form group has any controls
   * @returns {boolean}
   */
  public formGroupHasControls(): boolean {
    return (
      this.filterForm.controls &&
      Object.keys(this.filterForm.controls).length > 0
    );
  }

  /**
   * Compares two filter users by their id
   * @param filterUser1: FilterUser
   * @param filterUser2: FilterUser
   * @returns {boolean}
   */
  public compareFilterUser(
    filterUser1: FilterUser,
    filterUser2: FilterUser
  ): boolean {
    return filterUser1 && filterUser2
      ? filterUser1.id === filterUser2.id
      : filterUser1 === filterUser2;
  }

  /**
   * Compare two education courses by their id
   * @param educationCourse1 EducationCourse
   * @param educationCourse2 EducationCourse
   * @returns {boolean}
   */
  public compareEducationCourse(
    educationCourse1: EducationCourse,
    educationCourse2: EducationCourse
  ): boolean {
    return educationCourse1 && educationCourse2
      ? educationCourse1.id === educationCourse2.id
      : educationCourse1 === educationCourse2;
  }

  /**
   * Compares two labels by their name
   * @param label1 Label
   * @param label2 Label
   * @returns {boolean}
   */
  public compareLabel(label1: Label, label2: Label): boolean {
    return label1 && label2 ? label1.name === label2.name : label1 === label2;
  }

  /**
   * Returns true if any advanced filter is in the form
   * @returns {boolean}
   */
  public showAdvancedFilters(): boolean {
    return !!(
      this.filterForm.get(this.filterType.REGISTERED_COURSES) ||
      this.filterForm.get(this.filterType.FINISHED_COURSES) ||
      this.filterForm.get(this.filterType.OPEN_COURSES) ||
      this.filterForm.get(this.filterType.PENDING_ELOGS) ||
      this.filterForm.get(this.filterType.ROOM_ACTIVE) ||
      this.filterForm.get(this.filterType.OPEN_ROOMPLANNING)
    );
  }

  /**
   * Cancels all requests and unsubscribes from all subscriptions
   * @returns {void}
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
