import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  CooperationPartnerCreateModel,
  CooperationPartnerModel,
  CooperationPartnerUpdateModel,
} from 'src/app/models/cooperation-partner.model';
import { ExpertiseModel } from 'src/app/models/expertise.model';
import { FileModel } from 'src/app/models/file.model';
import { ProcedureModel } from 'src/app/models/procedure.model';
import { SupplySectorModel } from 'src/app/models/supply-sector.model';
import { AddressService } from 'src/app/services/address.service';
import { AlertService } from 'src/app/services/alert.service';
import { CooperationPartnerService } from 'src/app/services/cooperation-partner.service';
import { ExpertiseService } from 'src/app/services/expertise.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { SupplySectorService } from 'src/app/services/supply-sector.service';
import { hasErrors, isRequired } from 'src/app/utils/form.utils';
import { emailValidator } from 'src/app/validators/email.validator';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { phoneNumberValidator } from 'src/app/validators/phone-number.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-cooperation-partner',
  templateUrl: './create-edit-cooperation-partner.component.html',
  styleUrl: './create-edit-cooperation-partner.component.scss',
})
export class CreateEditCooperationPartnerComponent
  implements OnInit, OnDestroy
{
  public initialFormValues: {};
  public cooperationPartner: CooperationPartnerModel;
  public cooperationPartnerForm: FormGroup;
  private destroy$: Subject<void> = new Subject<void>();
  public isLoading = true;
  public editMode: boolean = false;
  public expertises: ExpertiseModel[];
  public procedures: ProcedureModel[];
  public supplySectors: SupplySectorModel[];
  public today = moment();
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.cooperationPartnerForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  // import from utils
  public isRequired = isRequired;
  public hasErrors = hasErrors;

  public allowedFileTypes: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private cooperationPartnerService: CooperationPartnerService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private router: Router,
    private procedureService: ProcedureService,
    private expertiseService: ExpertiseService,
    private supplySectorService: SupplySectorService,
    public addressService: AddressService,
    private formSubmitValidationService: FormSubmitValidationService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getExpertises();
    this.getProcedures();
    this.getSupplySectors();
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.editMode = true;
          this.getCooperationPartnerById(+atob(params.id));
        } else {
          this.isLoading = false;
        }
      });
    this.initialFormValues = this.cooperationPartnerForm.value;

    // subscribe to uploadedFiles changes and convert them to base64
    this.cooperationPartnerForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.cooperationPartnerForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.cooperationPartnerForm.get('documents'),
          false,
          true
        );
      });
  }

  /**
   * createForm
   * creates the form
   * @returns void
   */
  private createForm(): void {
    this.cooperationPartnerForm = this.formBuilder.group({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      contactPersonAcademicTitle: new FormControl(null),
      contactPersonFirstName: new FormControl(null, [
        Validators.maxLength(50),
        Validators.required,
      ]),
      contactPersonLastName: new FormControl(null, [
        Validators.maxLength(50),
        Validators.required,
      ]),
      email: new FormControl(null, [emailValidator()]),
      phone: new FormControl(null, [
        Validators.maxLength(20),
        phoneNumberValidator(),
      ]),
      mobile: new FormControl(null, [
        Validators.maxLength(20),
        phoneNumberValidator(),
      ]),
      street: new FormControl(null, [Validators.maxLength(255)]),
      houseNumber: new FormControl(null, [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl(null, [Validators.maxLength(100)]),
      zipCode: new FormControl(null, [
        minNumberLength(5),
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl(null, [Validators.maxLength(255)]),
      country: new FormControl(null, [Validators.maxLength(100)]),
      expertises: new FormControl([]),
      supplySector: new FormControl(null, [Validators.required]),
      procedures: new FormControl([]),
      cooperationAgreementAvailable: new FormControl(null),
      cooperationAgreementDate: new FormControl(null),
      surveyFormAvailable: new FormControl(null),
      surveyFormDate: new FormControl(null),
      isAdvancedTrainingApproved: new FormControl(null),
      scopeOfAdvancedTrainingApproval: new FormControl(null, [
        Validators.maxLength(500),
      ]),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * Getter for the academic title form control.
   * @returns The FormControl for the academic title field.
   */
  get academicTitleControl(): FormControl {
    return this.cooperationPartnerForm.get(
      'contactPersonAcademicTitle'
    ) as FormControl;
  }

  /**
   * Returns the FormControl for the 'country' field in the cooperationPartnerForm.
   * @returns The FormControl for the 'country' field.
   */
  get countryControl(): FormControl {
    return this.cooperationPartnerForm.get('country') as FormControl;
  }

  /**
   * getCooperationPartnerById
   * get the cooperation partner by id
   * @param cooperationPartnerId
   * @returns void
   */
  private getCooperationPartnerById(cooperationPartnerId: number): void {
    this.cooperationPartnerService
      .getCooperationPartnerById(cooperationPartnerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.cooperationPartner =
            await this.cooperationPartnerService.parseBackendCooperationPartner(
              response.body
            );

          this.cooperationPartner.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.cooperationPartnerForm.patchValue({
            name: this.cooperationPartner.name,
            contactPersonAcademicTitle:
              this.cooperationPartner.contactPerson.academicTitle,
            contactPersonFirstName:
              this.cooperationPartner.contactPerson.firstName,
            contactPersonLastName:
              this.cooperationPartner.contactPerson.lastName,
            email: this.cooperationPartner.email,
            phone: this.cooperationPartner.phone,
            mobile: this.cooperationPartner.mobile,
            street: this.cooperationPartner.address.street,
            houseNumber: this.cooperationPartner.address.houseNumber,
            addressAddition: this.cooperationPartner.address.addressAddition,
            zipCode: this.cooperationPartner.address.zipCode,
            city: this.cooperationPartner.address.city,
            country: this.cooperationPartner.address.country,
            expertises: this.cooperationPartner.expertises?.map(
              (expertise: ExpertiseModel) => expertise.id
            ),
            supplySector: this.cooperationPartner.supplySector?.id,
            procedures: this.cooperationPartner.procedures?.map(
              (procedure: ProcedureModel) => procedure.id
            ),
            cooperationAgreementAvailable:
              this.cooperationPartner.cooperationAgreementAvailable,
            cooperationAgreementDate:
              this.cooperationPartner.cooperationAgreementDate,
            surveyFormAvailable: this.cooperationPartner.surveyFormAvailable,
            surveyFormDate: this.cooperationPartner.surveyFormDate,
            isAdvancedTrainingApproved:
              this.cooperationPartner.isAdvancedTrainingApproved,
            scopeOfAdvancedTrainingApproval:
              this.cooperationPartner.scopeOfAdvancedTrainingApproval,
            documents: this.cooperationPartner.files,
            uploadedFiles: this.uploadedFiles,
            existingFiles: JSON.parse(JSON.stringify(this.existingFiles)),
          });
          this.initialFormValues = this.cooperationPartnerForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten des Kooperationspartners konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * getExpertises
   * Get the expertises
   * @returns void
   */
  private getExpertises(): void {
    this.expertiseService
      .getAllExpertises()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.expertises = result.body;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Fachkunden konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getProcedures
   * Get the procedures
   * @returns void
   */
  private getProcedures(): void {
    this.procedureService
      .getAllProcedures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.procedures = result.body;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Richtlinienverfahren konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getSupplySectors
   * Get the supply sectors
   * @returns void
   */
  private getSupplySectors(): void {
    this.supplySectorService
      .getAllSupplySectors()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.supplySectors = result.body;
        },
        error: error => {},
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the cooperation partner is created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.cooperationPartnerForm
      )
    ) {
      return;
    }

    // check if the form has unsaved changes
    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.cooperationPartnerForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    // set default values for date fields
    if (
      this.cooperationPartnerForm.get('cooperationAgreementAvailable').value &&
      !this.cooperationPartnerForm.get('cooperationAgreementDate').value
    ) {
      this.cooperationPartnerForm
        .get('cooperationAgreementDate')
        .setValue(moment());
    }
    if (
      this.cooperationPartnerForm.get('surveyFormAvailable').value &&
      !this.cooperationPartnerForm.get('surveyFormDate').value
    ) {
      this.cooperationPartnerForm.get('surveyFormDate').setValue(moment());
    }

    this.loadingService.show();
    this.editMode
      ? this.updateCooperationPartner()
      : this.createCooperationPartner();
  }

  /**
   * onCancel
   * handles the form cancel
   * @returns void
   */
  public onCancel(): void {
    this.editMode
      ? this.router.navigate(['../../'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * createCooperationPartner
   * creates a cooperation partner
   * @returns Promise<void>
   */
  private async createCooperationPartner(): Promise<void> {
    const files: FileModel[] = this.cooperationPartnerForm.value.documents
      ? this.existingFiles.concat(this.cooperationPartnerForm.value.documents)
      : this.existingFiles;

    const cooperationPartnerCreateModel: CooperationPartnerCreateModel = {
      name: this.cooperationPartnerForm.get('name').value,
      contactPerson: {
        academicTitle: this.cooperationPartnerForm.get(
          'contactPersonAcademicTitle'
        ).value,
        firstName: this.cooperationPartnerForm.get('contactPersonFirstName')
          .value,
        lastName: this.cooperationPartnerForm.get('contactPersonLastName')
          .value,
      },
      email: this.cooperationPartnerForm.get('email').value,
      phone: this.cooperationPartnerForm.get('phone').value,
      mobile: this.cooperationPartnerForm.get('mobile').value,
      address: {
        street: this.cooperationPartnerForm.get('street').value,
        houseNumber: this.cooperationPartnerForm.get('houseNumber').value,
        addressAddition:
          this.cooperationPartnerForm.get('addressAddition').value,
        zipCode: this.cooperationPartnerForm.get('zipCode').value,
        city: this.cooperationPartnerForm.get('city').value,
        country: this.cooperationPartnerForm.get('country').value,
      },
      expertiseIds: this.cooperationPartnerForm.get('expertises').value,
      supplySectorId: this.cooperationPartnerForm.get('supplySector').value,
      procedureIds: this.cooperationPartnerForm.get('procedures').value,
      cooperationAgreementAvailable: this.cooperationPartnerForm.get(
        'cooperationAgreementAvailable'
      ).value,
      cooperationAgreementDate:
        this.cooperationPartnerForm.get('cooperationAgreementDate').value &&
        moment(
          this.cooperationPartnerForm.get('cooperationAgreementDate').value
        ).format('YYYY-MM-DD'),
      surveyFormAvailable: this.cooperationPartnerForm.get(
        'surveyFormAvailable'
      ).value,
      surveyFormDate:
        this.cooperationPartnerForm.get('surveyFormDate').value &&
        moment(this.cooperationPartnerForm.get('surveyFormDate').value).format(
          'YYYY-MM-DD'
        ),
      isAdvancedTrainingApproved: Boolean(
        this.cooperationPartnerForm.get('isAdvancedTrainingApproved').value
      ),
      scopeOfAdvancedTrainingApproval: this.cooperationPartnerForm.get(
        'scopeOfAdvancedTrainingApproval'
      ).value,
      files: files,
    };

    const createCooperationPartnerObservable =
      await this.cooperationPartnerService.createCooperationPartner(
        cooperationPartnerCreateModel
      );

    createCooperationPartnerObservable.pipe(first()).subscribe({
      next: result => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Kooperationspartner wurde angelegt.'
        );
        this.initialFormValues = this.cooperationPartnerForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Kooperationspartner konnte nicht erstellt werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * updateCooperationPartner
   * updates a cooperation partner
   * @returns Promise<void>
   */
  private async updateCooperationPartner(): Promise<void> {
    const files: FileModel[] = this.cooperationPartnerForm.value.documents
      ? this.existingFiles.concat(this.cooperationPartnerForm.value.documents)
      : this.existingFiles;
    const cooperationPartnerUpdateModel: CooperationPartnerUpdateModel = {
      name: this.cooperationPartnerForm.get('name').value,
      contactPerson: {
        academicTitle: this.cooperationPartnerForm.get(
          'contactPersonAcademicTitle'
        ).value,
        firstName: this.cooperationPartnerForm.get('contactPersonFirstName')
          .value,
        lastName: this.cooperationPartnerForm.get('contactPersonLastName')
          .value,
      },
      email: this.cooperationPartnerForm.get('email').value,
      phone: this.cooperationPartnerForm.get('phone').value,
      mobile: this.cooperationPartnerForm.get('mobile').value,
      address: {
        street: this.cooperationPartnerForm.get('street').value,
        houseNumber: this.cooperationPartnerForm.get('houseNumber').value,
        addressAddition:
          this.cooperationPartnerForm.get('addressAddition').value,
        zipCode: this.cooperationPartnerForm.get('zipCode').value,
        city: this.cooperationPartnerForm.get('city').value,
        country: this.cooperationPartnerForm.get('country').value,
      },
      expertiseIds: this.cooperationPartnerForm.get('expertises').value,
      supplySectorId: this.cooperationPartnerForm.get('supplySector').value,
      procedureIds: this.cooperationPartnerForm.get('procedures').value,
      cooperationAgreementAvailable: this.cooperationPartnerForm.get(
        'cooperationAgreementAvailable'
      ).value,
      cooperationAgreementDate:
        this.cooperationPartnerForm.get('cooperationAgreementDate').value &&
        moment(
          this.cooperationPartnerForm.get('cooperationAgreementDate').value
        ).format('YYYY-MM-DD'),
      surveyFormAvailable: this.cooperationPartnerForm.get(
        'surveyFormAvailable'
      ).value,
      surveyFormDate:
        this.cooperationPartnerForm.get('surveyFormDate').value &&
        moment(this.cooperationPartnerForm.get('surveyFormDate').value).format(
          'YYYY-MM-DD'
        ),
      isAdvancedTrainingApproved: Boolean(
        this.cooperationPartnerForm.get('isAdvancedTrainingApproved').value
      ),
      scopeOfAdvancedTrainingApproval: this.cooperationPartnerForm.get(
        'scopeOfAdvancedTrainingApproval'
      ).value,
      files: files,
    };

    const updateCooperationPartnerObservable =
      await this.cooperationPartnerService.updateCooperationPartner(
        this.cooperationPartner.id,
        cooperationPartnerUpdateModel
      );

    updateCooperationPartnerObservable.pipe(first()).subscribe({
      next: result => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Kooperationspartner wurde aktualisiert.'
        );
        this.initialFormValues = this.cooperationPartnerForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Kooperationspartner konnte nicht aktualisiert werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * changeArrayFormFieldValue
   * @param fieldValueId
   * @param fieldName
   * @returns void
   */
  public changeArrayFormFieldValue(
    fieldValueId: number | null,
    fieldName: string
  ): void {
    if (!fieldValueId) {
      this.cooperationPartnerForm.get(fieldName).setValue([]);
      return;
    }
    if (
      this.cooperationPartnerForm.get(fieldName).value.includes(fieldValueId)
    ) {
      this.cooperationPartnerForm
        .get(fieldName)
        .setValue(
          this.cooperationPartnerForm
            .get(fieldName)
            .value.filter((id: number) => id !== fieldValueId)
        );
    } else {
      this.cooperationPartnerForm
        .get(fieldName)
        .setValue(
          this.cooperationPartnerForm.get(fieldName).value.concat(fieldValueId)
        );
    }
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   */
  public onOpenExistingFile(file: FileModel) {
    this.cooperationPartnerService.openFile(
      this.cooperationPartner.id,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download the file
   * @param file
   */
  public onDownloadExistingFile(file: FileModel) {
    this.cooperationPartnerService.downloadFile(
      this.cooperationPartner.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.cooperationPartnerForm
      .get('existingFiles')
      .setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.cooperationPartnerForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
