import { KeyValue } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  EducationCourse,
  EducationCourseCreateModel,
  EducationCourseUpdateModel,
  Expertise,
  PolicyProcedure,
  ProfessionalAssociation,
  Regulation,
} from 'src/app/models/education-course.model';
import { ExpertiseModel } from 'src/app/models/expertise.model';
import { ProcedureModel } from 'src/app/models/procedure.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { ExpertiseService } from 'src/app/services/expertise.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { isRequired } from 'src/app/utils/form.utils';
import { yearRangeValidator } from 'src/app/validators/year-range.validator';

@Component({
  selector: 'app-create-edit-education-course',
  templateUrl: './create-edit-education-course.component.html',
  styleUrl: './create-edit-education-course.component.scss',
})
export class CreateEditEducationCourseComponent implements OnInit, OnDestroy {
  public educationCourseForm: FormGroup;
  private educationCourse: EducationCourse;
  public editMode: boolean = false;
  public initialFormValues: {};
  public formIsValid: boolean = false;
  public isLoading: boolean = true;
  public expertises: ExpertiseModel[];
  public procedures: ProcedureModel[];

  // to access enum in template
  public Regulation = Regulation;
  public Expertise = Expertise;
  public ProfessionalAssociation = ProfessionalAssociation;
  public PolicyProcedure = PolicyProcedure;

  // import from form.utils.ts
  public isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.educationCourseForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  private destroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private educationCourseService: EducationCourseService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private procedureService: ProcedureService,
    private expertiseService: ExpertiseService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      const educationCourseId: number = +atob(
        this.activatedRoute.snapshot.paramMap.get('id')
      );
      this.getData(educationCourseId);
      this.editMode = true;
    }
    this.getExpertises();
    this.getProcedures();
    this.createForm();

    if (!this.editMode) {
      this.isLoading = false;
      this.initialFormValues = this.educationCourseForm.value;
    }
  }

  /**
   * createForm creates the form and sets Validators
   */
  private createForm() {
    this.educationCourseForm = new FormGroup({
      regulation: new FormControl(null, Validators.required),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      expertise: new FormControl(null, Validators.required),
      procedure: new FormControl(null, Validators.required),
      professionalAssociation: new FormControl(null, Validators.required),
      accreditationYear: new FormControl(null, [
        Validators.required,
        yearRangeValidator(1800, new Date().getFullYear()),
      ]),
      chamber: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      internalNotes: new FormControl('', Validators.maxLength(6000)),
      externalNotes: new FormControl('', Validators.maxLength(6000)),
      contingentTheoreticalScientificHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentTheoreticalScientificAutoTrainingHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentTheoreticalScientificBalintGroupWorkHours: new FormControl(
        null,
        [Validators.min(0), Validators.max(10000)]
      ),
      contingentPractical1Hours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentPractical1MedicalHistorySurveyMin: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      contingentPractical2Hours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentTreatmentInternshipHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentCompletedTreatmentCases: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      contingentTreatmentCases: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      contingentTreatmentCasesMin: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      contingentTreatmentCasesMinHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentSupervisionHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentSupervisionSingleSessionHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentSupervisionGroupSessionHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentSupervisionMedicalHistorySurveyMin: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      contingentSelfExperienceHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      contingentSelfExperienceSessionsMin: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
    });

    this.educationCourseForm.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.formIsValid = status === 'VALID';
        this.cdr.detectChanges();
      });

    // mark depentent fields as touched when parent field changes
    this.educationCourseForm
      .get('contingentTreatmentInternshipHours')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.educationCourseForm
          .get('contingentTreatmentCasesMinHours')
          .markAsTouched();
      });

    this.educationCourseForm
      .get('contingentTreatmentCases')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.educationCourseForm
          .get('contingentTreatmentCasesMin')
          .markAsTouched();
      });

    this.educationCourseForm
      .get('contingentSupervisionHours')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.educationCourseForm
          .get('contingentSupervisionSingleSessionHours')
          .markAsTouched();
        this.educationCourseForm
          .get('contingentSupervisionGroupSessionHours')
          .markAsTouched();
      });
  }

  /**
   * getData
   * get the education course data
   * @returns void
   */
  private getData(educationCourseId: number): void {
    this.educationCourseService
      .getEducationCourseById(educationCourseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.educationCourse =
            await this.educationCourseService.parseBackendEducationCourse(
              response.body
            );

          // put data into form
          this.educationCourseForm.patchValue(this.educationCourse);
          this.educationCourseForm.patchValue({
            procedure: this.educationCourse.procedure?.id,
            expertise: this.educationCourse.expertise?.id,
          });

          this.initialFormValues = this.educationCourseForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Bildungsgang konnte nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * getExpertises
   * Get the expertises
   * @returns void
   */
  private getExpertises(): void {
    this.expertiseService
      .getAllExpertises()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.expertises = result.body;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Fachkunden konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getProcedures
   * Get the procedures
   * @returns void
   */
  private getProcedures(): void {
    this.procedureService
      .getAllProcedures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.procedures = result.body;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Richtlinienverfahren konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * onCancel
   * navigates back to education course list
   * @returns void
   */
  public onCancel(): void {
    const route = this.editMode ? '../../' : '../';
    this.router.navigate([route], { relativeTo: this.activatedRoute });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the education course is created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.educationCourseForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.educationCourseForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateEducationCourse() : this.createEducationCourse();
  }

  /**
   * createEducationCourse
   * creates a new education course
   * @returns void
   */
  private async createEducationCourse(): Promise<void> {
    const educationCourseCreateModel: EducationCourseCreateModel =
      this.buildEducationCourse();
    const createEducationCourseObservable =
      await this.educationCourseService.createEducationCourse(
        educationCourseCreateModel
      );

    createEducationCourseObservable.pipe(first()).subscribe({
      next: response => {
        /* reset inititalFormValue to current value */
        this.initialFormValues = this.educationCourseForm.value;
        this.onCancel();
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Bildungsgang wurde angelegt.'
        );
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Bildungsgang konnte nicht erstellt werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * updateEducationCourse
   * updates an existing education course
   * @returns void
   */
  private async updateEducationCourse(): Promise<void> {
    let EducationCourseUpdateModel: EducationCourseUpdateModel =
      this.buildEducationCourse();
    const updateEducationCourseObservable =
      await this.educationCourseService.updateEducationCourse(
        this.educationCourse.id,
        EducationCourseUpdateModel
      );
    updateEducationCourseObservable.pipe(first()).subscribe({
      next: response => {
        /* reset inititalFormValue to current value */
        this.initialFormValues = this.educationCourseForm.value;
        this.onCancel();
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Bildungsgang wurde bearbeitet.'
        );
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Bildungsgang konnte nicht bearbeitet werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * buildEducationCourse
   * builds an education course object from the form data
   * @returns EducationCourseCreateModel
   */
  private buildEducationCourse(): EducationCourseCreateModel {
    return {
      regulation: this.educationCourseForm.get('regulation').value,
      title: this.educationCourseForm.get('title').value,
      expertiseId: this.educationCourseForm.get('expertise').value,
      procedureId: this.educationCourseForm.get('procedure').value,
      professionalAssociation: this.educationCourseForm.get(
        'professionalAssociation'
      ).value,
      accreditationYear:
        this.educationCourseForm.get('accreditationYear').value,
      chamber: this.educationCourseForm.get('chamber').value,
      internalNotes: this.educationCourseForm.get('internalNotes').value,
      externalNotes: this.educationCourseForm.get('externalNotes').value,
      contingentTheoreticalScientificHours: this.educationCourseForm.get(
        'contingentTheoreticalScientificHours'
      ).value,
      contingentTheoreticalScientificAutoTrainingHours:
        this.educationCourseForm.get(
          'contingentTheoreticalScientificAutoTrainingHours'
        ).value,
      contingentTheoreticalScientificBalintGroupWorkHours:
        this.educationCourseForm.get(
          'contingentTheoreticalScientificBalintGroupWorkHours'
        ).value,
      contingentPractical1Hours: this.educationCourseForm.get(
        'contingentPractical1Hours'
      ).value,
      contingentPractical1MedicalHistorySurveyMin: this.educationCourseForm.get(
        'contingentPractical1MedicalHistorySurveyMin'
      ).value,
      contingentPractical2Hours: this.educationCourseForm.get(
        'contingentPractical2Hours'
      ).value,
      contingentTreatmentInternshipHours: this.educationCourseForm.get(
        'contingentTreatmentInternshipHours'
      ).value,
      contingentCompletedTreatmentCases: this.educationCourseForm.get(
        'contingentCompletedTreatmentCases'
      ).value,
      contingentTreatmentCases: this.educationCourseForm.get(
        'contingentTreatmentCases'
      ).value,
      contingentTreatmentCasesMin: this.educationCourseForm.get(
        'contingentTreatmentCasesMin'
      ).value,
      contingentTreatmentCasesMinHours: this.educationCourseForm.get(
        'contingentTreatmentCasesMinHours'
      ).value,
      contingentSupervisionHours: this.educationCourseForm.get(
        'contingentSupervisionHours'
      ).value,
      contingentSupervisionSingleSessionHours: this.educationCourseForm.get(
        'contingentSupervisionSingleSessionHours'
      ).value,
      contingentSupervisionGroupSessionHours: this.educationCourseForm.get(
        'contingentSupervisionGroupSessionHours'
      ).value,
      contingentSupervisionMedicalHistorySurveyMin:
        this.educationCourseForm.get(
          'contingentSupervisionMedicalHistorySurveyMin'
        ).value,
      contingentSelfExperienceHours: this.educationCourseForm.get(
        'contingentSelfExperienceHours'
      ).value,
      contingentSelfExperienceSessionsMin: this.educationCourseForm.get(
        'contingentSelfExperienceSessionsMin'
      ).value,
    };
  }

  /**
   * set Regulation
   * @param regulation
   */
  public setRegulation(regulation: Regulation) {
    this.educationCourseForm.get('regulation').setValue(regulation);
  }

  /**
   * set ProfessionalAssociation
   * @param professionalAssociation ProfessionalAssociation
   */
  public setPAA(professionalAssociation: ProfessionalAssociation) {
    this.educationCourseForm
      .get('professionalAssociation')
      .setValue(professionalAssociation);
  }

  /**
   * Preserve the original enum order
   */
  public originalRegulationOrder = (
    a: KeyValue<string, Regulation>,
    b: KeyValue<string, Regulation>
  ): number => {
    return 0;
  };

  public originalPaaOrder = (
    a: KeyValue<string, ProfessionalAssociation>,
    b: KeyValue<string, ProfessionalAssociation>
  ): number => {
    return 0;
  };

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.educationCourseForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
