<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Etikett verwalten</h1>
      <h3 class="hasomed-text-h3">Verwalte Sie Ihre Etiketten</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="createLabel()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-file-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Neues Etikett</span>
      </button>
    </div>
  </div>
  <div class="main-content">
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Label column -->
        <ng-container matColumnDef="label">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Etikett
            <app-custom-table-sort-icon [sort]="sort" columnName="label">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let label; dataSource: dataSource"
            class="gray-900"
            mat-cell>
            <div [style.background]="label.color" class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="hasomed-text-small label-text">{{
                label.name
              }}</span>
            </div>
          </td>
        </ng-container>

        <!-- createdAt column -->
        <ng-container matColumnDef="createdAt">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Erstellt am
            <app-custom-table-sort-icon [sort]="sort" columnName="createdAt">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let label; dataSource: dataSource"
            class="gray-600"
            mat-cell>
            <span>
              {{ label.timeCreated | date: 'dd.MM.yyyy' }}
            </span>
          </td>
        </ng-container>

        <!-- createdBy column -->
        <ng-container matColumnDef="createdBy">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Erstellt von
            <app-custom-table-sort-icon [sort]="sort" columnName="createdBy">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let label; dataSource: dataSource"
            class="gray-600"
            mat-cell>
            <div class="user-container">
              <div class="user-icon">
                <img
                  src="{{
                    label.creator?.profilePicture ?? '../assets/img/user.jpg'
                  }}"
                  alt
                  class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(label.creator) }}
                </span>
                <span class="hasomed-text-small">
                  <ng-container [ngSwitch]="label.creator?.roleId">
                    <ng-container *ngSwitchCase="role.ADMINISTRATOR"
                      >Verwaltung</ng-container
                    >
                    <ng-container *ngSwitchCase="role.LECTURER"
                      >Lehrpersonal</ng-container
                    >
                    <ng-container *ngSwitchCase="role.STUDENT"
                      >Kandidat*in</ng-container
                    >
                  </ng-container>
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td
            *matCellDef="let label; dataSource: dataSource"
            class="gray-600"
            mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Etikett Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button (click)="editLabel(label)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>
                <button
                  (click)="deleteLabel(label)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurden noch keine Etiketten angelegt. Legen Sie Etiketten an,
                um Mitgliedern zusätzliche Informationen zuzuordnen.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
