<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Kooperationspartner</h1>
      <h3 class="hasomed-text-h3">
        {{
          isAdmin
            ? 'Erstellen Sie oder bearbeiten Sie Kooperationspartner.'
            : 'Hier finden Sie die Kooperationspartner.'
        }}
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <!-- Search -->
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
        <!-- Filter Button -->
        <button
          id="filterButton"
          [ngClass]="{
            'hasomed-button-primary': hasActiveFilterValue(
              cooperationPartnerFilter,
              true
            ),
            'hasomed-button-white-grey': !hasActiveFilterValue(
              cooperationPartnerFilter,
              true
            ),
            'hasomed-button-active': filterOpened,
          }"
          [matMenuTriggerFor]="filterMenu"
          (onMenuOpen)="filterOpened = true"
          (onMenuClose)="filterOpened = false"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-filter-list hasomed-button-icon"></i>
          <span class="hasomed-button-text">Filter</span>
        </button>

        <!-- Filter Menu -->
        <mat-menu #filterMenu="matMenu" class="filter-overlay">
          <app-filter
            *ngIf="filterOpened"
            [filters]="cooperationPartnerFilter"
            (filterChanged)="cooperationPartnerFilterChanged($event)">
          </app-filter>
        </mat-menu>
      </div>

      <button
        *appCheckPermissions="{
          feature: feature.COOPERATION_PARTNER,
          permission: permission.ADMIN,
        }"
        (click)="onCreateCooperationPartner()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Neuer Kooperationspartner </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="cooperationPartnerFilter"
      (filterChanged)="cooperationPartnerFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Student column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Name
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            (click)="onOpenCooperationPartnerDetail(row.id)"
            matTooltip="Kooperationspartner anzeigen"
            mat-cell
            class="gray-900 cursor-pointer">
            {{ row.name }}
          </td>
        </ng-container>

        <!-- contactPerson column -->
        <ng-container matColumnDef="contactPerson">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Ansprechpartner
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="contactPerson">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ getFullContactPersonName(row) }}
          </td>
        </ng-container>

        <!-- expertise column -->
        <ng-container matColumnDef="expertise">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Fachkunde
            <app-custom-table-sort-icon [sort]="sort" columnName="expertise">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <span *ngIf="row.expertises?.length == 1">
              {{ row.expertises[0].name }}
            </span>
            <span
              *ngIf="row.expertises?.length > 1"
              [matTooltip]="getAllExpertises(row)">
              Mehrere
            </span>
            <span *ngIf="!row.expertises || row.expertises?.length == 0"
              >-</span
            >
          </td>
        </ng-container>

        <!-- supplySector column -->
        <ng-container matColumnDef="supplySector">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Versorgungsbereich
            <app-custom-table-sort-icon [sort]="sort" columnName="supplySector">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            mat-cell
            class="hasomed-text-body gray-600">
            {{ row.supplySector?.name ?? '-' }}
          </td>
        </ng-container>

        <!-- procedure column -->
        <ng-container matColumnDef="procedure">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Richtlinienverfahren
            <app-custom-table-sort-icon [sort]="sort" columnName="procedure">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <span *ngIf="row.procedures?.length == 1">
              {{ row.procedures[0].name }}
            </span>
            <span
              *ngIf="row.procedures?.length > 1"
              [matTooltip]="getAllProcedures(row)">
              Mehrere
            </span>
            <span *ngIf="!row.procedures || row.procedures?.length == 0"
              >-</span
            >
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="center-header-cell">
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                *ngIf="!isAdmin"
                (click)="onOpenCooperationPartnerDetail(row.id)"
                class="table-button-icon"
                matTooltip="Kooperationspartner anzeigen"
                type="button">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
              <button
                #t="matMenuTrigger"
                *appCheckPermissions="{
                  feature: feature.COOPERATION_PARTNER,
                  permission: permission.ADMIN,
                }"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Kooperationspartner Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  *appCheckPermissions="{
                    feature: feature.COOPERATION_PARTNER,
                    permission: permission.VIEW,
                  }"
                  (click)="onOpenCooperationPartnerDetail(row.id)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider
                  *appCheckPermissions="{
                    feature: feature.COOPERATION_PARTNER,
                    permission: permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.COOPERATION_PARTNER,
                    permission: permission.ADMIN,
                  }"
                  (click)="onEditCooperationPartner(row.id)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
                <mat-divider
                  *appCheckPermissions="{
                    feature: feature.COOPERATION_PARTNER,
                    permission: permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.COOPERATION_PARTNER,
                    permission: permission.ADMIN,
                  }"
                  (click)="onDeleteCooperationPartner(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Keine Kooperationspartner gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="isMobile ? 0 : 1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
