import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { InstituteModel } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { SessionService } from 'src/app/services/session.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/configs/environment';
import { SessionWarningComponent } from '../shared-components/session-warning/session-warning.component';

@Component({
  selector: 'app-eleguide',
  templateUrl: './eleguide.component.html',
  styleUrls: ['./eleguide.component.scss'],
})
export class EleGuideComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event) {
    const userMenu = this.el.nativeElement.querySelector('#userMenu');
    const isClickInside = userMenu.contains(event.target as HTMLElement);
    const className = (
      (event.target as HTMLElement).className as unknown as SVGAnimatedString
    ).baseVal?.includes('user-menu-trigger');

    if (!isClickInside && !className && this.userMenuOpen) {
      this.userMenuOpen = false;
    }
    const instituteMenu = this.el.nativeElement.querySelector('#instituteMenu');
    const isClickInsideInstituteMenu = instituteMenu?.contains(
      event.target as HTMLElement
    );
    const classNameInstitute = (
      (event.target as HTMLElement).className as unknown as SVGAnimatedString
    ).baseVal?.includes('institute-menu-trigger');

    if (
      !isClickInsideInstituteMenu &&
      !classNameInstitute &&
      this.instituteMenuOpen
    ) {
      this.instituteMenuOpen = false;
    }
  }

  public userMenuOpen = false;
  public instituteMenuOpen = false;
  public institutes: InstituteModel[] = [];
  public showInstituteMenu = false;

  private sessionWarningDialogRef: any;
  private destroy$: Subject<void> = new Subject<void>();
  public showRoleSwitch =
    environment.roleSwitchingEnabled &&
    !this.userService.currentUserIsSuperadmin();

  constructor(
    public authService: AuthService,
    private router: Router,
    public sidenavService: SidenavService,
    public userService: UserService,
    private el: ElementRef,
    public instituteService: InstituteService,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private telemetryService: TelemetryService
  ) {
    if (this.userService.currentUser.currentInstituteId) {
      this.loadCurrentInstitute();
    }
  }

  public async ngOnInit() {
    this.checkSessionValidity();

    await this.userService.setDecryptedCurrentUser();

    if (!this.userService.currentUser.isSuperadmin) {
      this.showInstituteMenu = true;
      this.loadInstitutes();
    }
  }

  /**
   * checks if the session is expiring and opens a warning dialog if it is
   * @returns void
   */
  private checkSessionValidity(): void {
    this.sessionService
      .checkSessionValidity()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isSessionExpiring => {
        if (isSessionExpiring && !this.sessionWarningDialogRef) {
          this.sessionWarningDialogRef = this.dialog.open(
            SessionWarningComponent
          );
          this.sessionWarningDialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.sessionWarningDialogRef = null;
            });
        } else if (!isSessionExpiring && this.sessionWarningDialogRef) {
          this.sessionWarningDialogRef.close();
        }
      });
  }

  /**
   * navigates to the personal data page and closes the user menu
   * @returns void
   */
  public onOpenSettings(): void {
    this.router.navigate(['/eleguide/settings/personal-data']);
    this.userMenuOpen = false;
  }

  /**
   * navigates to the imprint page and closes the user menu
   * @returns void
   */
  public onOpenInformation(): void {
    this.router.navigate(['/eleguide/settings/imprint']);
    this.userMenuOpen = false;
  }

  /**
   * logout the user and close the user menu
   * @returns void
   */
  public logout(): void {
    this.authService.logout();
    this.userMenuOpen = false;
  }

  /**
   * open or close user menu
   * @returns void
   */
  public onOpenInstituteMenu(): void {
    // if there is no institute to switch to, do nothing
    if (this.filterCurrentInstitute()?.length == 0) {
      return;
    }
    !this.instituteMenuOpen
      ? this.loadInstitutes(true)
      : (this.instituteMenuOpen = false);
  }

  /**
   * open the institute details page
   * @param instituteId
   * @returns void
   */
  public openInstituteDetails(instituteId: number): void {
    const route = this.userService.currentUserIsAdministrator()
      ? 'eleguide/institute/master-data'
      : `eleguide/settings/institute/${btoa(String(instituteId))}`;
    this.router.navigate([route]);
  }

  /**
   * load all institutes for current user
   * @param openMenu
   * @returns void
   */
  private loadInstitutes(openMenu = false): void {
    this.instituteService
      .getCurrentUsersInstitutes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.institutes = response.body
            ? await Promise.all(
                response.body?.map(
                  async (institute: InstituteModel) =>
                    await this.instituteService.parseBackendInstitute(institute)
                )
              )
            : [];
          if (this.filterCurrentInstitute()?.length > 0 && openMenu) {
            this.instituteMenuOpen = true;
          }
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Institutsliste konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * filter out current institute from institutes
   * @returns all institutes except the current one
   */
  public filterCurrentInstitute(): InstituteModel[] {
    return this.institutes?.filter(
      institute => institute.id !== this.instituteService.currentInstitute?.id
    );
  }

  /**
   * switch to selected institute
   * @param institute
   * @returns void
   */
  public onSwitchInstitute(institute: InstituteModel): void {
    this.instituteMenuOpen = false;
    this.userService
      .switchUsersInstitute(institute.id)
      .pipe(first())
      .subscribe({
        next: response => {
          window.location.reload();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler!',
            'Wechsel des Instituts fehlgeschlagen.'
          );
          return;
        },
      });
  }

  /**
   * load current institute
   * @returns Promise<void>
   */
  public async loadCurrentInstitute(): Promise<void> {
    this.instituteService
      .getInstitute(this.userService.currentUser.currentInstituteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.instituteService.setCurrentInstitute(
            await this.instituteService.parseBackendInstitute(response.body)
          );
        },
        error: error => {},
      });
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
