import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { RoomModel } from 'src/app/models/room.model';
import { FilterRoomOverviewPipe } from 'src/app/pipes/filter-room-overview.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { RoomService } from 'src/app/services/room.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrl: './rooms.component.scss',
})
export class RoomsComponent implements OnInit, OnDestroy {
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });
  public rooms: RoomModel[] = [];

  displayedColumns: string[] = [
    'name',
    'roomType',
    'floor',
    'building',
    'seatNumber',
    'equipment',
    'available',
    'actions',
  ];
  public dataSource: MatTableDataSource<RoomModel> =
    new MatTableDataSource<RoomModel>();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  public roomFilter: Filter[] = [
    {
      type: FilterType.ROOM_FLOOR,
      value: null,
    },
    {
      type: FilterType.ROOM_CAPACITY,
      value: null,
    },
    {
      type: FilterType.ROOM_ACTIVE,
      value: null,
    },
  ];
  public filterOpened: boolean = false;
  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private roomService: RoomService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the rooms of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        case 'roomType':
          return item.roomType?.name;
        case 'floor':
          return item.floor;
        case 'building':
          return item.building;
        case 'seatNumber':
          return item.seatNumber;
        case 'equipment':
          return item.equipment;
        case 'available':
          return item.isAvailable;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getRooms();
  }

  /**
   * roomFilterChanged
   * gets called when the room filter changed
   * @param roomFilter Filter[]
   * @returns void
   */
  public roomFilterChanged(roomFilter: Filter[]): void {
    this.roomFilter = roomFilter;
    this.applyRoomFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applyRoomFilter
   * applies the room filter
   * @returns void
   */
  private applyRoomFilter(): void {
    this.dataSource.data = FilterRoomOverviewPipe.prototype.transform(
      this.rooms,
      this.roomFilter
    );
  }

  /**
   * retrieves all rooms of the current institute and initializes the table data
   * @returns void
   */
  public getRooms(): void {
    this.roomService
      .getAllRooms()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.rooms = response.body
            ? await Promise.all(
                response.body?.map(
                  async (roomData: any): Promise<RoomModel> => {
                    return await this.roomService.parseBackendRoom(roomData);
                  }
                )
              )
            : [];
          this.dataSource.data = this.rooms;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Räume konnten nicht geladen werden! Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * goBackToRoomOrganization
   * navigates back to the room organization
   * @returns void
   */
  public goBackToRoomOrganization(): void {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreateNewRoom
   * navigates to the create new room component
   * @returns void
   */
  public onCreateNewRoom(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onOpen
   * navigates to the detail room component
   * @param room
   * @returns void
   */
  public onOpen(room: RoomModel): void {
    this.router.navigate([btoa(String(room.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditRoom
   * navigates to the edit room component
   * @param room
   * @returns void
   */
  public onEdit(room: RoomModel): void {
    this.router.navigate(['./edit/', btoa(String(room.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteRoom
   * check if there are upcoming eventDates in the room
   * Ask the user if he wants to delete the room
   * @param room
   * @returns void
   */
  public onDelete(room: RoomModel): void {
    this.roomService
      .hasRoomUpcomingEventDates(room.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.body) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                title: `Bevorstehende Termine in Raum '${room.name}'!`,
                message:
                  'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
              Möchten Sie den Raum trotzdem löschen?',
              },
            });

            dialogRef
              .afterClosed()
              .pipe(first())
              .subscribe(dialogResult => {
                if (dialogResult) {
                  this.deleteRoom(room);
                }
              });
          } else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                title: 'Raum löschen',
                message: 'Möchten Sie den Raum wirklich löschen?',
              },
            });

            dialogRef
              .afterClosed()
              .pipe(first())
              .subscribe(dialogResult => {
                if (dialogResult) {
                  this.deleteRoom(room);
                }
              });
          }
        },
      });
  }

  /**
   * deleteRoom
   * calls the deleteRoom service function
   * @param room
   * @returns void
   */
  private deleteRoom(room: RoomModel): void {
    this.roomService
      .deleteRoom(room.id)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Der Raum '${room.name}' wurde gelöscht!`
          );
          this.dataSource.data = this.dataSource.data.filter(
            (data: RoomModel) => data.id !== room.id
          );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Der Raum '${room.name}' wurde nicht gelöscht!`
          );
        },
      });
  }

  /**
   * onRoomActiveChanged
   * checks if there are upcoming eventDates in the room and asks if the user wants to deactivate the room
   * @param slideToggleData MatSlideToggleChange
   * @param room Room
   * @returns void
   */
  public onRoomActiveChanged(
    slideToggleData: MatSlideToggleChange,
    room: RoomModel
  ): void {
    // check if there are upcoming eventDates in the room
    // if so, ask if the user wants to deactivate the room
    if (!slideToggleData.checked) {
      this.roomService
        .hasRoomUpcomingEventDates(room.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: response => {
            if (response.body) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                maxWidth: '400px',
                data: {
                  title: `Bevorstehende Termine in Raum '${room.name}'!`,
                  message:
                    'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
                  Wenn Sie den Raum deaktivieren, wird der Raum aus allen bevorstehenden Terminen entfernt. \
                  Möchten Sie den Raum trotzdem deaktivieren?',
                },
              });

              dialogRef
                .afterClosed()
                .pipe(first())
                .subscribe(async dialogResult => {
                  if (dialogResult) {
                    await this.removeRoomFromFutureEventDates(room);
                    this.updateRoomAvailableState(slideToggleData, room);
                  } else {
                    slideToggleData.source.checked = true;
                  }
                });
            } else {
              this.updateRoomAvailableState(slideToggleData, room);
            }
          },
        });
    } else {
      this.updateRoomAvailableState(slideToggleData, room);
    }
  }

  /**
   * updateRoomAvailableState
   * updates the available state of the room
   * @param data MatSlideToggleChange
   * @param room Room
   * @returns void
   */
  private updateRoomAvailableState(
    data: MatSlideToggleChange,
    room: RoomModel
  ): void {
    const updateRoomObservable = this.roomService.enableOrDisableRoom(
      room.id,
      data.checked
    );
    updateRoomObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          `Das hat geklappt!`,
          `Der Raum '${room.name}' wurde ${data.checked ? 'aktiviert' : 'deaktiviert'}!`
        );
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Raum '${room.name}' konnte nicht ${data.checked ? 'aktiviert' : 'deaktiviert'} werden!`
        );
      },
    });
  }

  /**
   * removeRoomFromFutureEventDates
   * @param room
   */
  private async removeRoomFromFutureEventDates(room: RoomModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.roomService
        .removeRoomFromUpcomingEventDates(room.id)
        .pipe(first())
        .subscribe({
          next: result => {
            this.alertService.showSuccessAlert(
              'Raum aus Terminen entfernt',
              `Der Raum '${room.name}' wurde aus allen bevorstehenden Terminen entfernt!`
            );
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Fehler',
              `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden!`
            );
            reject();
          },
        });
    });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
