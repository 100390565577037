<app-event-room-planning
  *ngIf="roomPlanningOpen"
  [title]="courseForm.value.title"
  [participants]="courseForm.value.maxStudents"
  [numberOfLecturers]="selectedLecturerIds.length"
  [isCourse]="true"
  [eventDates]="events"
  (onRoomPlanningClose)="roomPlanningChanged($event)"
  subtitle="Planen Sie die Räume des Kurses">
</app-event-room-planning>

<form
  [class.hide]="roomPlanningOpen"
  [formGroup]="courseForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Kurs bearbeiten' : 'Kurs erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Kurs.'
            : 'Erstellen Sie einen neuen Kurs.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Zurück zur Übersicht </span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content-form">
    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Aus- und Weiterbildungsgang
          <span *ngIf="isRequired(courseForm.get('educationCourses'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie einen oder mehrere Aus- und Weiterbildungsgänge.
        </span>
      </div>
      <div class="formular-row-input">
        <eleguide-multiple-select
          [array]="educationCoursesFormArray"
          arrayName="educationCourses"
          [options]="availableEducationCoursesSelectOptions"
          placeholder="Aus- und Weiterbildungsgang"
          [isRequired]="
            isRequired(educationCoursesFormArray)
          "></eleguide-multiple-select>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Kurstitel
          <span *ngIf="isRequired(courseForm.get('title'))">*</span>
        </span>
        <span class="label-description">Name des Kurses.</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung des Kurses
          <span *ngIf="isRequired(courseForm.get('description'))">*</span>
        </span>
        <span class="label-description"> Ergänzende Informationen. </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <editor
            id="description"
            class="custom-tinymce"
            formControlName="description"
            apiKey="pwuyu1utswoe8a7fq51fce9sg0l14qtxvcpxxnbpi180702l"
            [init]="tinyMceSettings"></editor>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('description')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="titlePicture">
        <span class="label-title">
          Titelbild des Kurses
          <span>*</span>
        </span>
        <span class="label-description">
          Hier können Sie ein Bild fürden Kurs hochladen oder eines aus der
          Vorauswahl wählen.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div id="title-picture" class="flex-c title-picture-upload-wrapper">
            <div class="formular-row-input-image-upload">
              <div
                *ngIf="courseForm.get('titlePicture').value"
                class="uploaded-image-container">
                <img
                  [attr.src]="courseForm.get('titlePicture').value"
                  class="uploaded-image"
                  alt="Titelbild Kurs" />
                <div class="delete-image" (click)="deleteImage()">
                  <i class="fa-light fa-trash-can delete-icon"></i>
                  <div class="delete-bg"></div>
                </div>
              </div>
              <app-upload-area-dnd
                [context]="'courseTitlePictureUpload'"
                [multiple]="false"
                [scrollTo]="false"
                [showFileList]="false"
                [allowedFileTypes]="allowedFileTypes"
                (change)="imageChangeEvent($event)"
                class="image-upload-area"
                inputId="titlePicture"
                maxFileSize="10 MB">
              </app-upload-area-dnd>
            </div>
            <span
              *ngIf="!courseForm.get('titlePicture').value"
              class="span-between">
              oder
            </span>
            <div
              *ngIf="!courseForm.get('titlePicture').value"
              class="formular-row-input">
              <div (click)="onDefaultImageSelected(1)" class="image">
                <img
                  src="/assets/img/course-img-1.jpg"
                  class="image"
                  alt="Default Image 1" />
                <div *ngIf="selectedImageId === 1" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>

              <div (click)="onDefaultImageSelected(2)" class="image">
                <img
                  src="/assets/img/course-img-2.jpg"
                  class="image"
                  alt="Default Image 2" />
                <div *ngIf="selectedImageId === 2" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>

              <div (click)="onDefaultImageSelected(3)" class="image">
                <img
                  src="/assets/img/course-img-3.jpg"
                  class="image"
                  alt="Default Image 3 - Vorlesungssaal" />
                <div *ngIf="selectedImageId === 3" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('titlePicture')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Kurstyp
          <span *ngIf="isRequired(courseForm.get('type'))">*</span>
        </span>
        <span class="label-description"> Wählen Sie einen Kurstyp. </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="courseTypeControl"
            controlId="type"
            [options]="availableCourseTypesSelectOptions"
            placeholder="Kurstyp"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('type')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Lehrinhalt
          <span *ngIf="isRequired(courseForm.get('learningContents'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie einen oder mehrere Lehrinhalt/-e.
        </span>
      </div>

      <div class="formular-row-input">
        <eleguide-multiple-select
          [array]="learningContentsFormArray"
          arrayName="learningContents"
          [options]="availableLearningContentsSelectOptions"
          placeholder="Lehrinhalt"
          [isRequired]="
            isRequired(learningContentsFormArray)
          "></eleguide-multiple-select>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Art der Prüfung
          <span *ngIf="isRequired(courseForm.get('examType'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie eine oder mehrere Prüfungsart/-en.
        </span>
      </div>
      <div class="formular-row-input">
        <eleguide-multiple-select
          [array]="examTypesFormArray"
          arrayName="examTypes"
          [options]="availableExamTypesSelectOptions"
          placeholder="Prüfungsart"
          [isRequired]="
            isRequired(examTypesFormArray)
          "></eleguide-multiple-select>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Curriculum relevant
          <span *ngIf="isRequired(courseForm.get('mandatory'))">*</span>
        </span>
        <span class="label-description">
          Legen Sie fest, ob der Kurs ausbildungsrelevant ist.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-checkbox
            id="mandatoryCheckbox"
            class="input-checkbox"
            type="checkbox"
            color="primary"
            formControlName="mandatory">
            <div class="checkbox-title">Curriculum relevant</div>
          </mat-checkbox>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('mandatory')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <label class="formular-row-label" for="lecturerSearch">
        <span class="label-title">
          Lehrpersonal
          <span *ngIf="isRequired(courseForm.get('lecturers'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie ein oder mehrere Personen als Lehrpersonal für den Kurs
          aus.
        </span>
      </label>

      <div class="hasomed-input-field gap-16">
        <div class="formular-row-input">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                #lecturerInput
                id="lecturerSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="lecturerSearch" />
            </div>
            <div
              *ngIf="lecturers && selectedLecturerIds"
              [ngClass]="{
                'error-border':
                  courseForm.get('lecturers').invalid &&
                  courseForm.get('lecturers').touched,
              }"
              id="lecturers"
              class="flex-c vertical-overflow gap-8 users">
              <div
                *ngFor="
                  let lecturer of lecturers | searchFilter: lecturerInput.value
                "
                [ngClass]="{
                  active: selectedLecturerIds.includes(lecturer.id),
                }"
                (click)="onLecturerSelected(lecturer)"
                class="flex-r user-container">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        lecturer.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt="Profilbild des Lehrpersonals"
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ getFullName(lecturer) }}
                    </span>
                    <span class="hasomed-text-small">
                      {{
                        getAllAdditionalQualifications(lecturer)
                          ? 'Lehrpersonal, ' +
                            getAllAdditionalQualifications(lecturer)
                          : 'Lehrpersonal'
                      }}
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedLecturerIds.includes(lecturer.id)">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('lecturers')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Geschlossener Kurs
          <span *ngIf="isRequired(courseForm.get('closedCourse'))">*</span>
        </span>
        <span class="label-description">
          Legen Sie fest, ob nur eingeladene Kandidaten an dem Kurs teilnehmen
          können.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="closedCourseControl"
            controlId="closedCourse"
            [options]="availableClosedCourseSelectOptions"
            placeholder="offen / geschlossen"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('closedCourse')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer && courseClosed" class="formular-row">
      <label class="formular-row-label" for="participantsSearch">
        <span class="label-title">
          Teilnehmer
          <span *ngIf="isRequired(courseForm.get('participants'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie die Teilnehmer für diesen Kurs.
        </span>
      </label>

      <div class="hasomed-input-field gap-16">
        <div class="formular-row-input">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                #participantInput
                id="participantsSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="participantSearch" />
            </div>
            <div
              *ngIf="participants && selectedParticipantIds"
              class="flex-c vertical-overflow gap-8 users">
              <div
                *ngFor="
                  let participant of participants
                    | searchFilter: participantInput.value
                "
                [ngClass]="{
                  active: selectedParticipantIds.includes(participant.id),
                }"
                (click)="onParticipantSelected(participant)"
                class="flex-r user-container">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        participant.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ getFullName(participant) }}
                    </span>
                    <span class="hasomed-text-small">
                      {{
                        participant.educationCourse?.title
                          ? participant.educationCourse?.title
                          : 'Kein Aus- / Weiterbildungsgang'
                      }}
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedParticipantIds.includes(participant.id)">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('participants')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer && courseClosed"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <label class="formular-row-label" for="maxStudents">
        <span class="label-title">
          Maximale Anzahl der Teilnehmer
          <span *ngIf="isRequired(courseForm.get('maxStudents'))">*</span>
        </span>
        <span class="label-description">
          Regulieren Sie die Teilnehmerzahl.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            [min]="participantCount"
            id="maxStudents"
            class="hasomed-text-input"
            type="number"
            max="999"
            formControlName="maxStudents" />
          <span class="hasomed-text-body italic">
            + {{ lecturerCount }} Lehrpersonal =
            {{ courseForm.get('maxStudents').value + lecturerCount }}
            Teilnehmer
          </span>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('maxStudents')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Einheit pro Termin
          <span *ngIf="isRequired(courseForm.get('duration'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie die Einheiten pro Termin.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="durationControl"
            controlId="duration"
            [options]="availableCourseDurationSelectOptions"
            placeholder="Dauer"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Gesamtzeitraum des Kurses
          <span>*</span>
        </span>
        <span class="label-description">
          Definieren Sie zunächst den Gesamtzeitraum für den Kurs und
          konfigurieren Sie anschließend alle individuellen Termine.
        </span>
      </div>

      <div class="hasomed-input-field">
        <app-event-picker
          *ngIf="events.length > 0 || !editMode || (startDate && endDate)"
          [events]="events"
          [duration]="+courseForm.get('duration').value"
          [startDate]="startDate"
          [endDate]="endDate"
          [allDayEvent]="allDayEvent"
          [recurrencePattern]="recurrencePattern"
          [parentFormSubmitValidation]="formSubmitted"
          (eventsChanged)="onEventsChanged($event)"
          (recurrencePatternChanged)="recurrencePattern = $event"
          (datesChanged)="onDatesChanged($event)"
          class="formular-row-input"
          formControlName="dateGroup"
          id="dateGroup"></app-event-picker>
        <app-error-message
          [formField]="courseForm.get('dateGroup')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div *ngIf="!isLecturer" class="formular-row">
      <label class="formular-row-label" for="roomPlanning">
        <span class="label-title">
          Ort des Kurses
          <span *ngIf="isRequired(courseForm.get('location'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie zunächst den Ort des Kurses und rufen Sie anschließend die
          Raumplanung auf oder/und fügen Sie eine URL für ein Online-Meeting
          hinzu.
        </span>
      </label>

      <div class="formular-row-input-columns">
        <div class="flex-r gap-16">
          <button
            *ngFor="
              let courseLocation of CourseLocation
                | keyvalue: originalCourseLocationOrder
            "
            [ngClass]="{
              'hasomed-button-primary':
                courseForm.get('location').value === courseLocation.value,
              'hasomed-button-white-grey':
                courseForm.get('location').value != courseLocation.value,
            }"
            (click)="setCourseLocation(courseLocation.value)"
            type="button"
            class="hasomed-text-button">
            <span class="hasomed-button-text">
              {{ courseLocation.value }}
            </span>
          </button>
        </div>

        <button
          [class.hasomed-button-red]="roomPlanningValid !== true"
          [disabled]="roomPlanningDisabled"
          (click)="openRoomPlanning()"
          id="roomPlanning"
          type="button"
          class="hasomed-text-button hasomed-button-primary button-full-width">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon"></i>
          <span class="hasomed-button-text">
            {{ roomPlanningValid ? 'Raumplanung bearbeiten' : 'Raumplanung' }}
          </span>
        </button>
        <span *ngIf="roomPlanningValid !== true" class="flex-r justify-center">
          Nicht alle Termine haben einen Raum
        </span>

        <!-- LINK -->
        <div *ngIf="showLink" class="hasomed-input-field">
          <div class="formular-row-input">
            <label class="formular-row-label link-label" for="link">
              <span class="label-title">
                URL für Video-Meeting
                <span *ngIf="isRequired(courseForm.get('link'))">*</span>
              </span>
            </label>
            <input
              id="link"
              class="hasomed-text-input"
              type="text"
              formControlName="link"
              placeholder="URL für Video-Meeting" />
          </div>
          <app-error-message
            [formField]="courseForm.get('link')"></app-error-message>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="internalNotes">
        <span class="label-title">
          Interne Notizen
          <span *ngIf="isRequired(courseForm.get('internalNotes'))">*</span>
        </span>
        <span class="label-description">
          Diese Notizen sind ausschließlich für das Lehrpersonal und die
          Verwaltung sichtbar.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="internalNotes"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="internalNotes"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('internalNotes')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumente für den Kurs
          <span *ngIf="isRequired(courseForm.get('courseDocumentsUpload'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Hier können alle Kursrelevanten Dokumente hochgeladen werden.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [allowedFileTypes]="allowedFileTypesDocuments"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="courseDocumentsUpload"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
