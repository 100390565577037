import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  PracticalWorkCreateModel,
  PracticalWorkModel,
} from '../models/practical-work.model';
import { CancellationService } from './cancellation.service';
import { CooperationPartnerService } from './cooperation-partner.service';
import { FileService } from './file.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PracticalWorkService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cooperationPartnerService: CooperationPartnerService,
    private fileService: FileService,
    private cancellationService: CancellationService
  ) {}

  /**
   * Gets all practical works from the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllPracticalWorksByInstituteId(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/practical-works`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets all practical works from a student
   * @param studentId The student id
   * @returns Observable<HttpResponse<any>>
   */
  public getAllPracticalWorksByStudentId(
    studentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/practical-works/student/${studentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets a practical work by id
   * @param practicalWorkId The practical work id
   * @returns Observable<HttpResponse<any>>
   */
  public getPracticalWorkById(
    practicalWorkId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/practical-works/${practicalWorkId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Creates a new practical work for a student
   * @param practicalWork The practical work
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createPracticalWork(
    practicalWork: PracticalWorkCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/practical-works`,
      practicalWork,
      {
        headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Updates a practical work
   * @param practicalWorkId The practical work id
   * @param practicalWork The practical work
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updatePracticalWork(
    practicalWorkId: number,
    practicalWork: PracticalWorkCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/practical-works/${practicalWorkId}`,
      practicalWork,
      {
        headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Deletes a practical work
   * @param practicalWorkId The practical work id
   * @returns Observable<HttpResponse<any>>
   */
  public deletePracticalWork(
    practicalWorkId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/practical-works/${practicalWorkId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param practicalWorkId
   * @param fileId
   * @returns void
   */
  public openFile(practicalWorkId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/practical-works/${practicalWorkId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param practicalWorkId
   * @param fileId
   * @returns void
   */
  public downloadFile(practicalWorkId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/practical-works/${practicalWorkId}/files/${fileId}`
    );
  }

  /**
   * Parses a practical work from the backend
   * @param practicalWork The practical work data from the backend
   * @returns Promise<PracticalWorkModel>
   */
  public async parsePracticalWork(
    practicalWork: PracticalWorkModel
  ): Promise<PracticalWorkModel> {
    const parsedPracticalWork: PracticalWorkModel = {
      id: practicalWork.id,
      type: practicalWork.type,
      description: practicalWork.description,
      cooperationPartner:
        await this.cooperationPartnerService.parseBackendCooperationPartner(
          practicalWork.cooperationPartner
        ),
      duration: practicalWork.duration,
      startDate: moment(practicalWork.startDate).tz('Europe/Berlin').toDate(),
      endDate: moment(practicalWork.endDate).tz('Europe/Berlin').toDate(),
      files: await this.fileService.parseBackendFiles(practicalWork.files),
    };
    return parsedPracticalWork;
  }
}
