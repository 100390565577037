import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CourseModel } from 'src/app/models/course.model';
import { UserModel } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { getTotalEducationalProgressInPercent } from 'src/app/utils/educational-progress.utils';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-course-participants',
  templateUrl: './course-participants.component.html',
  styleUrls: ['./course-participants.component.scss'],
})
export class CourseParticipantsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<UserModel> =
    new MatTableDataSource<UserModel>();
  public columnsToDisplay = ['user'];
  public isLoading = true;
  public course: CourseModel;
  public isStudent: boolean = this.userService.currentUserIsStudent();
  private courseParticipants: UserModel[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // import from utils
  public getTotalEducationalProgressInPercent =
    getTotalEducationalProgressInPercent;
  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private cancellationService: CancellationService,
    private navigationService: NavigationService
  ) {}

  public ngOnInit() {
    const courseId = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.initTable(courseId);
  }

  /**
   * adjust the displayed columns based on the user role
   * initializes the sorting and pagination of the table
   * inits the table data with the students of the current course
   * @param courseId The id of the course
   * @returns void
   */
  private initTable(courseId: number): void {
    if (!this.isStudent) {
      this.columnsToDisplay = [
        'user',
        'userIdentifier',
        'educationalProgress',
        'entryDate',
        'label',
        'actions',
      ];
    }
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'user':
          return item.name.firstName + ' ' + item.name.lastName;
        case 'educationalProgress':
          return getTotalEducationalProgressInPercent(item);
        case 'label':
          return item.label?.name;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getCourseParticipants(courseId);
  }

  /**
   * retrieves all students of a course and initializes the table data
   * @param courseId The id of the course
   * @returns void
   */
  private getCourseParticipants(courseId: number): void {
    this.courseService
      .getCourseStudents(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.courseParticipants = response.body
            ? await Promise.all(
                response.body.map(async (user: UserModel) => {
                  return await this.userService.parseBackendUser(user);
                })
              )
            : [];

          this.dataSource.data = this.courseParticipants;

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
        },
      });
  }

  /**
   * showParticipantDetails
   * navigates to the participants details page
   * @param user
   */
  public showParticipantDetails(user: UserModel) {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate(
      ['../../../', 'students', 'detail', btoa(user.id.toString())],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * cancel all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
