<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Behandlungsfall</h1>
      <h3 class="hasomed-text-h3">Sehen Sie sich den Behandlungsfall an.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.TREATMENT_CASE,
          permission: permission.ADMIN,
        }"
        (click)="onEditTreatmentCase()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Patienten Chiffre</span>
      </div>
      <div class="detail-value">
        {{ treatmentCase.patientChiffre }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Alter</span>
      </div>

      <div class="detail-value">
        {{ treatmentCase.patientAge }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Therapieform</span>
      </div>
      <div class="detail-value">
        {{ treatmentCase.therapyForm }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Therapyphase</span>
      </div>
      <div class="detail-value">
        {{ treatmentCase.therapyPhase.name }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Behandlungsverfahren </span>
      </div>
      <div class="detail-value">
        {{ getAllTreatmentProcedures(treatmentCase) }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Supervisor(en)</span>
      </div>
      <div class="detail-value">
        {{ getAllSupervisors(treatmentCase) ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Begleitpersonen</span>
      </div>
      <div class="detail-value">
        {{ getAllAccompanyingPersons(treatmentCase) ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Beschreibung</span>
      </div>
      <div class="detail-value">
        {{ treatmentCase.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dokumente für den Behandlungsfall </span>
      </div>
      <div class="detail-value-files">
        <app-file-view
          *ngFor="let file of treatmentCase?.files"
          [file]="file"
          (downloadFile)="onDownloadFile($event)"
          (openFile)="onOpenFile($event)"></app-file-view>
        <span
          *ngIf="!treatmentCase?.files || treatmentCase?.files?.length == 0"
          class="detail-value">
          Keine Dokumente hochgeladen.
        </span>
      </div>
    </div>
  </div>
</div>
