<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Dokumentenübersicht</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie Ihre Dokumente.</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>

      <!-- <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            courseFilter,
            true
          ),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button> -->

      <!-- Filter Menu -->
      <!-- <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened"
          [filters]="courseFilter"
          (filterChanged)="courseFilterChanged($event)"
          [courses]="courses">
        </app-filter>
      </mat-menu> -->

      <button
        *appCheckPermissions="{
          feature: Feature.DB_DOCUMENTS,
          permission: Permission.ADMIN,
        }"
        type="button"
        (click)="onUpload()"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-file-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Dokument hochladen</span>
      </button>
    </form>
  </div>

  <div class="main-content">
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="flex-c table course-details-main">
      <table
        [dataSource]="dataSource"
        mat-table
        class="course-details-table"
        matSort>
        <!-- lecturer column / using firstName for sorting -->
        <ng-container matColumnDef="file">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Dateien
            <app-custom-table-sort-icon [sort]="sort" columnName="file">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            mat-cell
            class="gray-900">
            <div class="flex-r gap-12 align-center">
              <i class="fa-light fa-file"></i>
              <span>
                {{ getFileNameOnly(row) }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- owner column -->
        <ng-container matColumnDef="creator">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Eigentümer</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="creator">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ getFullName(row.creator) }}
          </td>
        </ng-container>

        <!-- mofiedDate column -->
        <ng-container matColumnDef="modifiedDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Änderungsdatum
            <app-custom-table-sort-icon [sort]="sort" columnName="modifiedDate">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            mat-cell
            [matTooltip]="
              ((row.timeModified | date: 'dd.MM.yyyy HH:mm') ??
                (row.timeCreated | date: 'dd.MM.yyyy HH:mm')) + ' Uhr'
            ">
            {{
              (row.timeModified | date: 'dd.MM.yyyy') ??
                (row.timeCreated | date: 'dd.MM.yyyy')
            }}
          </td>
        </ng-container>

        <!-- fileSize column -->
        <ng-container matColumnDef="fileSize">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Dateigröße
            <app-custom-table-sort-icon [sort]="sort" columnName="fileSize">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ formatBytes(row.size, 2) }}
          </td>
        </ng-container>

        <!-- fileType column -->
        <ng-container matColumnDef="fileType">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Dateityp
            <app-custom-table-sort-icon [sort]="sort" columnName="fileType">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <span>
              {{ row.type.toUpperCase() + '-' + getFileType(row) }}
            </span>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Datei Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button (click)="onOpen(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>

                <mat-divider
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN,
                  }"
                  (click)="onEdit(row)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button (click)="onDownload(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
                    <span class="hasomed-text-body gray-600"
                      >Herunterladen</span
                    >
                  </div>
                </button>

                <mat-divider
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN,
                  }"
                  (click)="onDelete(row)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
          <tr *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="columnsToDisplay.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurden keine Dateien gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
