import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  EducationCourse,
  EducationCourseCreateModel,
  EducationCourseUpdateModel,
} from '../models/education-course.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EducationCourseService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private userService: UserService
  ) {}

  /**
   * getInstituteEducationCourses
   * get education courses of institute
   * @returns Observable
   */
  public getInstituteEducationCourses(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/educationCourses`,
        { responseType: 'json', observe: 'response' }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getEducationCourseByID
   * get education course by id
   * @param educationCourseId id of the educationCourse
   * @returns Observable
   */
  public getEducationCourseById(
    educationCourseId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/educationCourses/${educationCourseId}`,
        { responseType: 'json', observe: 'response' }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * newEducationCourse
   * create a new educationCourse
   * @param educationCourseCreateModel educationCourse to create
   * @returns Observable
   */
  public async createEducationCourse(
    educationCourseCreateModel: EducationCourseCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    educationCourseCreateModel.internalNotes =
      educationCourseCreateModel.internalNotes
        ? await this.cryptoService.encrypt(
            educationCourseCreateModel.internalNotes
          )
        : null;

    educationCourseCreateModel.externalNotes =
      educationCourseCreateModel.externalNotes
        ? await this.cryptoService.encrypt(
            educationCourseCreateModel.externalNotes
          )
        : null;

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/educationCourses`,
      educationCourseCreateModel,
      {
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  /**
   * updateEducationCourse
   * update educationCourse
   * @param educationCourseId id of the educationCourse
   * @param educationCourse educationCourse to update
   * @returns Observable
   */
  public async updateEducationCourse(
    educationCourseId: number,
    educationCourseUpdateModel: EducationCourseUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    educationCourseUpdateModel.internalNotes =
      educationCourseUpdateModel.internalNotes
        ? await this.cryptoService.encrypt(
            educationCourseUpdateModel.internalNotes
          )
        : null;

    educationCourseUpdateModel.externalNotes =
      educationCourseUpdateModel.externalNotes
        ? await this.cryptoService.encrypt(
            educationCourseUpdateModel.externalNotes
          )
        : null;

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/educationCourses/${educationCourseId}`,
      educationCourseUpdateModel,
      { responseType: 'json', observe: 'response' }
    );
  }

  /**
   * deleteEducationCourse
   * delete educationCourse
   * @param educationCourseId id of the educationCourse
   * @returns Observable
   */
  public deleteEducationCourse(
    educationCourseId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/educationCourses/${educationCourseId}`,
      { responseType: 'json', observe: 'response' }
    );
  }

  /**
   * parseBackendEducationCourse
   */
  public async parseBackendEducationCourse(
    educationCourse: EducationCourse
  ): Promise<EducationCourse> {
    const parsedEducationCourse: EducationCourse = {
      id: educationCourse.id,
      instituteId: educationCourse.instituteId,
      regulation: educationCourse.regulation,
      title: educationCourse.title,
      expertise: educationCourse.expertise,
      procedure: educationCourse.procedure,
      professionalAssociation: educationCourse.professionalAssociation,
      accreditationYear: educationCourse.accreditationYear,
      chamber: educationCourse.chamber,
      internalNotes: await this.decryptionService.decryptString(
        educationCourse.internalNotes
      ),
      externalNotes: await this.decryptionService.decryptString(
        educationCourse.externalNotes
      ),
      contingentTheoreticalScientificHours:
        educationCourse.contingentTheoreticalScientificHours,
      contingentTheoreticalScientificAutoTrainingHours:
        educationCourse.contingentTheoreticalScientificAutoTrainingHours,
      contingentTheoreticalScientificBalintGroupWorkHours:
        educationCourse.contingentTheoreticalScientificBalintGroupWorkHours,
      contingentPractical1Hours: educationCourse.contingentPractical1Hours,
      contingentPractical1MedicalHistorySurveyMin:
        educationCourse.contingentPractical1MedicalHistorySurveyMin,
      contingentPractical2Hours: educationCourse.contingentPractical2Hours,
      contingentTreatmentInternshipHours:
        educationCourse.contingentTreatmentInternshipHours,
      contingentCompletedTreatmentCases:
        educationCourse.contingentCompletedTreatmentCases,
      contingentTreatmentCases: educationCourse.contingentTreatmentCases,
      contingentTreatmentCasesMin: educationCourse.contingentTreatmentCasesMin,
      contingentTreatmentCasesMinHours:
        educationCourse.contingentTreatmentCasesMinHours,
      contingentSupervisionHours: educationCourse.contingentSupervisionHours,
      contingentSupervisionSingleSessionHours:
        educationCourse.contingentSupervisionSingleSessionHours,
      contingentSupervisionGroupSessionHours:
        educationCourse.contingentSupervisionGroupSessionHours,
      contingentSupervisionMedicalHistorySurveyMin:
        educationCourse.contingentSupervisionMedicalHistorySurveyMin,
      contingentSelfExperienceHours:
        educationCourse.contingentSelfExperienceHours,
      contingentSelfExperienceSessionsMin:
        educationCourse.contingentSelfExperienceSessionsMin,
    };

    return parsedEducationCourse;
  }
}
