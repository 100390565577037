<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Selbsterfahrung hinzufügen</h1>
      <h3 class="hasomed-text-h3">Fügen Sie neue Einträge hinzu.</h3>
    </div>

    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <button
        (click)="navigateToCreateSelfAwareness()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Neuen Eintrag vornehmen </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- selfAwarenessTrainer column -->
        <ng-container matColumnDef="selfAwarenessTrainer">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Selbsterfahrungsleitung
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="selfAwarenessTrainer">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let selfAwarenessEntry; dataSource: dataSource"
            (click)="navigateToViewSelfAwareness(selfAwarenessEntry)"
            class="cursor-pointer gray-900"
            mat-cell
            matTooltip="Selbsterfahrungeintrag anzeigen">
            {{ getFullName(selfAwarenessEntry.selfAwarenessTrainer) }}
          </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Datum
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let selfAwarenessEntry; dataSource: dataSource"
            mat-cell>
            {{ selfAwarenessEntry.date | date: 'dd.MM.yyyy' }}
          </td>
        </ng-container>

        <!-- duration Column -->
        <ng-container matColumnDef="duration">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Dauer
            <app-custom-table-sort-icon [sort]="sort" columnName="duration">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let selfAwarenessEntry; dataSource: dataSource"
            mat-cell>
            {{ selfAwarenessEntry.duration | floatToHoursMinutes }}
          </td>
        </ng-container>

        <!-- confirmation Column -->
        <ng-container matColumnDef="confirmation">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Bestätigung
            <app-custom-table-sort-icon [sort]="sort" columnName="confirmation">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let selfAwarenessEntry; dataSource: dataSource"
            mat-cell>
            <div
              *ngIf="selfAwarenessEntry?.files?.length > 0"
              class="flex-r align-center">
              <button
                (click)="
                  onDownload(
                    selfAwarenessEntry.id,
                    selfAwarenessEntry?.files[0]?.id
                  )
                "
                type="button"
                class="table-button-icon"
                matTooltip="Datei herunterladen">
                <i class="fa-light fa-file-contract icon-sm"></i>
              </button>
              <span>Dokument</span>
            </div>
            <div
              *ngIf="
                !selfAwarenessEntry?.files ||
                selfAwarenessEntry?.files?.length == 0
              "
              class="flex-r gap-8 align-center">
              <i class="fa-light fa-file-slash icon-sm"></i>
              <span>Keine Bestätigung</span>
            </div>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="center-header-cell">
            Aktionen
          </th>
          <td
            *matCellDef="let selfAwarenessEntry; dataSource: dataSource"
            mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                *appCheckPermissions="{
                  feature: feature.PRACTICAL_WORK,
                  permission: permission.VIEW,
                }"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Selbsterfahrungseintrag Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  *appCheckPermissions="{
                    feature: feature.PRACTICAL_WORK,
                    permission: permission.VIEW,
                  }"
                  (click)="navigateToViewSelfAwareness(selfAwarenessEntry)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider
                  *appCheckPermissions="{
                    feature: feature.PRACTICAL_WORK,
                    permission: permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.PRACTICAL_WORK,
                    permission: permission.ADMIN,
                  }"
                  (click)="navigateToEditSelfAwareness(selfAwarenessEntry)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
                <mat-divider
                  *appCheckPermissions="{
                    feature: feature.PRACTICAL_WORK,
                    permission: permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.PRACTICAL_WORK,
                    permission: permission.ADMIN,
                  }"
                  (click)="deleteSelfAwareness(selfAwarenessEntry)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde kein Eintrag gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
