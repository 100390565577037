<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Benutzerverwaltung</h1>
      <h3 class="hasomed-text-h3">Benutzer ohne Institut verwalten.</h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- User column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
            <app-custom-table-sort-icon [sort]="sort" columnName="user">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let user; dataSource: dataSource">
            <div class="user-container">
              <div class="user-icon">
                <img
                  src="../assets/img/user.jpg"
                  alt="Profilbild"
                  class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(user) }}
                </span>
                <span class="user-info hasomed-text-small">
                  {{ user.email }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Role column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Rolle
            <app-custom-table-sort-icon [sort]="sort" columnName="role">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let user; dataSource: dataSource">
            <div
              class="label"
              [style.background]="getLabelBackgroundColor(user)">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">{{ getRoleName(user) }}</span>
            </div>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell class="center-header-cell" *matHeaderCellDef>
            Aktionen
          </th>
          <td mat-cell *matCellDef="let user; dataSource: dataSource">
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Benutzer Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  (click)="openEditUserDialog(user)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>

                <mat-divider
                  class="menu-divider"
                  *ngIf="user.id !== currentUserId"></mat-divider>
                <button
                  *ngIf="user.id !== currentUserId"
                  (click)="openDeleteUserDialog(user)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <app-lottie-loading-spinner
                size="45px"></app-lottie-loading-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Keinen Nutzer gefunden.
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
