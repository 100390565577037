import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  CourseCreateModel,
  CourseEvent,
  CourseLocation,
  CourseModel,
  CourseType,
  CourseUpdateModel,
  RecurrencePattern,
} from 'src/app/models/course.model';
import { EducationCourse } from 'src/app/models/education-course.model';
import {
  EventDate,
  EventDateCreateModel,
  EventDateUpdateModel,
} from 'src/app/models/event.model';
import { ExamTypeModel } from 'src/app/models/exam-type.model';
import { FileModel } from 'src/app/models/file.model';
import { LearningContentModel } from 'src/app/models/learning-content.model';
import { Role } from 'src/app/models/permission.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { ExamTypeService } from 'src/app/services/exam-type.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LearningContentService } from 'src/app/services/learning-content.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { hasErrors, isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-course',
  templateUrl: './create-edit-course.component.html',
  styleUrls: ['./create-edit-course.component.scss'],
})
export class CreateEditCourseComponent implements OnInit, OnDestroy {
  public selectedImageId: number;
  public selectedType: string;
  public selectedDuration: number = 45;
  public editMode: boolean = false;
  public duplicateMode: boolean = false;
  public currentCourse: CourseModel;
  public courseForm: FormGroup;
  public lecturers: UserModel[];
  public participants: UserModel[];
  public selectedLecturerIds: number[] = [];
  public selectedParticipantIds: number[] = [];
  public uploadedImage: File;
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public startDate: any;
  public endDate: any;
  public allDayEvent: boolean = false;
  public recurrencePattern: RecurrencePattern;
  public events: EventDate[] = [];
  public initialEvents: EventDate[] = [];
  public roomPlanningOpen: boolean = false;
  public roomPlanningValid: boolean = null;
  public roomPlanningDisabled: boolean = false;
  public availableEducationCoursesSelectOptions: SelectOption[];
  public availableLearningContentsSelectOptions: SelectOption[];
  public availableExamTypesSelectOptions: SelectOption[];
  public availableCourseTypesSelectOptions: SelectOption[];
  public availableClosedCourseSelectOptions: SelectOption[] = [
    { value: false, label: 'offen' },
    { value: true, label: 'geschlossen' },
  ];
  public availableCourseDurationSelectOptions: SelectOption[] = [
    { value: 330, label: '330min' },
    { value: 315, label: '315min' },
    { value: 300, label: '300min' },
    { value: 270, label: '270min' },
    { value: 225, label: '225min' },
    { value: 210, label: '210min' },
    { value: 180, label: '180min' },
    { value: 135, label: '135min' },
    { value: 120, label: '120min' },
    { value: 90, label: '90min' },
    { value: 45, label: '45min' },
  ];
  public isLoading = true;
  public isLecturer: boolean = false;
  public formSubmitted = false;
  private initialFormValues: {};
  public showLink: boolean = false;

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'WEBP', mimeType: 'image/webp' },
    {
      type: 'DOCX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    {
      type: 'XLSX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    { type: 'PPT', mimeType: 'application/vnd.ms-powerpoint' },
    {
      type: 'PPTX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  public tinyMceSettings = {
    toolbar: 'undo redo | blocks fontsize | bold italic underline | help',
    help_tabs: ['shortcuts'],
    menubar: false,
  };

  // import from utils
  public isRequired = isRequired;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;
  public getFullName = getFullName;
  public hasErrors = hasErrors;

  // to access enum in template
  public CourseLocation = CourseLocation;

  private destroy$ = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.courseForm.value,
        this.initialFormValues
      ) ||
      this.formDeactivateService.hasUnsavedChanges(
        this.events,
        this.initialEvents
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private userService: UserService,
    private examTypeService: ExamTypeService,
    private learningContentService: LearningContentService,
    private educationCourseService: EducationCourseService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private router: Router,
    private cancellationService: CancellationService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public ngOnInit() {
    this.isLecturer = this.userService.currentUserIsLecturer();
    this.getEducationCourses();
    if (!this.isLecturer) {
      this.getParticipants();
      this.getLecturers();
      this.getLearningContents();
      this.getExamTypes();
      this.getCourseTypes();
    }
    this.createForm();

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      // if route contains create, set duplicateMode to true
      if (this.router.url.includes('create')) {
        this.duplicateMode = true;
        this.editMode = false;
      }

      const courseId = +atob(this.activatedRoute.snapshot.params['id']);
      this.getCourse(courseId);
    }

    if (!this.editMode && !this.duplicateMode) {
      this.selectedImageId = 1;
      this.isLoading = false;
      this.courseForm.patchValue({
        duration: this.selectedDuration,
        selectedTitlePicture: this.selectedImageId,
      });
      this.courseForm.controls['titlePicture'].clearValidators();
      this.courseForm.controls['titlePicture'].updateValueAndValidity();

      this.initialFormValues = this.courseForm.value;
    }

    // subscribe to closedCourse changes and remove selected participants if the course is not closed
    this.courseForm.controls['closedCourse'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(closed => {
        if (!closed) {
          this.selectedParticipantIds = [];
          this.courseForm.get('participants').setValue(null);
        }
      });

    // subscribe to participants and set the value of maxStudents to the number of selected participants if it is greater than the current maxStudents
    this.courseForm.controls['participants'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(participants => {
        const maxStudents = this.courseForm.get('maxStudents').value;
        if (participants?.length > maxStudents) {
          this.courseForm.get('maxStudents').setValue(participants.length);
        }
      });

    // subscribe to location changes and set the value of roomPlanningDisabled to true if the location is ONLINE
    this.courseForm.controls['location'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        if (location == CourseLocation.ONLINE) {
          this.roomPlanningDisabled = true;
          this.roomPlanningValid = true;
          this.showLink = true;

          // set required validator for link if location is ONLINE
          this.courseForm.get('link').setValidators([Validators.required]);
          return;
        }

        if (location == CourseLocation.ONSITE) {
          this.roomPlanningDisabled = false;
          this.showLink = false;

          // remove required validator for link if location is ONSITE
          this.courseForm.get('link').clearValidators();
          this.courseForm.get('link').updateValueAndValidity();
          return;
        }

        if (location == CourseLocation.HYBRID) {
          this.roomPlanningDisabled = false;
          this.showLink = true;

          // set required validator for link if location is HYBRID
          this.courseForm.get('link').setValidators([Validators.required]);
          return;
        }
      });

    this.courseForm.controls['duration'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(duration => {
        this.selectedDuration = duration;
      });

    // subscribe to uploadedFiles changes and convert them to base64
    this.courseForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.courseForm.get('documents').setValue(null);
          return;
        }
        this.fileService.handleFileUpload(
          value,
          this.courseForm.get('documents'),
          false,
          false
        );
      });
  }

  /**
   * createForm
   * @description create the course form
   * @returns void
   */
  private createForm(): void {
    this.courseForm = new FormGroup({
      educationCourses: new FormArray(
        [new FormControl(null, Validators.required)],
        Validators.required
      ),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl('', [Validators.maxLength(6000)]),
      titlePicture: new FormControl(null, Validators.required),
      selectedTitlePicture: new FormControl(null),
      type: new FormControl('', Validators.required),
      learningContents: new FormArray(
        [new FormControl(null, Validators.required)],
        Validators.required
      ),
      examTypes: new FormArray([new FormControl(null)]),
      mandatory: new FormControl(null),
      lecturers: new FormControl(null, Validators.required),
      lecturerSearch: new FormControl(null),
      closedCourse: new FormControl(false),
      participants: new FormControl(null),
      participantSearch: new FormControl(null),
      maxStudents: new FormControl(null, [
        Validators.required,
        positiveNumbersOnlyValidator(false),
        maxNumberLength(3),
      ]),
      duration: new FormControl(
        {
          value: null,
        },
        Validators.required
      ),
      location: new FormControl(CourseLocation.ONSITE, Validators.required),
      dateGroup: new FormControl(null),
      link: new FormControl('', [Validators.maxLength(2000)]),
      internalNotes: new FormControl('', [Validators.maxLength(6000)]),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * Returns the educationCoursesFormArray control of the courseForm.
   * @returns The educationCoursesFormArray control.
   */
  get educationCoursesFormArray(): FormArray {
    return this.courseForm.get('educationCourses') as FormArray;
  }

  /**
   * Returns the FormArray for the learning contents in the courseForm.
   * @returns The FormArray for the learning contents.
   */
  get learningContentsFormArray(): FormArray {
    return this.courseForm.controls['learningContents'] as FormArray;
  }

  /**
   * Returns the examTypesFormArray of the courseForm.
   * @returns The examTypesFormArray of the courseForm.
   */
  get examTypesFormArray(): FormArray {
    return this.courseForm.controls['examTypes'] as FormArray;
  }

  /**
   * Returns the FormControl for the courseType control in the courseForm.
   * @returns The FormControl for the courseType control.
   */
  get courseTypeControl(): FormControl {
    return this.courseForm.get('type') as FormControl;
  }

  /**
   * Returns the FormControl for the closedCourse control in the courseForm.
   * @returns The FormControl for the closedCourse control.
   */
  get closedCourseControl(): FormControl {
    return this.courseForm.get('closedCourse') as FormControl;
  }

  /**
   * Returns the duration control of the course form.
   * @returns The duration control as a FormControl.
   */
  get durationControl(): FormControl {
    return this.courseForm.get('duration') as FormControl;
  }

  /**
   * getEducationCourses
   * @description get all institute education courses and save them in this.availableEducationCourses array
   */
  private getEducationCourses() {
    this.educationCourseService
      .getInstituteEducationCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const educationCourses = response.body?.map(
            (educationCourseData: any): EducationCourse => {
              return {
                id: educationCourseData.id,
                title: educationCourseData.title,
              };
            }
          );

          this.availableEducationCoursesSelectOptions = educationCourses.map(
            (educationCourse: EducationCourse): SelectOption => {
              return {
                value: educationCourse.id,
                label: educationCourse.title,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Bildungsgänge konnten nicht geladen werden.'
          );
        },
      });
  }
  /**
   * getLecturers
   * @description get all institute lecturers and save them in this.lecturers array
   * @returns void
   */
  private getLecturers(): void {
    this.userService
      .getInstituteUsersByRole(Role.LECTURER, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.lecturers = response.body
            ? await Promise.all(
                response.body?.map(
                  async (userData: UserModel): Promise<UserModel> => {
                    return await this.userService.parseBackendUser(userData);
                  }
                )
              )
            : [];
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Lehrpersonal konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getParticipants
   * @description get all institute students and save them in this.participants array
   * @returns void
   */
  private getParticipants(): void {
    this.userService
      .getInstituteUsersByRole(Role.STUDENT, true, 'educationCourse')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.participants = response.body
            ? await Promise.all(
                response.body?.map(
                  async (userData: UserModel): Promise<UserModel> => {
                    return await this.userService.parseBackendUser(userData);
                  }
                )
              )
            : [];
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Studenten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getLearningContents
   * @description get all institute learning contents and save them in this.availableLearningContents array
   * @returns void
   */
  private getLearningContents(): void {
    this.learningContentService
      .getInstituteLearningContents()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.availableLearningContentsSelectOptions = response.body.map(
            (learningContent: LearningContentModel) => {
              return {
                value: learningContent.id,
                label:
                  (learningContent.shortName
                    ? ` (${learningContent.shortName})`
                    : '') + learningContent.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Lehrinhalte konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getExamTypes
   * @description get all institute exam types and save them in this.availableExamTypes array
   * @returns void
   */
  private getExamTypes(): void {
    this.examTypeService
      .getAllExamTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          const examTypes = response.body
            ? response.body.map((examType: ExamTypeModel) =>
                this.examTypeService.parseBackendExamType(examType)
              )
            : [];

          this.availableExamTypesSelectOptions = examTypes.map(
            (examType: ExamTypeModel): SelectOption => {
              return {
                value: examType.id,
                label: examType.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Prüfungsarten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getCourseTypes
   * @description get all institute course types and save them in this.availableCourseTypes array
   * @returns void
   */
  private getCourseTypes(): void {
    this.courseService
      .getAllCourseTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.availableCourseTypesSelectOptions = response.body.map(
            (type: CourseType): SelectOption => {
              return {
                value: type.id,
                label: type.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurstypen konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getCourse
   * @description get the course by id
   * @param courseId
   * @returns void
   */
  private getCourse(courseId: number): void {
    this.courseService
      .getCourseById(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentCourse = await this.courseService.parseBackendCourse(
            response.body
          );
          this.initCourseForm();
        },
        error: () => {
          this.isLoading = false;
          this.onCancel();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Der Kurs konnte nicht geladen werden!`
          );
        },
      });
  }

  /**
   * init the course form with the current course data
   * @returns void
   */
  private initCourseForm(): void {
    this.selectedLecturerIds = [];
    this.currentCourse.lecturers?.map((lecturer: UserModel) =>
      this.selectedLecturerIds.push(lecturer.id)
    );
    if (!this.duplicateMode) {
      this.currentCourse.students?.map((participant: UserModel) =>
        this.selectedParticipantIds.push(participant.id)
      );
      this.startDate = moment(this.currentCourse.startDate);
      this.endDate = moment(this.currentCourse.endDate);
      this.currentCourse.files?.forEach(file => {
        this.existingFiles.push(file);
      });
      this.recurrencePattern = this.currentCourse.recurrencePattern;
    }
    this.events = this.duplicateMode
      ? []
      : (this.currentCourse.courseEventDates ?? []);
    this.initialEvents = this.currentCourse.courseEventDates.slice() ?? [];
    this.selectedDuration = this.currentCourse.duration;
    this.allDayEvent = this.currentCourse.allDayEvent;
    this.selectedImageId = this.currentCourse.defaultTitlePicture;

    // set room planning to valid
    let invalidCourseRooms = 0;
    for (const courseEvent of this.events) {
      if (!courseEvent.room && moment().isSameOrBefore(courseEvent.startDate)) {
        invalidCourseRooms++;
      }
    }
    if (invalidCourseRooms > 0) {
      this.roomPlanningValid = false;
    } else {
      this.roomPlanningValid = true;
    }

    if (this.selectedImageId) {
      this.courseForm.controls['selectedTitlePicture'].setValue(
        this.selectedImageId
      );
      this.courseForm.controls['titlePicture'].clearValidators();
      this.courseForm.controls['titlePicture'].updateValueAndValidity();
    } else {
      this.courseForm
        .get('titlePicture')
        .setValue(this.currentCourse.titlePicture);
    }

    this.courseForm.patchValue({
      title: this.currentCourse.title + (this.duplicateMode ? ' (Kopie)' : ''),
      description: this.currentCourse.description,
      mandatory: this.currentCourse.mandatory,
      closedCourse: this.currentCourse.closed,
      type: this.currentCourse.courseType.id,
      duration: this.currentCourse.duration,
      link: this.currentCourse.link,
      maxStudents: this.currentCourse.maxParticipants,
      internalNotes: this.currentCourse.internalNotes,
      documents: this.currentCourse.files,
      uploadedFiles: this.uploadedFiles,
      existingFiles: this.existingFiles,
      participants: JSON.parse(JSON.stringify(this.selectedParticipantIds)),
      lecturers: JSON.parse(JSON.stringify(this.selectedLecturerIds)),
      location: this.currentCourse.location,
    });

    if (
      this.currentCourse.location == CourseLocation.ONLINE ||
      this.currentCourse.location == CourseLocation.HYBRID
    ) {
      this.showLink = true;
      this.courseForm.get('link').setValidators([Validators.required]);
    }

    // create formControls for all educationCourses
    this.courseForm.setControl(
      'educationCourses',
      new FormArray(
        this.currentCourse.educationCourses?.length > 0
          ? this.currentCourse.educationCourses?.map(educationCourse => {
              return new FormControl(educationCourse.id, Validators.required);
            })
          : [new FormControl(null, Validators.required)],
        Validators.required
      )
    );

    // create formControls for all learningContents
    this.courseForm.setControl(
      'learningContents',
      new FormArray(
        this.currentCourse.learningContents?.length > 0
          ? this.currentCourse.learningContents?.map(learningContent => {
              return new FormControl(learningContent.id, Validators.required);
            })
          : [new FormControl(null, Validators.required)],
        Validators.required
      )
    );

    // create formControls for all examTypes
    this.courseForm.setControl(
      'examTypes',
      new FormArray(
        this.currentCourse.examTypes?.length > 0
          ? this.currentCourse.examTypes?.map(examType => {
              return new FormControl(examType.id);
            })
          : [new FormControl(null)]
      )
    );

    this.initialFormValues = JSON.parse(JSON.stringify(this.courseForm.value));
    this.isLoading = false;
  }

  /**
   * get courseClosed
   * getter for the closedCourse form control
   * @returns boolean
   */
  get courseClosed(): boolean {
    return this.courseForm.get('closedCourse').value;
  }

  /**
   * get lecturerCount
   * getter for the selected lecturers count
   * @returns number
   */
  get lecturerCount(): number {
    return this.selectedLecturerIds.length;
  }

  /**
   * get participantCount
   * getter for the selected participants count
   * @returns number
   */
  get participantCount(): number {
    return this.selectedParticipantIds.length;
  }

  /**
   * setCourseLocation
   * set the course location in the course form
   * @param location CourseLocation
   */
  public setCourseLocation(location: CourseLocation) {
    this.courseForm.get('location').setValue(location);
  }

  public onEventsChanged(courseEvents: CourseEvent[]) {
    this.events = courseEvents;

    // if location is online or hybrid, set room planning to valid
    if (
      this.courseForm.get('location').value == CourseLocation.ONLINE ||
      this.courseForm.get('location').value == CourseLocation.HYBRID
    ) {
      this.roomPlanningValid = true;
      return;
    }

    let eventsWithoutRoom = 0;
    let eventsWithoutDate = 0;
    this.events?.forEach(event => {
      if (!event.startDate) {
        eventsWithoutDate++;
      }
      if (!event.room && moment().isSameOrBefore(event.startDate)) {
        eventsWithoutRoom++;
      }
    });
    if (eventsWithoutDate > 0) {
      this.roomPlanningDisabled = true;
      this.roomPlanningValid = true;
    } else {
      this.roomPlanningDisabled = false;
      if (eventsWithoutRoom > 0) {
        this.roomPlanningValid = false;
      } else {
        this.roomPlanningValid = true;
      }
    }
  }

  /**
   * onDatesChanged
   * @description update the start and end date of the course
   * @param dates
   * @returns void
   */
  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }): void {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  /**
   * roomPlanningChanged
   * @description update the room planning and check if all rooms are assigned
   * @param courseEvents
   * @returns void
   */
  public roomPlanningChanged(courseEvents: CourseEvent[]): void {
    if (!courseEvents) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      let invalidCourseRooms = 0;
      for (const courseEvent of courseEvents) {
        if (
          !courseEvent.room &&
          moment().isSameOrBefore(courseEvent.startDate)
        ) {
          invalidCourseRooms++;
        }
      }
      if (invalidCourseRooms > 0) {
        this.roomPlanningValid = false;
      } else {
        this.roomPlanningValid = true;
      }
      this.events = courseEvents;
      this.roomPlanningOpen = false;
    }
  }

  /**
   * openRoomPlanning
   * @description open the room planning and check if courseEvents have been selected
   * @returns void
   */
  public openRoomPlanning(): void {
    /* only open room planning if courseEvents have been selected */
    if (this.events.length > 0) {
      this.roomPlanningOpen = true;
    } else {
      this.alertService.showErrorAlert(
        'Fehler',
        'Bitte wählen Sie mindestens einen Termin aus!'
      );
    }
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the course will be created or updated.
   * @returns
   */
  public onSubmit(): void {
    this.formSubmitted = true;

    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.courseForm
      )
    ) {
      return;
    }

    if (!this.roomPlanningValid) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Bitte weisen Sie allen Terminen einen Raum zu.'
      );

      // scroll to room planning
      const roomPlanningElement = document.getElementById('roomPlanning');
      roomPlanningElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.courseForm.value,
        this.initialFormValues
      ) &&
      !this.formDeactivateService.hasUnsavedChanges(
        this.initialEvents,
        this.events
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.editCourse() : this.createCourse();
  }

  /**
   * createCourse
   * @description create a new course with the course data
   * @returns Promise<void>
   */
  private async createCourse(): Promise<any> {
    const files: FileModel[] = this.courseForm.value.documents
      ? this.existingFiles.concat(this.courseForm.value.documents)
      : this.existingFiles;
    const courseCreateModel: CourseCreateModel = {
      title: this.courseForm.value.title,
      description: this.courseForm.value.description,
      courseTypeId: this.courseForm.value.type,
      recurrencePattern: this.recurrencePattern,
      startDate: this.startDate,
      endDate: this.endDate,
      allDayEvent: this.allDayEvent,
      duration: this.courseForm.getRawValue().duration,
      maxParticipants: this.courseForm.value.maxStudents,
      link: this.courseForm.value.link,
      location: this.courseForm.value.location,
      internalNotes: this.courseForm.value.internalNotes,
      logonEnabled: true,
      mandatory: Boolean(this.courseForm.value.mandatory),
      closed: this.courseForm.value.closedCourse,
      courseEventDates: this.events.map(
        (eventDate: EventDate): EventDateCreateModel => {
          const eventDateCreateModel: EventDateCreateModel = {
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateCreateModel;
        }
      ),
      defaultTitlePicture: this.selectedImageId,
      titlePicture: this.courseForm.get('titlePicture').value,
      lecturerIds: this.selectedLecturerIds,
      studentIds: this.selectedParticipantIds,
      educationCourseIds: this.courseForm.get('educationCourses').value,
      learningContentIds: this.courseForm.get('learningContents').value,
      examTypeIds: this.courseForm
        .get('examTypes')
        .value?.filter((item: any) => item != null),
      files: files,
    };

    const createCourseObservable =
      await this.courseService.createCourse(courseCreateModel);
    createCourseObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Kurs '${courseCreateModel.title}' wurde angelegt.`
        );
        this.initialFormValues = this.courseForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Kurs '${courseCreateModel.title}' konnte nicht angelegt werden.`
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * editCourse
   * @description update the existing course with the new course data
   * @returns Promise<void>
   */
  private async editCourse(): Promise<void> {
    const files: FileModel[] = this.courseForm.value.documents
      ? this.existingFiles.concat(this.courseForm.value.documents)
      : this.existingFiles;
    const courseUpdateModel: CourseUpdateModel = {
      title: this.courseForm.value.title,
      description: this.courseForm.value.description,
      courseTypeId: this.courseForm.value.type,
      recurrencePattern: this.recurrencePattern,
      startDate: this.startDate,
      endDate: this.endDate,
      allDayEvent: this.allDayEvent,
      duration: this.courseForm.getRawValue().duration,
      maxParticipants: this.courseForm.value.maxStudents,
      link: this.courseForm.value.link,
      location: this.courseForm.value.location,
      internalNotes: this.courseForm.value.internalNotes,
      logonEnabled: this.currentCourse.logonEnabled,
      mandatory: Boolean(this.courseForm.value.mandatory),
      closed: this.courseForm.value.closedCourse,
      courseEventDates: this.events.map(
        (eventDate: EventDate): EventDateUpdateModel => {
          const eventDateUpdateModel: EventDateUpdateModel = {
            id: eventDate.id,
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateUpdateModel;
        }
      ),
      defaultTitlePicture: this.selectedImageId,
      titlePicture: this.courseForm.get('titlePicture').value,
      lecturerIds: this.selectedLecturerIds,
      studentIds: this.courseForm.value.closedCourse
        ? this.selectedParticipantIds
        : this.currentCourse.students.map(student => student.id),
      educationCourseIds: this.courseForm.get('educationCourses').value,
      learningContentIds: this.courseForm.get('learningContents').value,
      examTypeIds: this.courseForm
        .get('examTypes')
        .value?.filter((item: any) => item != null),
      files: files,
    };

    const updateCourseObservable = await this.courseService.updateCourse(
      this.currentCourse.id,
      courseUpdateModel
    );
    updateCourseObservable.pipe(first()).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Kurs '${courseUpdateModel.title}' wurde aktualisiert.`
        );
        this.initialFormValues = this.courseForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Kurs '${courseUpdateModel.title}' konnte nicht aktualisiert werden.`
        );
        this.loadingService.hide();
      },
    });
  }

  /* on button click navigate up to parent route */
  public onCancel() {
    this.location.back();
  }

  /* remove selected picture on click */
  public removePicture() {}

  /* remove selected file on click */
  public removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
  }

  /**
   * set or removes the selected image based on the users action
   * Remove validator on image upload when a default image is selected
   * @param imageId
   * @returns void
   */
  public onDefaultImageSelected(imageId: number): void {
    if (this.selectedImageId === imageId) {
      this.selectedImageId = null;
      this.courseForm.controls['titlePicture'].setValidators([
        Validators.required,
      ]);
    } else {
      this.selectedImageId = imageId;
      this.courseForm.controls['titlePicture'].clearValidators();
    }
    this.courseForm.controls['selectedTitlePicture'].setValue(
      this.selectedImageId
    );
    // mark as touched and dirty
    this.courseForm.controls['titlePicture'].markAsTouched();
    this.courseForm.controls['titlePicture'].markAsDirty();
    this.courseForm.controls['titlePicture'].updateValueAndValidity();
  }

  /**
   * deleteImage
   * @description delete the uploaded image and set the selectedImageId to 1
   * @returns void
   */
  public deleteImage() {
    this.courseForm.get('titlePicture').setValue(null);
    this.courseForm.controls['titlePicture'].clearValidators();
    this.courseForm.controls['titlePicture'].updateValueAndValidity();
    this.selectedImageId = 1;
  }

  /**
   * when an image is selected and the file type is valid, open the image cropper dialog
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    if (
      !this.allowedFileTypes.some(fileType =>
        fileType.mimeType.includes(event.target.files[0].type)
      )
    ) {
      return;
    }
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Bild zuschneiden',
        round: false,
        height: 300,
        aspectRatio: 4 / 3,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result) {
          this.courseForm.get('titlePicture').setValue(result);
          this.selectedImageId = null;
        }
      });
  }

  /*
  adds or removes the clicked lecturer to the selected lecturers array
  */
  public onLecturerSelected(lecturer: UserModel) {
    if (this.selectedLecturerIds.includes(lecturer.id)) {
      this.selectedLecturerIds.splice(
        this.selectedLecturerIds.findIndex(it => it == lecturer.id),
        1
      );
    } else {
      this.selectedLecturerIds.push(lecturer.id);
    }
    this.courseForm.get('lecturers').setValue(this.selectedLecturerIds);

    if (this.selectedLecturerIds.length == 0) {
      this.courseForm.get('lecturers').setErrors({ required: true });
    } else {
      this.courseForm.get('lecturers').setErrors(null);
    }

    this.courseForm.get('lecturers').markAsTouched();
    this.courseForm.get('lecturers').markAsDirty();
    this.courseForm.get('lecturers').updateValueAndValidity();
  }

  /*
  adds or removes the clicked lecturer to the selected lecturers array
  */
  public onParticipantSelected(participant: UserModel) {
    if (this.selectedParticipantIds.includes(participant.id)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Teilnehmer*in entfernen',
          message: `Möchten Sie die Teilnehmer*in ${participant.name.firstName} ${participant.name.lastName} wirklich entfernen?`,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (result) {
            this.selectedParticipantIds.splice(
              this.selectedParticipantIds.findIndex(it => it == participant.id),
              1
            );
          }
        });
    } else {
      this.selectedParticipantIds.push(participant.id);
    }
    this.courseForm.get('participants').setValue(this.selectedParticipantIds);

    if (this.selectedParticipantIds.length == 0) {
      this.courseForm.get('participants').setErrors({ required: true });
    } else {
      this.courseForm.get('participants').setErrors(null);
    }

    this.courseForm.get('participants').markAsTouched();
    this.courseForm.get('participants').markAsDirty();
    this.courseForm.get('participants').updateValueAndValidity();
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.courseService.openFile(this.currentCourse?.id, file.id);
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.courseService.downloadFile(this.currentCourse?.id, file.id);
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.courseForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.events,
        this.initialEvents
      )
    ) {
      return this.formDeactivateService.confirmDeactivation(
        this.events,
        this.initialEvents
      );
    }

    return this.formDeactivateService.confirmDeactivation(
      this.courseForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalCourseLocationOrder = (
    a: KeyValue<string, CourseLocation>,
    b: KeyValue<string, CourseLocation>
  ): number => {
    return 0;
  };

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
