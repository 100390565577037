import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { first } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  private sessionTimeout: moment.Moment;
  public isLoading: boolean = true;
  public hasNoInstitute: boolean = false;
  public notConfirmedByAdmin: boolean = false;
  public loginFailed: boolean = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {
    const sessionTimeoutCookie = this.cookieService.get(
      this.config.sessionTimeoutCookieName
    );
    this.sessionTimeout = moment.utc(
      sessionTimeoutCookie,
      'MM/DD/YYYY HH:mm:ss'
    );
  }

  public ngOnInit(): void {
    if (moment().isAfter(this.sessionTimeout)) {
      window.location.href = this.config.backendUrl + '/login';
      return;
    }

    this.authService
      .isLoggedIn()
      .pipe(first())
      .subscribe({
        next: response => {
          if (response.body === 'true') {
            if (!this.userService.currentUser) {
              this.loginUser();
              return;
            }
            this.handleLoginRedirect();
          } else {
            this.isLoading = false;
            this.loginFailed = true;
          }
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Login fehlgeschlagen',
            'Bitte versuchen Sie es erneut.'
          );
          this.isLoading = false;
          this.loginFailed = true;
        },
      });
  }

  /**
   * loginUser
   * login the user
   */
  private loginUser() {
    this.authService
      .loginUser()
      .pipe(first())
      .subscribe({
        next: async response => {
          if (response?.status !== 200) {
            this.alertService.showErrorAlert(
              'Login fehlgeschlagen',
              'Bitte versuchen Sie es erneut.'
            );
            this.router.navigate(['logout']);
            return;
          }

          this.userService.setEncryptedCurrentUser(response.body);

          this.handleLoginRedirect();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Login fehlgeschlagen',
            'Bitte versuchen Sie es erneut.'
          );
          this.isLoading = false;
          this.loginFailed = true;
        },
      });
  }

  /**
   * Checks whether the user has been assigned a role or institute or is a superadmin
   * @returns true if the user has no role or institute or is not a superadmin, false otherwise
   */
  private userHasNoInstitute(): boolean {
    return (
      (!this.userService.currentUser?.currentInstituteId ||
        !this.userService.currentUser?.roleId) &&
      !this.userService.currentUser?.isSuperadmin
    );
  }

  /**
   * Handles the login redirect, navigates to the loginRedirectUrl if set, otherwise to the eleguide page
   * @returns Promise<void>
   */
  public async handleLoginRedirect(): Promise<void> {
    if (this.userHasNoInstitute()) {
      this.isLoading = false;
      this.hasNoInstitute = true;
      return;
    }

    if (
      this.userService.currentUser?.encryptionKeysGenerated &&
      !this.userService.currentUser?.isConfirmedByAdmin &&
      !this.userService.currentUser?.isPrimaryAdmin
    ) {
      this.isLoading = false;
      this.notConfirmedByAdmin = true;
      return;
    }

    if (localStorage.getItem('loginRedirectUrl')) {
      const encodedUrl = localStorage.getItem('loginRedirectUrl');
      const decodedUrl = decodeURIComponent(encodedUrl);
      this.router.navigate([decodedUrl]);
      localStorage.removeItem('loginRedirectUrl');
      return;
    }
    this.router.navigate(['eleguide']);
  }

  /**
   * Calls the logout method of the AuthService
   * @returns void
   */
  public onLogout(): void {
    this.authService.logout();
  }
}
