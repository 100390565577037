import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileModel } from 'src/app/models/file.model';
import { Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';
import {
  formatBytes,
  getFileNameOnly,
  getFileType,
} from 'src/app/utils/file.utils';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-course-documents',
  templateUrl: './course-documents.component.html',
  styleUrls: ['./course-documents.component.scss'],
})
export class CourseDocumentsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<FileModel> =
    new MatTableDataSource<FileModel>();
  public columnsToDisplay = [
    'file',
    'creator',
    'modifiedDate',
    'fileSize',
    'fileType',
    'actions',
  ];
  public isLoading = true;
  public uploadedFiles: any[] = [];
  public isAdministrator: boolean = false;
  public courseId: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // import from utils
  public getFullName = getFullName;
  public getFileType = getFileType;
  public getFileNameOnly = getFileNameOnly;
  public formatBytes = formatBytes;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.courseId = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.isAdministrator =
      this.userService.currentUser?.roleId === Role.ADMINISTRATOR;

    this.initTable();
  }

  /**
   * initializes the sorting and pagination of the table
   * retrieves all course files
   * @returns void
   */
  private initTable() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'file':
          return item.name;
        case 'creator':
          return (
            item.creator?.name?.firstName + ' ' + item.creator?.name?.lastName
          );
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getCourseFiles(this.courseId);
  }

  /**
   * getCourseFiles
   * get all course files
   * @param courseId
   */
  private getCourseFiles(courseId: number) {
    this.courseService
      .getAllCourseFiles(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const files = response.body
            ? await Promise.all(
                response.body.map(
                  async (file: any): Promise<FileModel> =>
                    await this.fileService.parseBackendFile(file)
                )
              )
            : [];

          files?.forEach((file: FileModel) => {
            file.type = file.name.split('.').pop();
          });

          this.dataSource.data = files;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kursdateien konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onOpen
   * open the file
   * @param file
   */
  public onOpen(file: FileModel) {
    this.courseService.openFile(this.courseId, file.id);
  }

  /**
   * onDownload
   * download the file
   * @param file
   */
  public onDownload(file: FileModel) {
    this.courseService.downloadFile(this.courseId, file.id);
  }

  /**
   * onDelete
   * @param file
   */
  public onDelete(file: FileModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Datei löschen',
        message: `Möchten Sie die Datei "${file.name}" wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (!dialogResult) {
          return;
        }
        this.courseService
          .deleteCourseFileById(this.courseId, file.id)
          .pipe(first())
          .subscribe({
            next: response => {
              this.alertService.showSuccessAlert(
                'Das hat geklappt! ',
                'Die Datei wurde erfolgreich gelöscht.'
              );
              this.dataSource.data = this.dataSource.data.filter(
                item => item.id !== file.id
              );
            },
            error: error => {
              this.alertService.showErrorAlert(
                'Das hat leider nicht geklappt! ',
                'Die Datei konnte nicht gelöscht werden.'
              );
            },
          });
      });
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
