import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, Subject, takeUntil } from 'rxjs';
import { availableRolesSelectOptions } from 'src/app/constants/role-options.constant';
import { InstituteModel } from 'src/app/models/institute.model';
import { Role } from 'src/app/models/permission.model';
import { SelectOption } from 'src/app/models/select-option.model';
import {
  UserModel,
  UserUpdatePermissionModel,
} from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

interface InstituteUser extends UserModel {
  institute?: {
    id: number;
    name: string;
  };
}

@Component({
  selector: 'app-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.scss'],
})
export class UpdateUserDialogComponent implements OnInit, OnDestroy {
  public availableInstituteSelectOptions: SelectOption[];
  public editUserForm: FormGroup;
  public user: InstituteUser;
  public currentUserId: number = this.userService.currentUser?.id;

  private destroy$: Subject<void> = new Subject<void>();

  public isRequired = isRequired;

  public Role = Role;

  public availableSuperAdminSelectOptions: SelectOption[] = [
    {
      value: false,
      label: 'Nein',
    },
    {
      value: true,
      label: 'Ja',
    },
  ];

  public availableRolesSelectOptions: SelectOption[] =
    availableRolesSelectOptions;

  constructor(
    public dialogRef: MatDialogRef<UpdateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { user: UserModel },
    private instituteService: InstituteService,
    private userService: UserService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {
    dialogRef.disableClose = true;
    this.user = data.user;
  }

  public ngOnInit(): void {
    this.editUserForm = new FormGroup({
      firstName: new FormControl(this.user.name?.firstName),
      lastName: new FormControl(this.user.name?.lastName),
      genderTitle: new FormControl(this.user.name?.genderTitle),
      academicTitle: new FormControl(this.user.name?.academicTitle),
      email: new FormControl(this.user.email),
      superadmin: new FormControl(this.user.isSuperadmin),
      institute: new FormControl(this.user.institute?.id),
      role: new FormControl(this.user.roleId),
    });
    this.getAllInstitutes();
  }

  /**
   * Returns the superAdminControl form control.
   * @returns The superAdminControl form control.
   */
  get superAdminControl(): FormControl {
    return this.editUserForm.get('superadmin') as FormControl;
  }

  /**
   * Returns the institute control form control.
   * @returns The institute control form control.
   */
  get instituteControl(): FormControl {
    return this.editUserForm.get('institute') as FormControl;
  }

  /**
   * Returns the role control of the update user dialog component.
   * @returns The role control as a FormControl.
   */
  get roleControl(): FormControl {
    return this.editUserForm.get('role') as FormControl;
  }

  /**
   * getAllInstitutes
   * Fetches all institutes from the backend
   * @returns void
   */
  private getAllInstitutes(): void {
    this.instituteService
      .getAllInstitutes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const institutes = response.body
            ? await Promise.all(
                response.body?.map(async (instituteData: InstituteModel) => {
                  return await this.instituteService.parseBackendInstitute(
                    instituteData
                  );
                })
              )
            : [];

          this.availableInstituteSelectOptions = institutes.map(
            (institute: InstituteModel): SelectOption => {
              return {
                value: institute.id,
                label: institute.name,
              };
            }
          );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Institute konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * onCancel
   * Closes the dialog
   * @returns void
   */
  public onCancel(): void {
    this.dialogRef.close();
  }

  /**
   * onSave
   * Updates the user with the new values
   * @returns void
   */
  public onSave(): void {
    const userUpdateModel: UserUpdatePermissionModel = {
      name: {
        genderTitle: this.editUserForm.value.genderTitle,
        academicTitle: this.editUserForm.value.academicTitle,
        firstName: this.editUserForm.value.firstName,
        lastName: this.editUserForm.value.lastName,
      },
      email: this.editUserForm.value.email,
      isSuperadmin: this.editUserForm.value.superadmin,
      instituteId:
        this.editUserForm.value.superadmin === true
          ? null
          : this.editUserForm.value.institute,
      roleId:
        this.editUserForm.value.superadmin === true
          ? null
          : this.editUserForm.value.role,
    };

    this.userService
      .updateUserInstituteAndPermissions(this.user.id, userUpdateModel)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt',
            'Der Benutzer wurde erfolgreich aktualisiert'
          );
          this.dialogRef.close(true);
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Benutzer konnte nicht aktualisiert werden'
          );
        },
      });
  }

  /**
   * ngOnDestroy
   * Unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
