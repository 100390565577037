import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NewsArticleService } from 'src/app/services/news-article.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public navLinks: NavLink[];

  constructor(
    private userService: UserService,
    private newsArticleService: NewsArticleService,
    private cancellationService: CancellationService
  ) {
    this.navLinks = [
      {
        label: 'Mein Bereich',
        link: 'my-space',
        feature: Feature.DB_MYSPACE,
        permission: Permission.VIEW,
      },
      // {
      //   label: 'Digitale Pinnwand',
      //   link: 'digital-bulletin-board',
      //   feature: Feature.DB_BULLETIN_BOARD,
      //   permission: Permission.VIEW,
      // },
      // {
      //   label: 'Nachrichten',
      //   link: 'messages',
      //   feature: Feature.DB_MESSAGES,
      //   permission: Permission.VIEW,
      // },
      {
        label: 'Dokumente',
        link: 'documents',
        feature: Feature.DB_DOCUMENTS,
        permission: Permission.VIEW,
      },
      {
        label: 'Institutsneuigkeiten',
        link: 'institute-news',
        notifications: this.newsArticleService.newNewsArticlesCount$,
        feature: Feature.DB_INSTITUTE_NEWS,
        permission: Permission.VIEW,
      },
      {
        label: 'Infozentrum',
        link: 'wiki',
        feature: Feature.DB_WIKI,
        permission: Permission.VIEW,
      },
    ];
  }

  public currentUser = this.userService.currentUser;
  public refreshIntervalId: any;

  public ngOnInit(): void {
    this.getNewsNotifications();

    // get the number of new news articles every 5 minutes
    this.refreshIntervalId = setInterval(
      () => this.getNewsNotifications(),
      300000
    );
  }

  /**
   * get the number of new news articles
   * @returns Promise<void>
   */
  private async getNewsNotifications(): Promise<void> {
    // unsubscribe from previous request
    this.destroy$.next();
    this.destroy$.complete();

    this.newsArticleService
      .getNewNewsArticleCount()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.newsArticleService.updateNewNewsArticlesCount(response.body);
        },
        error: error => {},
      });
  }

  /**
   * unsubscribe from all subscriptions and clear the interval
   */
  public ngOnDestroy(): void {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
    }

    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
