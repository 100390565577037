<div class="content-container">
	<div class="content-container-header">
		<h1 class="hasomed-text-title">Institutsverwaltung</h1>
		<nav [tabPanel]="onboardingPanel"
			mat-tab-nav-bar
			mat-stretch-tabs="false"
			mat-align-tabs="start">
			<ng-container *ngFor="let link of navLinks">
				<a #rla="routerLinkActive"
					*appCheckPermissions="{
          feature: link.feature,
          permission: link.permission
        }"
					[routerLink]="link.link"
					[active]="rla.isActive"
					mat-tab-link
					routerLinkActive>
					<span>{{ link.label }}</span>
				</a>
			</ng-container>
		</nav>
	</div>
	<mat-divider></mat-divider>
	<div class="content-container-content">
		<mat-tab-nav-panel #onboardingPanel
			class="onboardingPanel">
			<router-outlet></router-outlet>
		</mat-tab-nav-panel>
	</div>
</div>
