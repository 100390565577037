import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../models/label.model';
import { Filter, FilterType, FilterDateRange } from '../models/filter.model';
import { UserModel } from '../models/user.model';
import { isDateInRange } from '../utils/date.utils';

@Pipe({
  name: 'filterLecturer',
})
export class FilterLecturerPipe implements PipeTransform {
  transform(lecturers: UserModel[], filter: Filter[]): UserModel[] {
    if (!lecturers || !filter) {
      return lecturers;
    }

    return lecturers.filter(lecturer => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              lecturer.entryDate,
              lecturer.entryDate,
              filter.value as FilterDateRange
            );
          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return lecturer.label?.id === filter.value.id;
            }
            return false;
          case FilterType.PENDING_ELOGS:
            return lecturer.courses?.some(
              course => course.elogStatusCounts.pending > 0
            );
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
