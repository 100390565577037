<div class="flex-c flex-grow">
  <div
    class="flex-c course-details-main table"
    [class.table-bicolor]="dataSource?.filteredData?.length > 1">
    <table
      mat-table
      [dataSource]="dataSource"
      class="course-details-table"
      matSort>
      <!-- date column-->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Datum
          <app-custom-table-sort-icon [sort]="sort" columnName="date">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          <span>{{ row.startDate | date: 'dd.MM.yyyy' }}</span>
        </td>
      </ng-container>

      <!-- time column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Uhrzeit</th>
        <td mat-cell *matCellDef="let row">
          <span class="gray-600">
            {{ row.startDate | date: 'HH:mm' }} -
            {{ row.endDate | date: 'HH:mm' }} Uhr
          </span>
        </td>
      </ng-container>

      <!-- room column -->
      <ng-container matColumnDef="room">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Raum
          <app-custom-table-sort-icon [sort]="sort" columnName="room">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.room?.name ?? '-' }}</td>
      </ng-container>

      <!-- participants column -->
      <ng-container matColumnDef="participantAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Teilnehmeranzahl
          <app-custom-table-sort-icon
            [sort]="sort"
            columnName="participantAmount">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ getParticipantAmount(row) + ' Teilnehmende' }}
        </td>
      </ng-container>

      <!-- eLog column -->
      <ng-container matColumnDef="eLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Anwesenheit
          <app-custom-table-sort-icon [sort]="sort" columnName="eLog">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row; dataSource: dataSource">
          <app-elog-cell
            *ngIf="!isStudent"
            [eLogStatusCounts]="row.elogStatusCounts"></app-elog-cell>
          <app-elog-cell
            *ngIf="isStudent"
            [eLogStatus]="row.elogStatus"></app-elog-cell>
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th
          class="gray-600 center-header-cell"
          mat-header-cell
          *matHeaderCellDef>
          Aktionen
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="table-button-icons">
            <button
              type="button"
              class="table-button-icon"
              matTooltip="Anwesenheiten anzeigen"
              (click)="viewELog(row)">
              <i class="fa-light fa-arrow-up-right-from-square"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td [attr.colspan]="columnsToDisplay.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <app-lottie-loading-spinner
              size="45px"></app-lottie-loading-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurden keine Kurstermine gefunden.
          </div>
        </td>
      </tr>
    </table>

    <mat-divider></mat-divider>
    <mat-paginator
      custom-paginator
      [appCustomLength]="dataSource.filteredData.length"
      [pageSize]="5"
      [pageNumber]="currentPage"
      [renderButtonsNumber]="1"
      aria-label="Select page"></mat-paginator>
  </div>
  <div class="vertical-spacer"></div>
</div>
