<form [formGroup]="roomForm" (ngSubmit)="onSubmit()" class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Raum bearbeiten' : 'Raum erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie den Raum.'
            : 'Erstellen Sie einen neuen Raum.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-orange">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADING WRAPPER -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Room name -->
    <div class="formular-row">
      <label class="formular-row-label" for="name">
        <span class="label-title">
          Raum
          <span *ngIf="isRequired(roomForm.get('name'))">*</span>
        </span>
        <span class="label-description">Name des Raums.</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            formControlName="name"
            autocomplete="off"
            placeholder="z.B Seminarraum" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room type -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Raumtyp
          <span *ngIf="isRequired(roomForm.get('roomType'))">*</span>
        </span>
        <span class="label-description">
          Die Buchbarkeit hängt vom Raumtyp ab. Im Raumtyp 'Kurs' sind
          beispielsweise nur Buchungen für Kurse möglich.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="roomTypeControl"
            controlId="roomType"
            [options]="availableRoomTypes"
            placeholder="Raumtyp auswählen"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('roomType')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room floor -->
    <div class="formular-row">
      <label class="formular-row-label" for="floor">
        <span class="label-title">
          Etage
          <span *ngIf="isRequired(roomForm.get('floor'))">*</span>
        </span>
        <span class="label-description">Etage des Raums.</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="floor"
            class="hasomed-text-input"
            type="number"
            min="-2"
            max="10"
            formControlName="floor"
            placeholder="z.B. 1" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('floor')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room building -->
    <div class="formular-row">
      <label class="formular-row-label" for="building">
        <span class="label-title">
          Gebäude
          <span *ngIf="isRequired(roomForm.get('building'))">*</span>
        </span>
        <span class="label-description">Gebäude des Raums.</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="building"
            class="hasomed-text-input"
            type="text"
            formControlName="building"
            placeholder="z.B. H2" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('building')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room seatNumber -->
    <div class="formular-row">
      <label class="formular-row-label" for="seatNumber">
        <span class="label-title">
          Maximale Anzahl der Teilnehmer
          <span *ngIf="isRequired(roomForm.get('seatNumber'))">*</span>
        </span>
        <span class="label-description">
          Anzahl der maximalen Teilnehmerplätze in diesem Raum.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="seatNumber"
            class="hasomed-text-input"
            type="number"
            min="0"
            max="1000"
            formControlName="seatNumber"
            placeholder="z.B. 250" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('seatNumber')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room equipment -->
    <div class="formular-row">
      <label class="formular-row-label" for="equipment">
        <span class="label-title">
          Ausstattung
          <span *ngIf="isRequired(roomForm.get('equipment'))">*</span>
        </span>
        <span class="label-description"> Ausstattung des Raumes. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="equipment"
            class="hasomed-text-input"
            type="text"
            formControlName="equipment"
            placeholder="z.B. Beamer " />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('equipment')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">Adresse des Raumes.</span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <!-- Street, houseNumber -->
            <div class="formular-row-input">
              <input
                [placeholder]="
                  'Straße' + (isRequired(roomForm.get('street')) ? ' *' : '')
                "
                id="street"
                class="hasomed-text-input"
                type="text"
                formControlName="street" />
              <input
                [placeholder]="
                  'Hausnummer' +
                  (isRequired(roomForm.get('houseNumber')) ? ' *' : '')
                "
                id="houseNumber"
                size="6"
                class="hasomed-text-input"
                type="text"
                formControlName="houseNumber" />
            </div>

            <!-- addressAddition -->
            <input
              [placeholder]="
                'Adresszusatz (Etage, ...)' +
                (isRequired(roomForm.get('addressAddition')) ? ' *' : '')
              "
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              formControlName="addressAddition" />

            <!-- zipCode, city -->
            <div class="formular-row-input">
              <input
                [placeholder]="
                  'Postleitzahl' +
                  (isRequired(roomForm.get('zipCode')) ? ' *' : '')
                "
                size="5"
                maxlength="5"
                id="zipCode"
                class="hasomed-text-input"
                type="text"
                formControlName="zipCode" />
              <input
                [placeholder]="
                  'Stadt / Ort' + (isRequired(roomForm.get('city')) ? ' *' : '')
                "
                id="city"
                class="hasomed-text-input"
                type="text"
                formControlName="city" />
            </div>

            <!-- country -->
            <div class="formular-row-input">
              <country-select
                class="flex-1"
                [control]="countryControl"></country-select>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('addressAddition')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Responsible -->
    <div class="formular-row">
      <label class="formular-row-label" for="responsible">
        <span class="label-title">
          Verantwortlicher
          <span *ngIf="isRequired(roomForm.get('responsible'))">*</span>
        </span>
        <span class="label-description"> Verantwortlicher des Raums. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="responsible"
            class="hasomed-text-input"
            type="text"
            formControlName="responsible"
            placeholder="Verantwortliche*r" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('responsible')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- openingHours -->
    <div class="formular-row">
      <label class="formular-row-label" for="openingHours">
        <span class="label-title">
          Zeitliche Verfügbarkeit des Raums
          <span *ngIf="isRequired(roomForm.get('openingHours'))">*</span>
        </span>
        <span class="label-description">
          In diesen Zeiten ist der Raum buchbar.
        </span>
      </label>
      <div class="hasomed-input-field">
        <app-opening-hours
          class="formular-row-input"
          [openingHours]="openingHours"
          [parentFormGroup]="roomForm"
          [editMode]="editMode"
          (openingHoursChange)="onOpeningHoursChange($event)"
          (openingHoursInitialized)="
            onOpeningHoursInitialized()
          "></app-opening-hours>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Available -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Verfügbarkeit
          <span *ngIf="isRequired(roomForm.get('available'))">*</span>
        </span>
        <span class="label-description">
          Dieser Status zeigt, ob der Raum aktuell verfügbar ist.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-slide-toggle
            [matTooltip]="
              this.roomForm.get('available').value
                ? 'Raum deaktivieren'
                : 'Raum aktivieren'
            "
            hideIcon
            formControlName="available">
            <span>
              {{ this.roomForm.get('available').value ? 'Aktiv' : 'Inaktiv' }}
            </span>
          </mat-slide-toggle>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('available')"></app-error-message>
      </div>
    </div>
  </div>
</form>
