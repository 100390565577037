<div class="content-container">
  <div class="content-container-header">
    <h1 class="hasomed-text-title">
      Willkommen {{ currentUser.name.firstName }}
      {{ currentUser.name.lastName }}
    </h1>
    <nav
      mat-tab-nav-bar
      [tabPanel]="dashboardPanel"
      mat-stretch-tabs="false"
      mat-align-tabs="start">
      <div *ngFor="let link of navLinks">
        <a
          *appCheckPermissions="{
            feature: link.feature,
            permission: link.permission
          }"
          mat-tab-link
          [routerLink]="link.link"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive">
          <div class="flex-r gap-8 align-center">
            <span>{{ link.label }}</span>
            <span
              *ngIf="
                (link.notifications | async) && (link.notifications | async) > 0
              "
              class="navigation-notifications">
              {{ link.notifications | async }}
            </span>
          </div>
        </a>
      </div>
    </nav>
  </div>
  <mat-divider></mat-divider>
  <div class="content-container-content">
    <mat-tab-nav-panel #dashboardPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
