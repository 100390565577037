<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Institutsneuigkeiten</h1>
      <h3 class="hasomed-text-h3">Aktuelle Informationen des Instituts.</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
      <button
        *appCheckPermissions="{
          feature: feature.DB_INSTITUTE_NEWS,
          permission: permission.ADMIN,
        }"
        [disabled]="!selectedNewsArticle"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onEdit(selectedNewsArticle)">
        <i class="hasomed-button-icon fa-light fa-pen"></i>
        <span class="hasomed-button-text">Artikel Bearbeiten</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.DB_INSTITUTE_NEWS,
          permission: permission.ADMIN,
        }"
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onCreateNewNewsArticle()">
        <i class="hasomed-button-icon fa-light fa-file-plus"></i>
        <span class="hasomed-button-text">Neuer Artikel</span>
      </button>
    </form>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div
    class="main-content"
    [class.main-content-article-selected]="selectedNewsArticle"
    *ngIf="!isLoading">
    <div class="articles-container">
      <div class="article-grid">
        <div
          class="article"
          *ngFor="
            let newsArticle of filteredNewsArticles
              | filterNewsArticles: showOnlyPublishedArticles
          ">
          <app-news-article
            [newsArticle]="newsArticle"
            (selectedNewsArticle)="onSelectNewsArticle($event)"
            [isSelected]="
              selectedNewsArticle === newsArticle
            "></app-news-article>
        </div>
      </div>

      <!-- NEWS ARTICLE CONTENT -->
      <div
        class="article-content flex-c flex-1 gap-32"
        *ngIf="selectedNewsArticle">
        <h1 class="hasomed-text-h1">{{ selectedNewsArticle?.title }}</h1>
        <div [innerHTML]="getSanitizedArticleContent()"></div>
        <div *ngIf="selectedNewsArticle?.image">
          <img
            [attr.src]="selectedNewsArticle?.image"
            alt="Titelbild"
            class="title-picture" />
        </div>
        <div class="flex-r">
          <a
            *ngIf="selectedNewsArticle?.link"
            [href]="selectedNewsArticle?.link"
            class="hasomed-link-button hasomed-button-primary">
            <i class="fa-light fa-link hasomed-button-icon"></i>
            <span class="hasomed-button-text">
              {{ selectedNewsArticle?.link }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="newsArticles?.length == 0">
      <span class="hasomed-text-body">
        Es wurde noch kein Artikel angelegt.
      </span>
    </div>
    <div
      *ngIf="
        (filteredNewsArticles | filterNewsArticles: showOnlyPublishedArticles)
          .length === 0 && newsArticles?.length > 0
      ">
      <span class="hasomed-text-body"> Es wurden keine Artikel gefunden. </span>
    </div>
  </div>
</div>
