import { BankAccountModel, BankAccountUpdateModel } from './bank-account.model';
import { CourseModel } from './course.model';
import {
  EducationCourse,
  ProfessionalAssociation,
} from './education-course.model';
import { EducationalProgressModel } from './education-progress.model';
import { ExpertiseModel } from './expertise.model';
import { FileModel } from './file.model';
import { Label } from './label.model';
import { Role } from './permission.model';
import { ProcedureModel } from './procedure.model';

export type UserModel = {
  id: number;
  externalId?: string;
  currentInstituteId?: number;
  email?: string;
  pw?: string;
  roleId?: Role;
  isSuperadmin?: boolean;
  name?: {
    genderTitle?: string;
    academicTitle?: string;
    firstName?: string;
    lastName?: string;
  };
  birthdate?: Date;
  profilePicture?: string | ArrayBuffer | null;
  educationCourse?: EducationCourse;
  courses?: CourseModel[];
  userIdentifier?: string;
  entryDate?: Date;
  address?: {
    zipCode?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addressAddition?: string;
    country?: string;
  };
  label?: Label;
  documents?: string;
  isInvited?: boolean;
  measlesProtection?: boolean;
  additionalQualifications?: AdditionalQualification[];
  expertises?: ExpertiseModel[];
  procedures?: ProcedureModel[];
  timeCreated?: Date;
  timeLastLogin?: Date;
  files?: FileModel[];
  bankAccount?: BankAccountModel;
  medicalAuthorizationOnly?: boolean;
  professionalAssociation?: ProfessionalAssociation;
  ptkConsultation?: boolean;
  ptkConsultationDate?: Date;
  confirmed?: boolean;
  confirmationDate?: Date;
  landlineNumber?: string;
  mobileNumber?: string;
  educationalProgress?: EducationalProgressModel[];
  isPrimaryAdmin?: boolean;
  encryptionKeysGenerated?: boolean;
  isConfirmedByAdmin?: boolean;
  confirmingAdminIdpId?: string;
  isInitialEducationStockSet?: boolean;
};

export type UserCreateModel = {
  name: {
    firstName: string;
    lastName: string;
  };
  email: string;
  roleId: Role;
};

export type UserUpdateModel = {
  labelId?: number;
  educationCourseId?: number;
  email: string;
  name: {
    genderTitle?: string;
    academicTitle?: string;
    firstName: string;
    lastName: string;
  };
  userIdentifier?: string;
  birthdate?: string;
  address?: {
    zipCode?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addressAddition?: string;
    country?: string;
  };
  entryDate?: string;
  profilePicture?: string | ArrayBuffer | null;
  bankDetails?: string;
  measlesProtection: boolean;
  additionalQualificationIds?: number[];
  expertiseIds?: number[];
  procedureIds?: number[];
  bankAccount?: BankAccountUpdateModel;
  medicalAuthorizationOnly?: boolean;
  professionalAssociation?: ProfessionalAssociation;
  ptkConsultation?: boolean;
  ptkConsultationDate?: string;
  confirmed?: boolean;
  confirmationDate?: string;
  landlineNumber?: string;
  mobileNumber?: string;
  files?: FileModel[];
};

export type AdditionalQualification = {
  id: number;
  name: string;
};

export enum AdditionalQualificationEnum {
  SUPERVISOR = 1,
  TEACHING_ANALYST_AP = 2,
  TEACHING_ANALYST_TP = 3,
  SELF_AWARENESS_TRAINER = 4,
}

export type UserUpdatePermissionModel = {
  name: {
    genderTitle?: string;
    academicTitle?: string;
    firstName: string;
    lastName: string;
  };
  email: string;
  isSuperadmin: boolean;
  instituteId?: number;
  roleId?: Role;
};
