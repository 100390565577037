import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { availableRolesSelectOptions } from 'src/app/constants/role-options.constant';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  FileCreateModel,
  FileModel,
  FileUpdateModel,
} from 'src/app/models/file.model';
import { Role } from 'src/app/models/permission.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-file',
  templateUrl: './create-edit-file.component.html',
  styleUrl: './create-edit-file.component.scss',
})
export class CreateEditFileComponent implements OnInit, OnDestroy {
  public editMode = false;
  public isLoading = true;
  public form: FormGroup = new FormGroup({
    filename: new FormControl(''),
    file: new FormControl(null, Validators.required),
    fileUpload: new FormControl(null),
    existingFiles: new FormControl(null),
    rolesWithAccess: new FormControl(null, Validators.required),
  });
  private initialFormValues: any;
  private currentFile: FileModel;

  public uploadedFile: FileModel;
  public existingFiles: FileModel[] = [];

  public allowedFileTypes: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
    { type: 'DOC', mimeType: 'application/msword' },
    {
      type: 'DOCX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    { type: 'XLS', mimeType: 'application/vnd.ms-excel' },
    {
      type: 'XLSX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    { type: 'PPT', mimeType: 'application/vnd.ms-powerpoint' },
    {
      type: 'PPTX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
  ];

  public availableRolesSelectOptions: SelectOption[] =
    availableRolesSelectOptions;

  // import from form.utils.ts
  public isRequired = isRequired;

  public Role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.form.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private location: Location,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private formSubmitValidationService: FormSubmitValidationService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.editMode = true;
          this.getFile(+atob(params.id));
        } else {
          this.isLoading = false;
          this.initialFormValues = this.form.value;
        }
      });

    // subscribe to uploadedFiles changes and convert them to base64
    this.form
      .get('fileUpload')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.form.get('file').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.form.get('file'),
          true,
          false
        );
      });
  }

  /**
   * Returns the FormControl for the rolesWithAccess field.
   * @returns The FormControl for the rolesWithAccess field.
   */
  get rolesWithAccessControl(): FormControl {
    return this.form.get('rolesWithAccess') as FormControl;
  }

  /**
   * getFile
   * get the file by id from the backend
   * @param fileId
   * @returns void
   */
  private getFile(fileId: number): void {
    this.fileService
      .getInstituteFile(this.userService.currentUser.currentInstituteId, fileId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentFile = await this.fileService.parseBackendFile(
            response.body
          );

          this.existingFiles.push(this.currentFile);

          this.form.patchValue({
            filename: this.currentFile.name,
            rolesWithAccess: this.currentFile.filePermissions?.map(
              permission => permission.role?.id
            ),
            uploadedFile: this.uploadedFile,
            existingFiles: this.existingFiles,
          });

          // make file not required and filename required
          this.form.get('file').setValidators(null);
          this.form.get('filename').setValidators(Validators.required);
          this.form.get('file').updateValueAndValidity();
          this.form.get('filename').updateValueAndValidity();

          this.initialFormValues = this.form.value;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
        },
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, it will either upload a new file or update an existing file.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(this.form)
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.form.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateFile() : this.uploadFile();
  }

  /**
   * uploadFile
   * upload the file
   * @returns void
   */
  private uploadFile(): void {
    const fileCreateModel: FileCreateModel = {
      name: this.form.value.file.name,
      size: this.form.value.file.size,
      data: this.form.value.file.data,
      mimeType: this.form.value.file.mimeType,
      roleIds: this.form.value.rolesWithAccess,
      signature: this.form.value.file.signature,
      publicKey: this.form.value.file.publicKey,
      hash: this.form.value.file.hash,
    };

    this.fileService
      .createFile(
        this.userService.currentUser.currentInstituteId,
        fileCreateModel
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Datei wurde erfolgreich hochgeladen.'
          );
          this.initialFormValues = this.form.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Die Datei konnte nicht hochgeladen werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * updateFile
   * edit the file
   * @returns void
   */
  private updateFile(): void {
    const fileUpdateModel: FileUpdateModel = {
      name: this.form.value.filename,
      roleIds: this.form.value.rolesWithAccess,
    };

    this.fileService
      .updateInstituteFile(
        this.userService.currentUser.currentInstituteId,
        this.currentFile.id,
        fileUpdateModel
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Datei wurde erfolgreich aktualisiert.'
          );
          this.initialFormValues = this.form.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Die Datei konnte nicht aktualisiert werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * onCancel
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.fileService.openInstituteFile(
      this.userService.currentUser.currentInstituteId,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.fileService.downloadInstituteFile(
      this.userService.currentUser.currentInstituteId,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * delete the existing file
   * @returns void
   */
  public onDeleteExistingFile(): void {
    this.existingFiles = [];
    this.form.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.form.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
