import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { InstituteModel } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';

@Component({
  selector: 'app-institute-information',
  templateUrl: './institute-information.component.html',
  styleUrl: './institute-information.component.scss',
})
export class InstituteInformationComponent implements OnDestroy {
  public institute: InstituteModel;
  public isLoading = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService,
    private instituteService: InstituteService,
    private alertService: AlertService,
    private location: Location
  ) {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.getData(+atob(String(params.id)));
      });
  }

  /**
   * getData
   * get the institute data
   * @param instituteId
   * @returns Promise<void>
   */
  private async getData(instituteId: number): Promise<void> {
    this.instituteService
      .getInstitute(instituteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.institute = await this.instituteService.parseBackendInstitute(
            response.body
          );
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte erneut versuchen.'
          );
          this.location.back();
        },
      });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
