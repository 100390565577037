import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';
import { UpdateUserDialogComponent } from './update-user-dialog/update-user-dialog.component';

@Component({
  selector: 'app-users-and-roles',
  templateUrl: './users-and-roles.component.html',
  styleUrls: ['./users-and-roles.component.scss'],
})
export class UsersAndRolesComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<UserModel> =
    new MatTableDataSource<UserModel>();
  public isLoading = true;
  public displayedColumns = ['user', 'role', 'actions'];
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });
  public currentUserId = this.userService.currentUser.id;

  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.initTable();
  }

  /**
   * initialize the table and fetch the table data
   * @returns void
   */
  private initTable(): void {
    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr =
        data.email?.toLowerCase() + getFullName(data).toLowerCase();
      return dataStr ? dataStr.indexOf(filter) != -1 : false;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'email':
          return item.email;
        case 'role':
          return item.roleId;
        default:
          return item[property];
      }
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getUsersWithoutInstitute();
  }

  /**
   * retrieves all users without an institute from the backend and initializes the table data
   * @returns void
   */
  private getUsersWithoutInstitute(): void {
    this.userService
      .getAllUsersWithoutInstitute()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body?.map(
                  async (userData: UserModel): Promise<UserModel> => {
                    const user: UserModel =
                      await this.userService.parseBackendUser(userData);
                    return user;
                  }
                )
              )
            : [];

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Benutzerdaten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * Apply the search filter to the table data
   * @param event
   * @returns void
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Get the role name based on the role id
   * @param user
   * @returns void
   */
  public getRoleName(user: UserModel): string {
    if (user.isSuperadmin) {
      return 'Superadmin';
    }
    switch (user.roleId) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return '-';
    }
  }

  /**
   * Get the label background color
   * @param user
   * @returns string
   */
  public getLabelBackgroundColor(user: UserModel): string {
    if (user.isSuperadmin) {
      return '#49881D';
    }
    switch (user.roleId) {
      case Role.ADMINISTRATOR:
        return '#49881D';
      case Role.LECTURER:
        return '#20BBAB';
      case Role.STUDENT:
        return '#79B63F';
      default:
        return '#BBBDBE';
    }
  }

  /**
   * openEditUserDialog
   * @param user
   * @returns void
   */
  public openEditUserDialog(user: UserModel): void {
    const dialogRef = this.dialog.open(UpdateUserDialogComponent, {
      data: {
        user: user,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.getUsersWithoutInstitute();
        }
      });
  }

  /**
   * opens a dialog to delete a user
   * when confirmed, the user will be deleted
   * when canceled, nothing will happen
   * @param user the user to delete
   * @returns void
   */
  public openDeleteUserDialog(user: UserModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '445px',
      data: {
        title: 'Benutzer löschen',
        message: `Möchten Sie den Benutzer "${getFullName(
          user
        )}" wirklich löschen?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.userService
            .deleteUser(user.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Benutzer "${getFullName(user)}" wurde erfolgreich gelöscht`
                );
                this.dataSource.data = this.dataSource.data.filter(
                  it => it.id !== user.id
                );
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Der Benutzer "${getFullName(user)}" konnte nicht gelöscht werden`
                );
              },
            });
        }
      });
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
