import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import {
  TreatmentCaseAppointmentEventDateModel,
  TreatmentCaseModel,
} from 'src/app/models/treatment-case.model';
import { FilterTreatmentCaseEventDatesPipe } from 'src/app/pipes/filter-treatment-case-event-dates.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionAppointmentService } from 'src/app/services/supervision-appointment.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import {
  getAllEventDateSupervisors,
  getAllSupervisors,
} from 'src/app/utils/treatment-case.utils';

@Component({
  selector: 'app-treatment-case-event-dates',
  templateUrl: './treatment-case-event-dates.component.html',
  styleUrl: './treatment-case-event-dates.component.scss',
})
export class TreatmentCaseEventDatesComponent {
  public canCreateAppointment: boolean = true;
  public treatmentCaseId: number;
  public treatmentCase?: TreatmentCaseModel;
  public treatmentCaseEventDates: TreatmentCaseAppointmentEventDateModel[] = [];
  public searchForm: FormGroup = new FormGroup({
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });
  public displayedColumns = [
    'date',
    'room',
    'type',
    'supervisors',
    'status',
    'actions',
  ];
  public dataSource: MatTableDataSource<TreatmentCaseAppointmentEventDateModel> =
    new MatTableDataSource();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public treatmentCaseEventDatesFilter: Filter[] = [this.dateRangeFilter];
  public filterOpened: boolean = false;

  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getAllEventDateSupervisors = getAllEventDateSupervisors;
  public getAllSupervisors = getAllSupervisors;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private treatmentCaseService: TreatmentCaseService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionAppointmentService: SupervisionAppointmentService,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params.treatmentCaseId) {
          return;
        }
        if (params.studentId) {
          this.canCreateAppointment = false;
        }

        this.treatmentCaseId = +atob(
          decodeURIComponent(params.treatmentCaseId)
        );

        this.getTreatmentCase();

        this.initTable();
      });

    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }

        // update value inside treatmentCaseEventDatesFilter
        this.dateRangeFilter.value = dateRange;
        this.treatmentCaseEventDatesFilter =
          this.treatmentCaseEventDatesFilter.map(filter => {
            if (filter.type === FilterType.DATE_RANGE) {
              filter.value = dateRange;
              this.applyFilter();
            }
            return filter;
          });
      },
    });
  }

  /**
   * retrieves the treatment case by it's id
   * @returns void
   */
  private getTreatmentCase(): void {
    this.treatmentCaseService
      .getTreatmentCaseById(this.treatmentCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.treatmentCase = response.body
            ? await this.treatmentCaseService.parseBackendTreatmentCase(
                response.body
              )
            : null;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Behandlungsfall konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the event dates of the current treatment case
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return item.startDate;
        case 'room':
          return item.room?.name;
        case 'supervisors':
          return item.supervisors.map(supervisor => supervisor.name.lastName);
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getTreatmentCaseEventDates();
  }

  /**
   * retrieves all event dates of a treatment case and initializes the table data
   * @returns void
   */
  private getTreatmentCaseEventDates(): void {
    this.treatmentCaseService
      .getAllAppointmentEventDatesByTreatmentCaseId(this.treatmentCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.treatmentCaseEventDates = response.body;
          this.dataSource.data = response.body;

          this.isLoading = false;
          this.applyFilter();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Termine konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * Navigates to the create/edit appointment page.
   * @returns void
   */
  public onCreateAppointment(): void {
    this.router.navigate(['../create-appointment'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Applies the treatment case event dates filter
   * @returns void
   */
  public applyFilter(): void {
    this.dataSource.data =
      FilterTreatmentCaseEventDatesPipe.prototype.transform(
        this.treatmentCaseEventDates,
        this.treatmentCaseEventDatesFilter
      );
  }

  /**
   * Applies the date range filter
   * @returns void
   */
  public applyDateRange(): void {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * Gets called when the treatment case event dates filter has changed
   * @param treatmentCaseEventDatesFilter Filter[]
   * @returns void
   */
  public treatmentCaseEventDatesFilterChanged(
    treatmentCaseEventDatesFilter: Filter[]
  ): void {
    this.treatmentCaseEventDatesFilter = treatmentCaseEventDatesFilter;

    if (!treatmentCaseEventDatesFilter.includes(this.dateRangeFilter)) {
      treatmentCaseEventDatesFilter.push(this.dateRangeFilter);
    }

    this.applyFilter();
    this.filterMenuTrigger?.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range subject
    const dateRangeFilter = treatmentCaseEventDatesFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    } else if (dateRangeFilter) {
      this.dateRangeSubject.next(dateRangeFilter.value as FilterDateRange);
    }
  }

  /**
   * Navigates to the view appointment page.
   * @param treatmentCaseEventDate event date to view
   * @returns void
   */
  public onViewAppointment(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    const appointmentId = encodeURIComponent(
      btoa(treatmentCaseEventDate.appointmentId.toString())
    );

    this.router.navigate([appointmentId], {
      relativeTo: this.activatedRoute,
      queryParams: { type: treatmentCaseEventDate.type },
    });
  }

  /**
   * Navigates to the edit appointment page.
   * @param treatmentCaseEventDate event date to edit
   */
  public onEditAppointment(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    const appointmentId = encodeURIComponent(
      btoa(treatmentCaseEventDate.appointmentId.toString())
    );

    if (treatmentCaseEventDate.type === 'Patiententermin') {
      this.router.navigate(['../edit-appointment', appointmentId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['../../edit-appointment', appointmentId], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * Calls the function to cancel the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to cancel
   * @returns void
   */
  public onCancelEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin absagen',
        message: `Möchten Sie den Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} absagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          treatmentCaseEventDate.type === 'Patiententermin'
            ? this.cancelPatientAppointmentEventDate(treatmentCaseEventDate)
            : this.cancelSupervisionAppointmentEventDate(
                treatmentCaseEventDate
              );
        }
      });
  }

  /**
   * Cancels the patient appointment event date
   * @param treatmentCaseEventDate the event date to cancel
   * @returns void
   */
  private cancelPatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.patientAppointmentService
      .cancelPatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich abgesagt.'
          );

          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht abgesagt werden.'
          );
        },
      });
  }

  /**
   * Cancels the supervision appointment event date
   * @param treatmentCaseEventDate the event date to cancel
   * @returns void
   */
  private cancelSupervisionAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.supervisionAppointmentService
      .cancelSupervisionAppointmentEventDate(
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich abgesagt.'
          );

          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht abgesagt werden.'
          );
        },
      });
  }

  /**
   * Calls the function to confirm the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to confirm
   * @returns void
   */
  public onConfirmEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin zusagen',
        message: `Möchten Sie dem Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} zusagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          treatmentCaseEventDate.type === 'Patiententermin'
            ? this.confirmPatientAppointmentEventDate(treatmentCaseEventDate)
            : this.confirmSupervisionAppointmentEventDate(
                treatmentCaseEventDate
              );
        }
      });
  }

  /**
   * Confirms the patient appointment event date
   * @param treatmentCaseEventDate the event date to confirm
   * @returns void
   */
  private confirmPatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.patientAppointmentService
      .confirmPatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich zugesagt.'
          );
          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht zugesagt werden.'
          );
        },
      });
  }

  /**
   * Confirms the supervision appointment event date
   * @param treatmentCaseEventDate the event date to confirm
   * @returns void
   */
  private confirmSupervisionAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.supervisionAppointmentService
      .confirmSupervisionAppointmentEventDate(
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich zugesagt.'
          );

          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht zugesagt werden.'
          );
        },
      });
  }

  /**
   * Calls the function to delete the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to delete
   * @returns void
   */
  public onDeleteEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin löschen',
        message: `Möchten Sie den Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          treatmentCaseEventDate.type === 'Patiententermin'
            ? this.deletePatientAppointmentEventDate(treatmentCaseEventDate)
            : this.deleteSupervisionAppointmentEventDate(
                treatmentCaseEventDate
              );
        }
      });
  }

  /**
   * Deletes the patient appointment event date
   * @param treatmentCaseEventDate the event date to delete
   * @returns void
   */
  private deletePatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.patientAppointmentService
      .deletePatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich gelöscht.'
          );

          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht gelöscht werden.'
          );
        },
      });
  }

  /**
   * Deletes the supervision appointment event date
   * @param treatmentCaseEventDate the event date to delete
   * @returns void
   */
  private deleteSupervisionAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseAppointmentEventDateModel
  ): void {
    this.supervisionAppointmentService
      .deleteSupervisionAppointmentEventDate(
        treatmentCaseEventDate.appointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich gelöscht.'
          );

          // reload the data
          this.ngOnInit();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht gelöscht werden.'
          );
        },
      });
  }

  /**
   * Returns the class for the label of the event date status
   * @param status the status of the event date
   * @returns label class name
   */
  public getStatusLabelClass(status: string): string {
    const approvedStatuses = ['supervidiert', 'geprüft'];
    return approvedStatuses.includes(status) ? 'label-green' : 'label-white';
  }

  /**
   * Returns the class for the icon of the event date status
   * @param status the status of the event date
   * @returns icon class name
   */
  public getStatusIconClass(status: string): string {
    const checkedStatuses = ['supervidiert', 'geprüft', 'erfasst'];
    const failedStatuses = ['abgesagt'];

    if (checkedStatuses.includes(status)) {
      return 'fa-circle-check';
    } else if (failedStatuses.includes(status)) {
      return 'fa-circle-x';
    }
    return 'fa-circle-dashed';
  }

  /**
   * Navigates back to the treatment case details page
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  /**
   * cancels all requests and unsubscribes from the destroy$ subject
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
