import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  SelfAwarenessEntryCreateModel,
  SelfAwarenessEntryModel,
  SelfAwarenessEntryUpdateModel,
} from '../models/self-awareness-entry.model';
import { CancellationService } from './cancellation.service';
import { FileService } from './file.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SelfAwarenessService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private fileService: FileService,
    private cancellationService: CancellationService
  ) {}

  /**
   * Gets all self awareness entries from the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllSelfAwarenessEntriesByInstituteId(): Observable<
    HttpResponse<any>
  > {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets all self awareness entries from a student
   * @param studentId The student id
   * @returns Observable<HttpResponse<any>>
   */
  public getAllSelfAwarenessEntriesByStudentId(
    studentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses/student/${studentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets a self awareness entry by id
   * @param selfAwarenessEntryId The self awareness id
   * @returns Observable<HttpResponse<any>>
   */
  public getSelfAwarenessEntryById(
    selfAwarenessEntryId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses/${selfAwarenessEntryId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Creates a new self awareness entry
   * @param selfAwarenessEntry The self awareness entry
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createSelfAwarenessEntry(
    selfAwarenessEntry: SelfAwarenessEntryCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    return this.http
      .post(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses`,
        selfAwarenessEntry,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Updates a self awareness entry
   * @param selfAwarenessEntryId The self awareness id
   * @param selfAwarenessEntry The self awareness entry
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateSelfAwarenessEntry(
    selfAwarenessEntryId: number,
    selfAwarenessEntry: SelfAwarenessEntryUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    return this.http
      .put(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses/${selfAwarenessEntryId}`,
        selfAwarenessEntry,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Deletes a self awareness entry
   * @param selfAwarenessEntryId The self awareness id
   * @returns Observable<HttpResponse<any>>
   */
  public deleteSelfAwarenessEntry(
    selfAwarenessEntryId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/self-awarenesses/${selfAwarenessEntryId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param selfAwarenessEntryId
   * @param fileId
   * @returns void
   */
  public openFile(selfAwarenessEntryId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/self-awarenesses/${selfAwarenessEntryId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param selfAwarenessEntryId
   * @param fileId
   * @returns void
   */
  public downloadFile(selfAwarenessEntryId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/self-awarenesses/${selfAwarenessEntryId}/files/${fileId}`
    );
  }

  /**
   * Parses a backend self awareness entry
   * @param selfAwarenessEntry The self awareness entry
   * @returns Promise<SelfawarenessEntryModel>
   */
  public async parseBackendSelfAwarenessEntry(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): Promise<SelfAwarenessEntryModel> {
    return {
      id: selfAwarenessEntry.id,
      selfAwarenessTrainer: await this.userService.parseBackendUser(
        selfAwarenessEntry.selfAwarenessTrainer
      ),
      description: selfAwarenessEntry.description,
      duration: selfAwarenessEntry.duration,
      date: moment(selfAwarenessEntry.date).tz('Europe/Berlin').toDate(),
      files: await this.fileService.parseBackendFiles(selfAwarenessEntry.files),
    };
  }
}
