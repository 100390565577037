<form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Mitglieder einladen</h1>
      <h3 class="hasomed-text-h3">Laden Sie neue Mitglieder ein.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <button
        [disabled]="usersFormArray.length === 0"
        type="submit"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-user-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Jetzt einladen</span>
      </button>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="supervisionType">
        <span class="label-title">Einladeoption</span>
        <span class="label-description"> Wählen Sie eine Einladeoption. </span>
      </label>
      <div class="invite-option">
        <div class="formular-row-input">
          <div class="hasomed-toggle-button">
            <button
              [ngClass]="{
                'border-red': !inviteMode || inviteMode === '',
                'hasomed-button-white-grey': inviteMode !== 'manual',
                'hasomed-button-primary': inviteMode === 'manual',
              }"
              (click)="inviteMode = 'manual'"
              type="button"
              class="hasomed-text-button btn-left">
              <div class="hasomed-button-text">Manuell</div>
            </button>
            <button
              [ngClass]="{
                'border-red': !inviteMode || inviteMode === '',
                'hasomed-button-white-grey': inviteMode !== 'excel',
                'hasomed-button-primary': inviteMode === 'excel',
              }"
              (click)="inviteMode = 'excel'"
              type="button"
              class="hasomed-text-button btn-right">
              <div class="hasomed-button-text">Per Excel Datei</div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="userForm.get('inviteMode')"></app-error-message>
        <div *ngIf="inviteMode === 'excel'" class="formular-row-input">
          <a
            class="hasomed-link-button hasomed-button-white-grey"
            href="assets/files/members.xlsx"
            download="members.xlsx">
            <i class="hasomed-button-icon fa-light fa-file-excel"></i>
            <span class="hasomed-button-text">
              Beispieldatei herunterladen
            </span>
          </a>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="users">
        <span class="label-title">
          Mitglieder einladen
          <span *ngIf="isRequired(userForm.get('users'))">*</span>
        </span>
        <span class="label-description">
          Laden Sie Kandidaten, Lehrpersonal oder Verwaltungsmitarbeiter ein.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-16">
          <div *ngIf="inviteMode === 'excel'" class="formular-row-input">
            <div class="flex-grow">
              <app-upload-area-dnd
                [context]="'profileDocumentUpload'"
                [logs]="false"
                [showFileList]="false"
                [multiple]="false"
                [allowedFileTypes]="allowedFileTypes"
                inputId="usersFile"
                formControlName="excelFile">
              </app-upload-area-dnd>
            </div>
          </div>
          <ng-container formArrayName="users">
            <div #eventsContainer class="flex-c gap-16">
              <ng-container
                *ngFor="
                  let userControls of usersFormArray.controls;
                  let i = index
                ">
                <div class="flex-c">
                  <div
                    [formGroupName]="i"
                    class="flex-r gap-16 align-center"
                    id="user{{ i }}">
                    <input
                      id="firstName"
                      type="text"
                      autocomplete="off"
                      class="hasomed-text-input"
                      placeholder="Max"
                      formControlName="firstName" />
                    <input
                      id="lastName"
                      type="text"
                      autocomplete="off"
                      class="hasomed-text-input"
                      placeholder="Mustermann"
                      formControlName="lastName" />
                    <input
                      id="email"
                      type="text"
                      autocomplete="off"
                      class="hasomed-text-input"
                      placeholder="email@example.com"
                      formControlName="email" />

                    <div class="hasomed-input-field role-select">
                      <div class="formular-row-input">
                        <eleguide-select
                          [control]="getRoleControl(i)"
                          controlId="role"
                          [options]="availableRoleSelectOptions"
                          placeholder="Rolle"></eleguide-select>
                      </div>
                    </div>

                    <div
                      (click)="removeUser(i)"
                      type="button"
                      class="hasomed-text-button hasomed-button-white-grey remove-button">
                      <i
                        class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                    </div>
                  </div>
                  <app-error-message
                    [formField]="
                      userControls.get('firstName')
                    "></app-error-message>
                  <app-error-message
                    [formField]="
                      userControls.get('lastName')
                    "></app-error-message>
                  <app-error-message
                    [formField]="userControls.get('email')"></app-error-message>
                  <app-error-message
                    [formField]="userControls.get('role')"></app-error-message>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <button
            (click)="addUser()"
            id="room"
            type="button"
            class="hasomed-text-button hasomed-button-white-grey add-button">
            <i class="fa-light fa-plus hasomed-button-icon"></i>
            <span class="hasomed-button-text">weitere hinzufügen</span>
          </button>
        </div>
        <app-error-message
          [formField]="userForm.get('users')"></app-error-message>
      </div>
    </div>
  </div>
</form>
