<div class="content">
  <app-lottie-loading-spinner
    *ngIf="isLoading"
    size="150px"></app-lottie-loading-spinner>
  <div class="dialog" *ngIf="!isLoading && hasNoInstitute">
    <div class="header">
      <img
        width="150"
        alt="EleGuideLogo"
        src="../../../../assets/img/EleGuide_Logo-Fullsize.svg" />
    </div>
    <div class="body gap-8">
      <div class="hasomed-text-h1">Nicht freigeschaltet</div>
      <div class="hasomed-text-body">
        Sie sind für kein Institut freigeschaltet. Bitte wenden Sie sich an
        Ihren Administrator.
      </div>
      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="onLogout()">
        <i class="hasomed-button-icon fa-light fa-right-from-bracket"></i>
        <span class="hasomed-button-text"> Log out </span>
      </button>
    </div>
  </div>
  <div class="dialog" *ngIf="!isLoading && loginFailed">
    <div class="header">
      <img
        width="150"
        alt="EleGuideLogo"
        src="../../../../assets/img/EleGuide_Logo-Fullsize.svg" />
    </div>

    <div class="body">
      <div class="hasomed-text-h1">Login Fehlgeschlagen</div>
      <div class="hasomed-text-body">
        Der Login ist leider fehlgeschlagen. Bitte versuchen Sie es erneut.
      </div>
      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="handleLoginRedirect()">
        <span class="hasomed-button-text"> Erneut versuchen </span>
      </button>
    </div>
  </div>
  <div class="dialog" *ngIf="!isLoading && notConfirmedByAdmin">
    <div class="header">
      <img
        width="150"
        alt="EleGuideLogo"
        src="../../../../assets/img/EleGuide_Logo-Fullsize.svg" />
    </div>
    <div class="body gap-8">
      <div class="hasomed-text-h1">
        Anmeldung erfolgreich, Bestätigung ausstehend
      </div>
      <div class="hasomed-text-body">
        Sie haben sich erfolgreich für EleGuide angemeldet! Nun wird die
        Verwaltung an Ihrem Institut Ihre Anmeldung bestätigen. Das dient der
        Sicherheit des Systems.
      </div>
      <div class="hasomed-text-body">
        Sobald Sie bestätigt wurden, erhalten Sie eine Benachrichtigung per
        E-Mail. Dann können Sie sich in EleGuide mit dem von Ihnen gerade
        gewählten Passwort und der E-Mail anmelden.
      </div>
      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="onLogout()">
        <i class="hasomed-button-icon fa-light fa-right-from-bracket"></i>
        <span class="hasomed-button-text"> Log out </span>
      </button>
    </div>
  </div>
</div>
