<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ appointmentType }}
      </h1>
      <h3 class="hasomed-text-h3">
        Schauen Sie sich den {{ appointmentType }} an.
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        [disabled]="!userService.currentUserIsStudent()"
        (click)="onEditTreatmentCaseEventDate()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <div *ngIf="!isLoading" class="main-content">
    <ng-container *ngIf="currentSupervisionAppointment">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Art der Supervision</span>
        </div>
        <div class="detail-value">
          {{ currentSupervisionAppointment.type }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Behandlungsfall</span>
        </div>
        <div class="detail-value">
          {{ getTreatmentCaseNameOfSupervisionAppointment() }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Dauer des Termins in Zeiteinheiten </span>
        </div>
        <div class="detail-value">
          {{ currentSupervisionAppointment.durationInTimeUnits }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Termine</span>
        </div>
        <div class="flex-c gap-8 full-height">
          <div
            *ngFor="
              let eventDate of currentSupervisionAppointment.eventDates;
              let last = last
            ">
            <div class="flex-r gap-8 hasomed-text-body">
              <div class="day-box">
                <i class="fa-light fa-calendar"></i>
                <span>
                  {{ eventDate.startDate | date: 'dd.MM.yyyy' }}
                </span>
              </div>
              <div class="time-box">
                <i class="fa-light fa-clock"></i>
                <span>
                  {{ eventDate.startDate | date: 'HH:mm' }}
                </span>
              </div>
              <div class="time-box">
                <i class="fa-light fa-clock"></i>
                <span>
                  {{ eventDate.endDate | date: 'HH:mm' }}
                </span>
              </div>
              <div class="room-box">
                <i class="fa-light fa-door-closed"></i>
                <span>
                  {{ eventDate.room ? eventDate.room.name : 'Kein Raum' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> URL für VideoMeeting </span>
        </div>
        <div class="detail-value">
          {{ currentSupervisionAppointment.videoMeetingLink ?? '-' }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Supervisor(en)</span>
        </div>
        <div class="detail-value">
          {{
            getAllSupervisionAppointmentSupervisors(
              currentSupervisionAppointment
            ) ?? '-'
          }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Beschreibung</span>
        </div>
        <div class="detail-value">
          {{ currentSupervisionAppointment.description ?? '-' }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Dokumente</span>
        </div>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of currentSupervisionAppointment?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
          <span
            *ngIf="
              !currentSupervisionAppointment?.files ||
              currentSupervisionAppointment?.files?.length == 0
            "
            class="detail-value">
            Keine Dokumente hochgeladen.
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentPatientAppointment">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Behandlungsfall</span>
        </div>
        <div class="detail-value">
          Patient: Chiffre ({{
            currentPatientAppointment.treatmentCase.patientChiffre
          }})
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Begleitpersonen</span>
        </div>
        <div class="detail-value">
          {{
            getAllPatientAppointmentAccompanyingPersons(
              currentPatientAppointment
            ) ?? 'Keine'
          }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Dauer des Termins in Zeiteinheiten </span>
        </div>
        <div class="detail-value">
          {{ currentPatientAppointment.durationInTimeUnits }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Termine</span>
        </div>
        <div class="flex-c gap-8 full-height">
          <div
            *ngFor="
              let eventDate of currentPatientAppointment.eventDates;
              let last = last
            ">
            <div class="flex-r gap-8 hasomed-text-body">
              <div class="day-box">
                <i class="fa-light fa-calendar"></i>
                <span>
                  {{ eventDate.startDate | date: 'dd.MM.yyyy' }}
                </span>
              </div>
              <div class="time-box">
                <i class="fa-light fa-clock"></i>
                <span>
                  {{ eventDate.startDate | date: 'HH:mm' }}
                </span>
              </div>
              <div class="time-box">
                <i class="fa-light fa-clock"></i>
                <span>
                  {{ eventDate.endDate | date: 'HH:mm' }}
                </span>
              </div>
              <div class="room-box">
                <i class="fa-light fa-door-closed"></i>
                <span>
                  {{ eventDate.room ? eventDate.room.name : 'Kein Raum' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> URL für VideoMeeting </span>
        </div>
        <div class="detail-value">
          {{ currentPatientAppointment.videoMeetingLink ?? '-' }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
