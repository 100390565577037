import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  NewsArticleCreateModel,
  NewsArticleModel,
  NewsArticleType,
  NewsArticleUpdateModel,
} from 'src/app/models/news-article.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NewsArticleService } from 'src/app/services/news-article.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-news-article',
  templateUrl: './create-edit-news-article.component.html',
  styleUrls: ['./create-edit-news-article.component.scss'],
})
export class CreateEditNewsArticleComponent implements OnInit, OnDestroy {
  public newsForm: FormGroup;
  public initialFormValues: {};
  public uploadedImage: File;
  public newsArticles: NewsArticleModel[];
  public currentArticle: NewsArticleModel;
  public availableNewsArticleTypes: SelectOption[];
  public editMode = false;
  public isLoading = true;

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  public tinyMceSettings = {
    toolbar:
      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | help',
    help_tabs: ['shortcuts'],
    menubar: false,
  };

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.newsForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private newsArticleService: NewsArticleService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit() {
    // create form and set data to input fields
    this.getArticleTypes();
    this.createForm();
    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const newsArticleId = +atob(this.activatedRoute.snapshot.params['id']);
      this.initData(newsArticleId);
    } else {
      this.isLoading = false;
      this.initialFormValues = this.newsForm.value;
    }
  }

  /**
   * retrieves the news article data from backend and initializes the form values
   * @returns void
   */
  private initData(newsArticleId: number): void {
    this.newsArticleService
      .getNewsArticleById(newsArticleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentArticle =
            await this.newsArticleService.parseBackendNewsArticle(
              response.body
            );

          this.newsForm.patchValue({
            title: this.currentArticle.title,
            link: this.currentArticle.link,
            tinyMCE: this.currentArticle.content,
            type: this.currentArticle.newsArticleType?.id,
            articlePicture: this.currentArticle.image,
          });

          this.initialFormValues = this.newsForm.value;

          this.isLoading = false;
        },
        error: error => {
          this.onCancel();
        },
      });
  }

  /**
   * createForm
   * creates the form for the news article
   * @returns void
   */
  private createForm(): void {
    this.newsForm = new FormGroup({
      title: new FormControl(this.currentArticle?.title, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      link: new FormControl(
        this.currentArticle?.link,
        Validators.maxLength(2000)
      ),
      articlePicture: new FormControl(this.currentArticle?.image),
      tinyMCE: new FormControl(this.currentArticle?.content, [
        Validators.required,
        Validators.maxLength(10000),
      ]),
      type: new FormControl(
        this.currentArticle?.newsArticleType?.id.toString() ?? null,
        Validators.required
      ),
    });
  }

  /**
   * Returns the FormControl for the news article type.
   * @returns The FormControl for the news article type.
   */
  get newsArticleTypeControl(): FormControl {
    return this.newsForm.get('type') as FormControl;
  }

  /**
   * getArticleTypes
   * get all available news article types
   * @returns void
   */
  private getArticleTypes(): void {
    this.newsArticleService
      .getAllNewsTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.availableNewsArticleTypes = response.body.map(
            (type: NewsArticleType): SelectOption => {
              return {
                value: type.id,
                label: type.name,
              };
            }
          );
        },
        error: error => {},
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid, the news article is either created or updated.
   * @param publish - Optional parameter indicating whether the news article should be published.
   * @returns A Promise that resolves to void.
   */
  public async onSubmit(publish?: boolean): Promise<void> {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.newsForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.newsForm.value,
        this.initialFormValues
      ) &&
      publish === this.currentArticle?.isPublished
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode
      ? await this.editNewsArticle(publish)
      : await this.createNewsArticle(publish, false);
  }

  /**
   * createNewsArticle
   * creates a new news article
   * @param publishArticle boolean
   * @param resetForm boolean
   * @returns Promise<void>
   */
  public async createNewsArticle(
    publishArticle: boolean,
    resetForm: boolean
  ): Promise<void> {
    const newsArticleCreateModel: NewsArticleCreateModel = {
      title: this.newsForm.value.title,
      content: this.newsForm.value.tinyMCE,
      newsArticleTypeId: this.newsForm.value.type,
      image: this.newsForm.value.articlePicture,
      link: this.newsForm.value.link,
      isPublished: publishArticle,
      timePublished: publishArticle ? moment().utc().toDate() : null,
    };

    const createNewsArticleObservable =
      await this.newsArticleService.createNewsArticle(newsArticleCreateModel);

    createNewsArticleObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Artikel '${this.newsForm.value.title}' wurde erstellt.`
        );

        if (resetForm) {
          this.newsForm.reset();
          this.currentArticle = null;
          this.initialFormValues = this.newsForm.value;
          return;
        }
        this.initialFormValues = this.newsForm.value;
        const id = response.body;
        this.onCancel(id);
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Artikel '${this.newsForm.value.title}' konnte nicht erstellt werden.`
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * editNewsArticle
   * updates an existing news article
   * @param publishArticle boolean
   * @returns Promise<void>
   */
  private async editNewsArticle(publishArticle: boolean): Promise<void> {
    const newsArticleUpdateModel: NewsArticleUpdateModel = {
      title: this.newsForm.value.title,
      content: this.newsForm.value.tinyMCE,
      newsArticleTypeId: this.newsForm.value.type,
      image: this.newsForm.value.articlePicture,
      link: this.newsForm.value.link,
      isPublished: publishArticle,
      timePublished: publishArticle ? moment().utc().toDate() : null,
    };

    const updateNewsArticleObservable =
      await this.newsArticleService.updateNewsArticle(
        this.currentArticle.id,
        newsArticleUpdateModel
      );
    updateNewsArticleObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Artikel '${this.newsForm.value.title}' wurde erfolgreich bearbeitet`
        );
        this.initialFormValues = this.newsForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Artikel '${this.newsForm.value.title}' konnte nicht bearbeitet werden`
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * onCancel
   * navigates back to the parent route
   * @param newsArticleId
   * @param deleteAction
   * @returns void
   */
  public onCancel(newsArticleId?: number, deleteAction?: boolean): void {
    if (this.editMode) {
      this.router.navigate(
        [
          '../../',
          deleteAction || !this.currentArticle?.id
            ? ''
            : btoa(this.currentArticle?.id.toString()),
        ],
        {
          relativeTo: this.activatedRoute,
        }
      );
    } else {
      this.router.navigate(['../'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * onPublish
   * publishes the current article
   * @returns void
   */
  public onPublish(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Veröffentlichen',
        message:
          this.currentArticle && this.currentArticle?.isPublished
            ? 'Möchten Sie Ihre Änderungen wirklich veröffentlichen?'
            : 'Möchten Sie den Entwurf wirklich veröffentlichen?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.onSubmit(true);
        }
      });
  }

  /**
   * onUnpublish
   * unpublishes the current article
   * @param newsArticle
   */
  public onUnpublish(newsArticle: { id: number }): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Verbergen',
        message: 'Möchten Sie den Artikel wirklich verbergen?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.onSubmit(false);
        }
      });
  }

  /**
   * onDelete
   * deletes the current article
   * @param newsArticle
   * @returns void
   */
  public onDelete(newsArticle: NewsArticleModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: 'Möchten Sie den Artikel wirklich löschen?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.newsArticleService
            .deleteNewsArticle(newsArticle.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Artikel '${newsArticle.title}' wurde erfolgreich gelöscht`
                );
                this.onCancel(null, true);
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Der Artikel '${newsArticle.title}' konnte nicht gelöscht werden`
                );
              },
            });
        }
      });
  }

  /**
   * imageChangeEvent
   * opens the image cropper dialog and sets the cropped image as article picture
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Artikelbild zuschneiden',
        round: false,
        height: 500,
        aspectRatio: 11 / 5,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result) {
          this.newsForm.get('articlePicture').setValue(result);
        }
      });
  }

  /**
   * deleteImage
   * @description delete the uploaded image
   * @returns void
   */
  public deleteImage() {
    this.newsForm.get('articlePicture').setValue(null);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.newsForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * cancels all requests and unsubscribes from the destroy$ Subject
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
