<div class="component-content">
  <app-initial-education-stock-alert></app-initial-education-stock-alert>
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">{{ getDashboardHeaderTitle() }}</h1>
      <h3 class="hasomed-text-h3">
        {{ getDashboardHeaderSubTitle() }}
      </h3>
    </div>
  </div>

  <div class="main-content">
    <app-my-space-header></app-my-space-header>

    <div class="calendar-header flex-r justify-space-between align-center">
      <div class="header-title">
        <h1 class="hasomed-text-h1">
          {{ showRoomUtilizationCalendar ? 'Raumauslastung' : 'Kalender' }}
        </h1>
        <h3 class="hasomed-text-h3">Ihre bevorstehenden Termine.</h3>
      </div>
      <div class="header-buttons flex-r gap-16">
        <!-- Add date button -->
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="onChangeCalendar()">
          <i class="hasomed-button-icon fa-light fa-calendar-week"></i>
          <span class="hasomed-button-text">
            {{
              showRoomUtilizationCalendar ? 'Kalenderansicht' : 'Raumansicht'
            }}
          </span>
        </button>
        <!-- <button
          type="button"
          class="hasomed-text-button hasomed-button-primary"
          (click)="onManageAppointments()">
          <i class="hasomed-button-icon fa-light fa-calendar-plus"></i>
          <span class="hasomed-button-text">Termine verwalten</span>
        </button> -->
      </div>
    </div>

    <!-- Calendar  -->
    <app-calendar *ngIf="!showRoomUtilizationCalendar"></app-calendar>
    <app-room-utilization-calendar
      *ngIf="showRoomUtilizationCalendar"></app-room-utilization-calendar>
  </div>
</div>
