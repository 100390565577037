import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { FileModel } from 'src/app/models/file.model';
import { PatientAppointmentModel } from 'src/app/models/patient-appointment.model';
import { SupervisionAppointmentModel } from 'src/app/models/supervision-appointment.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionAppointmentService } from 'src/app/services/supervision-appointment.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllPatientAppointmentAccompanyingPersons,
  getAllSupervisionAppointmentSupervisors,
} from 'src/app/utils/treatment-case.utils';

@Component({
  selector: 'app-treatment-case-event-dates-overview',
  templateUrl: './treatment-case-event-dates-overview.component.html',
  styleUrl: './treatment-case-event-dates-overview.component.scss',
})
export class TreatmentCaseEventDatesOverviewComponent
  implements OnInit, OnDestroy
{
  public appointmentType: AppointmentType = 'Supervisionstermin';
  public treatmentCaseId: number = 0;
  public appointmentId: number = 0;
  public currentPatientAppointment?: PatientAppointmentModel;
  public currentSupervisionAppointment?: SupervisionAppointmentModel;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getAllSupervisionAppointmentSupervisors =
    getAllSupervisionAppointmentSupervisors;
  public getAllPatientAppointmentAccompanyingPersons =
    getAllPatientAppointmentAccompanyingPersons;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionAppointmentService: SupervisionAppointmentService,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.appointmentType = this.activatedRoute.snapshot.queryParams.type;
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.appointmentId = +atob(decodeURIComponent(params.appointmentId));
        this.treatmentCaseId = +atob(
          decodeURIComponent(params.treatmentCaseId)
        );

        if (this.appointmentType === 'Patiententermin') {
          this.initPatientAppointment(this.treatmentCaseId, this.appointmentId);
        } else if (this.appointmentType === 'Supervisionstermin') {
          this.initSupervisionAppointment(this.appointmentId);
        }
      });
  }

  /**
   * Initialize patient appointment
   * @param treatmentCaseId The treatment case id
   * @param appointmentId The appointment id
   * @returns void
   */
  private initPatientAppointment(
    treatmentCaseId: number,
    appointmentId: number
  ): void {
    this.patientAppointmentService
      .getPatientAppointmentById(treatmentCaseId, appointmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentPatientAppointment =
            await this.patientAppointmentService.parseBackendPatientAppointment(
              response.body
            );

          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Patiententermin konnte nicht geladen werden!'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * Initialize supervision appointment
   * @param appointmentId The appointment id
   * @returns void
   */
  private initSupervisionAppointment(appointmentId: number) {
    this.supervisionAppointmentService
      .getSupervisionAppointmentById(appointmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentSupervisionAppointment =
            await this.supervisionAppointmentService.parseBackendSupervisionAppointment(
              response.body
            );
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Die Supervision konnte nicht geladen werden!'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * Gets the treatment case name of the supervision appointment
   * @returns string
   */
  public getTreatmentCaseNameOfSupervisionAppointment(): string {
    const treatmentCase =
      this.currentSupervisionAppointment?.treatmentCases.find(
        treatmentCase => treatmentCase.id === this.treatmentCaseId
      );

    return 'Patient: Chiffre (' + treatmentCase?.patientChiffre + ')' || '';
  }

  /**
   * Open the edit appointment page
   * @returns void
   */
  public onEditTreatmentCaseEventDate(): void {
    if (this.appointmentType === 'Patiententermin') {
      this.router.navigate(
        ['../../edit-appointment', btoa(String(this.appointmentId))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    } else {
      this.router.navigate(
        ['../../../edit-appointment', btoa(String(this.appointmentId))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * Go back to the previous page
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.supervisionAppointmentService.openFile(
      this.currentSupervisionAppointment.id,
      file.id
    );
  }

  /**
   * onDownloadFile
   * download the file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.supervisionAppointmentService.downloadFile(
      this.currentSupervisionAppointment.id,
      file.id
    );
  }

  /**
   * ngOnDestroy
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
