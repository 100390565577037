import { Pipe, PipeTransform } from '@angular/core';
import { CourseModel, CourseType } from '../models/course.model';
import {
  Filter,
  FilterDateRange,
  FilterType,
  FilterUser,
} from '../models/filter.model';
import { RoomModel } from '../models/room.model';
import { isDateInRange } from '../utils/date.utils';
import { isCourseFinished } from '../utils/course.utils';

@Pipe({
  name: 'filterCourse',
  standalone: true,
})
export class FilterCoursePipe implements PipeTransform {
  transform(courses: CourseModel[], filter: Filter[]): CourseModel[] {
    if (!courses || !filter) {
      return courses;
    }

    return courses.filter(course => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              course.startDate,
              course.endDate,
              filter.value as FilterDateRange
            );
          case FilterType.COURSE_TYPE:
            if (this.isCourseType(filter.value)) {
              return course.courseType.id === filter.value.id;
            }
            return false;
          case FilterType.LECTURER:
            return course.lecturers.find(
              it => it.id === (filter.value as FilterUser).id
            );
          case FilterType.ROOM_NAME:
            if (this.isRoom(filter.value)) {
              return course.room?.id === filter.value.id;
            }
            return false;
          case FilterType.REGISTERED_COURSES:
            return course.isRegistered;
          case FilterType.FINISHED_COURSES:
            return isCourseFinished(course);
          case FilterType.OPEN_COURSES:
            return !course.isRegistered;
          case FilterType.PENDING_ELOGS:
            return course.elogStatusCounts.pending > 0;
          default:
            return true;
        }
      });
    });
  }

  private isCourseType(obj: any): obj is CourseType {
    return obj && typeof obj === 'object' && 'name' in obj;
  }

  private isRoom(obj: any): obj is RoomModel {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }
}
