import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { EventLocation } from 'src/app/enums/event-location.enum';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { AccompanyingPerson as AccompaningPersonModel } from 'src/app/models/accompanying-person.model';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { RecurrencePattern } from 'src/app/models/course.model';
import {
  EventDate,
  EventDateCreateModel,
  EventDateUpdateModel,
} from 'src/app/models/event.model';
import { FileModel } from 'src/app/models/file.model';
import {
  PatientAppointmentCreateModel,
  PatientAppointmentModel,
  PatientAppointmentUpdateModel,
} from 'src/app/models/patient-appointment.model';
import {
  SupervisionAppointmentCreateModel,
  SupervisionAppointmentModel,
  SupervisionAppointmentUpdateModel,
} from 'src/app/models/supervision-appointment.model';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import {
  AdditionalQualificationEnum,
  UserModel,
} from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionAppointmentService } from 'src/app/services/supervision-appointment.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-create-edit-appointment',
  templateUrl: './create-edit-appointment.component.html',
  styleUrl: './create-edit-appointment.component.scss',
})
export class CreateEditAppointmentComponent implements OnInit, OnDestroy {
  public appointmentType: AppointmentType = 'Patiententermin';
  public currentAppointment?:
    | PatientAppointmentModel
    | SupervisionAppointmentModel;
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public appointmentForm: FormGroup;
  public initialFormValues: any = {};
  public availableTreatmentCases: TreatmentCaseModel[] = [];
  public availableAccompanyingPersons: AccompaningPersonModel[] = [];
  public availableSupervisors: UserModel[] = [];
  public availablePatientAppointmentEventDates: EventDate[] = [];
  public defaultSelectedSupervisors: UserModel[] = [];
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];
  private studentId: number = this.userService.currentUser.id;

  // event picker
  public recurrencePattern: RecurrencePattern;
  public eventDates: EventDate[] = [];
  public startDate: any;
  public endDate: any;
  public allDayEvent: boolean = false;
  public formSubmitted: boolean = false;

  public roomPlanningOpen: boolean = false;
  public roomPlanningValid: boolean = true;
  public roomPlanningDisabled: boolean = false;
  public showLink: boolean = false;

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  public EventLocation = EventLocation;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.appointmentForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionAppointmentService: SupervisionAppointmentService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();

    if (this.activatedRoute.snapshot.params['appointmentId']) {
      this.editMode = true;
    }

    if (this.activatedRoute.snapshot.params['studentId']) {
      this.studentId = +atob(
        decodeURIComponent(this.activatedRoute.snapshot.params['studentId'])
      );
    }

    await Promise.all([
      this.initAvailableTreatmentCases(),
      this.initAvailableSupervisors(),
    ]);

    this.initAvailableAccompanyingPersons();
    this.initAvailablePatientAppointmentEventDates();

    // if the route contains a treatment case id, the treatment case should be preselected
    if (this.activatedRoute.snapshot.params['treatmentCaseId']) {
      this.setSelectedTreatmentCase();
    }

    if (this.editMode) {
      this.determineAndInitializeAppointment();
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Create the form
   * @returns void
   */
  private createForm(): void {
    this.appointmentForm = this.formBuilder.group({
      supervisionsType: new FormControl('', Validators.required),
      patientAppointmentTreatmentCaseId: new FormControl(
        null,
        Validators.required
      ),
      supervisionAppointmentTreatmentCaseIds: new FormControl(
        [],
        Validators.required
      ),
      patientAppointmentEventDateIds: new FormControl({
        value: [],
        disabled: true,
      }),
      accompanyingPersonsIds: new FormControl({
        value: [],
        disabled: true,
      }),
      durationInTimeUnits: new FormControl(1, Validators.required),
      dateGroup: new FormControl(null),
      location: new FormControl(EventLocation.ONSITE),
      videoMeetingLink: new FormControl(null),
      supervisorIds: new FormControl([], Validators.required),
      supervisorSearch: new FormControl(null),
      description: new FormControl(null, Validators.maxLength(6000)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });

    // react to location changes
    this.appointmentForm.controls['location'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        if (location == EventLocation.ONLINE) {
          this.roomPlanningDisabled = true;
          this.roomPlanningValid = true;
          this.showLink = true;

          // set required validator for link if location is ONLINE
          this.appointmentForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
          return;
        }

        if (location == EventLocation.ONSITE) {
          this.roomPlanningDisabled = false;
          this.showLink = false;

          // remove required validator for link if location is ONSITE
          this.appointmentForm.get('videoMeetingLink').clearValidators();
          this.appointmentForm.get('videoMeetingLink').updateValueAndValidity();
          return;
        }

        if (location == EventLocation.HYBRID) {
          this.roomPlanningDisabled = false;
          this.showLink = true;

          // set required validator for link if location is HYBRID
          this.appointmentForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
          return;
        }
      });

    // subscribe to uploadedFiles changes and convert them to base64
    this.appointmentForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.appointmentForm.get('documents').setValue(null);
          return;
        }
        this.fileService.handleFileUpload(
          value,
          this.appointmentForm.get('documents'),
          false,
          true
        );
      });

    this.initialFormValues = this.appointmentForm.value;
  }

  /**
   * Returns the FormControl for the patientAppointmentEventDateIds.
   * @returns The FormControl for the patientAppointmentEventDateIds.
   */
  get patientAppointmentEventDateIdsFormControl(): FormControl {
    return this.appointmentForm.get(
      'patientAppointmentEventDateIds'
    ) as FormControl;
  }

  /**
   * Returns the FormControl for the accompanyingPersonsIds.
   * @returns The FormControl for the accompanyingPersonsIds.
   */
  get accompanyingPersonsIdsFormControl(): FormControl {
    return this.appointmentForm.get('accompanyingPersonsIds') as FormControl;
  }

  /**
   * Returns an array of IDs for the available patient appointment event dates.
   * @returns {number[]} An array of IDs for the available patient appointment event dates.
   */
  get availablePatientAppointmentEventDatesIds(): number[] {
    return this.availablePatientAppointmentEventDates.map(
      (eventDate: EventDate) => eventDate.id
    );
  }

  /**
   * Returns an array of IDs for the available accompanying persons.
   * @returns {number[]} An array of IDs for the available accompanying persons.
   */
  get availableAccompanyingPersonsIds(): number[] {
    return this.availableAccompanyingPersons.map(
      (accompanyingPerson: AccompaningPersonModel) => accompanyingPerson.id
    );
  }

  /**
   * Init available treatment cases and supervisors for the select fields
   * @returns void
   */
  private async initAvailableTreatmentCases(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.treatmentCaseService
        .getAllTreatmentCasesByInstituteIdAndStudentId(this.studentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableTreatmentCases = response.body
              ? await Promise.all(
                  response.body.map(async (treatmentCaseData: any) => {
                    return this.treatmentCaseService.parseBackendTreatmentCase(
                      treatmentCaseData
                    );
                  })
                )
              : [];
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Behandlungsfälle konnten nicht geladen werden!'
            );
            this.isLoading = false;
            reject(error);
          },
        });
    });
  }

  /**
   * init the available supervisors for the select field
   * @returns void
   */
  private async initAvailableSupervisors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByAdditionalQualification(
          AdditionalQualificationEnum.SUPERVISOR
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableSupervisors = response.body
              ? await Promise.all(
                  response.body.map(
                    async (userData: any) =>
                      await this.userService.parseBackendUser(userData)
                  )
                )
              : [];
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Supervisoren konnten nicht geladen werden!'
            );
            reject(error);
          },
        });
    });
  }

  /**
   * initalizes the available accompanying persons from the treatment case that is selected
   * @returns void
   */
  private initAvailableAccompanyingPersons(): void {
    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value) {
          this.appointmentForm.get('accompanyingPersonsIds').enable();
          this.availableAccompanyingPersons = this.availableTreatmentCases.find(
            (treatmentCase: TreatmentCaseModel) => treatmentCase.id === value
          )?.accompanyingPersons;
        } else {
          this.appointmentForm.get('accompanyingPersonsIds').disable();
          this.availableAccompanyingPersons = [];
        }
      });
  }

  /**
   * initalizes the available patient appointment event dates from the selected treatment cases
   * @returns void
   */
  private initAvailablePatientAppointmentEventDates(): void {
    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: number[]) => {
        if (value.length === 0) {
          this.appointmentForm.get('patientAppointmentEventDateIds').disable();
          return;
        }

        if (value.length > 0) {
          this.appointmentForm.get('supervisorIds').enable();
          this.appointmentForm.get('patientAppointmentEventDateIds').enable();
          const currentPatientAppointmentEventDateIds =
            this.appointmentForm.get('patientAppointmentEventDateIds').value;

          // get all eventDates of patient appointments inside the selected treatment cases
          this.availablePatientAppointmentEventDates =
            this.availableTreatmentCases
              .filter((treatmentCase: TreatmentCaseModel) =>
                value.includes(treatmentCase.id)
              )
              .reduce((acc: EventDate[], treatmentCase: TreatmentCaseModel) => {
                return acc.concat(
                  treatmentCase.patientAppointments?.reduce(
                    (
                      acc: EventDate[],
                      appointment: PatientAppointmentModel
                    ) => {
                      return acc.concat(appointment.eventDates);
                    },
                    []
                  )
                );
              }, []);

          this.appointmentForm
            .get('patientAppointmentEventDateIds')
            .setValue(
              currentPatientAppointmentEventDateIds.filter((it: number) =>
                this.availablePatientAppointmentEventDatesIds.includes(it)
              )
            );
        } else {
          this.availablePatientAppointmentEventDates = [];
        }
      });
  }

  /**
   * Sets the selected treatment case for the appointment.
   * @returns void
   */
  private setSelectedTreatmentCase(): void {
    if (!this.activatedRoute.snapshot.params['treatmentCaseId']) {
      return;
    }

    const treatmentCaseId = +atob(
      decodeURIComponent(this.activatedRoute.snapshot.params['treatmentCaseId'])
    );

    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .setValue(treatmentCaseId);

    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .setValue([treatmentCaseId]);
  }

  /**
   * Determines and initializes the appointment based on the route parameters.
   * If the route contains a treatment case id, it is a patient appointment and the `initPatientAppointment` method is called.
   * If the route does not contain a treatment case id, it is a supervision appointment and the `initSupervisionAppointment` method is called.
   */
  private determineAndInitializeAppointment(): void {
    if (!this.activatedRoute.snapshot.params['appointmentId']) {
      return;
    }

    const appointmentId = +atob(
      decodeURIComponent(this.activatedRoute.snapshot.params['appointmentId'])
    );
    // if the route contains a treatment case id, it is a patient appointment, because a patient appointment is always connected to only one treatment case
    if (this.activatedRoute.snapshot.params['treatmentCaseId']) {
      const treatmentCaseId = +atob(
        decodeURIComponent(
          this.activatedRoute.snapshot.params['treatmentCaseId']
        )
      );
      this.appointmentType = 'Patiententermin';
      this.initPatientAppointment(treatmentCaseId, appointmentId);
    } else {
      // if the route does not contain a treatment case id, it is a supervision appointment, because supervision appointments are connected to multiple treatment cases
      this.appointmentType = 'Supervisionstermin';
      this.initSupervisionAppointment(appointmentId);
    }
  }

  /**
   * initalizes the patient appointment to edit
   * @param treatmentCaseId the treatment case id
   * @param appointmentId the appointment id
   * @returns void
   */
  private initPatientAppointment(
    treatmentCaseId: number,
    appointmentId: number
  ): void {
    this.patientAppointmentService
      .getPatientAppointmentById(treatmentCaseId, appointmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentAppointment =
            await this.patientAppointmentService.parseBackendPatientAppointment(
              response.body
            );

          this.appointmentForm.patchValue({
            patientAppointmentTreatmentCaseId:
              this.currentAppointment.treatmentCase.id,
            accompanyingPersonsIds:
              this.currentAppointment.accompanyingPersons.map(
                (accompanyingPerson: AccompaningPersonModel) =>
                  accompanyingPerson.id
              ),
            durationInTimeUnits: this.currentAppointment.durationInTimeUnits,
            location: this.currentAppointment.location,
            videoMeetingLink: this.currentAppointment.videoMeetingLink,
          });

          this.eventDates = this.currentAppointment.eventDates;
          this.recurrencePattern = this.currentAppointment.recurrencePattern;

          // set start date to the earliest date and end date to the latest date
          this.startDate = this.eventDates.reduce((acc, date) => {
            return acc < date.startDate ? acc : date.startDate;
          }, this.eventDates[0].startDate);

          this.endDate = this.eventDates.reduce((acc, date) => {
            return acc > date.endDate ? acc : date.endDate;
          }, this.eventDates[0].endDate);

          // set patientAppointmentTreatmentCaseId to disabled
          this.appointmentForm
            .get('patientAppointmentTreatmentCaseId')
            .disable();

          this.cdr.detectChanges();
          this.initialFormValues = this.appointmentForm.value;
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Patiententermin konnte nicht geladen werden.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * initalizes the supervision appointment to edit
   * @param appointmentId the appointment id
   * @returns void
   */
  private initSupervisionAppointment(appointmentId: number): void {
    this.supervisionAppointmentService
      .getSupervisionAppointmentById(appointmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentAppointment =
            await this.supervisionAppointmentService.parseBackendSupervisionAppointment(
              response.body
            );

          this.appointmentForm.patchValue({
            supervisionsType: this.currentAppointment.type,
            supervisionAppointmentTreatmentCaseIds:
              this.currentAppointment.treatmentCases.map(
                (treatmentCase: TreatmentCaseModel) => treatmentCase.id
              ),
            patientAppointmentEventDateIds:
              this.currentAppointment.patientAppointmentEventDates.map(
                (eventDate: EventDate) => eventDate.id
              ),
            durationInTimeUnits: this.currentAppointment.durationInTimeUnits,
            location: this.currentAppointment.location,
            videoMeetingLink: this.currentAppointment.videoMeetingLink,
            supervisorIds: this.currentAppointment.supervisors.map(
              (supervisor: UserModel) => supervisor.id
            ),
            description: this.currentAppointment.description,
          });

          this.eventDates = this.currentAppointment.eventDates;
          this.recurrencePattern = this.currentAppointment.recurrencePattern;

          // set start date to the earliest date and end date to the latest date
          this.startDate = this.eventDates.reduce((acc, date) => {
            return acc < date.startDate ? acc : date.startDate;
          }, this.eventDates[0].startDate);

          this.endDate = this.eventDates.reduce((acc, date) => {
            return acc > date.endDate ? acc : date.endDate;
          }, this.eventDates[0].endDate);

          this.cdr.detectChanges();
          this.initialFormValues = this.appointmentForm.value;
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervision konnte nicht geladen werden.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * Check if the form has unsaved changes on appointment type change, sets and clears validators
   * @param appointmentType the appointment type
   * @returns void
   */
  public onAppointmentTypeChanged(appointmentType: AppointmentType): void {
    if (appointmentType !== this.appointmentType) {
      if (
        this.formDeactivateService.hasUnsavedChanges(
          this.appointmentForm.value,
          this.initialFormValues
        )
      ) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Ungespeicherte Änderungen!',
            message:
              'Sie haben ungespeicherte Änderungen. Wenn Sie die Art des Termins ändern, gehen Daten verloren. \
                Möchten Sie die Art des Termins trotzdem ändern?',
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (result) {
              this.appointmentType = appointmentType;
              this.initialFormValues = this.appointmentForm.value;

              if (appointmentType === 'Patiententermin') {
                this.setPatientAppointmentValidators();
                this.clearSupervisionAppointmentValidators();
              } else {
                this.setSupervisionAppointmentValidators();
                this.clearPatientAppointmentValidators();
              }
            }
          });
      } else {
        this.appointmentType = appointmentType;
        this.initialFormValues = this.appointmentForm.value;

        if (appointmentType === 'Patiententermin') {
          this.setPatientAppointmentValidators();
          this.clearSupervisionAppointmentValidators();
        } else {
          this.setSupervisionAppointmentValidators();
          this.clearPatientAppointmentValidators();
        }
      }
    }
  }

  /**
   * event dates changed from event picker
   * @param eventDates
   * @returns void
   */
  public onEventsChanged(eventDates: EventDate[]): void {
    this.eventDates = eventDates;
  }

  /**
   * dates changed from event picker
   * @param dates
   * @returns void
   */
  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }): void {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  /**
   * setEventLocation
   * set the event location in the appointment form
   * @param location event location
   */
  public setEventLocation(location: EventLocation) {
    this.appointmentForm.get('location').setValue(location);
  }

  /**
   * open room planning
   * @returns void
   */
  public openRoomPlanning(): void {
    /* only open room planning if eventDates have been selected */
    if (this.eventDates.length > 0) {
      this.roomPlanningOpen = true;
    } else {
      this.alertService.showErrorAlert(
        'Fehler',
        'Bitte wählen Sie mindestens einen Termin aus!'
      );
    }
  }

  /**
   * room planning changed
   * @param eventDates
   * @returns void
   */
  public roomPlanningChanged(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      this.eventDates = eventDates;
      this.roomPlanningOpen = false;
    }
  }
  /**
   * adds or removes the clicked supervisor to the selected supervisors control
   * @param supervisor
   * @returns void
   */
  public onSupervisorSelected(supervisor: UserModel): void {
    if (
      this.appointmentForm.get('supervisorIds').value.includes(supervisor.id)
    ) {
      // remove supervisor
      this.appointmentForm
        .get('supervisorIds')
        .setValue(
          this.appointmentForm
            .get('supervisorIds')
            .value.filter((id: number) => id !== supervisor.id)
        );
    } else {
      // add supervisor
      this.appointmentForm
        .get('supervisorIds')
        .setValue(
          this.appointmentForm.get('supervisorIds').value.concat(supervisor.id)
        );
    }
  }

  /**
   * On cancel
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * Handles the form submission for creating or updating an appointment.
   * Clears the validators based on the appointment type.
   * If the form is valid and has changes it creates or updates the appointment.
   * @returns void
   */
  public onSubmit(): void {
    // clear validators for patientAppointment if appointmentType is supervision, and vice versa
    if (this.appointmentType === 'Patiententermin') {
      this.clearSupervisionAppointmentValidators();
    } else {
      this.clearPatientAppointmentValidators();
    }

    this.formSubmitted = true;

    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.appointmentForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.appointmentForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    if (this.appointmentType === 'Patiententermin') {
      this.editMode
        ? this.updatePatientAppointment()
        : this.createPatientAppointment();
    } else {
      this.editMode
        ? this.updateSupervisionAppointment()
        : this.createSupervisionAppointment();
    }
  }

  /**
   * Sets the patient appointment validators
   * @returns void
   */
  private setPatientAppointmentValidators(): void {
    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .setValidators(Validators.required);
    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .updateValueAndValidity();
  }

  /**
   * Clears the patient appointment validators
   * @returns void
   */
  private clearPatientAppointmentValidators(): void {
    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .clearValidators();
    this.appointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .updateValueAndValidity();
  }

  /**
   * Sets the supervision appointment validators
   * @returns void
   */
  private setSupervisionAppointmentValidators(): void {
    this.appointmentForm
      .get('supervisionsType')
      .setValidators(Validators.required);
    this.appointmentForm.get('supervisionsType').updateValueAndValidity();
    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .setValidators(Validators.required);
    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .updateValueAndValidity();
    this.appointmentForm
      .get('supervisorIds')
      .setValidators(Validators.required);
    this.appointmentForm.get('supervisorIds').updateValueAndValidity();
  }

  /**
   * Clears the supervision appointment validators
   * @returns void
   */
  private clearSupervisionAppointmentValidators() {
    this.appointmentForm.get('supervisionsType').clearValidators();
    this.appointmentForm.get('supervisionsType').updateValueAndValidity();
    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .clearValidators();
    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .updateValueAndValidity();
    this.appointmentForm.get('supervisorIds').clearValidators();
    this.appointmentForm.get('supervisorIds').updateValueAndValidity();
  }

  /**
   * create a patient appointment
   * @returns Promise<void>
   */
  private async createPatientAppointment(): Promise<void> {
    const treatmentCaseId = this.appointmentForm.get(
      'patientAppointmentTreatmentCaseId'
    ).value;

    const patientAppointment: PatientAppointmentCreateModel = {
      accompanyingPersonsIds: this.appointmentForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
        .value,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateCreateModel => {
          const eventDateCreateModel: EventDateCreateModel = {
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateCreateModel;
        }
      ),
      location: this.appointmentForm.get('location').value,
      videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
    };

    const createPatientAppointmentObservable =
      await this.patientAppointmentService.createPatientAppointment(
        treatmentCaseId,
        patientAppointment
      );

    createPatientAppointmentObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Patiententermin wurde erfolgreich erstellt!'
        );
        this.initialFormValues = this.appointmentForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt',
          'Der Patiententermin konnte nicht erstellt werden!'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * update a patient appointment
   * @returns Promise<void>
   */
  private async updatePatientAppointment(): Promise<void> {
    const treatmentCaseId = this.appointmentForm.get(
      'patientAppointmentTreatmentCaseId'
    ).value;

    const patientAppointment: PatientAppointmentUpdateModel = {
      accompanyingPersonsIds: this.appointmentForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
        .value,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateUpdateModel => {
          const eventDateUpdateModel: EventDateUpdateModel = {
            id: eventDate.id,
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateUpdateModel;
        }
      ),
      location: this.appointmentForm.get('location').value,
      videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
    };

    const updatePatientAppointmentObservable =
      await this.patientAppointmentService.updatePatientAppointment(
        treatmentCaseId,
        this.currentAppointment.id,
        patientAppointment
      );

    updatePatientAppointmentObservable.pipe(first()).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Patiententermin wurde erfolgreich aktualisiert.'
        );
        this.initialFormValues = this.appointmentForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Patiententermin konnte nicht aktualisiert werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * create a supervision appointment
   * @returns Promise<void>
   */
  private async createSupervisionAppointment(): Promise<void> {
    const files: FileModel[] = this.appointmentForm.value.documents
      ? this.existingFiles.concat(this.appointmentForm.value.documents)
      : this.existingFiles;

    const supervisionAppointment: SupervisionAppointmentCreateModel = {
      type: this.appointmentForm.get('supervisionsType').value,
      treatmentCaseIds: this.appointmentForm.get(
        'supervisionAppointmentTreatmentCaseIds'
      ).value,
      patientAppointmentEventDateIds: this.appointmentForm.get(
        'patientAppointmentEventDateIds'
      ).value,
      recurrencePattern: this.recurrencePattern,
      durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
        .value,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateCreateModel => {
          const eventDateCreateModel: EventDateCreateModel = {
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateCreateModel;
        }
      ),
      location: this.appointmentForm.get('location').value,
      videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
      supervisorIds: this.appointmentForm.get('supervisorIds').value,
      description: this.appointmentForm.get('description').value,
      files: files,
    };

    const createSupervisionAppointmentObservable =
      await this.supervisionAppointmentService.createSupervisionAppointment(
        supervisionAppointment
      );

    createSupervisionAppointmentObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Supervision wurde erfolgreich erstellt.'
          );
          this.initialFormValues = this.appointmentForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervision konnte nicht erstellt werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * update a supervision appointment
   * @returns Promise<void>
   */
  private async updateSupervisionAppointment(): Promise<void> {
    const files: FileModel[] = this.appointmentForm.value.documents
      ? this.existingFiles.concat(this.appointmentForm.value.documents)
      : this.existingFiles;

    const supervisionAppointment: SupervisionAppointmentUpdateModel = {
      type: this.appointmentForm.get('supervisionsType').value,
      treatmentCaseIds: this.appointmentForm.get(
        'supervisionAppointmentTreatmentCaseIds'
      ).value,
      patientAppointmentEventDateIds: this.appointmentForm.get(
        'patientAppointmentEventDateIds'
      ).value,
      recurrencePattern: this.recurrencePattern,
      durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
        .value,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateUpdateModel => {
          const eventDateUpdateModel: EventDateUpdateModel = {
            id: eventDate.id,
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateUpdateModel;
        }
      ),
      location: this.appointmentForm.get('location').value,
      videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
      supervisorIds: this.appointmentForm.get('supervisorIds').value,
      description: this.appointmentForm.get('description').value,
      files: files,
    };

    const updateSupervisionAppointmentObservable =
      await this.supervisionAppointmentService.updateSupervisionAppointment(
        this.currentAppointment.id,
        supervisionAppointment
      );

    updateSupervisionAppointmentObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Supervision wurde erfolgreich aktualisiert.'
          );
          this.initialFormValues = this.appointmentForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervision konnte nicht aktualisiert werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.appointmentForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalEventLocationOrder = (
    a: KeyValue<string, EventLocation>,
    b: KeyValue<string, EventLocation>
  ): number => {
    return 0;
  };

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.supervisionAppointmentService.openFile(
      this.currentAppointment?.id,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.supervisionAppointmentService.downloadFile(
      this.currentAppointment?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.appointmentForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * ngOnDestroy
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
