import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ViewProfilePictureDialogComponent } from 'src/app/components/shared-components/view-profile-picture-dialog/view-profile-picture-dialog.component';
import { FileModel } from 'src/app/models/file.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-view-member',
  templateUrl: './view-member.component.html',
  styleUrl: './view-member.component.scss',
})
export class ViewMemberComponent implements OnInit, OnDestroy {
  public user: UserModel;
  public isLoading = true;
  public existingFiles: FileModel[] = [];
  // import from utils
  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private location: Location,
    private router: Router,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    const userId = +atob(this.activatedRoute.snapshot.paramMap.get('userId'));

    this.getUser(userId);
  }

  /**
   * retrieve user data from the backend
   * @param userId The id of the user
   * @returns void
   */
  private getUser(userId: number): void {
    this.userService
      .getInstituteUserById(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * navigate back to the previous page
   * @returns void
   */
  public onBackToOverview(): void {
    this.location.back();
  }

  /**
   * navigate to edit student page
   * @returns void
   */
  public onEdit(): void {
    this.router.navigate([
      `/eleguide/institute/members/edit/${btoa(String(this.user.id))}`,
    ]);
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    this.dialog.open(ViewProfilePictureDialogComponent, {
      data: {
        image: this.user.profilePicture,
      },
    });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
