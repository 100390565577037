import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ChangePasswordDialogComponent } from 'src/app/components/shared-components/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { FileModel } from 'src/app/models/file.model';
import { Role } from 'src/app/models/permission.model';
import { UserModel, UserUpdateModel } from 'src/app/models/user.model';
import { AddressService } from 'src/app/services/address.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-edit-personal-data',
  templateUrl: './edit-personal-data.component.html',
  styleUrls: ['./edit-personal-data.component.scss'],
})
export class EditPersonalDataComponent implements OnInit, OnDestroy {
  public personalDataForm: FormGroup;
  public initialFormValues: {};
  public user: UserModel;
  public today = moment();
  public datePickerStartDate = new Date(1990, 0, 1);
  public isLoading = true;
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public imageUrl: string | ArrayBuffer | null;

  public Role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.personalDataForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  // import from form.utils.ts
  public isRequired = isRequired;

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];
  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private alert: AlertService,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private formSubmitValidationService: FormSubmitValidationService,
    public addressService: AddressService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public ngOnInit() {
    this.getCurrentUserData();

    // create form
    this.createForm();

    // subscribe to uploadedFiles changes and convert them to base64
    this.personalDataForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.personalDataForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.personalDataForm.get('documents'),
          false,
          false
        );
      });
  }

  /**
   * createForm
   * @description create the form
   * @returns void
   */
  private createForm(): void {
    this.personalDataForm = this.formBuilder.group({
      genderTitle: new FormControl('', Validators.maxLength(25)),
      academicTitle: new FormControl('', Validators.maxLength(25)),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      email: new FormControl(''),
      birthdate: new FormControl(null),
      profilePicture: new FormControl(null),
      educationCourse: new FormControl(null),
      measlesProtection: new FormControl(null),
      street: new FormControl('', Validators.maxLength(255)),
      houseNumber: new FormControl('', [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        minNumberLength(5),
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', Validators.maxLength(255)),
      country: new FormControl('', Validators.maxLength(100)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * Returns the FormControl for the gender title in the personal data form.
   * @returns The FormControl for the gender title.
   */
  get genderTitleControl(): FormControl {
    return this.personalDataForm.get('genderTitle') as FormControl;
  }

  /**
   * Returns the FormControl for the country field in the personalDataForm.
   * @returns The FormControl for the country field.
   */
  get countryControl(): FormControl {
    return this.personalDataForm.get('country') as FormControl;
  }

  /**
   * Getter for the academic title form control.
   * @returns The FormControl for the academic title field.
   */
  get academicTitleControl(): FormControl {
    return this.personalDataForm.get('academicTitle') as FormControl;
  }

  /**
   * getCurrentUserData
   * @description get user data of current user
   * @returns void
   */
  public getCurrentUserData(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.isLoading = false;
          this.personalDataForm.patchValue({
            genderTitle: this.user.name.genderTitle,
            academicTitle: this.user.name.academicTitle,
            firstName: this.user.name.firstName,
            lastName: this.user.name.lastName,
            email: this.user.email,
            birthdate: this.user.birthdate,
            educationCourse: this.user.educationCourse?.id,
            measlesProtection: this.user.measlesProtection,
            street: this.user.address?.street,
            houseNumber: this.user.address?.houseNumber,
            addressAddition: this.user.address?.addressAddition,
            zipCode: this.user.address?.zipCode,
            city: this.user.address?.city,
            country: this.user.address?.country,
            documents: this.user.files,
            uploadedFiles: this.uploadedFiles,
            existingFiles: JSON.parse(JSON.stringify(this.existingFiles)),
          });

          if (this.user.profilePicture) {
            this.personalDataForm
              .get('profilePicture')
              .setValue(this.user.profilePicture);
          }

          this.cdr.detectChanges();
          this.initialFormValues = this.personalDataForm.value;
        },
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, it will update the user data.
   * @returns A promise of void
   */
  public async onSubmit(): Promise<void> {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.personalDataForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.personalDataForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    const files: FileModel[] = this.personalDataForm.value.documents
      ? this.existingFiles.concat(this.personalDataForm.value.documents)
      : this.existingFiles;

    /* reset inititalFormValue to current value */
    this.initialFormValues = this.personalDataForm.value;

    this.user.email = this.personalDataForm.value.email;
    this.user.name.genderTitle = this.personalDataForm.value.genderTitle;
    this.user.name.academicTitle = this.personalDataForm.value.academicTitle;
    this.user.name.firstName = this.personalDataForm.value.firstName;
    this.user.name.lastName = this.personalDataForm.value.lastName;
    this.user.birthdate = this.personalDataForm.value.birthdate;
    this.user.profilePicture = this.personalDataForm.value.profilePicture;
    this.user.address = {
      street: this.personalDataForm.value.street,
      houseNumber: this.personalDataForm.value.houseNumber,
      addressAddition: this.personalDataForm.value.addressAddition,
      zipCode: this.personalDataForm.value.zipCode,
      city: this.personalDataForm.value.city,
      country: this.personalDataForm.value.country,
    };
    this.user.userIdentifier = this.userService.createUserIdentifier(this.user);
    this.user.files = files;

    if (
      this.userService.currentUserIsAdministrator() ||
      this.userService.currentUserIsSuperadmin()
    ) {
      this.user.measlesProtection =
        this.personalDataForm.value.measlesProtection;
    }

    const userUpdateModel: UserUpdateModel = {
      labelId: this.user.label?.id,
      educationCourseId: this.user.educationCourse?.id,
      email: this.user.email,
      name: {
        genderTitle: this.user.name.genderTitle,
        academicTitle: this.user.name.academicTitle,
        firstName: this.user.name.firstName,
        lastName: this.user.name.lastName,
      },
      userIdentifier: this.user.userIdentifier,
      birthdate:
        this.user.birthdate && moment(this.user.birthdate).format('YYYY-MM-DD'),
      address: this.user.address,
      entryDate:
        this.user.entryDate && moment(this.user.entryDate).format('YYYY-MM-DD'),
      profilePicture: this.user.profilePicture,
      measlesProtection: this.user.measlesProtection,
      additionalQualificationIds: this.user.additionalQualifications?.map(
        item => item.id
      ),
      professionalAssociation: this.user.professionalAssociation,
      expertiseIds: this.user.expertises?.map(item => item.id),
      procedureIds: this.user.procedures?.map(item => item.id),
      ptkConsultation: this.user.ptkConsultation,
      ptkConsultationDate:
        this.user.ptkConsultationDate &&
        moment(this.user.ptkConsultationDate).format('YYYY-MM-DD'),
      confirmed: this.user.confirmed,
      confirmationDate:
        this.user.confirmationDate &&
        moment(this.user.confirmationDate).format('YYYY-MM-DD'),
      landlineNumber: this.user.landlineNumber,
      mobileNumber: this.user.mobileNumber,
      files: this.user.files,
    };

    this.loadingService.show();
    const updateUserObservable = await this.userService.updateUser(
      this.user.id,
      userUpdateModel
    );
    updateUserObservable.pipe(first()).subscribe({
      next: response => {
        this.userService.updateCurrentUser(this.user);
        this.alert.showSuccessAlert(
          'Das hat geklappt!',
          'Ihre Daten wurden erfolgreich aktualisiert.'
        );
        this.loadingService.hide();
        this.onCancel();
      },
      error: error => {
        this.loadingService.hide();
        this.alert.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Ihre Daten konnten nicht gespeichert werden.'
        );
      },
    });
  }

  /**
   * onCancel
   * @description navigate back to the previous page
   * @returns void
   */
  public onCancel(): void {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * changePassword
   * @description opens the change password dialog
   * @returns void
   */
  public changePassword(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '600px',
    });
  }

  /**
   * onDeleteAccount
   * @description opens the delete account dialog
   * @returns void
   */
  public onDeleteAccount(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {
        title: 'Account löschen',
        message:
          'Möchten Sie Ihren Account wirklich löschen? \
          Wenn Sie den Account löschen, verlieren Sie jegliche Informationen,\
          die mit ihrem Account verbunden sind. \
          Eine Wiederherstellung ist nicht mehr möglich. \
          Sind Sie sicher, dass Sie fortfahren möchten?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.alertService.showSuccessAlert(
            'Account Löschung beantragt',
            'Wir haben eine Löschanfrage an das Supportteam gesendet und melden uns, sobald die Löschung erfolgreich war.'
          );
        }
      });
  }

  /**
   * imageChangeEvent
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Profilbild zuschneiden',
        round: true,
        height: 300,
        aspectRatio: 1,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result) {
          this.personalDataForm.get('profilePicture').setValue(result);
        }
      });
  }

  /**
   * openProfilePictureDialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    const dialogRef = this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.personalDataForm.get('profilePicture').value,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result && result === 'delete') {
          this.personalDataForm.get('profilePicture').setValue(null);
        }
      });
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.userService.openFile(this.user?.id, file.id);
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.userService.downloadFile(this.user?.id, file.id);
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.personalDataForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * onMeaslesProtectionChanged
   * Set the measlesProtection value in the form
   * @param data
   * @returns void
   */
  public onMeaslesProtectionChanged(data: MatSlideToggleChange): void {
    this.personalDataForm.get('measlesProtection').setValue(data.checked);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.personalDataForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
