import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Feature, Permission } from 'src/app/models/permission.model';
import { RoomModel } from 'src/app/models/room.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { RoomService } from 'src/app/services/room.service';
import { getGermanWeekday } from 'src/app/utils/date.utils';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
  styleUrl: './room-details.component.scss',
})
export class RoomDetailsComponent {
  public isLoading = true;
  public room: RoomModel;

  private destroy$: Subject<void> = new Subject<void>();

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  public getGermanWeekday = getGermanWeekday;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService,
    private router: Router,
    private roomService: RoomService,
    private location: Location,
    private alertService: AlertService
  ) {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.getData(+atob(String(params.id)));
      });
  }

  public onGoBack() {
    this.location.back();
  }

  public onEdit() {
    this.router.navigate(['../edit', btoa(String(this.room.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * getData
   * get the institute data
   * @param roomId
   * @returns void
   */
  private async getData(roomId: number): Promise<void> {
    this.roomService
      .getRoomById(roomId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.room = await this.roomService.parseBackendRoom(response.body);
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte erneut versuchen.'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
