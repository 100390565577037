import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-practical-work',
  templateUrl: './practical-work.component.html',
  styleUrls: ['./practical-work.component.scss'],
})
export class PracticalWorkComponent implements OnInit {
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {}

  /**
   * Redirect to create practical work page
   * @returns void
   */
  public createPracticalWork(): void {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }
}
