import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ViewProfilePictureDialogComponent } from 'src/app/components/shared-components/view-profile-picture-dialog/view-profile-picture-dialog.component';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { FilterLecturerPipe } from 'src/app/pipes/filter-lecturer.pipe';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-lecturers',
  templateUrl: './lecturers.component.html',
  styleUrls: ['./lecturers.component.scss'],
})
export class LecturersComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'entryDate',
    'additionalQualifications',
    'label',
    'actions',
  ];
  public dataSource: MatTableDataSource<UserModel> = new MatTableDataSource();
  public allLecturers: UserModel[] = [];
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  public lecturerFilter: Filter[] = [
    {
      type: FilterType.LABEL,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator()),
  });

  // permission
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.initTable();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['name', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['name', 'label', 'actions'];
    } else {
      this.displayedColumns = [
        'name',
        'entryDate',
        'additionalQualifications',
        'label',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the lecturers of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name.firstName + ' ' + item.name.lastName;
        case 'entryDate':
          return item.entryDate;
        case 'label':
          return item.label?.name;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr =
        data.name.academicTitle?.toLowerCase() +
        ' ' +
        data.name.firstName.toLowerCase() +
        ' ' +
        data.name.lastName.toLowerCase();
      return dataStr.indexOf(filter) != -1;
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getInstituteLecturers();
  }

  /**
   * retrieves all lecturers of the current institute and initializes the table data
   * @returns Promise<void>
   */
  private async getInstituteLecturers(): Promise<void> {
    this.userService
      .getInstituteUsersByRole(Role.LECTURER, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.allLecturers = response.body
            ? await Promise.all(
                response.body?.map(
                  async (userData: UserModel): Promise<UserModel> => {
                    return this.userService.parseBackendUser(userData);
                  }
                )
              )
            : [];
          this.dataSource.data = this.allLecturers;

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Lehrpersonal konnte nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * lecturerFilterChanged
   * gets called when the lecturer filter changed
   * @param lecturerFilter Filter[]
   * @returns void
   */
  public lecturerFilterChanged(lecturerFilter: Filter[]): void {
    this.lecturerFilter = lecturerFilter;

    this.applyLecturerFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applyLecturerFilter
   * applies the lecturer filter
   * @returns void
   */
  public applyLecturerFilter(): void {
    this.dataSource.data = FilterLecturerPipe.prototype.transform(
      this.allLecturers,
      this.lecturerFilter
    );
  }

  /**
   * applySearch
   * applies the search filter to the table data
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * openLecturerDetail
   * navigate to the lecturer detail page
   * @param lecturerId
   */
  public openLecturerDetail(lecturerId: number) {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate(['detail', btoa(String(lecturerId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditLecturer
   * navigate to edit page
   * @param userId
   */
  public onEditLecturer(userId: number) {
    this.router.navigate(['edit', btoa(String(userId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: UserModel): void {
    this.dialog.open(ViewProfilePictureDialogComponent, {
      data: {
        image: user.profilePicture,
      },
    });
  }

  /**
   * navigate to the invite members page
   * @returns void
   */
  public onInviteUser(): void {
    this.router.navigate(['/eleguide/institute/members/invite']);
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
