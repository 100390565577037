import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { Feature, Permission } from 'src/app/models/permission.model';
import { PracticalWorkModel } from 'src/app/models/practical-work.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { PracticalWorkService } from 'src/app/services/practical-work.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-practical-work-table',
  templateUrl: './practical-work-table.component.html',
  styleUrls: ['./practical-work-table.component.scss'],
})
export class PracticalWorkTableComponent implements OnInit, OnDestroy {
  @Input() searchText: string;

  public displayedColumns = [
    'cooperationPartner',
    'date',
    'duration',
    'type',
    'confirmation',
    'actions',
  ];
  public dataSource: MatTableDataSource<PracticalWorkModel> =
    new MatTableDataSource();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private practicalWorkService: PracticalWorkService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        let userId = +atob(params.get('userId'));

        if (!userId) {
          userId = this.userService.currentUser.id;
        }

        this.getPracticalWorksFromStudent(userId);
      });
  }

  /**
   * ngOnChanges
   * apply search filter on changes
   * @param changes
   * @returns void
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText) {
      this.applySearch(this.searchText);
    }
  }

  /**
   * getPracticalWorksFromStudent
   * Get the practical works from student
   * @param userId The user id
   * @returns void
   */
  public getPracticalWorksFromStudent(userId: number): void {
    this.practicalWorkService
      .getAllPracticalWorksByStudentId(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const practicalWorks: PracticalWorkModel[] = response.body
            ? await Promise.all(
                response.body.map(async (practicalWork: PracticalWorkModel) => {
                  return await this.practicalWorkService.parsePracticalWork(
                    practicalWork
                  );
                })
              )
            : [];

          this.dataSource.data = practicalWorks;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Praktischen Tätigkeiten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );

          this.isLoading = false;
        },
      });
  }

  /**
   * applySearch
   * Apply the search filter
   * @param filterValue
   * @returns void
   */
  public applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * viewPracticalWork
   * Opens the view of the practical work
   * @param practialWork The practical work
   * @returns void
   */
  public viewPracticalWork(practialWork: PracticalWorkModel) {
    this.router.navigate(['./', btoa(String(practialWork.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * editPracticalWork
   * Opens the edit page of the practical work
   * @param practicalWork The practical work
   * @returns void
   */
  public editPracticalWork(practicalWork: PracticalWorkModel) {
    this.router.navigate(['./', 'edit', btoa(String(practicalWork.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * deletePracticalWork
   * Deletes the practical work
   * @param practicalWork The practical work
   * @returns void
   */
  public deletePracticalWork(practicalWork: PracticalWorkModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Eintrag löschen',
        message: `Möchten Sie den Eintrag wirklich löschen? Der Eintrag kann nicht wiederhergestellt werden.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.practicalWorkService
            .deletePracticalWork(practicalWork.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  'Die praktische Tätigkeit wurde gelöscht.'
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(practicalWork);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== practicalWork
                  );
                }
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat nicht geklappt!',
                  'Der Eintrag konnte nicht gelöscht werden.'
                );
              },
            });
        }
      });
  }

  /**
   * onDownload
   * download the file
   * @param practicalWorkId
   * @param fileId
   * @returns void
   */
  public onDownload(practicalWorkId: number, fileId: number): void {
    this.practicalWorkService.downloadFile(practicalWorkId, fileId);
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
