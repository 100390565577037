import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EventDate } from 'src/app/models/event.model';
import { RoomAvailabilityModel, RoomModel } from 'src/app/models/room.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { RoomService } from 'src/app/services/room.service';

@Component({
  selector: 'app-event-room-planning',
  templateUrl: './event-room-planning.component.html',
  styleUrls: ['./event-room-planning.component.scss'],
})
export class EventRoomPlanningComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() eventDates: EventDate[];
  @Input() participants: number = 2; // default value for participants, because in patient sessions there are always 2 participants
  @Input() numberOfLecturers?: number;
  @Input() isCourse: boolean = false;
  @Output() onRoomPlanningClose = new EventEmitter<EventDate[]>();

  public totalPersons: number;

  private initialEventDates: EventDate[];
  public selectedEventDate: EventDate;
  public allRooms: RoomModel[] = [];
  public bookRoomForEveryEvent: boolean = true;

  public pastEventDates: EventDate[] = [];
  public futureEventDates: EventDate[] = [];

  public roomsLoading: boolean = true;
  public roomAvailabilityCheckLoading: boolean = false;

  public displayedColumns: string[] = ['event', 'room'];
  public dataSource: MatTableDataSource<EventDate> = new MatTableDataSource();
  private tableData: EventDate[];

  public roomAvailabilityMap: Map<number, RoomAvailabilityModel> = new Map<
    number,
    RoomAvailabilityModel
  >();

  public showPastEventDates: boolean = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (this.hasUnsavedChanges()) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private roomService: RoomService,
    public dialog: MatDialog,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: NavigationEnd) => {
        if (
          event.url?.includes('room-organization') &&
          this.router.url.includes('room-organization')
        ) {
          this.cancelRoomPlanning();
        }
      });

    this.totalPersons = this.participants + (this.numberOfLecturers ?? 0);

    if (this.eventDates.length > 0) {
      // decrypt the events
      this.eventDates = this.eventDates
        ? await Promise.all(
            this.eventDates.map(async event => {
              event.room = event.room
                ? await this.roomService.parseBackendRoom(event.room)
                : null;
              return event;
            })
          )
        : [];

      // create a deep copy of the objects so the original objects won't be updated
      this.initialEventDates = this.eventDates.map(event => ({
        ...event,
      }));
      this.eventDates = this.eventDates.map(event => ({ ...event }));

      // get past and future events
      const { pastEventDates, futureEventDates } =
        this.getPastAndFutureEventDates(this.eventDates);
      this.pastEventDates = pastEventDates;
      this.futureEventDates = futureEventDates;

      // get all Rooms of institute
      this.roomService
        .getAllRooms()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.allRooms = response.body
              ? await Promise.all(
                  response.body?.map(
                    async (roomData: any): Promise<RoomModel> => {
                      return await this.roomService.parseBackendRoom(roomData);
                    }
                  )
                )
              : [];

            this.roomsLoading = false;
          },
          error: error => {},
        });

      // Create table data to display events
      this.tableData = this.showPastEventDates
        ? this.pastEventDates
        : this.futureEventDates;

      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'event':
            return item.startDate;
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;

      // set selected row to first row when upcoming events are shown
      this.selectedEventDate = this.showPastEventDates
        ? null
        : this.tableData[0];

      // check room availability for every room
      if (this.selectedEventDate) {
        await this.checkAvailabilityOfRooms(this.selectedEventDate);
      }
    }
  }

  /**
   * changing selectedRow to the row that the user has clicked
   * @param eventDate the eventDate that the user has clicked
   */
  public async onChangeSelectedEventDate(eventDate: EventDate) {
    if (eventDate === this.selectedEventDate) return;
    this.selectedEventDate = eventDate;
    await this.checkAvailabilityOfRooms(this.selectedEventDate);
  }

  /**
   *
   * @param row row of table
   * @returns if the selected row is the current selected row
   */
  isSelectedRow(row: EventDate) {
    return this.selectedEventDate === row;
  }

  /**
   * rowClickable
   */
  public rowClickable(row: EventDate) {
    const currentDate = new Date();
    // if row end date is in the past, row is not clickable
    const eventDateEndDate = new Date(row.endDate);
    if (eventDateEndDate < currentDate) {
      return false;
    }
    return true;
  }

  public async checkAvailabilityOfRooms(eventDate: EventDate): Promise<void> {
    this.roomAvailabilityCheckLoading = true;
    return new Promise<void>((resolve, reject) => {
      this.roomService
        .checkAvailabilityOfRooms(
          eventDate.startDate,
          eventDate.endDate,
          this.totalPersons,
          eventDate.id
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: response => {
            this.roomAvailabilityMap.clear();
            response.body.forEach((roomAvailability: RoomAvailabilityModel) => {
              this.roomAvailabilityMap.set(
                roomAvailability.roomId,
                roomAvailability
              );
            });
            this.roomAvailabilityCheckLoading = false;
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Handles the button click on a room and tries to book it for the event
   * @param roomId
   */
  public onRoomSelect(room: RoomModel) {
    if (this.roomAvailabilityMap.get(room.id)?.isAvailable) {
      // Check if more then 1 future event has no room
      if (this.futureEventDatesWithNoRoom() > 1 && this.bookRoomForEveryEvent) {
        // Open dialog and let the user decide, if the room should be used for all events with no room
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Raumplanung',
            message:
              'Soll dieser Raum für jeden Termin ohne Raum gebucht werden? (Falls möglich)',
          },
        });

        dialogRef
          .afterClosed()
          .pipe(first())
          .subscribe(async dialogResult => {
            if (dialogResult) {
              // try to book room for every event with no room
              this.futureEventDates.forEach(async eventDate => {
                if (!eventDate.room) {
                  await this.checkAvailabilityOfRooms(eventDate);
                  if (this.roomAvailabilityMap.get(room.id)?.isAvailable) {
                    eventDate.room = room;
                  }
                }
              });
              await this.checkAvailabilityOfRooms(this.selectedEventDate);
              this.updateTableData();
            } else {
              this.bookRoomForEveryEvent = false;
              this.selectedEventDate.room = room;
              this.updateTableData();
            }
          });
      } else {
        // update event where id is equal to selected row id
        this.selectedEventDate.room = room;
        this.updateTableData();
      }
    }
  }

  /**
   * update table data after room was selected, to show room in table
   */
  updateTableData() {
    // Create table data to displa correct events
    this.tableData = this.showPastEventDates
      ? this.pastEventDates
      : this.futureEventDates;

    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    const selectedRow = this.tableData.find(
      row => row === this.selectedEventDate
    );
    this.selectedEventDate = selectedRow;
  }

  /**
   * returns number of future events with no room
   */
  private futureEventDatesWithNoRoom() {
    const eventsWithNoRoom = this.futureEventDates.filter(event => !event.room);

    return eventsWithNoRoom.length;
  }

  /**
   * restore initial rooms of events and emit onRoomPlanningClose with the events
   */
  cancelRoomPlanning() {
    // check if there are unsaved changes
    if (this.hasUnsavedChanges()) {
      this.onRoomPlanningClose.emit(null);
      return;
    }

    this.onRoomPlanningClose.emit(this.initialEventDates);
  }

  /**
   * emit the onRoomPlanningClose Event with the events
   */
  saveRoomPlanning() {
    this.onRoomPlanningClose.emit(this.eventDates);
  }

  /**
   * getPastAndFutureEvents
   * @param eventDates
   * @returns past and future events
   */
  private getPastAndFutureEventDates(eventDates: EventDate[]): {
    pastEventDates: EventDate[];
    futureEventDates: EventDate[];
  } {
    const currentDate = new Date();
    let pastEventDates = eventDates.filter(
      eventDate => eventDate.endDate < currentDate
    );
    let futureEventDates = eventDates.filter(
      eventDate => eventDate.endDate >= currentDate
    );

    return { pastEventDates, futureEventDates };
  }

  /**
   * onShowUpcomingEventDatesChanged
   * changes the table data to show past or future events
   * @param slideToggleData
   * @returns void
   */
  public onShowUpcomingEventDatesChanged(
    slideToggleData: MatSlideToggleChange
  ) {
    this.showPastEventDates = slideToggleData.checked;
    if (this.showPastEventDates) {
      this.tableData = this.pastEventDates;
    } else {
      this.tableData = this.futureEventDates;
    }
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;

    // set selected row to first row when upcoming events are shown
    this.selectedEventDate = this.showPastEventDates ? null : this.tableData[0];
  }

  /**
   * hasUnsavedChanges
   * @returns if there are unsaved changes
   */
  public hasUnsavedChanges(): boolean {
    return (
      JSON.stringify(this.initialEventDates) !== JSON.stringify(this.eventDates)
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
