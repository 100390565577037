<div class="flex-r gap-24">
  <p-skeleton height="145px" width="195px" borderRadius="8px" />
  <div class="flex-c gap-8 justify-space-between w-full">
    <p-skeleton width="20rem" height="20px" borderRadius="16px" />
    <div class="flex-r justify-space-between align-center gray-600">
      <p-skeleton width="30rem" height="18px" borderRadius="16px" />
    </div>
    <mat-divider></mat-divider>
    <div class="flex-r gap-16 justify-space-between">
      <div class="flex-r gap-24">
        <div class="icon-item">
          <p-skeleton width="10rem" height="18px" borderRadius="16px" />
        </div>
        <div class="icon-item">
          <p-skeleton width="7rem" height="18px" borderRadius="16px" />
        </div>
        <div class="icon-item">
          <p-skeleton width="9rem" height="18px" borderRadius="16px" />
        </div>
        <div class="icon-item">
          <p-skeleton width="8rem" height="18px" borderRadius="16px" />
        </div>
      </div>
    </div>
  </div>
</div>
