<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Institutsübersicht</h1>
      <h3 class="hasomed-text-h3">Die wichtigsten Daten im Überblick.</h3>
    </div>
    <div class="header-buttons">
      <ng-container *ngIf="!institute">
        <button
          *appCheckPermissions="{
            feature: feature.INSTITUTE,
            permission: permission.ADMIN,
          }"
          (click)="onCreate()"
          type="button"
          class="hasomed-text-button hasomed-button-primary">
          <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
          <span class="hasomed-button-text">Institut erstellen</span>
        </button>
      </ng-container>
      <ng-container *ngIf="institute">
        <button
          *appCheckPermissions="{
            feature: feature.MODIFY_INSTITUTE,
            permission: permission.ADMIN,
          }"
          [disabled]="isLoading"
          (click)="onEdit()"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-pen hasomed-button-icon"></i>
          <span class="hasomed-button-text">Bearbeiten</span>
        </button>
        <button
          *appCheckPermissions="{
            feature: feature.INSTITUTE,
            permission: permission.ADMIN,
          }"
          (click)="downloadPdf()"
          type="button"
          class="hasomed-text-button hasomed-button-primary">
          <i class="fa-light fa-cloud-arrow-down hasomed-button-icon"></i>
          <span class="hasomed-button-text">PDF herunterladen</span>
        </button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading && !institute" class="main-content">
    Kein Institut zugewiesen.
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading && institute" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Institutsname</span>
        <span class="label-description">Name des Instituts.</span>
      </div>
      <div class="detail-value">
        {{ institute?.name }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Description -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Beschreibung des Instituts oder Slogan
        </span>
        <span class="label-description"> Ergänzende Informationen. </span>
      </div>
      <div class="detail-value">
        {{ institute?.description ?? 'Keine Beschreibung hinterlegt.' }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description"> E-Mail als Kontaktmöglichkeit. </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-envelope"></i>
        <span>
          {{ institute?.email ?? 'Keine E-Mail Adresse hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- phone number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Telefonnummer (Festnetz) </span>
        <span class="label-description">
          Festnetznummer als Kontaktmöglichkeit.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-phone"></i>
        <span>
          {{ institute?.phone ?? 'Keine Nummer hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- website -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Webseite</span>
        <span class="label-description"> Webseite des Instituts. </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-globe"></i>
        <span>
          {{ institute?.website ?? 'Keine Webseite hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description"> Anschrift des Instituts. </span>
      </div>
      <div class="flex-c">
        <span
          *ngIf="
            !institute?.address?.street &&
            !institute?.address?.houseNumber &&
            !institute?.address?.addressAddition &&
            !institute?.address?.zipCode &&
            !institute?.address?.city &&
            !institute?.address?.country
          "
          class="detail-value">
          Nicht hinterlegt.
        </span>
        <span
          *ngIf="institute?.address?.street || institute?.address?.houseNumber"
          class="detail-value">
          {{ institute?.address?.street }} {{ institute?.address?.houseNumber }}
        </span>
        <span *ngIf="institute?.address?.addressAddition" class="detail-value">
          {{ institute?.address?.addressAddition }}
        </span>
        <span
          *ngIf="institute?.address?.zipCode || institute?.address?.city"
          class="detail-value">
          {{ institute?.address?.zipCode }} {{ institute?.address?.city }}
        </span>
        <span *ngIf="institute?.address?.country" class="detail-value">
          {{ institute?.address?.country }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- openingHours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Öffnungszeiten des Instituts </span>
        <span class="label-description">
          In diesen Zeiten ist das Institut geöffnet.
        </span>
      </div>
      <div class="flex-c gap-16 flex-1 opening-hours">
        <span
          *ngIf="
            !institute?.openingHours || institute?.openingHours?.length == 0
          "
          class="detail-value">
          Keine Öffnungszeiten hinterlegt.
        </span>
        <div
          *ngFor="let openingHour of institute?.openingHours"
          class="flex-r gap-16">
          <div class="day-box">
            <i class="fa-light fa-calendar"></i>
            <span>
              {{ getGermanWeekday(openingHour.dayOfWeek) }}
            </span>
          </div>
          <div class="time-box">
            <i class="fa-light fa-clock-seven"></i>
            <span>
              {{ openingHour.openingTime }}
            </span>
          </div>
          <div class="time-box">
            <i class="fa-light fa-clock-seven"></i>
            <span>
              {{ openingHour.closingTime }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- logo -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Logo des Instituts</span>
        <span class="label-description">
          Bitte laden Sie ein Logo für Ihr Institut hoch.
        </span>
      </div>
      <div class="detail-value">
        <span *ngIf="!institute?.logo"> Kein Logo hinterlegt. </span>
        <img
          *ngIf="institute?.logo"
          [src]="institute.logo"
          height="60"
          alt="Institut Logo" />
      </div>
    </div>
  </div>
</div>
