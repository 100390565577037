<div class="component-content">
  <div class="header">
    <div class="header-title">
      <span class="hasomed-text-h1">Raumplanung</span>
      <span class="hasomed-text-h3">
        {{ subtitle }}
      </span>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="cancelRoomPlanning()">
        <i class="fa-light fa-arrow-left-long-to-line hasomed-button-icon"></i>
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Save button -->
      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="saveRoomPlanning()">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div class="main-content">
    <div class="course">
      <span class="hasomed-text-h1">{{ title }}</span>
      <div class="date-room">
        <div class="choose-date">
          <div class="flex-r justify-space-between">
            <span class="hasomed-text-h2"> Terminauswahl </span>
            <div class="flex-r gap-8 align-center">
              <span class="hasomed-text-body gray-600">
                {{
                  showPastEventDates
                    ? 'Vergangene Termine ausblenden'
                    : 'Vergangene Termine einblenden'
                }}
              </span>
              <mat-slide-toggle
                hideIcon
                (change)="onShowUpcomingEventDatesChanged($event)"
                [checked]="showPastEventDates ? true : false"
                [matTooltip]="
                  showPastEventDates
                    ? 'Vergangene Termine ausblenden'
                    : 'Vergangene Termine einblenden'
                ">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="table">
            <table mat-table [dataSource]="dataSource" matSort>
              <!-- Date column -->
              <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span>Termin</span>
                  <app-custom-table-sort-icon [sort]="sort" columnName="event">
                  </app-custom-table-sort-icon>
                </th>
                <td class="date-cell" mat-cell *matCellDef="let event">
                  <div class="date-info">
                    <span class="hasomed-text-body">
                      {{ event.startDate | date: 'dd.MM.yyyy' }}
                    </span>
                    <span class="hasomed-text-small">
                      {{ event.startDate | date: 'HH:mm' }} -
                      {{ event.endDate | date: 'HH:mm' }} Uhr
                    </span>
                  </div>
                </td>
              </ng-container>

              <!-- Room column -->
              <ng-container matColumnDef="room">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span>Raum zugewiesen</span>
                  <app-custom-table-sort-icon [sort]="sort" columnName="room">
                  </app-custom-table-sort-icon>
                </th>
                <td class="room-cell" mat-cell *matCellDef="let event">
                  <!-- Label with room -->
                  <div
                    *ngIf="event.room"
                    class="label"
                    [ngClass]="event.room ? 'label-green' : 'label-white'">
                    <i class="fa-light fa-square-check label-icon"></i>
                    <span class="hasomed-text-small label-text">
                      {{
                        'Raum: ' +
                          event.room.name +
                          ' - ' +
                          (event.room.floor === 0
                            ? 'EG'
                            : event.room.floor
                              ? event.room.floor + '. OG'
                              : 'Unbekannte Etage')
                      }}
                    </span>
                  </div>

                  <!-- Label without room -->
                  <div
                    *ngIf="!event.room"
                    class="label"
                    [ngClass]="event.room ? 'label-green' : 'label-white'">
                    <i class="fa-light fa-square-x label-icon"></i>
                    <span class="hasomed-text-small label-text">
                      Raum offen
                    </span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                [ngClass]="{
                  'clickable-row': rowClickable(row),
                  'non-clickable-row': !rowClickable(row),
                }"
                (click)="
                  rowClickable(row) ? onChangeSelectedEventDate(row) : null
                "
                [class.row-is-selected]="isSelectedRow(row)"
                [matTooltip]="
                  !rowClickable(row) ? 'Termin liegt in der Vergangenheit' : ''
                "
                matTooltipPosition="after"
                *matRowDef="let row; columns: displayedColumns"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td
                  class="mat-cell table-no-match hasomed-text-body font-grey"
                  colspan="displayedColumns.length">
                  {{
                    showPastEventDates
                      ? 'Es wurden keine vergangenen Termine gefunden.'
                      : 'Es wurden keine bevorstehenden Termine gefunden.'
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          *ngIf="!roomsLoading && !roomAvailabilityCheckLoading"
          class="choose-room-wrapper">
          <span class="hasomed-text-h2">
            Raumauswahl
            {{
              selectedEventDate
                ? '- ' +
                  ((selectedEventDate.startDate | date: 'dd.MM.YYYY HH:mm') +
                    ' - ' +
                    (selectedEventDate.endDate | date: 'HH:mm')) +
                  ' Uhr'
                : ''
            }}

            <span *ngIf="isCourse">
              <br />
              {{ participants ? participants + ' Teilnehmer*innen' : '' }}
              {{
                numberOfLecturers
                  ? ' + ' + numberOfLecturers + ' Lehrpersonal'
                  : ''
              }}
              {{
                totalPersons && numberOfLecturers
                  ? ' = ' + totalPersons + ' gesamt'
                  : ''
              }}
            </span>
          </span>
          <div class="choose-room">
            <button
              type="button"
              (click)="onRoomSelect(room)"
              class="hasomed-text-button"
              *ngFor="let room of allRooms"
              [disabled]="selectedEventDate ? false : true"
              [ngClass]="{
                'hasomed-button-light-red': selectedEventDate
                  ? !roomAvailabilityMap.get(room.id)?.isAvailable
                  : false,
                'hasomed-button-orange ': selectedEventDate
                  ? room.id === selectedEventDate.room?.id
                  : false,
                'hasomed-button-white-grey ': selectedEventDate
                  ? room.id != selectedEventDate.room?.id &&
                    roomAvailabilityMap.get(room.id)?.isAvailable
                  : true,
              }">
              <i class="hasomed-button-icon fa-light fa-screen-users"></i>
              <div
                class="hasomed-button-text flex-r justify-space-between full-width">
                <span>
                  Raum: {{ room.name }} -
                  {{
                    room.floor === 0
                      ? 'EG'
                      : room.floor
                        ? room.floor + '. OG'
                        : 'Unbekannte Etage'
                  }}
                  -
                  {{ room.seatNumber + ' Plätze' }}
                </span>
                <i
                  *ngIf="selectedEventDate"
                  class="hasomed-text-small roomplanning-hint-text">
                  {{ roomAvailabilityMap.get(room.id)?.reason ?? '' }}
                </i>
              </div>
            </button>
          </div>
          <div
            *ngIf="allRooms.length === 0"
            class="card align-center justify-center gap-8">
            <div><i class="fa-light fa-screen-users"></i></div>
            <span class="hasomed-button-text">Kein Raum gefunden.</span>
          </div>
        </div>
        <div
          *ngIf="roomsLoading || roomAvailabilityCheckLoading"
          class="loading-wrapper choose-room-wrapper">
          <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
