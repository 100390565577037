export enum Role {
  ADMINISTRATOR = 1,
  LECTURER = 2,
  STUDENT = 3,
}

export enum Permission {
  NONE = 'None',
  VIEW = 'View',
  ADMIN = 'Admin',
}

export enum Feature {
  /* Dashboard */
  DASHBOARD = 'Dashboard',
  DB_MYSPACE = 'DBMySpace',
  DB_BULLETIN_BOARD = 'DBBulletinBoard',
  DB_MESSAGES = 'DBMessages',
  DB_DOCUMENTS = 'DBDocuments',
  DB_INSTITUTE_NEWS = 'DBInstituteNews',
  DB_WIKI = 'DBWiki',

  /* Education */
  EDUCATION = 'Education',
  COURSE_ADMINISTRATION = 'CourseAdministration',
  ELOG = 'ELog',
  THEORETICAL_EDUCATION = 'TheoreticalEducation',
  STUDENT_ADMINISTRATION = 'StudentAdministration',
  LECTURER_ADMINISTRATION = 'LecturerAdministration',
  PRACTICAL_WORK = 'PracticalWork',
  PRACTICAL_WORK_NAVIGATION = 'PracticalWorkNavigation',
  AMBULATORY_PART = 'AmbulatoryPart',
  TREATMENT_CASE = 'TreatmentCase',
  SELF_AWARENESS = 'SelfAwareness',
  COOPERATION_PARTNER = 'CooperationPartner',
  ADVANCED_LECTURERS = 'AdvancedLecturer',
  ROOM_PLANNING = 'RoomPlanning',

  /* Organization */
  ORGANIZATION = 'Organization',
  LABEL = 'Label',
  OVERVIEW_OF_FEES = 'OverviewOfFees',
  AUDIT_LOG = 'AuditLog',
  ANALYTICS = 'Analytics',
  BILLING = 'Billing',
  EDUCATION_PROGRESS = 'EducationProgress',
  INITIAL_EDUCATION_STOCK = 'InitialEducationStock',

  /* Institute */
  INSTITUTE = 'Institute',
  MODIFY_INSTITUTE = 'ModifyInstitute',
  ROOM_ADMINISTRATION = 'RoomAdministration',

  /* Exams */
  EXAMS = 'Exams',

  /* Administration */
  ADMINISTRATION = 'Administration',

  /* Settings */
  PERSONAL_DATA = 'PersonalData',
}

export interface FeaturePermission {
  feature: Feature;
  permission: Permission;
}
