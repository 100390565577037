import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { SwitchRoleDialogComponent } from 'src/app/components/shared-components/switch-role-dialog/switch-role-dialog.component';
import { ViewProfilePictureDialogComponent } from 'src/app/components/shared-components/view-profile-picture-dialog/view-profile-picture-dialog.component';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { Role } from 'src/app/models/permission.model';
import { UserCreateModel, UserModel } from 'src/app/models/user.model';
import { FilterMembersPipe } from 'src/app/pipes/filter-members.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { KeyManagementService } from 'src/app/services/key-management.service';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { getFullName } from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss',
})
export class MembersComponent implements OnInit, OnDestroy {
  public searchForm = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator),
  });
  public members: UserModel[] = [];

  public displayedColumns: string[] = [
    'name',
    'email',
    'entryDate',
    'logonState',
    'role',
    'actions',
  ];
  public dataSource: MatTableDataSource<UserModel> =
    new MatTableDataSource<UserModel>();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  public membersFilter: Filter[] = [
    {
      type: FilterType.ROLE,
      value: null,
    },
    {
      type: FilterType.LOGON_STATE,
      value: null,
    },
  ];
  public filterOpened: boolean = false;
  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$: Subject<void> = new Subject();

  // import from utils
  public getFullName = getFullName;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private userService: UserService,
    private dialog: MatDialog,
    private keyManagementService: KeyManagementService
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with all users of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name.firstName + ' ' + item.name.lastName;
        case 'email':
          return item.email;
        case 'entryDate':
          return item.entryDate;
        case 'logonState':
          if (item.isInvited) {
            return 'Eingeladen';
          }
          if (item.isConfirmedByAdmin) {
            return 'Beigetreten';
          }
          return '-';
        case 'role':
          return item.roleId;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr =
        data.name.academicTitle?.toLowerCase() +
        ' ' +
        data.name.firstName.toLowerCase() +
        ' ' +
        data.name.lastName.toLowerCase();
      return dataStr.indexOf(filter) != -1;
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getAllInstituteUsers();
  }

  /**
   * gets called when the members filter changed
   * @param membersFilter Filter[]
   * @returns void
   */
  public membersFilterChanged(membersFilter: Filter[]): void {
    this.membersFilter = membersFilter;
    this.applyMembersFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applies the members filter
   * @returns void
   */
  private applyMembersFilter(): void {
    this.dataSource.data = FilterMembersPipe.prototype.transform(
      this.members,
      this.membersFilter
    );
  }

  /**
   * applySearch
   * apply search filter
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * retrieves all users of the current institute and initializes the table data
   * @returns void
   */
  public getAllInstituteUsers(): void {
    this.userService
      .getAllUsersByInstitute()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.members = response.body
            ? await Promise.all(
                response.body?.map(
                  async (userData: UserModel): Promise<UserModel> => {
                    return await this.userService.parseBackendUser(userData);
                  }
                )
              )
            : [];
          this.dataSource.data = this.members;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Benutzer konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onInvite
   * navigates to the invite user component
   * @returns void
   */
  public onInvite(): void {
    this.router.navigate(['./invite'], { relativeTo: this.activatedRoute });
  }

  /**
   * onOpen
   * navigates to the user detail component
   * @param user
   * @returns void
   */
  public onOpen(user: UserModel): void {
    this.router.navigate([btoa(String(user.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * navigates to the edit members component
   * @param user
   * @returns void
   */
  public onEdit(user: UserModel): void {
    this.router.navigate(['./edit/', btoa(String(user.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * opens the profile picture dialog
   * @param user the user
   * @returns void
   */
  public openProfilePictureDialog(user: UserModel): void {
    this.dialog.open(ViewProfilePictureDialogComponent, {
      data: {
        image: user.profilePicture,
      },
    });
  }

  /**
   * getRoleName
   * Get the role name based on the role id
   * @returns void
   */
  public getRoleName(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return '-';
    }
  }

  /**
   * getLabelBackgroundColor
   * Get the label background color
   * @param role
   * @returns
   */
  public getLabelBackgroundColor(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return '#F08A00';
      case Role.LECTURER:
        return '#20BBAB';
      case Role.STUDENT:
        return '#79B63F';
      default:
        return '#BBBDBE';
    }
  }

  /**
   * openEditRoleDialog
   * Open the edit user dialog
   * @param user
   * @returns void
   */
  public onEditRole(user: UserModel): void {
    const dialogRef = this.dialog.open(SwitchRoleDialogComponent, {
      width: '480px',
      data: { user },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          user.roleId = result;
        }
      });
  }

  /**
   * shows a confirm dialog and if confirmed deletes the user from the institute
   * @param user the user to delete
   * @returns void
   */
  public onDelete(user: UserModel): void {
    const instituteId = this.userService.currentUser.currentInstituteId;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Benutzer löschen',
        message: `Möchten Sie den Benutzer '${getFullName(
          user
        )}' wirklich löschen?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) return;

        this.userService
          .deleteInstituteUser(instituteId, user.id)
          .pipe(first())
          .subscribe({
            next: response => {
              this.alertService.showSuccessAlert(
                'Das hat geklappt!',
                'Der Benutzer wurde aus dem Institut entfernt.'
              );
              this.members = this.members.filter(
                member => member.id !== user.id
              );
              this.dataSource.data = this.members;
            },
            error: error => {
              this.alertService.showErrorAlert(
                'Das hat leider nicht geklappt!',
                'Der Benutzer konnte nicht gelöscht werden.'
              );
            },
          });
      });
  }

  /**
   * isCurrentUser
   * Check if the user is the current user
   * @param user
   * @returns boolean
   */
  public isCurrentUser(user: UserModel): boolean {
    return user.id === this.userService.currentUser.id;
  }

  /**
   * onInviteAgain
   * invite the user again
   * @param user
   * @returns void
   */
  public onInviteAgain(user: UserModel): void {
    const inviteUserModel: UserCreateModel = {
      name: {
        firstName: user.name?.firstName,
        lastName: user.name?.lastName,
      },
      email: user.email,
      roleId: user.roleId,
    };
    this.userService
      .inviteInstituteUser(
        this.userService.currentUser.currentInstituteId,
        inviteUserModel
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Einladung wurde verschickt.'
          );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Einladung konnte nicht verschickt werden.'
          );
        },
      });
  }

  /**
   * Check if there are unconfirmed users
   * @returns true if there are unconfirmed users, false otherwise
   */
  get hasUnconfirmedUsers(): boolean {
    return this.dataSource.data.some(
      user =>
        !user.isConfirmedByAdmin &&
        user.isInvited &&
        user.encryptionKeysGenerated
    );
  }

  /**
   * Iterates over all users and calls the confirmUser method if they are not already confirmed
   * @returns void
   */
  public confirmAllUsers(): void {
    this.dataSource.data.forEach(async user => {
      if (
        !user.isConfirmedByAdmin &&
        user.isInvited &&
        user.encryptionKeysGenerated
      ) {
        await this.confirmUser(user, false);
      }
    });

    this.alertService.showSuccessAlert(
      'Das hat geklappt!',
      'Alle Benutzer wurden bestätigt.'
    );
  }

  /**
   * Calls the confirmUserAndEncryptKeys method of the KeyManagementService
   * @param user The user to confirm
   * @param showSuccessAlert If true, a success alert will be shown
   * @returns void
   */
  public async confirmUser(
    user: UserModel,
    showSuccessAlert: boolean = true
  ): Promise<void> {
    const userKeysGeneratedAndConfirmed =
      await this.keyManagementService.encryptKekForInvitedUserAndConfirmRegistration(
        user,
        this.userService.currentUser.externalId
      );

    if (userKeysGeneratedAndConfirmed) {
      user.isConfirmedByAdmin = true;
      if (!showSuccessAlert) {
        return;
      }

      this.alertService.showSuccessAlert(
        'Das hat geklappt!',
        `Der Benutzer ${getFullName(user)} wurde bestätigt.`
      );
      return;
    }

    this.alertService.showErrorAlert(
      'Das hat leider nicht geklappt!',
      `Der Benutzer ${getFullName(user)} konnte nicht bestätigt werden.`
    );

    return;
  }

  /**
   * Get the confirm button tooltip
   * @param user
   * @returns string
   */
  public getConfirmButtonTooltip(user: UserModel): string {
    if (user.isConfirmedByAdmin) {
      return 'Bereits bestätigt';
    }

    if (!user.isInvited && !user.isConfirmedByAdmin) {
      return 'Nicht eingeladen';
    }

    if (user.isInvited && !user.encryptionKeysGenerated) {
      return 'Nicht registriert';
    }

    return 'Bestätigen';
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
