<div class="component-content">
  <!-- Header -->
  <div class="header">
    <div class="header-title">
      <app-user-container-header-skeleton
        *ngIf="isLoading"></app-user-container-header-skeleton>

      <div class="user-container-header" *ngIf="!isLoading">
        <img
          src="{{
            student.profilePicture ?? '../../../../../assets/img/user.jpg'
          }}"
          alt=""
          class="user-icon-image" />
        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ getFullName(student) }}
          </h1>
          <div class="flex-r gap-16 align-center">
            <h3 class="hasomed-text-h3">
              {{ student?.userIdentifier ?? '-' }}
            </h3>
            <div
              *ngIf="student?.label"
              class="label"
              [style.background]="student?.label?.color">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">{{ student?.label?.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <button
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onBackToOverview()">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text">Zurück zur Übersicht</span>
      </button>

      <button
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN,
        }"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="editStudent()">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <!-- Main content -->
  <div class="main-content">
    <div class="child-navigation student-detail-child-nav">
      <a
        *ngFor="let link of navLinks"
        #rla="routerLinkActive"
        [routerLink]="link.link"
        routerLinkActive="hasomed-button-transparent-primary"
        [class.hasomed-button-blank]="!rla.isActive"
        class="hasomed-text-button">
        <span class="hasomed-text-menu"> {{ link.label }}</span>
      </a>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
