import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  NewsArticleCreateModel,
  NewsArticleModel,
  NewsArticleUpdateModel,
} from '../models/news-article.model';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NewsArticleService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  public newsArticlesVisited = new Subject<number>();
  private newNewsArticlesCountSubject = new BehaviorSubject<number>(0);
  public newNewsArticlesCount$ =
    this.newNewsArticlesCountSubject.asObservable();

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private userService: UserService,
    private http: HttpClient,
    private cancellationService: CancellationService
  ) {}

  /**
   * update the amount of new news articles
   * @param count
   */
  public updateNewNewsArticlesCount(count: number) {
    this.newNewsArticlesCountSubject.next(count);
  }

  /**
   * get all news articles of the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllNewsArticles(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/news-articles`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get the amount of articles that the current user has not read yet
   * @returns Observable<HttpResponse<any>>
   */
  public getNewNewsArticleCount(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/news-articles/count`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all news article types from the backend
   * @returns Observable<HttpResponse<any>>
   */
  public getAllNewsTypes(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + `/api/news-article-types`, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get news article by its id
   * @param newsArticleId the id of the news article
   * @returns Observable<HttpResponse<any>>
   */
  public getNewsArticleById(
    newsArticleId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/news-articles/${newsArticleId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * creates a new news article
   * @param newsArticleCreateModel the news article to create
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createNewsArticle(
    newsArticleCreateModel: NewsArticleCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/news-articles`,
      newsArticleCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * updates an existing news article
   * @param newsArticleId the id of the news article
   * @param newsArticleCreateModel the news article to update
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateNewsArticle(
    newsArticleId: number,
    newsArticleUpdateModel: NewsArticleUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/news-articles/${newsArticleId}`,
      newsArticleUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * delete a news article
   * @param newsArticleId the id of the news article
   * @returns Observable<HttpResponse<any>>
   */
  public deleteNewsArticle(
    newsArticleId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/news-articles/${newsArticleId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parses the backend news article to a frontend news article
   * @param newsArticle the backend news article to parse
   * @returns Promise<NewsArticleModel>
   */
  public async parseBackendNewsArticle(
    newsArticle: NewsArticleModel
  ): Promise<NewsArticleModel> {
    const parsedNewsArticle: NewsArticleModel = {
      id: newsArticle.id,
      title: newsArticle.title,
      link: newsArticle.link,
      image: newsArticle.image,
      content: newsArticle.content,
      newsArticleType: newsArticle.newsArticleType,
      creator:
        newsArticle.creator &&
        (await this.userService.parseBackendUser(newsArticle.creator)),
      modifier:
        newsArticle.modifier &&
        (await this.userService.parseBackendUser(newsArticle.modifier)),
      timeCreated:
        newsArticle.timeCreated &&
        moment(newsArticle.timeCreated).tz('Europe/Berlin').toDate(),
      timeModified:
        newsArticle.timeModified &&
        moment(newsArticle.timeModified).tz('Europe/Berlin').toDate(),
      timePublished:
        newsArticle.timePublished &&
        moment(newsArticle.timePublished).tz('Europe/Berlin').toDate(),
      isPublished: newsArticle.isPublished,
    };
    return parsedNewsArticle;
  }
}
