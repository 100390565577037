import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject, takeUntil } from 'rxjs';
import { SelectOptionValue } from 'src/app/models/select-option.model';

@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrl: './select-check-all.component.scss',
})
export class SelectCheckAllComponent implements OnInit, OnDestroy {
  @Input() control: FormControl = new FormControl();
  @Input() values: SelectOptionValue[] = [];

  public allSelected: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.initializeAllSelected();
    this.updateAllSelectedStatus();
  }

  /**
   * Initializes the `allSelected` property based on the current state of the control value.
   * @returns void
   */
  private initializeAllSelected(): void {
    const currentValue = this.control.value || [];
    if (
      currentValue.length === this.values.length &&
      currentValue.length !== 0
    ) {
      this.allSelected = true;
    }
  }

  /**
   * Updates the `allSelected` property based on the current selection.
   * @returns void
   */
  private updateAllSelectedStatus(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(values => {
        if (!values || values.length === 0) {
          this.allSelected = false;
          return;
        }

        this.allSelected = values.length === this.values.length;
      });
  }

  /**
   * Toggles the selection of all items in the component.
   * @param event - The MatCheckboxChange event object.
   * @returns void
   */
  public toggleSelectAll(event: MatCheckboxChange): void {
    this.allSelected = event.checked;

    if (this.allSelected) {
      this.control.patchValue(this.values);
    } else {
      this.control.patchValue([]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
