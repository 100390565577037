import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ExamTypeModel } from 'src/app/models/exam-type.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ExamTypeService } from 'src/app/services/exam-type.service';
import { getFullName } from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-exam-types',
  templateUrl: './exam-types.component.html',
  styleUrl: './exam-types.component.scss',
})
export class ExamTypesComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'timeCreated',
    'creator',
    'actions',
  ];
  public dataSource: MatTableDataSource<ExamTypeModel> =
    new MatTableDataSource<ExamTypeModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getFullName = getFullName;

  public searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
  });

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private examTypeService: ExamTypeService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the exam types of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        case 'timeCreated':
          return item.timeCreated;
        case 'creator':
          return (
            item.creator?.name?.firstName + ' ' + item.creator?.name?.lastName
          );
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getExamTypes();
  }

  /**
   * retrieves all exam types of the current institute and initializes the table data
   * @returns void
   */
  private getExamTypes(): void {
    this.examTypeService
      .getAllExamTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.dataSource.data = response.body
            ? response.body.map((examType: ExamTypeModel) =>
                this.examTypeService.parseBackendExamType(examType)
              )
            : [];
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Prüfungsarten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * applySearch
   * apply search filter
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onCreate
   * navigate to create exam type page
   * @returns void
   */
  public onCreate(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onEdit
   * navigate to edit exam type page
   * @param examType
   * @returns void
   */
  public onEdit(examType: ExamTypeModel): void {
    this.router.navigate(['edit', btoa(String(examType.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDelete
   * delete an exam type
   * @param examType
   * @returns void
   */
  public onDelete(examType: ExamTypeModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Prüfungsart löschen',
        message: `Möchten Sie die Prüfungsart '${examType.name}' wirklich löschen?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.examTypeService
            .deleteExamType(examType.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  'Die Prüfungsart wurde gelöscht.'
                );
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== examType
                );
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  'Die Prüfungsart konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.'
                );
              },
            });
        }
      });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
