import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  CalendarEventDateModel,
  RoomCalenderEventDateModel,
} from '../models/calendar-event.model';
import { EventDate } from '../models/event.model';
import { UserModel } from '../models/user.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private roomService: RoomService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * Gets all event dates of the institute in the given time range
   * @param startDate The start date of the time range
   * @param endDate The end date of the time range
   * @returns Observable<HttpResponse<any>>
   */
  public getAllEventDatesByInstituteId(
    startDate: Date,
    endDate: Date
  ): Observable<HttpResponse<any>> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/event-dates`,
        {
          params: params,
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets all event dates of the student in the given time range
   * @param startDate The start date of the time range
   * @param endDate The end date of the time range
   * @returns Observable<HttpResponse<any>>
   */
  public getAllEventDatesByStudentId(
    startDate: Date,
    endDate: Date
  ): Observable<HttpResponse<any>> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/event-dates/students/${this.userService.currentUser.id}`,
        {
          params: params,
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets all event dates of the lecturer in the given time range
   * @param startDate The start date of the time range
   * @param endDate The end date of the time range
   * @returns Observable<HttpResponse<any>>
   */
  public getAllEventDatesByLecturerId(
    startDate: Date,
    endDate: Date
  ): Observable<HttpResponse<any>> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/event-dates/lecturers/${this.userService.currentUser.id}`,
        {
          params: params,
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Gets all event dates for the room utilization calendar in the given time range
   * @param startDate The start date of the time range
   * @param endDate The end date of the time range
   * @returns Observable<HttpResponse<any>>
   */
  public getAllRoomCalendarEventDatesByInsitute(
    startDate: Date,
    endDate: Date
  ): Observable<HttpResponse<any>> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/event-dates/room-calendar-event-dates`,
        {
          params: params,
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateEventDates
   */
  public updateEventDates(eventDates: EventDate[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl + 'event/updateEventDates.php',
      eventDates,
      { headers: headers }
    );
  }

  /**
   * getAllInstituteEvents
   */
  public getAllInstituteEvents(): Observable<any> {
    const id_institute = this.userService.currentUser.currentInstituteId;
    return this.http
      .get(
        this.config.backendUrl +
          'event/getAllInstituteEvents.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Parses the backend calendar event date to the frontend calendar event date
   * @param eventDateData
   * @returns
   */
  public async parseCalendarEventDate(
    eventDateData: CalendarEventDateModel
  ): Promise<CalendarEventDateModel> {
    const calendarEventDate: CalendarEventDateModel = {
      eventDateId: eventDateData.eventDateId,
      eventDateType: eventDateData.eventDateType,
      startDate: moment(eventDateData.startDate).tz('Europe/Berlin').toDate(),
      endDate: moment(eventDateData.endDate).tz('Europe/Berlin').toDate(),
      room: eventDateData.room
        ? await this.roomService.parseBackendRoom(eventDateData.room)
        : null,
      isCanceled: eventDateData.isCanceled,
      courseId: eventDateData.courseId ?? null,
      courseTitle: eventDateData.courseTitle ?? null,
      treatmentCaseId: eventDateData.treatmentCaseId ?? null,
      supervisors: eventDateData.supervisors
        ? await Promise.all(
            eventDateData.supervisors.map(
              async (supervisor: any): Promise<UserModel> => {
                return await this.userService.parseBackendUser(supervisor);
              }
            )
          )
        : null,
      lecturers: eventDateData.lecturers
        ? await Promise.all(
            eventDateData.lecturers.map(
              async (lecturer: any): Promise<UserModel> => {
                return await this.userService.parseBackendUser(lecturer);
              }
            )
          )
        : null,
      treatmentCaseStudent: eventDateData.treatmentCaseStudent
        ? await this.userService.parseBackendUser(
            eventDateData.treatmentCaseStudent
          )
        : null,
      patientChiffre:
        (await this.decryptionService.decryptString(
          eventDateData.patientChiffre
        )) ?? null,
      patientAppointmentId: eventDateData.patientAppointmentId ?? null,
      supervisionAppointmentId: eventDateData.supervisionAppointmentId ?? null,
      location: eventDateData.location,
      videoMeetingLink: await this.decryptionService.decryptString(
        eventDateData.videoMeetingLink
      ),
    };
    return calendarEventDate;
  }

  /**
   * Parses the backend room calendar event date to the frontend room calendar event date
   * @param eventDate
   * @returns Promise<RoomCalenderEventDateModel>
   */
  public async parseRoomCalendarEventDate(
    eventDate: RoomCalenderEventDateModel
  ): Promise<RoomCalenderEventDateModel> {
    const roomCalendarEventDate: RoomCalenderEventDateModel = {
      eventDateId: eventDate.eventDateId,
      eventDateType: eventDate.eventDateType,
      startDate: moment(eventDate.startDate).tz('Europe/Berlin').toDate(),
      endDate: moment(eventDate.endDate).tz('Europe/Berlin').toDate(),
      room: eventDate.room
        ? await this.roomService.parseBackendRoom(eventDate.room)
        : null,
      isCanceled: eventDate.isCanceled,
      treatmentCaseStudent: eventDate.treatmentCaseStudent
        ? await this.userService.parseBackendUser(
            eventDate.treatmentCaseStudent
          )
        : null,
      location: eventDate.location,
      videoMeetingLink: await this.decryptionService.decryptString(
        eventDate.videoMeetingLink
      ),
    };
    return roomCalendarEventDate;
  }
}
