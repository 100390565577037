import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { practicalWorkTypeSelectOptions } from 'src/app/constants/practical-work-type.constant';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CooperationPartnerModel } from 'src/app/models/cooperation-partner.model';
import { FileModel } from 'src/app/models/file.model';
import {
  InitialEducationStockCreateModel,
  InitialEducationStockModel,
  InitialEducationStockUpdateModel,
  InitialPracticalWorkStockCreateModel,
  InitialPracticalWorkStockModel,
  InitialPracticalWorkStockUpdateModel,
} from 'src/app/models/initial-education-stock.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CooperationPartnerService } from 'src/app/services/cooperation-partner.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { InitialEducationStockService } from 'src/app/services/initial-education-stock.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { convertDateToDateOnly } from 'src/app/utils/date.utils';
import { areAllValuesNull, isRequired } from 'src/app/utils/form.utils';
import { FileFormat } from '../upload-area-dnd/upload-area-dnd.component';

@Component({
  selector: 'app-create-edit-initial-education-stock',
  templateUrl: './create-edit-initial-education-stock.component.html',
  styleUrl: './create-edit-initial-education-stock.component.scss',
})
export class CreateEditInitialEducationStockComponent
  implements OnInit, OnDestroy
{
  public initialEducationStockForm: FormGroup = new FormGroup({});
  public initialFormValues: any = {};
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public availableCooperationPartners: SelectOption[] = [];
  private studentId?: number = null;
  private initialEducationStock?: InitialEducationStockModel = null;
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];

  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  public practicalWotkTypeSelectOptions: SelectOption[] =
    practicalWorkTypeSelectOptions;

  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.initialEducationStockForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  /**
   * Returns a boolean value indicating whether the current user is an administrator
   * @returns True if the current user is an administrator, false otherwise
   */
  get isCurrentUserAdmin(): boolean {
    return this.userService.currentUserIsAdministrator();
  }

  constructor(
    private initialEducationStockService: InitialEducationStockService,
    private cooperationPartnerService: CooperationPartnerService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public async ngOnInit() {
    this.createForm();
    await this.initializeAvailableCooperationPartners();
    await this.loadInitialEducationStock();
    this.isLoading = false;
    this.initialFormValues = this.initialEducationStockForm.value;
  }

  /**
   * Creates the form for the initial education stock.
   * @returns void
   */
  public createForm(): void {
    this.initialEducationStockForm = new FormGroup({
      id: new FormControl(null),
      theoreticalEducationDurationHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      theoreticalEducationDateRange: new FormGroup({
        theoreticalEducationStartDate: new FormControl(null),
        theoreticalEducationEndDate: new FormControl(null),
      }),
      initialPracticalWorkStocks: new FormArray([
        new FormGroup({
          id: new FormControl(null),
          type: new FormControl(null),
          cooperationPartnerId: new FormControl(null),
          durationHours: new FormControl(null, [
            Validators.min(0),
            Validators.max(10000),
          ]),
          dateRange: new FormGroup({
            startDate: new FormControl(null),
            endDate: new FormControl(null),
          }),
        }),
      ]),
      patientTreatmentDurationHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      completedPatientTreatments: new FormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      patientTreatmentDateRange: new FormGroup({
        patientTreatmentStartDate: new FormControl(null),
        patientTreatmentEndDate: new FormControl(null),
      }),
      supervisionDurationHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      supervisionDateRange: new FormGroup({
        supervisionStartDate: new FormControl(null),
        supervisionEndDate: new FormControl(null),
      }),
      selfAwarenessDurationHours: new FormControl(null, [
        Validators.min(0),
        Validators.max(10000),
      ]),
      selfAwarenessDateRange: new FormGroup({
        selfAwarenessStartDate: new FormControl(null),
        selfAwarenessEndDate: new FormControl(null),
      }),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });

    // subscribe to uploadedFiles changes and convert them to base64
    this.initialEducationStockForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.initialEducationStockForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.initialEducationStockForm.get('documents'),
          false,
          true
        );
      });
  }

  /**
   * Retrieves the FormControl for the practical work type at the specified index.
   * @param index - The index of the practical work stock.
   * @returns The FormControl for the practical work type.
   */
  public getPracticalWorkTypeControl(index: number): FormControl {
    return (this.initialPracticalWorkStocks.controls[index] as FormGroup).get(
      'type'
    ) as FormControl;
  }

  /**
   * Retrieves the FormControl for the cooperation partner at the specified index.
   * @param index - The index of the FormControl to retrieve.
   * @returns The FormControl for the cooperation partner at the specified index.
   */
  public getCooperationPartnerControl(index: number): FormControl {
    return (this.initialPracticalWorkStocks.controls[index] as FormGroup).get(
      'cooperationPartnerId'
    ) as FormControl;
  }

  /**
   * initializeAvailableCooperationPartners
   * Initializes the available cooperation partners for the select input
   * @returns Promise<void>
   */
  private async initializeAvailableCooperationPartners(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cooperationPartnerService
        .getAllCooperationPartners()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            const cooperationPartners: CooperationPartnerModel[] = response.body
              ? await Promise.all(
                  response.body.map(
                    async (cooperationPartner: CooperationPartnerModel) => {
                      return this.cooperationPartnerService.parseBackendCooperationPartner(
                        cooperationPartner
                      );
                    }
                  )
                )
              : [];
            this.availableCooperationPartners = cooperationPartners.map(
              (cooperationPartner: CooperationPartnerModel): SelectOption => {
                return {
                  value: cooperationPartner.id,
                  label: cooperationPartner.name,
                };
              }
            );
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die verfügbaren Kooperationspartner konnten nicht geladen werden.'
            );
            reject();
          },
        });
    });
  }

  /**
   * Loads the initial education stock if it exists and sets the form values
   * @returns Promise<void>
   */
  private loadInitialEducationStock(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.studentId = await this.resolveStudentId();

      if (!this.studentId) {
        resolve();
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Anfangsbestand konnte nicht geladen werden.'
        );
        this.onCancel();
        return;
      }

      this.initialEducationStockService
        .getInitialEducationStockByUserId(this.studentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            if (!response.body) {
              resolve();
              return;
            }

            this.editMode = true;
            this.initialEducationStock =
              await this.initialEducationStockService.parseBackendInitialEducationStock(
                response.body
              );

            this.initialEducationStock.files?.forEach(file => {
              this.existingFiles.push(file);
            });

            this.patchFormValues(this.initialEducationStock);
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Der Anfangsbestand konnte nicht geladen werden.'
            );
            reject();
          },
        });
    });
  }

  /**
   * Resolves the student ID
   * @returns A promise that resolves to the user ID.
   */
  public resolveStudentId(): Promise<number> {
    return new Promise(resolve => {
      this.activatedRoute.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          let studentId: number;
          if (params['userId']) {
            studentId = +atob(params['userId']);
          }

          if (!studentId) {
            studentId = this.userService.currentUser.id;
          }

          resolve(studentId);
        });
    });
  }

  /**
   * Patches the form values with the initial education stock values
   * @param initialEducationStock The initial education stock
   * @returns void
   */
  private patchFormValues(
    initialEducationStock: InitialEducationStockModel
  ): void {
    this.initialEducationStockForm.patchValue({
      id: initialEducationStock.id,
      theoreticalEducationDurationHours:
        initialEducationStock.theoreticalEducationDurationHours,
      theoreticalEducationDateRange: {
        theoreticalEducationStartDate:
          initialEducationStock.theoreticalEducationStartDate,
        theoreticalEducationEndDate:
          initialEducationStock.theoreticalEducationEndDate,
      },
      patientTreatmentDurationHours:
        initialEducationStock.patientTreatmentDurationHours,
      completedPatientTreatments:
        initialEducationStock.completedPatientTreatments,
      patientTreatmentDateRange: {
        patientTreatmentStartDate:
          initialEducationStock.patientTreatmentStartDate,
        patientTreatmentEndDate: initialEducationStock.patientTreatmentEndDate,
      },
      supervisionDurationHours: initialEducationStock.supervisionDurationHours,
      supervisionDateRange: {
        supervisionStartDate: initialEducationStock.supervisionStartDate,
        supervisionEndDate: initialEducationStock.supervisionEndDate,
      },
      selfAwarenessDurationHours:
        initialEducationStock.selfAwarenessDurationHours,
      selfAwarenessDateRange: {
        selfAwarenessStartDate: initialEducationStock.selfAwarenessStartDate,
        selfAwarenessEndDate: initialEducationStock.selfAwarenessEndDate,
      },
    });

    // create formgroups for each initial practical work stock
    const initialPracticalWorkStocks =
      initialEducationStock.initialPracticalWorkStocks.map(
        (initialPracticalWorkStock: InitialPracticalWorkStockModel) => {
          return new FormGroup({
            id: new FormControl(initialPracticalWorkStock.id),
            type: new FormControl(initialPracticalWorkStock.type),
            cooperationPartnerId: new FormControl(
              initialPracticalWorkStock.cooperationPartner?.id
            ),
            durationHours: new FormControl(
              initialPracticalWorkStock.durationHours,
              [Validators.min(0), Validators.max(10000)]
            ),
            dateRange: new FormGroup({
              startDate: new FormControl(initialPracticalWorkStock.startDate),
              endDate: new FormControl(initialPracticalWorkStock.endDate),
            }),
          });
        }
      );

    this.initialEducationStockForm.setControl(
      'initialPracticalWorkStocks',
      new FormArray(initialPracticalWorkStocks)
    );
  }

  /**
   * Gets the initial practical work stocks form array
   * @returns FormArray
   */
  get initialPracticalWorkStocks(): FormArray {
    return this.initialEducationStockForm.get(
      'initialPracticalWorkStocks'
    ) as FormArray;
  }

  /**
   * Adds a new practical work stock to the initial practical work stocks form array
   * @returns void
   */
  public addPracticalWorkStock(): void {
    this.initialPracticalWorkStocks.push(
      new FormGroup({
        id: new FormControl(null),
        type: new FormControl(null),
        cooperationPartnerId: new FormControl(null),
        durationHours: new FormControl(null, [
          Validators.min(0),
          Validators.max(10000),
        ]),
        dateRange: new FormGroup({
          startDate: new FormControl(null),
          endDate: new FormControl(null),
        }),
      })
    );
  }

  /**
   * Removes a practical work stock from the initial practical work stocks form array
   * @param index The index of the practical work stock to remove
   * @returns void
   */
  public removePracticalWorkStock(index: number) {
    this.initialPracticalWorkStocks.removeAt(index);
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the initial education stock will be created or updated
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.initialEducationStockForm
      )
    ) {
      return;
    }

    if (areAllValuesNull(this.initialEducationStockForm)) {
      this.alertService.showErrorAlert(
        'Das hat leider nicht geklappt!',
        'Bitte füllen Sie mindestens ein Feld aus.'
      );
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.initialEducationStockForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      return;
    }

    this.loadingService.show();
    this.editMode
      ? this.updateInitialEducationStock()
      : this.createInitialEducationStock();
  }

  /**
   * Creates the initial education stock from the form values and calls the service to create it
   * @returns void
   */
  private createInitialEducationStock(): void {
    const files: FileModel[] = this.initialEducationStockForm.value.documents
      ? this.existingFiles.concat(
          this.initialEducationStockForm.value.documents
        )
      : this.existingFiles;

    const initialEducationStockCreateModel: InitialEducationStockCreateModel = {
      theoreticalEducationDurationHours: this.initialEducationStockForm.get(
        'theoreticalEducationDurationHours'
      ).value,
      theoreticalEducationStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('theoreticalEducationDateRange')
          .get('theoreticalEducationStartDate').value
      ),
      theoreticalEducationEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('theoreticalEducationDateRange')
          .get('theoreticalEducationEndDate').value
      ),
      initialPracticalWorkStocks: this.initialPracticalWorkStocks.controls.map(
        (
          practicalWorkStock: FormGroup
        ): InitialPracticalWorkStockCreateModel => {
          return {
            type: practicalWorkStock.get('type').value,
            cooperationPartnerId: practicalWorkStock.get('cooperationPartnerId')
              .value,
            durationHours: practicalWorkStock.get('durationHours').value,
            startDate: convertDateToDateOnly(
              practicalWorkStock.get('dateRange').get('startDate').value
            ),
            endDate: convertDateToDateOnly(
              practicalWorkStock.get('dateRange').get('endDate').value
            ),
          };
        }
      ),
      patientTreatmentDurationHours: this.initialEducationStockForm.get(
        'patientTreatmentDurationHours'
      ).value,
      completedPatientTreatments: this.initialEducationStockForm.get(
        'completedPatientTreatments'
      ).value,
      patientTreatmentStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('patientTreatmentDateRange')
          .get('patientTreatmentStartDate').value
      ),
      patientTreatmentEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('patientTreatmentDateRange')
          .get('patientTreatmentEndDate').value
      ),
      supervisionDurationHours: this.initialEducationStockForm.get(
        'supervisionDurationHours'
      ).value,
      supervisionStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('supervisionDateRange')
          .get('supervisionStartDate').value
      ),
      supervisionEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('supervisionDateRange')
          .get('supervisionEndDate').value
      ),
      selfAwarenessDurationHours: this.initialEducationStockForm.get(
        'selfAwarenessDurationHours'
      ).value,
      selfAwarenessStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('selfAwarenessDateRange')
          .get('selfAwarenessStartDate').value
      ),
      selfAwarenessEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('selfAwarenessDateRange')
          .get('selfAwarenessEndDate').value
      ),
      files: files,
    };

    this.initialEducationStockService
      .createInitialEducationStock(
        initialEducationStockCreateModel,
        this.isCurrentUserAdmin ? this.studentId : null
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Anfangsbestand wurde erfolgreich angelegt.'
          );
          this.markInitialEducationStockAsSet();
          this.initialFormValues = this.initialEducationStockForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Anlegen des Anfangsbestandes hat nicht funktioniert.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * Updates the initial education stock from the form values and calls the service to update it
   * @returns void
   */
  private updateInitialEducationStock(): void {
    const files: FileModel[] = this.initialEducationStockForm.value.documents
      ? this.existingFiles.concat(
          this.initialEducationStockForm.value.documents
        )
      : this.existingFiles;

    const initialEducationStockUpdateModel: InitialEducationStockUpdateModel = {
      id: this.initialEducationStockForm.get('id').value,
      theoreticalEducationDurationHours: this.initialEducationStockForm.get(
        'theoreticalEducationDurationHours'
      ).value,
      theoreticalEducationStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('theoreticalEducationDateRange')
          .get('theoreticalEducationStartDate').value
      ),
      theoreticalEducationEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('theoreticalEducationDateRange')
          .get('theoreticalEducationEndDate').value
      ),
      initialPracticalWorkStocks: this.initialPracticalWorkStocks.controls.map(
        (
          practicalWorkStock: FormGroup
        ): InitialPracticalWorkStockUpdateModel => {
          return {
            id: practicalWorkStock.get('id').value,
            type: practicalWorkStock.get('type').value,
            cooperationPartnerId: practicalWorkStock.get('cooperationPartnerId')
              .value,
            durationHours: practicalWorkStock.get('durationHours').value,
            startDate: convertDateToDateOnly(
              practicalWorkStock.get('dateRange').get('startDate').value
            ),
            endDate: convertDateToDateOnly(
              practicalWorkStock.get('dateRange').get('endDate').value
            ),
          };
        }
      ),
      patientTreatmentDurationHours: this.initialEducationStockForm.get(
        'patientTreatmentDurationHours'
      ).value,
      completedPatientTreatments: this.initialEducationStockForm.get(
        'completedPatientTreatments'
      ).value,
      patientTreatmentStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('patientTreatmentDateRange')
          .get('patientTreatmentStartDate').value
      ),
      patientTreatmentEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('patientTreatmentDateRange')
          .get('patientTreatmentEndDate').value
      ),
      supervisionDurationHours: this.initialEducationStockForm.get(
        'supervisionDurationHours'
      ).value,
      supervisionStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('supervisionDateRange')
          .get('supervisionStartDate').value
      ),
      supervisionEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('supervisionDateRange')
          .get('supervisionEndDate').value
      ),
      selfAwarenessDurationHours: this.initialEducationStockForm.get(
        'selfAwarenessDurationHours'
      ).value,
      selfAwarenessStartDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('selfAwarenessDateRange')
          .get('selfAwarenessStartDate').value
      ),
      selfAwarenessEndDate: convertDateToDateOnly(
        this.initialEducationStockForm
          .get('selfAwarenessDateRange')
          .get('selfAwarenessEndDate').value
      ),
      files: files,
    };

    this.initialEducationStockService
      .updateInitialEducationStock(initialEducationStockUpdateModel)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Anfangsbestand wurde erfolgreich aktualisiert.'
          );
          this.initialFormValues = this.initialEducationStockForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Aktualisieren des Anfangsbestandes hat nicht funktioniert.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.initialEducationStockService.openFile(
      this.initialEducationStock?.id,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.initialEducationStockService.downloadFile(
      this.initialEducationStock?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.initialEducationStockForm
      .get('existingFiles')
      .setValue(this.existingFiles);
  }

  /**
   * Redirects the user to the previous page
   * @returns void
   */
  public onCancel(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * Marks the initial education stock as set
   * @returns void
   */
  private markInitialEducationStockAsSet(): void {
    this.userService.currentUser.isInitialEducationStockSet = true;
  }

  /**
   * Checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.initialEducationStockForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
