<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Institute verwalten</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie alle Institute</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <button
        (click)="createInstitute()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Neues Institut anlegen </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Institute column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Institut
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div
              (click)="viewInstitute(row)"
              class="institute-container flex-r gap-16 align-center">
              <div class="institute-icon justify-center">
                <img
                  src="{{
                    row.logo && row.logo !== ''
                      ? row.logo
                      : '../assets/img/EleGuide_Logo-Fullsize.svg'
                  }}"
                  alt
                  class="institute-icon-image" />
              </div>
              <div class="institute-info gray-900">{{ row.name }}</div>
            </div>
          </td>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Beschreibung
            <app-custom-table-sort-icon [sort]="sort" columnName="description">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row.description ?? '-' }}
          </td>
        </ng-container>

        <!-- primaryAdmin column -->
        <ng-container matColumnDef="primaryAdmin">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Primärverwalter
            <app-custom-table-sort-icon [sort]="sort" columnName="primaryAdmin">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="user-container" *ngIf="row.primaryAdmin">
              <div class="user-icon">
                <img src="../assets/img/user.jpg" alt class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(row.primaryAdmin) }}
                </span>
              </div>
            </div>
            <span *ngIf="!row.primaryAdmin" class="hasomed-text-body">-</span>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="center-header-cell">
            Aktionen
          </th>
          <td *matCellDef="let row" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Institut Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  (click)="viewInstitute(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>

                <button
                  *ngIf="!row.primaryAdmin"
                  (click)="onInvitePrimaryAdmin(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-paper-plane icon-sm"></i>
                    <span class="hasomed-text-body gray-600">
                      Primärverwalter einladen
                    </span>
                  </div>
                </button>
                <mat-divider
                  class="menu-divider"
                  *ngIf="!row.primaryAdmin"></mat-divider>

                <button
                  (click)="editInstitute(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button
                  (click)="deleteInstitute(row)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde kein Institut gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
