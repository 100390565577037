<div *ngIf="isLoading" class="loading-wrapper">
  <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
</div>
<!-- MAIN CONTENT -->
<div *ngIf="!isLoading" class="main-content">
  <!-- Name -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Anrede und Titel</span>
      <span class="label-description">
        {{ 'Anrede und Titel' + roleBasedLabel }}.</span
      >
    </div>
    <div
      *ngIf="!user.name.genderTitle && !user.name.academicTitle; else titles"
      class="detail-value">
      Noch nicht hinterlegt.
    </div>
    <ng-template #titles>
      <div class="detail-value">
        {{ user.name.genderTitle }} {{ user.name.academicTitle }}
      </div>
    </ng-template>
  </div>
  <mat-divider></mat-divider>

  <!-- Name -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Name</span>
      <span class="label-description">
        {{ 'Vorname und Nachname' + roleBasedLabel }}.
      </span>
    </div>
    <div class="detail-value">
      {{ user.name.firstName }} {{ user.name.lastName }}
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Etikett</span>
      <span class="label-description">
        {{ 'Spezifische Informationen' + roleBasedLabel }}.
      </span>
    </div>
    <div class="detail-value">
      <div
        *ngIf="user.label"
        [style.background]="user.label.color"
        class="label">
        <i class="fa-light fa-id-badge label-icon"></i>
        <span class="label-text">
          {{ user.label.name }}
        </span>
      </div>
      <span *ngIf="!user.label" class="hasomed-text-body"> - </span>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- Email -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">E-Mail Adresse</span>
      <span class="label-description">
        {{ 'E-Mail Adresse' + roleBasedLabel }}.</span
      >
    </div>
    <div class="detail-value">
      <i class="fa-light fa-envelope"></i>
      <span>{{ user.email }}</span>
      <app-copy-to-clipboard
        *ngIf="user.email"
        [textToCopy]="user.email"
        type="Email Adresse">
      </app-copy-to-clipboard>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- Birthdate -->
  <div class="formular-row">
    <div class="formular-row-label">
      <div class="label-title">Geburtsdatum</div>
      <div class="label-description">
        {{ 'Geburtsdatum' + roleBasedLabel }}.
      </div>
    </div>
    <div class="detail-value">
      <span>
        {{ (user?.birthdate | date: 'dd.MM.yyyy') ?? 'Noch nicht hinterlegt.' }}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- Student number -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Bildungs-ID</span>
      <span class="label-description"> Wird automatisch vergeben. </span>
    </div>
    <div class="detail-value">
      <i class="fa-light fa-lock"></i>
      <span>
        {{
          user?.userIdentifier != '' && user?.userIdentifier
            ? user?.userIdentifier
            : 'Bildungs-ID noch nicht definiert'
        }}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- entry date -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Eintrittsdatum</span>
      <span class="label-description">
        {{ 'Eintrittsdatum' + roleBasedLabel }}.
      </span>
    </div>
    <div class="detail-value">
      <i class="fa-light fa-lock"></i>
      <span>
        {{ (user?.entryDate | date: 'dd.MM.yyyy') ?? 'Noch nicht hinterlegt.' }}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- Avatar -->
  <div class="formular-row">
    <span class="formular-row-label">
      <span class="label-title">Profilfoto</span>
      <span class="label-description">
        {{ 'Profilfoto' + roleBasedLabel }}.
      </span>
    </span>
    <div class="profile-picture-container">
      <img
        #userIconImage
        [attr.src]="user.profilePicture ?? '../../../../../assets/img/user.jpg'"
        (click)="openProfilePictureDialog()"
        id="user-icon-image"
        class="uploaded-image"
        alt="{{ 'Profilfoto' + roleBasedLabel }}"
        matTooltip="{{ 'Profilfoto' + roleBasedLabel }}" />
    </div>
  </div>
  <mat-divider></mat-divider>

  <ng-container *ngIf="user.roleId == Role.STUDENT">
    <!-- Education Course -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Aus- oder Weiterbildungsgang </span>
        <span class="label-description">
          {{ 'Aus- oder Weiterbildungsgang' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{ user?.educationCourse?.title ?? 'Noch nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="user.roleId == Role.LECTURER">
    <!-- additional qualifications -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zusatzqualifikationen </span>
        <span class="label-description">
          {{ 'Zusatzqualifikationen' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllAdditionalQualifications(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- professional association -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Anerkennung durch Berufsverband </span>
        <span class="label-description">
          {{ 'Berufsverband' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ user?.professionalAssociation ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- medicalAuthorizationOnly -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Ausschließlich Zulassung für Ärzte </span>
        <span class="label-description">
          {{ 'Ausschließlich ärztliche Zulassung' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ user?.medicalAuthorizationOnly ? 'Ja' : 'Nein' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- expertise -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Fachkunde</span>
        <span class="label-description">
          {{ 'Fachkunde' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllExpertises(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- procedure -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Richtlinienverfahren </span>
        <span class="label-description">
          {{ 'Richtlinienverfahren' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllProcedures(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- ptk consultation -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">PTK Hinzuziehung</span>
        <span class="label-description">
          {{ 'PTK Hinzuziehung' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <i
          [class.fa-square-check]="user?.ptkConsultation"
          [class.fa-square]="!user?.ptkConsultation"
          class="fa-light check-mark"></i>
        <div *ngIf="user?.ptkConsultation">
          Liegt vor seit dem:
          {{ user?.ptkConsultationDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- confirmation -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Bestätigung durch AWA oder erweitertem Vorstand
        </span>
        <span class="label-description">
          {{
            'Bestätigung durch AWA oder erweitertem Vorstand' + roleBasedLabel
          }}.
        </span>
      </div>
      <div class="detail-value flex-r align-center">
        <i
          [class.fa-square-check]="user?.confirmed"
          [class.fa-square]="!user?.confirmed"
          class="fa-light check-mark"></i>
        <div *ngIf="user?.confirmed">
          Liegt vor seit dem: {{ user?.confirmationDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- landline number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Telefonnummer (Festnetz) </span>
        <span class="label-description">
          {{ 'Festnetznummer' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ user?.landlineNumber ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- mobile number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Telefonnummer (Mobile) </span>
        <span class="label-description">
          {{ 'Mobilfunknummer' + roleBasedLabel }}.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ user?.mobileNumber ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>

  <!-- Measles Protection -->
  <!-- <div class="formular-row">
		<div class="formular-row-label">
			<span class="label-title">Masernstatus</span>
			<span class="label-description">
				Impfschutz für Masern
			</span>
		</div>
		<div class="detail-value">
			<i class="fa-light fa-lock"></i>
			<span>
				{{ user?.measlesProtection ? 'Geprüft' : 'Nicht geprüft' }}
			</span>
		</div>
	</div>
	<mat-divider></mat-divider> -->

  <!-- Address -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Adresse</span>
      <span class="label-description">
        {{ 'Anschrift' + roleBasedLabel }} für Schriftverkehr.
      </span>
    </div>
    <div class="flex-c">
      <span
        *ngIf="
          !user.address?.street &&
          !user.address?.houseNumber &&
          !user.address?.addressAddition &&
          !user.address?.zipCode &&
          !user.address?.city &&
          !user.address?.country
        "
        class="detail-value">
        Nicht hinterlegt.
      </span>
      <span
        *ngIf="user.address?.street || user.address?.houseNumber"
        class="detail-value">
        {{ user.address?.street }} {{ user.address?.houseNumber }}
      </span>
      <span *ngIf="user.address?.addressAddition" class="detail-value">
        {{ user.address?.addressAddition }}
      </span>
      <span
        *ngIf="user.address?.zipCode || user.address?.city"
        class="detail-value">
        {{ user.address?.zipCode }} {{ user.address?.city }}
      </span>
      <span *ngIf="user.address?.country" class="detail-value">
        {{ user.address?.country }}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>

  <ng-container *ngIf="user.roleId == Role.STUDENT">
    <!-- Bankverbindung -->
    <div
      *appCheckPermissions="{
        feature: feature.STUDENT_ADMINISTRATION,
        permission: permission.ADMIN,
      }"
      class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Bankverbindung</span>
        <span class="label-description">
          {{ 'Daten für den Zahlungsverkehr' + roleBasedLabel }}.
        </span>
      </div>
      <div class="flex-c">
        <span
          *ngIf="
            !user?.bankAccount?.accountHolder &&
            !user?.bankAccount?.maskedIban &&
            !user?.bankAccount?.bic
          "
          class="detail-value">
          Nicht hinterlegt.
        </span>
        <span *ngIf="user?.bankAccount?.accountHolder" class="detail-value">
          {{ user?.bankAccount?.accountHolder }}
        </span>
        <div
          *ngIf="user?.bankAccount?.maskedIban && user?.bankAccount?.iban"
          class="flex-r gap-8 align-center">
          <span class="detail-value">
            {{ user?.bankAccount?.maskedIban }}
          </span>
          <app-copy-to-clipboard
            *ngIf="user?.bankAccount?.iban"
            [textToCopy]="user?.bankAccount?.iban"
            type="IBAN">
          </app-copy-to-clipboard>
        </div>
        <span *ngIf="user?.bankAccount?.bic" class="detail-value">
          {{ user?.bankAccount?.bic }}
        </span>
      </div>
    </div>
    <mat-divider
      *appCheckPermissions="{
        feature: feature.STUDENT_ADMINISTRATION,
        permission: permission.ADMIN,
      }"></mat-divider>
  </ng-container>

  <!-- Document -->
  <div class="formular-row">
    <div class="formular-row-label">
      <span class="label-title">Dokumentenupload</span>
      <span class="label-description"
        >{{ 'Hochgeladene Dokumente' + roleBasedLabel }}.
      </span>
    </div>
    <div
      class="user-detail-value-files"
      *ngIf="user.files && user.files?.length > 0">
      <app-file-view
        *ngFor="let file of user.files"
        [file]="file"
        (openFile)="onOpenFile($event)"
        (downloadFile)="onDownloadFile($event)"></app-file-view>
    </div>
    <span *ngIf="!user.files || user.files?.length == 0" class="detail-value">
      Keine Dokumente hochgeladen.
    </span>
  </div>
</div>
