import { Component } from '@angular/core';

@Component({
  selector: 'app-course-detail-skeleton',
  templateUrl: './course-detail-skeleton.component.html',
  styleUrl: './course-detail-skeleton.component.scss'
})
export class CourseDetailSkeletonComponent {

}
