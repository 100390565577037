import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CalendarEventDateModel } from 'src/app/models/calendar-event.model';
import { EventDateType } from 'src/app/models/event.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionAppointmentService } from 'src/app/services/supervision-appointment.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName, getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls: ['./calendar-event-detail.component.scss'],
})
export class CalendarEventDetailComponent implements OnInit, OnDestroy {
  public eventType = EventDateType;
  public eventForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionAppointmentService: SupervisionAppointmentService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public userService: UserService,
    private cancellationService: CancellationService,
    public dialogRef: MatDialogRef<CalendarEventDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      calendarEventDate: CalendarEventDateModel;
    }
  ) {}

  public ngOnInit() {
    moment.locale('de');

    const formattedDate =
      moment(this.data.calendarEventDate.startDate).format('dd') +
      ', ' +
      moment(this.data.calendarEventDate.startDate).format('DD.MM.YYYY') +
      ', ' +
      moment(this.data.calendarEventDate.startDate).format('HH:mm') +
      ' - ' +
      moment(this.data.calendarEventDate.endDate).format('HH:mm') +
      ' Uhr';

    // get names of lecturers, supervisors and treatmentCaseStudent
    const studentName = this.data.calendarEventDate.treatmentCaseStudent
      ? getFullName(this.data.calendarEventDate.treatmentCaseStudent)
      : null;
    const supervisorNames = this.data.calendarEventDate.supervisors
      ? getFullNames(this.data.calendarEventDate.supervisors)
      : null;
    const lecturerNames = this.data.calendarEventDate.lecturers
      ? getFullNames(this.data.calendarEventDate.lecturers)
      : null;

    this.eventForm = new FormGroup({
      date: new FormControl(formattedDate),
      room: new FormControl(
        this.data.calendarEventDate.room?.name ?? 'Kein Raum'
      ),
      chiffre: new FormControl(this.data.calendarEventDate.patientChiffre),
      supervisor: new FormControl(supervisorNames),
      lecturer: new FormControl(lecturerNames),
      treatmentCaseStudent: new FormControl(studentName),
    });
  }

  /**
   * get the event date title based on the event date type
   * @returns string
   */
  public getEventDateTitle(): string {
    if (this.data.calendarEventDate.eventDateType === EventDateType.COURSE) {
      return 'Kurs: ' + this.data.calendarEventDate.courseTitle;
    }

    if (
      this.data.calendarEventDate.eventDateType ===
      EventDateType.PATIENT_SESSION
    ) {
      return 'Patient: ' + this.data.calendarEventDate.patientChiffre;
    }

    if (
      this.data.calendarEventDate.eventDateType === EventDateType.SUPERVISION
    ) {
      return 'Einzelsupervision';
    }

    if (
      this.data.calendarEventDate.eventDateType ===
      EventDateType.GROUP_SUPERVISION
    ) {
      return 'Gruppensupervision';
    }

    return '';
  }

  /**
   * onManageAppointment
   * redirect to treatmentCase page
   * @returns void
   */
  public onManageAppointment(): void {
    this.router.navigate([
      'eleguide/education/ambulatory-part',
      btoa(this.data.calendarEventDate.treatmentCaseId.toString()),
      'event-dates',
    ]);
    this.dialogRef.close();
  }

  /**
   * onCancelAppointment
   * cancel the appointment
   * @returns void
   */
  public onCancelAppointment(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Absagen',
        message: `Möchten Sie den Termin wirklich absagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          if (
            this.data.calendarEventDate.eventDateType ===
            EventDateType.PATIENT_SESSION
          ) {
            this.cancelPatientAppointmentEventDate(
              this.data.calendarEventDate.treatmentCaseId,
              this.data.calendarEventDate.patientAppointmentId,
              this.data.calendarEventDate.eventDateId
            );
            return;
          }
          if (
            this.data.calendarEventDate.eventDateType ===
            EventDateType.SUPERVISION
          ) {
            this.cancelSupervisionAppointmentEventDate(
              this.data.calendarEventDate.supervisionAppointmentId,
              this.data.calendarEventDate.eventDateId
            );
            return;
          }
        }
      });
  }

  /**
   * Cancel patient appointment event date
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @param eventDateId The event date id
   * @returns void
   */
  private cancelPatientAppointmentEventDate(
    treatmentCaseId: number,
    patientAppointmentId: number,
    eventDateId: number
  ): void {
    this.patientAppointmentService
      .cancelPatientAppointmentEventDate(
        treatmentCaseId,
        patientAppointmentId,
        eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Der Termin wurde abgesagt.`
          );

          this.dialogRef.close({ eventDateHasBeenCanceled: true });
        },
        error: error => {
          this.alertService.showErrorAlert(
            `Das hat nicht geklappt!`,
            `Der Termin konnte nicht abgesagt werden.`
          );
        },
      });
  }

  /**
   * Cancel supervision appointment event date
   * @param supervisionAppointmentId The supervision appointment id
   * @param eventDateId The event date id
   * @returns void
   */
  private cancelSupervisionAppointmentEventDate(
    supervisionAppointmentId: number,
    eventDateId: number
  ): void {
    this.supervisionAppointmentService
      .cancelSupervisionAppointmentEventDate(
        supervisionAppointmentId,
        eventDateId
      )
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Der Termin wurde abgesagt.`
          );

          this.dialogRef.close({ eventDateHasBeenCanceled: true });
        },
        error: error => {
          this.alertService.showErrorAlert(
            `Das hat nicht geklappt!`,
            `Der Termin konnte nicht abgesagt werden.`
          );
        },
      });
  }

  /**
   * openCourseDetail
   * redirect to course detail page
   * @returns void
   */
  public onOpenCourseDetail(): void {
    if (this.userService.currentUserIsStudent()) {
      this.router.navigate([
        'eleguide/education/theoretical-education/',
        btoa(this.data.calendarEventDate.courseId?.toString()),
      ]);
    } else {
      this.router.navigate([
        'eleguide/education/course-administration',
        btoa(this.data.calendarEventDate.courseId?.toString()),
      ]);
    }

    this.dialogRef.close();
  }

  /**
   * onClose
   * close the dialog
   * @returns void
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
