import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BankAccountModel } from 'src/app/models/bank-account.model';
import { CourseModel } from 'src/app/models/course.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { getCourseRoom } from 'src/app/utils/course.utils';
import { isSameDay } from 'src/app/utils/utils';

@Component({
  selector: 'app-course-elog',
  templateUrl: './course-elog.component.html',
  styleUrls: ['./course-elog.component.scss'],
})
export class CourseElogComponent implements OnInit, OnDestroy {
  public userId: number;
  public showMore: boolean = false;

  public displayedColumns: string[] = [
    'title',
    'dates',
    'room',
    'participantAmount',
    'eLogStatus',
    'actions',
  ];
  public bankDetails: BankAccountModel;
  public dataSource: MatTableDataSource<CourseModel> =
    new MatTableDataSource<CourseModel>();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getCourseRoom = getCourseRoom;
  public isSameDay = isSameDay;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cancellationService: CancellationService,
    private courseService: CourseService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.userId = +atob(params.get('userId'));
        this.getStudentsCourses(this.userId);
      });
  }

  /**
   * getStudentsCourses
   * @description get the courses of the student
   * @param userId
   * @returns void
   */
  private getStudentsCourses(userId: number): void {
    this.courseService
      .getStudentsCourses(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'courseTitle':
                return item.title;
              case 'eLogStatus':
                // sort failed on top, followed by checked and then followed by pending
                if (item.elogStatusCounts.pending > 0) {
                  return item.elogStatusCounts.pending * 1000;
                } else if (item.elogStatusCounts.upcoming > 0) {
                  return item.elogStatusCounts.upcoming * 100;
                } else if (item.elogStatusCounts.checked > 0) {
                  return item.elogStatusCounts.checked * 10;
                } else {
                  return item.elogStatusCounts.pending * 10;
                }
              default:
                return item[property];
            }
          };
          this.isLoading = false;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        error: error => {
          this.isLoading = false;
        },
      });
  }

  /**
   * openELog
   * @description open the eLog page
   * @param courseId
   * @returns void
   */
  public openELog(courseId: number): void {
    this.router.navigate([
      `/eleguide/education/course-administration/${btoa(String(courseId))}/elog/student/${btoa(String(this.userId))}`,
    ]);
  }

  /**
   * ngOnDestroy
   * @description unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
