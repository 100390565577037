<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Patient: Chiffre ({{ treatmentCase?.patientChiffre ?? '' }})
      </h1>
      <h3 class="hasomed-text-h3">
        Supervision: {{ getAllSupervisors(treatmentCase) }}
      </h3>
    </div>

    <form [formGroup]="searchForm" class="header-buttons">
      <button
        (click)="onGoBack()"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <div class="hasomed-date-range">
        <mat-date-range-input
          [rangePicker]="picker"
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            (dateChange)="applyDateRange()"
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          [for]="picker"
          class="hasomed-date-range-toggle"
          matIconSuffix>
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>
      <button
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(
            treatmentCaseEventDatesFilter,
            true
          ),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            treatmentCaseEventDatesFilter,
            true
          ),
          'hasomed-button-active': filterOpened,
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>
      <button
        *ngIf="canCreateAppointment"
        (click)="onCreateAppointment()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Neuer Termin</span>
      </button>
    </form>
    <!-- Filter Menu -->
    <mat-menu #filterMenu="matMenu" class="filter-overlay">
      <app-filter
        *ngIf="filterOpened && !isLoading"
        [filters]="treatmentCaseEventDatesFilter"
        (filterChanged)="treatmentCaseEventDatesFilterChanged($event)">
      </app-filter>
    </mat-menu>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="treatmentCaseEventDatesFilter"
      (filterChanged)="treatmentCaseEventDatesFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wann
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let eventDate; dataSource: dataSource"
            (click)="onViewAppointment(eventDate)"
            class="cursor-pointer"
            matTooltip="Termin anzeigen"
            mat-cell>
            {{ eventDate.startDate | date: 'dd.MM.yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="room">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wo
            <app-custom-table-sort-icon [sort]="sort" columnName="room">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let eventDate; dataSource: dataSource"
            [matTooltip]="eventDate.room?.name ?? ''"
            mat-cell>
            {{ eventDate.room ? eventDate.room.name : '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Terminart
            <app-custom-table-sort-icon [sort]="sort" columnName="type">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let eventDate; dataSource: dataSource" mat-cell>
            {{ eventDate.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="supervisors">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Supervisor(en)
            <app-custom-table-sort-icon [sort]="sort" columnName="supervisors">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let eventDate; dataSource: dataSource" mat-cell>
            {{ getAllEventDateSupervisors(eventDate) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Status
            <app-custom-table-sort-icon [sort]="sort" columnName="status">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let eventDate; dataSource: dataSource" mat-cell>
            <div
              [ngClass]="getStatusLabelClass(eventDate.status)"
              class="label">
              <i
                [ngClass]="getStatusIconClass(eventDate.status)"
                class="fa-light label-icon"></i>
              <span class="hasomed-text-small label-text">
                {{ eventDate.status }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let eventDate; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Behandlungsfall Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  (click)="onViewAppointment(eventDate)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-calendar-plus icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button
                  (click)="onEditAppointment(eventDate)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>

                <mat-divider
                  *ngIf="!eventDate.isCanceled"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="!eventDate.isCanceled"
                  (click)="onCancelEventDate(eventDate)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-cancel icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Absagen</span>
                  </div>
                </button>

                <mat-divider
                  *ngIf="eventDate.isCanceled"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="eventDate.isCanceled"
                  (click)="onConfirmEventDate(eventDate)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-circle-check icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Zusagen</span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button
                  (click)="onDeleteEventDate(eventDate)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurden keine Termine gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
