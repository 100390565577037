import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CooperationPartnerModel } from 'src/app/models/cooperation-partner.model';
import { Filter } from 'src/app/models/filter.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CooperationPartnerService } from 'src/app/services/cooperation-partner.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllExpertises,
  getAllProcedures,
  getFullContactPersonName,
} from 'src/app/utils/cooperation-partner-utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-cooperation-partner',
  templateUrl: './cooperation-partner.component.html',
  styleUrl: './cooperation-partner.component.scss',
})
export class CooperationPartnerComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'contactPerson',
    'expertise',
    'supplySector',
    'procedure',
    'actions',
  ];
  public dataSource: MatTableDataSource<CooperationPartnerModel> =
    new MatTableDataSource<CooperationPartnerModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;
  public isAdmin: boolean = this.userService.currentUserIsAdministrator();
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  public cooperationPartnerFilter: Filter[] = [];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  public searchForm = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator()),
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getFullContactPersonName = getFullContactPersonName;
  public getAllProcedures = getAllProcedures;
  public getAllExpertises = getAllExpertises;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private cooperationPartnerService: CooperationPartnerService,
    private userService: UserService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.initTable();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['name', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['name', 'contactPerson', 'actions'];
    } else {
      this.displayedColumns = [
        'name',
        'contactPerson',
        'expertise',
        'supplySector',
        'procedure',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the cooperation partners of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        case 'contactPerson':
          return (
            item.contactPerson?.firstName + ' ' + item.contactPerson?.lastName
          );
        case 'expertise':
          if (item.expertises.length > 1) return 'Mehrere';
          return item.expertises[0]?.name;
        case 'supplySector':
          return item.supplySector?.name;
        case 'procedure':
          if (item.procedures.length > 1) return 'Mehrere';
          return item.procedures[0]?.name;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getCooperationPartners();
  }

  /**
   * retrieves all cooperation partners of the current institute and initializes the table data
   * @returns Promise<void>
   */
  private async getCooperationPartners(): Promise<void> {
    this.cooperationPartnerService
      .getAllCooperationPartners()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body.map(
                  async (
                    cooperationPartnerData: any
                  ): Promise<CooperationPartnerModel> =>
                    this.cooperationPartnerService.parseBackendCooperationPartner(
                      cooperationPartnerData
                    )
                )
              )
            : [];

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kooperationspartner konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  public cooperationPartnerFilterChanged(
    cooperationPartnerFilter: Filter[]
  ): void {
    this.cooperationPartnerFilter = cooperationPartnerFilter;

    this.applyCooperationPartnerFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applies the student filter
   * @returns void
   */
  public applyCooperationPartnerFilter(): void {
    // TODO
  }

  /**
   * applySearch
   * filter the table data
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   * openCooperationPartnerDetail
   * navigate to student detail page
   * @param cooperationPartnerId
   * @returns void
   */
  public onOpenCooperationPartnerDetail(cooperationPartnerId: number) {
    this.router.navigate([btoa(String(cooperationPartnerId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreateCooperationPartner
   * navigate to create page
   * @returns void
   */
  public onCreateCooperationPartner(): void {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditCooperationPartner
   * navigate to edit page
   * @param cooperationPartnerId
   * @returns void
   */
  public onEditCooperationPartner(cooperationPartnerId: number): void {
    this.router.navigate(['edit', btoa(String(cooperationPartnerId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteCooperationPartner
   * navigate to edit page
   * @param cooperationPartnerId
   * @returns void
   */
  public onDeleteCooperationPartner(
    cooperationPartner: CooperationPartnerModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Kooperationspartner löschen',
        message: `Möchten Sie den Kooperationspartner '${cooperationPartner.name}' wirklich löschen? \n Der Kooperationspartner kann nicht wiederhergestellt werden.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.cooperationPartnerService
            .deleteCooperationPartner(cooperationPartner.id)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Kooperationspartner '${cooperationPartner.name}' wurde gelöscht!`
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(cooperationPartner);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== cooperationPartner
                  );
                }
              },
              error: () => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Der Kooperationspartner '${cooperationPartner.name}' konnte nicht gelöscht werden.`
                );
              },
            });
        }
      });
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
