import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { getAllSupervisors } from 'src/app/utils/treatment-case.utils';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-treatment-cases-table',
  templateUrl: './treatment-cases-table.component.html',
  styleUrl: './treatment-cases-table.component.scss',
})
export class TreatmentCasesTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() searchText: string;

  public displayedColumns = ['chiffre', 'supervisors', 'dates', 'actions'];
  public dataSource: MatTableDataSource<TreatmentCaseModel> =
    new MatTableDataSource();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getAllSupervisors = getAllSupervisors;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private treatmentCaseService: TreatmentCaseService,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private matDialog: MatDialog
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        let userId = +atob(params.get('userId'));

        if (!userId) {
          userId = this.userService.currentUser.id;
        }

        this.initTable(userId);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText) {
      this.applySearch(this.searchText);
    }
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the treatment cases of the user
   * @param userId The id of the user
   * @returns void
   */
  private initTable(userId: number): void {
    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr = data.patientChiffre?.toLowerCase();
      return dataStr ? dataStr.indexOf(filter) != -1 : false;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'chiffre':
          return item.patientChiffre;
        case 'supervisors':
          return item.supervisors.map(supervisor => supervisor.name.lastName);
        case 'dates':
          return item.startDate;
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getTreatmentCasesFromUser(userId);
  }

  /**
   * retrieves all treatment cases of a user and initializes the table data
   * @param userId The id of the user
   * @returns Promise<void>
   */
  private async getTreatmentCasesFromUser(userId: number): Promise<void> {
    this.treatmentCaseService
      .getAllTreatmentCasesByInstituteIdAndStudentId(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const treatmentCases = response.body
            ? await Promise.all(
                response.body.map(async (treatmentCase: TreatmentCaseModel) => {
                  return await this.treatmentCaseService.parseBackendTreatmentCase(
                    treatmentCase
                  );
                })
              )
            : [];

          this.dataSource.data = treatmentCases;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Behandlungsfälle konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );

          this.isLoading = false;
        },
      });
  }

  /**
   * Apply search to the table
   * @param filterValue The value to filter
   * @returns void
   */
  public applySearch(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * opens the table of the treatment case event dates
   * @param treatmentCase The treatment case
   * @returns void
   */
  public onViewTreatmentCaseAppointmentEventDates(
    treatmentCase: TreatmentCaseModel
  ): void {
    const treatmentCaseId = encodeURIComponent(
      btoa(treatmentCase.id.toString())
    );
    this.router.navigate([treatmentCaseId, 'event-dates'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * opens the overview of the treatment case
   * @param treatmentCase The treatment case
   * @returns void
   */
  public onViewTreatmentCase(treatmentCase: TreatmentCaseModel): void {
    this.router.navigate(['./', btoa(treatmentCase.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * opens the edit treatment case page
   * @param treatmentCase The treatment case
   * @returns void
   */
  public onEditTreatmentCase(treatmentCase: TreatmentCaseModel): void {
    this.router.navigate(
      ['edit-treatment-case', btoa(treatmentCase.id.toString())],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * opens the delete treatment case dialog
   * @param treatmentCase The treatment case
   * @returns void
   */
  public onDeleteTreatmentCase(treatmentCase: TreatmentCaseModel): void {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Behandlungsfall Löschen',
        message: `Möchten Sie diesen Behandlungsfall wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.treatmentCaseService
            .deleteTreatmentCase(treatmentCase.id)
            .pipe(first())
            .subscribe({
              next: result => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  'Der Behandlungsfall wurde gelöscht!'
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(treatmentCase);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== treatmentCase
                  );
                }
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  'Der Behandlungsfall konnte nicht gelöscht werden.'
                );
              },
            });
        }
      });
  }

  /**
   * ngOnDestroy
   * Unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
