<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kandidatenübersicht</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie Ihre Kandidaten.</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <button
        (click)="exportDataToExcel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-arrow-down-to-line hasomed-button-icon"></i>
        <span class="hasomed-button-text">Liste exportieren</span>
      </button>

      <!-- Search -->
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>

      <!-- Filter Button -->
      <button
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(studentFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            studentFilter,
            true
          ),
          'hasomed-button-active': filterOpened,
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Invite Button -->
      <button
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN,
        }"
        (click)="onInviteUser()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-user-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Neuen Nutzer einladen</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu #filterMenu="matMenu" class="filter-overlay">
        <app-filter
          *ngIf="filterOpened"
          [filters]="studentFilter"
          [labelFilter]="true"
          (filterChanged)="studentFilterChanged($event)">
        </app-filter>
      </mat-menu>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="studentFilter"
      (filterChanged)="studentFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Student column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kandidaten
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let user; dataSource: dataSource" mat-cell>
            <div class="user-container">
              <div
                (click)="openProfilePictureDialog(user)"
                class="user-icon"
                matTooltip="Profilbild ansehen">
                <img
                  src="{{ user.profilePicture ?? '../assets/img/user.jpg' }}"
                  alt
                  class="user-icon-image" />
              </div>
              <div
                (click)="openStudentDetail(user.id)"
                class="user-info"
                matTooltip="Profil öffnen">
                <span class="user-name hasomed-text-body">
                  {{ user.name.academicTitle }}
                  {{ user.name.firstName }}
                  {{ user.name.lastName }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- userIdentifier column -->
        <ng-container matColumnDef="userIdentifier">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Bildungs-ID
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="userIdentifier">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let user; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body gray-600">
              {{ user.userIdentifier ?? '-' }}
            </span>
          </td>
        </ng-container>

        <!-- Education progress column -->
        <ng-container matColumnDef="educationalProgress">
          <th
            *matHeaderCellDef
            mat-header-cell
            style="width: 500px"
            mat-sort-header>
            Ausbildungsfortschritt
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="educationalProgress">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let user; dataSource: dataSource"
            mat-cell
            style="width: 500px">
            <div class="education-progress">
              <div class="progress-container">
                <div
                  [ngStyle]="{
                    width: getTotalEducationalProgressInPercent(user) + '%',
                  }"
                  class="progress-bar"></div>
              </div>
              <span
                [ngClass]="
                  getTotalEducationalProgressInPercent(user) < 10
                    ? 'text-red'
                    : ''
                "
                class="progress-label">
                {{ getTotalEducationalProgressInPercent(user) ?? '0' }}%
              </span>
            </div>
          </td>
        </ng-container>

        <!-- entryDate column -->
        <ng-container matColumnDef="entryDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Eintrittsdatum
            <app-custom-table-sort-icon [sort]="sort" columnName="entryDate">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let user; dataSource: dataSource"
            mat-cell
            class="hasomed-text-body gray-600">
            {{ user.entryDate ? (user.entryDate | date: 'dd.MM.YYYY') : '-' }}
          </td>
        </ng-container>

        <!-- Label column -->
        <ng-container matColumnDef="label">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Etikett
            <app-custom-table-sort-icon [sort]="sort" columnName="label">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let user; dataSource: dataSource" mat-cell>
            <div
              *ngIf="user.label"
              [style.background]="user.label.color"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ user.label.name }}
              </span>
            </div>
            <span *ngIf="!user.label">-</span>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th
            *matHeaderCellDef
            class="gray-600 center-header-cell"
            mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let user; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                *appCheckPermissions="{
                  feature: feature.STUDENT_ADMINISTRATION,
                  permission: permission.VIEW,
                }"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Benutzer Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.VIEW,
                  }"
                  (click)="openStudentDetail(user.id)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.ADMIN,
                  }"
                  class="menu-divider"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.ADMIN,
                  }"
                  (click)="onEditStudent(user.id)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Keine Kandidaten gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
