<div class="my-space-header">
  <!-- First card -->
  <div class="card">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(1) }} </span>
      <!-- navigate to theoretical-education for student -->
      <button
        *ngIf="userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Kurse anzeigen"
        (click)="navigateToRoute('../../education/theoretical-education')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <!-- navigate to course-administration for lecturer, admin -->
      <button
        *ngIf="!userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Kurse anzeigen"
        (click)="navigateToRoute('../../education/course-administration')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsStudent()
            ? (currentCoursesOfStudent | async) !== null
              ? (currentCoursesOfStudent | async)
              : 'Lade Daten...'
            : ''
        }}
        {{
          userService.currentUserIsLecturer()
            ? (ownCourses | async) !== null
              ? (ownCourses | async)
              : 'Lade Daten...'
            : ''
        }}
        {{
          userService.currentUserIsAdministrator()
            ? (currentCourses | async) !== null
              ? (currentCourses | async)
              : 'Lade Daten...'
            : ''
        }}
      </span>
    </div>
  </div>

  <!-- Second card -->
  <div class="card" *ngIf="userService.currentUserIsAdministrator()">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(2) }} </span>
      <button
        *ngIf="userService.currentUserIsAdministrator()"
        class="table-button-icon"
        matTooltip="Anwesenheiten anzeigen"
        (click)="navigateToELogAndSetFilter(openAdminELogs)">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsAdministrator()
            ? (openAdminELogs | async) !== null
              ? (openAdminELogs | async) + ' ausstehend'
              : 'Lade Daten...'
            : ''
        }}
      </span>
      <button
        *ngIf="
          (openAdminELogs | async) !== null &&
          (openAdminELogs | async) !== 0 &&
          userService.currentUserIsAdministrator()
        "
        type="button"
        class="hasomed-text-button-xsmall hasomed-button-primary"
        (click)="navigateToELogAndSetFilter(openAdminELogs)">
        <i class="hasomed-button-icon fa-light fa-user-check"></i>
        <span class="hasomed-button-text">Jetzt prüfen</span>
      </button>
    </div>
  </div>

  <!-- Third card -->
  <div class="card">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(3) }} </span>
      <button
        *ngIf="userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Ausbildungsfortschritt anzeigen"
        (click)="navigateToRoute('../../organization/education-progress')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <button
        *ngIf="userService.currentUserIsLecturer()"
        class="table-button-icon"
        matTooltip="Anwesenheiten anzeigen"
        (click)="navigateToELogAndSetFilter(openLecturerELogs)">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <button
        *ngIf="userService.currentUserIsAdministrator()"
        class="table-button-icon"
        matTooltip="Mitglieder anzeigen"
        (click)="navigateToRoute('../../institute/members')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div
      class="flex-r full-width align-center"
      *ngIf="
        userService.currentUserIsStudent() && !isLoadingEducationalProgress
      ">
      <div class="progress-container">
        <div
          class="progress-bar"
          [ngStyle]="{
            width: totalEducationalProgress
              ? totalEducationalProgress.progressInPercent + '%'
              : 0 + '%',
          }"></div>
      </div>
      <span class="progress-label hasomed-text-h2">
        {{
          totalEducationalProgress
            ? totalEducationalProgress.progressInPercent + '%'
            : 0 + '%'
        }}
      </span>
    </div>
    <span
      class="hasomed-text-h2"
      *ngIf="
        userService.currentUserIsStudent() && isLoadingEducationalProgress
      ">
      Lade Daten...
    </span>

    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsLecturer()
            ? (openLecturerELogs | async) !== null
              ? (openLecturerELogs | async) + ' ausstehend'
              : 'Lade Daten...'
            : ''
        }}
        <!-- {{
          userService.currentUserIsAdministrator()
            ? (currentLecturers | async) !== null
              ? (currentLecturers | async)
              : 'Lade Daten...'
            : ''
        }} -->
        {{
          userService.currentUserIsAdministrator()
            ? (amountOfUnconfirmedUsers | async) !== null
              ? (amountOfUnconfirmedUsers | async) + ' ausstehend'
              : 'Lade Daten...'
            : ''
        }}
      </span>
      <button
        *ngIf="
          (amountOfUnconfirmedUsers | async) !== null &&
          (amountOfUnconfirmedUsers | async) !== 0 &&
          userService.currentUserIsAdministrator()
        "
        type="button"
        class="hasomed-text-button-xsmall hasomed-button-primary"
        (click)="navigateToRoute('../../institute/members')">
        <i class="hasomed-button-icon fa-light fa-user-check"></i>
        <span class="hasomed-button-text">Jetzt ansehen</span>
      </button>
      <button
        *ngIf="
          (openLecturerELogs | async) !== null &&
          (openLecturerELogs | async) !== 0 &&
          userService.currentUserIsLecturer()
        "
        type="button"
        class="hasomed-text-button-xsmall hasomed-button-primary"
        (click)="navigateToELogAndSetFilter(openLecturerELogs)">
        <i class="hasomed-button-icon fa-light fa-user-check"></i>
        <span class="hasomed-button-text">Jetzt prüfen</span>
      </button>
    </div>
  </div>
</div>
