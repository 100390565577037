import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { InviteUserDialogComponent } from 'src/app/components/shared-components/invite-user-dialog/invite-user-dialog.component';
import { InstituteModel } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-institutes',
  templateUrl: './institutes.component.html',
  styleUrls: ['./institutes.component.scss'],
})
export class InstitutesComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<InstituteModel> =
    new MatTableDataSource<InstituteModel>();
  public isLoading = true;
  public displayedColumns = ['name', 'description', 'primaryAdmin', 'actions'];
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  public getFullName = getFullName;

  constructor(
    private instituteService: InstituteService,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router,
    private cancellationService: CancellationService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.getData();
  }

  /**
   * getData
   * get all institutes
   * @returns Promise<void>
   */
  private async getData(): Promise<void> {
    this.instituteService
      .getAllInstitutes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body?.map(
                  async (institute: InstituteModel): Promise<InstituteModel> =>
                    await this.instituteService.parseBackendInstitute(institute)
                )
              )
            : [];
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              default:
                return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Institute konnten nicht geladen werden'
          );
          this.isLoading = false;
        },
      });
  }

  /**
   * applySearch
   * filters the institutes by the search input
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * createInstitute
   * resets the current institute of the user and navigates to the onboarding
   * @returns void
   */
  public createInstitute(): void {
    this.userService.currentUser.currentInstituteId = null;
    this.instituteService.currentInstitute = null;
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * viewInstitute
   * navigate to the view page of the institute
   * @param institute
   * @returns void
   */
  public viewInstitute(institute: InstituteModel): void {
    this.router.navigate([btoa(institute.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onInvitePrimaryAdmin
   * opens the invite user dialog to invite a primary admin to the institute
   * @param institute
   * @returns void
   */
  public onInvitePrimaryAdmin(institute: InstituteModel): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      data: { instituteId: institute.id },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          // update the primary admin in the institute
          this.dataSource.data = this.dataSource.data?.map(i => {
            if (i.id === institute.id) {
              i.primaryAdmin = result;
            }
            return i;
          });
        }
      });
  }

  /**
   * editInstitute
   * navigate to the edit page of the institute
   * @param institute
   * @returns void
   */
  public editInstitute(institute: InstituteModel): void {
    this.router.navigate(['edit', btoa(institute.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * deleteInstitute
   * deletes the institute
   * @param institute
   * @returns void
   */
  public deleteInstitute(institute: InstituteModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '445px',
      data: {
        title: 'Institut löschen',
        message: `Möchten Sie das Institut "${institute.name}" wirklich löschen?`,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.instituteService
            .deleteInstitute(institute.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.dataSource.data = this.dataSource.data.filter(
                  i => i.id !== institute.id
                );
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Das Institut "${institute.name}" wurde gelöscht.`
                );
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Das Institut "${institute.name}" konnte nicht gelöscht werden`
                );
              },
            });
        }
      });
  }

  /**
   * ngOnDestroy
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
