import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  WikiPage,
  WikiPageCreateModel,
  WikiPageUpdateModel,
} from '../models/wiki.model';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  private instituteId: number = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllWikiPages
   * @returns Observable<HttpResponse<any>>
   */
  public getAllWikiPages(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/wiki-articles/`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getWikiPageById
   * @param wikiArticleId
   * @returns Observable<HttpResponse<any>>
   */
  public getWikiPageById(wikiArticleId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/wiki-articles/${wikiArticleId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createWikiPage
   * @param wikiPageCreateModel: WikiPage
   * @returns Observable<HttpResponse<any>>
   */
  public async createWikiPage(
    wikiPageCreateModel: WikiPageCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/wiki-articles`,
      wikiPageCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * updateWikiPage
   * @param wikiArticleId: number
   * @param wikiPageUpdateModel: WikiPage
   * @returns Observable<HttpResponse<any>>
   */
  public async updateWikiPage(
    wikiArticleId: number,
    wikiPageUpdateModel: WikiPageUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/wiki-articles/${wikiArticleId}`,
      wikiPageUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * deleteWikiPage
   * @param wikiArticleId
   * @returns Observable<HttpResponse<any>>
   */
  public deleteWikiPage(wikiArticleId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/wiki-articles/${wikiArticleId}`,

      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parseBackendWikiPage
   * decrypts the wiki page
   * @param wikiPage
   * @returns Promise<WikiPage>
   */
  public async parseBackendWikiPage(wikiPage: any): Promise<WikiPage> {
    const parsedWikiPage: WikiPage = {
      id: wikiPage.id,
      title: wikiPage.title,
      subtitle: wikiPage.subtitle,
      content: wikiPage.content,
      image: wikiPage.image,
      link: wikiPage.link,
      creator:
        wikiPage.creator &&
        (await this.userService.parseBackendUser(wikiPage.creator)),
      modifier:
        wikiPage.modifier &&
        (await this.userService.parseBackendUser(wikiPage.modifier)),
      timeCreated:
        wikiPage.timeCreated &&
        moment(wikiPage.timeCreated).tz('Europe/Berlin').toDate(),
      timeModified:
        wikiPage.timeModified &&
        moment(wikiPage.timeModified).tz('Europe/Berlin').toDate(),
    };

    return parsedWikiPage;
  }
}
