<div class="component-content">
  <div class="header">
    <div class="header-title">
      <span class="hasomed-text-h1">Räume verwalten</span>
      <span class="hasomed-text-h3">
        Erstellen oder bearbeiten Sie die Räume.
      </span>
    </div>
    <div class="header-buttons">
      <!-- Filter Button -->
      <button
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(roomFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(roomFilter, true),
          'hasomed-button-active': filterOpened,
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu #filterMenu="matMenu" class="filter-overlay">
        <app-filter
          *ngIf="filterOpened"
          [filters]="roomFilter"
          (filterChanged)="roomFilterChanged($event)">
        </app-filter>
      </mat-menu>

      <!-- Add room button -->
      <button
        (click)="onCreateNewRoom()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="hasomed-button-icon fa-light fa-plus"></i>
        <span class="hasomed-button-text">Neuen Raum hinzufügen</span>
      </button>
    </div>
  </div>

  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="roomFilter"
      (filterChanged)="roomFilterChanged($event)">
    </app-filter-tags>

    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Name column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Raum</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let room; dataSource: dataSource"
            (click)="onOpen(room)"
            class="cursor-pointer"
            mat-cell>
            <span class="hasomed-text-body">{{ room.name }}</span>
          </td>
        </ng-container>

        <!-- type column -->
        <ng-container matColumnDef="roomType">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Raumtyp</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="roomType">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">
              {{ room.roomType?.name }}
            </span>
          </td>
        </ng-container>

        <!-- Floor column -->
        <ng-container matColumnDef="floor">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Etage</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="floor">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">
              {{
                room.floor === 0
                  ? 'EG'
                  : room.floor
                    ? room.floor + '. OG'
                    : 'Unbekannte Etage'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Building column -->
        <ng-container matColumnDef="building">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Gebäude</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="building">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">{{ room.building }}</span>
          </td>
        </ng-container>

        <!-- seatNumber column -->
        <ng-container matColumnDef="seatNumber">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Platzanzahl</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="seatNumber">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">
              {{ room.seatNumber }}
            </span>
          </td>
        </ng-container>

        <!-- Equipment column -->
        <ng-container matColumnDef="equipment">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Ausstattung</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="equipment">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">
              {{ room.equipment || '-' }}
            </span>
          </td>
        </ng-container>

        <!-- Available column -->
        <ng-container matColumnDef="available">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Aktiv?</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="available">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <mat-slide-toggle
              [checked]="room.isAvailable"
              [matTooltip]="
                room.isAvailable ? 'Raum deaktivieren' : 'Raum aktivieren'
              "
              (change)="onRoomActiveChanged($event, room)"
              hideIcon>
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let room; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Raum Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button (click)="onOpen(room)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>
                <button (click)="onEdit(room)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>
                <button (click)="onDelete(room)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length" class="mat-cell">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="table-no-match hasomed-text-body font-grey">
                <span> Es wurden keine Räume gefunden </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
