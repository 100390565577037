<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Eintrag ansehen</h1>
      <h3 class="hasomed-text-h3">Sehen Sie sich Ihre Selbsterfahrung an.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        [disabled]="isLoading"
        (click)="onEditSelfAwarenessEntry()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title"> Selbsterfahrungsleitung </span>
      </label>
      <div class="detail-value">
        {{ getFullName(selfAwarenessEntry?.selfAwarenessTrainer) }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Sitzung in Stunden </span>
      </div>
      <div class="detail-value">
        {{ selfAwarenessEntry?.duration | floatToHoursMinutes }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Datum</span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{ selfAwarenessEntry?.date | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">Beschreibung</span>
      </label>
      <div class="detail-value">
        {{ selfAwarenessEntry?.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">Dokumente</span>
      </label>
      <div class="detail-value-files">
        <app-file-view
          *ngFor="let file of selfAwarenessEntry?.files"
          [file]="file"
          (downloadFile)="onDownloadFile($event)"
          (openFile)="onOpenFile($event)"></app-file-view>
        <span
          *ngIf="
            !selfAwarenessEntry?.files || selfAwarenessEntry?.files?.length == 0
          "
          class="detail-value">
          Keine Dokumente hochgeladen.
        </span>
      </div>
    </div>
  </div>
</div>
