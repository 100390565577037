<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kursübersicht</h1>
      <h3 class="hasomed-text-h3">
        Erstellen Sie oder bearbeiten Sie {{ isLecturer ? 'ihre' : '' }} Kurse.
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="hasomed-date-range">
        <mat-date-range-input
          [rangePicker]="picker"
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            (dateChange)="applyDateRange()"
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          [for]="picker"
          class="hasomed-date-range-toggle"
          matIconSuffix>
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>

      <button
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            courseFilter,
            true
          ),
          'hasomed-button-active': filterOpened,
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu #filterMenu="matMenu" class="filter-overlay">
        <app-filter
          *ngIf="filterOpened"
          [filters]="courseFilter"
          [courses]="courses"
          (filterChanged)="courseFilterChanged($event)">
        </app-filter>
      </mat-menu>

      <button
        *ngIf="!isLecturer"
        (click)="createCourse()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Neuen Kurs anlegen</span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="courseFilter"
      (filterChanged)="courseFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Course title column -->
        <ng-container matColumnDef="title">
          <th
            *matHeaderCellDef
            class="gray-600"
            mat-header-cell
            mat-sort-header>
            <span>Kurs</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            (click)="viewCourse(course)"
            class="gray-900 max-width-400 cell-overflow-hidden cursor-pointer"
            mat-cell>
            <span
              [matTooltip]="course.title"
              class="hasomed-text-body text-overflow-hidden">
              {{ course.title }}
            </span>
          </td>
        </ng-container>

        <!-- Course Lecturer Column -->
        <ng-container matColumnDef="lecturer">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Lehrpersonal</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="lecturer">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            class="lecturer-cell"
            mat-cell>
            <div class="flex-r cell-overflow-hidden">
              <span
                [matTooltip]="getFullLecturerNames(course)"
                class="text-overflow-hidden">
                {{ getFullLecturerNames(course) }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Course Dates Column -->
        <ng-container matColumnDef="dates">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Kurstermine</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="dates">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <span class>
              {{ getCourseStartDate(course) | date: 'dd.MM.yyyy' }}
            </span>
            <span
              *ngIf="
                !isSameDay(getCourseStartDate(course), getCourseEndDate(course))
              ">
              -
              {{ getCourseEndDate(course) | date: 'dd.MM.yyyy' }}
            </span>
          </td>
        </ng-container>

        <!-- Course elog column -->
        <ng-container matColumnDef="elog">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Anwesenheiten</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="elog">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <app-elog-cell
              [eLogStatusCounts]="course.elogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <!-- Course room column -->
        <ng-container matColumnDef="rooms">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Räume</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="rooms">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            class="hasomed-text-body text-overflow-hidden"
            mat-cell>
            {{ getCourseRoom(course) ?? '-' }}
          </td>
        </ng-container>

        <!-- Course active column -->
        <ng-container matColumnDef="active">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Aktiv</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="active">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <mat-slide-toggle
              [disabled]="isCourseFinished(course)"
              [matTooltip]="
                isCourseFinished(course)
                  ? 'Der Kurs ist bereits abgeschlossen und kann nicht temporär deaktiviert werden.'
                  : ''
              "
              [checked]="course.logonEnabled"
              (change)="onCourseActiveChanged($event, course)"
              hideIcon
              class="hasomed-slide-toggle-green-red">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Course actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Kurs Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  (click)="viewCourse(course)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button
                  (click)="editCourse(course.id)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>

                <mat-divider
                  *ngIf="isAdministrator"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="isAdministrator"
                  (click)="duplicateCourse(course.id)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-copy icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Duplizieren</span>
                  </div>
                </button>

                <mat-divider
                  *ngIf="isAdministrator && getParticipantAmount(course) === 0"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="isAdministrator && getParticipantAmount(course) === 0"
                  (click)="deleteCourse(course)"
                  type="button"
                  mat-menu-item>
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde kein Kurs gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
