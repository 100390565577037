<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Prüfungsarten</h1>
      <h3 class="hasomed-text-h3">
        Erstellen oder bearbeiten Sie Prüfungsarten.
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <!-- Search -->
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <button
        (click)="onCreate()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Prüfungsart hinzufügen </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- name column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Titel</span>
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="name"></app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            mat-cell
            class="gray-900">
            {{ row.name }}
          </td>
        </ng-container>

        <!-- timeCreated column -->
        <ng-container matColumnDef="timeCreated">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Erstellungsdatum</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="timeCreated">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row.timeCreated | date: 'dd.MM.yyyy' }}
          </td>
        </ng-container>

        <!-- creator column -->
        <ng-container matColumnDef="creator">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Erstellt von
            <app-custom-table-sort-icon [sort]="sort" columnName="creator">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row.creator ? getFullName(row.creator) : '-' }}
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            Aktionen
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Prüfungsart Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <mat-button-toggle-group
                  (click)="onEdit(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </mat-button-toggle-group>
                <mat-divider class="menu-divider"></mat-divider>
                <button (click)="onDelete(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Keine Prüfungsarten gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
