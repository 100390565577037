<form
  [formGroup]="cooperationPartnerForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode
            ? 'Kooperationspartner bearbeiten'
            : 'Neuen Kooperationspartner anlegen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen Kooperationspartner.'
            : 'Legen Sie einen neuen Kooperationspartner an.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Name
          <span *ngIf="isRequired(cooperationPartnerForm.get('name'))">*</span>
        </span>
        <span class="label-description">
          Fügen Sie den Name des Kooperationspartners ein.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            autocomplete="off"
            formControlName="name"
            placeholder="Name" />
        </div>
        <app-error-message
          [formField]="cooperationPartnerForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- contactPerson -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Ansprechpartner
          <span
            *ngIf="
              isRequired(
                cooperationPartnerForm.get('contactPersonFirstName')
              ) ||
              isRequired(cooperationPartnerForm.get('contactPersonLastName'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Fügen Sie Titel, sowie den Vor- und Nachnamen des Ansprechpartners
          hinzu.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <academic-title-select
            class="flex-1"
            [control]="academicTitleControl"></academic-title-select>
          <input
            id="contactPersonFirstName"
            class="hasomed-text-input"
            autocomplete="given-name"
            type="text"
            size="15"
            formControlName="contactPersonFirstName"
            placeholder="Vorname" />
          <input
            id="contactPersonLastName"
            class="hasomed-text-input"
            autocomplete="family-name"
            type="text"
            size="15"
            formControlName="contactPersonLastName"
            placeholder="Nachname" />
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('contactPersonAcademicTitle')
          "></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('contactPersonFirstName')
          "></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('contactPersonLastName')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">
          E-Mail Adresse
          <span *ngIf="isRequired(cooperationPartnerForm.get('email'))">*</span>
        </span>
        <span class="label-description">
          Fügen Sie eine E-Mail als Kontaktmöglichkeit hinzu.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-envelope input-icon"></i>
          <input
            id="email"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="off"
            formControlName="email"
            placeholder="email@example.com" />
        </div>
        <app-error-message
          [formField]="cooperationPartnerForm.get('email')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- phone -->
    <div class="formular-row">
      <label class="formular-row-label" for="phone">
        <span class="label-title">
          Telefonnummer (Festnetz)
          <span *ngIf="isRequired(cooperationPartnerForm.get('phone'))">*</span>
        </span>
        <span class="label-description">
          Fügen Sie eine Festnetznummer als Kontaktmöglichkeit hinzu.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-phone input-icon"></i>
          <input
            id="phone"
            class="hasomed-text-input"
            type="text"
            formControlName="phone"
            placeholder="01234/56789" />
        </div>
        <app-error-message
          [formField]="cooperationPartnerForm.get('phone')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- mobile -->
    <div class="formular-row">
      <label class="formular-row-label" for="mobile">
        <span class="label-title">
          Telefonnummer (Mobile)
          <span *ngIf="isRequired(cooperationPartnerForm.get('mobile'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Fügen Sie eine Mobilfunknummer als Kontaktmöglichkeit hinzu.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-mobile-screen-button input-icon"></i>
          <input
            id="mobile"
            class="hasomed-text-input"
            type="text"
            formControlName="mobile"
            placeholder="+49 123 / 4567890" />
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('mobile')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description"> Fügen Sie die Anschrift hinzu. </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="street"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="street"
              placeholder="Straße" />
            <input
              id="houseNumber"
              class="hasomed-text-input"
              type="text"
              maxlength="5"
              size="5"
              formControlName="houseNumber"
              placeholder="Hausnummer" />
          </div>
          <div class="formular-row-input">
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              size="10"
              formControlName="addressAddition"
              placeholder="Adresszusatz (Etage, Appartment)" />
          </div>
          <div class="formular-row-input">
            <input
              id="zipCode"
              class="hasomed-text-input"
              type="text"
              size="5"
              formControlName="zipCode"
              placeholder="PLZ" />
            <input
              id="city"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="city"
              placeholder="Ort" />
          </div>
          <div class="formular-row-input">
            <country-select
              class="flex-1"
              [control]="countryControl"></country-select>
          </div>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('street')
          "></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('houseNumber')
          "></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('addressAddition')
          "></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('zipCode')
          "></app-error-message>
        <app-error-message
          [formField]="cooperationPartnerForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('country')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- expertises -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Fachkunde
          <span *ngIf="isRequired(cooperationPartnerForm.get('expertises'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die angebotenen Fachkunde Bereiche aus.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="let expertise of expertises"
            [ngClass]="{
              'hasomed-button-primary': cooperationPartnerForm
                .get('expertises')
                .value?.includes(expertise.id),
              'hasomed-button-white-grey': !cooperationPartnerForm
                .get('expertises')
                .value?.includes(expertise.id),
            }"
            (click)="changeArrayFormFieldValue(expertise.id, 'expertises')"
            class="hasomed-text-button"
            type="button">
            <span class="hasomed-button-text">
              {{ expertise.name }}
            </span>
          </button>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('expertises')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- supplySectors -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Versorgungsbereich
          <span *ngIf="isRequired(cooperationPartnerForm.get('supplySector'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie den Versorgungsbereich aus.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="let supplySector of supplySectors"
            [class.border-red]="
              hasErrors(cooperationPartnerForm.get('supplySector'))
            "
            [ngClass]="{
              'hasomed-button-primary':
                cooperationPartnerForm.get('supplySector').value ===
                supplySector.id,
              'hasomed-button-white-grey':
                cooperationPartnerForm.get('supplySector').value !==
                supplySector.id,
            }"
            (click)="
              cooperationPartnerForm
                .get('supplySector')
                .setValue(supplySector.id)
            "
            class="hasomed-text-button"
            type="button">
            <span class="hasomed-button-text">
              {{ supplySector.name }}
            </span>
          </button>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('supplySector')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- procedures -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Richtlinienverfahren
          <span *ngIf="isRequired(cooperationPartnerForm.get('procedures'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die Richtlinienverfahren aus.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="let procedure of procedures"
            [ngClass]="{
              'hasomed-button-primary': cooperationPartnerForm
                .get('procedures')
                .value?.includes(procedure.id),
              'hasomed-button-white-grey': !cooperationPartnerForm
                .get('procedures')
                .value?.includes(procedure.id),
            }"
            (click)="changeArrayFormFieldValue(procedure.id, 'procedures')"
            class="hasomed-text-button"
            type="button">
            <span class="hasomed-button-text">
              {{ procedure.name }}
            </span>
          </button>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('procedures')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- cooperationAgreementAvailable -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Kooperationsvertrag
          <span
            *ngIf="
              isRequired(
                cooperationPartnerForm.get('cooperationAgreementAvailable')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie an ob ein Kooperationsvertrag vorliegt.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-checkbox
            id="cooperationAgreementAvailable"
            class="input-checkbox"
            type="checkbox"
            color="primary"
            formControlName="cooperationAgreementAvailable">
            <div
              *ngIf="
                cooperationPartnerForm.get('cooperationAgreementAvailable')
                  .value !== true
              "
              class="checkbox-title">
              Liegt nicht vor
            </div>
            <div
              *ngIf="
                cooperationPartnerForm.get('cooperationAgreementAvailable')
                  .value === true
              "
              class="checkbox-title">
              Liegt vor seit dem:
            </div>
          </mat-checkbox>
          <mat-form-field
            *ngIf="
              cooperationPartnerForm.get('cooperationAgreementAvailable')
                .value === true
            "
            [class.hasomed-date-field-empty]="
              !cooperationPartnerForm.get('cooperationAgreementDate').value
            "
            class="hasomed-date-field">
            <input
              [max]="today"
              [matDatepicker]="cooperationAgreementDatePicker"
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              placeholder="Wählen Sie ein Datum"
              formControlName="cooperationAgreementDate" />
            <mat-datepicker-toggle
              [for]="cooperationAgreementDatePicker"
              class="hasomed-date-toggle"
              matIconPrefix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #cooperationAgreementDatePicker
              startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('cooperationAgreementAvailable')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- surveyFormAvailable -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Erhebungsbogen
          <span
            *ngIf="
              isRequired(cooperationPartnerForm.get('surveyFormAvailable'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie an ob ein Erhebungsbogen vorliegt.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-checkbox
            id="surveyFormAvailable"
            class="input-checkbox"
            type="checkbox"
            color="primary"
            formControlName="surveyFormAvailable">
            <div
              *ngIf="
                cooperationPartnerForm.get('surveyFormAvailable').value !== true
              "
              class="checkbox-title">
              Liegt nicht vor
            </div>
            <div
              *ngIf="cooperationPartnerForm.get('surveyFormAvailable').value"
              class="checkbox-title">
              Liegt vor seit dem:
            </div>
          </mat-checkbox>
          <mat-form-field
            *ngIf="
              cooperationPartnerForm.get('surveyFormAvailable').value === true
            "
            [class.hasomed-date-field-empty]="
              !cooperationPartnerForm.get('surveyFormDate').value
            "
            class="hasomed-date-field">
            <input
              [max]="today"
              [matDatepicker]="surveyFormDateDatePicker"
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              placeholder="Wählen Sie ein Datum"
              formControlName="surveyFormDate" />
            <mat-datepicker-toggle
              [for]="surveyFormDateDatePicker"
              class="hasomed-date-toggle"
              matIconPrefix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #surveyFormDateDatePicker
              startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('surveyFormAvailable')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- isAdvancedTrainingApproved -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Weiterbildungsbefugnis
          <span
            *ngIf="
              isRequired(
                cooperationPartnerForm.get('isAdvancedTrainingApproved')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie an, ob eine Weiterbildungsbefugnis vorliegt.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-checkbox
            id="isAdvancedTrainingApproved"
            class="input-checkbox"
            type="checkbox"
            color="primary"
            formControlName="isAdvancedTrainingApproved">
            <div
              *ngIf="
                cooperationPartnerForm.get('isAdvancedTrainingApproved')
                  .value !== true
              "
              class="checkbox-title">
              Liegt nicht vor
            </div>
            <div
              *ngIf="
                cooperationPartnerForm.get('isAdvancedTrainingApproved').value
              "
              class="checkbox-title">
              Liegt vor
            </div>
          </mat-checkbox>
        </div>
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('isAdvancedTrainingApproved')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- scopeOfAdvancedTrainingApproval -->
    <div class="formular-row">
      <label class="formular-row-label" for="scopeOfAdvancedTrainingApproval">
        <span class="label-title">
          Umfang der Weiterbildungsbefugnis
          <span
            *ngIf="
              isRequired(
                cooperationPartnerForm.get('scopeOfAdvancedTrainingApproval')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie den Umfang der Weiterbildungsbefugnis an.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="scopeOfAdvancedTrainingApproval"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="scopeOfAdvancedTrainingApproval"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            cooperationPartnerForm.get('scopeOfAdvancedTrainingApproval')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Document -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Dokumente</span>
        <span class="label-description">
          Hier können alle relevanten Dokumente, wie der Kooperationsvertrag
          hochgeladen werden.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-grow">
            <app-upload-area-dnd
              [logs]="false"
              [existingFiles]="existingFiles"
              [showFileList]="true"
              [multiple]="true"
              [allowedFileTypes]="allowedFileTypes"
              (openExistingFile)="onOpenExistingFile($event)"
              (downloadExistingFile)="onDownloadExistingFile($event)"
              (deleteExistingFile)="onDeleteExistingFile($event)"
              context="cooperationPartnerDocumentUpload"
              inputId="cooperationPartnerDocumentUpload"
              formControlName="uploadedFiles">
            </app-upload-area-dnd>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
