import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllAdditionalQualifications,
  getAllExpertises,
  getAllProcedures,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-advanced-lecturer-details',
  templateUrl: './advanced-lecturer-details.component.html',
  styleUrl: './advanced-lecturer-details.component.scss',
})
export class AdvancedLecturerDetailsComponent implements OnInit, OnDestroy {
  public user: UserModel;
  public isLoading = true;
  public existingFiles: FileModel[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getAllAdditionalQualifications = getAllAdditionalQualifications;
  public getAllExpertises = getAllExpertises;
  public getAllProcedures = getAllProcedures;
  public getFullName = getFullName;

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const userId = +atob(params.get('id'));
        this.getLecturer(userId);
      });
  }

  /**
   * getLecturer
   * get user data of lecturer
   * @param userId
   * @returns void
   */
  private getLecturer(userId: number): void {
    this.userService
      .getInstituteUserById(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Stammdaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * onEdit
   * navigate to edit page
   * @returns void
   */
  public onEdit(): void {
    this.router.navigate(['edit'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.user.profilePicture,
        viewOnly: true,
      },
    });
  }

  /**
   * goBack
   * navigate back to overview component
   * @returns void
   */
  public goBack(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * ngOnDestroy
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
