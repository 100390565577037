import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Label } from 'src/app/models/label.model';
import { Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { LabelService } from 'src/app/services/label.service';
import { getFullName } from 'src/app/utils/user.utils';
import { CreateEditLabelDialogComponent } from './create-edit-label-dialog/create-edit-label-dialog.component';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'label',
    'createdAt',
    'createdBy',
    'actions',
  ];
  public dataSource: MatTableDataSource<Label> = new MatTableDataSource();
  public isLoading = true;

  // to access the role enum in the template
  public role = Role;
  public getFullName = getFullName;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private labelService: LabelService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.getLabels();
  }

  /**
   * getLabels
   * gets all labels from the current institute and sets them to the table dataSource
   * @returns void
   */
  private getLabels(): void {
    this.labelService
      .getAllLabels()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async result => {
          // parse createdBy user
          if (result.body) {
            const labels = result.body
              ? await Promise.all(
                  result.body.map(async (label: Label) => {
                    label = await this.labelService.parseBackendLabel(label);
                    return label;
                  })
                )
              : [];

            this.dataSource.data = labels;

            this.dataSource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'label':
                  return item.name;
                default:
                  return item[property];
              }
            };

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.isLoading = false;
          }
        },
        error: error => {
          this.isLoading = false;

          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Etiketten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * createLabel
   * opens a modal to create a new label
   * @returns void
   */
  public createLabel(): void {
    const dialogRef = this.dialog.open(CreateEditLabelDialogComponent, {
      data: {
        label: null,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.getLabels();
      });
  }

  /**
   * editLabel
   * opens a modal to edit the label
   * @param label
   * @returns void
   */
  public editLabel(label: Label): void {
    const dialogRef = this.dialog.open(CreateEditLabelDialogComponent, {
      data: {
        label: label,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
      });
  }

  /**
   * deleteLabel
   * deletes the label
   * @param label
   * @returns void
   */
  public deleteLabel(label: Label): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie das Etikett '${label.name}' wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.labelService
            .deleteLabel(label.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Etikett gelöscht!',
                  `Das Etikett '${label.name}' wurde gelöscht!`
                );

                // delete label from dataSource without reloading from backend
                const index = this.dataSource.data.indexOf(label);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== label
                  );
                }
              },
              error: error => {},
            });
        }
      });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
