import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { FileModel } from 'src/app/models/file.model';
import {
  SelfAwarenessEntryCreateModel,
  SelfAwarenessEntryModel,
} from 'src/app/models/self-awareness-entry.model';
import {
  AdditionalQualificationEnum,
  UserModel,
} from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-create-edit-self-awareness-entry',
  templateUrl: './create-edit-self-awareness-entry.component.html',
  styleUrls: ['./create-edit-self-awareness-entry.component.scss'],
})
export class CreateEditSelfAwarenessEntryComponent
  implements OnInit, OnDestroy
{
  public isLoading = true;
  public editMode = false;
  public selfAwarenessForm: FormGroup;
  public currentSelfAwarenessEntry: SelfAwarenessEntryModel;
  public initialFormValues: {};
  public availableSelfAwarenessTrainers: UserModel[] = [];
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];

  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.selfAwarenessForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private selfAwarenessService: SelfAwarenessService,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();
    await this.initializeAvailableSelfAwarenessTrainers();

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const selfAwarenessEntryId = +atob(
        this.activatedRoute.snapshot.params['id']
      );
      this.initializeSelfAwarenessEntry(selfAwarenessEntryId);
    } else {
      this.isLoading = false;
      this.initialFormValues = this.selfAwarenessForm.value;
    }
  }

  /**
   * Creates the form
   * @returns void
   */
  private createForm(): void {
    this.selfAwarenessForm = new FormGroup({
      selfAwarenessTrainerSearch: new FormControl(null),
      selfAwarenessTrainerId: new FormControl(null, [Validators.required]),
      description: new FormControl('', Validators.maxLength(6000)),
      duration: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
      ]),
      date: new FormControl(null, [Validators.required]),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });

    // subscribe to uploadedFiles changes and convert them to base64
    this.selfAwarenessForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.selfAwarenessForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.selfAwarenessForm.get('documents'),
          false,
          true
        );
      });
  }

  /**
   * Initializes the available self awareness trainers
   * @returns Promise<void>
   */
  private async initializeAvailableSelfAwarenessTrainers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByAdditionalQualification(
          AdditionalQualificationEnum.SELF_AWARENESS_TRAINER
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableSelfAwarenessTrainers = response.body
              ? await Promise.all(
                  response.body.map(
                    async (user: UserModel): Promise<UserModel> => {
                      return await this.userService.parseBackendUser(user);
                    }
                  )
                )
              : [];
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Initializes the self awareness entry
   * @param selfAwarenessEntryId The self awareness entry id
   * @returns void
   */
  public initializeSelfAwarenessEntry(selfAwarenessEntryId: number) {
    this.selfAwarenessService
      .getSelfAwarenessEntryById(selfAwarenessEntryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentSelfAwarenessEntry = response.body
            ? await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                response.body
              )
            : null;

          if (!this.currentSelfAwarenessEntry) {
            this.alertService.showErrorAlert(
              'Das hat nicht geklappt!',
              'Fehler beim Laden des Selbsterfahrungseintrags.'
            );
            // reset initial form values to prevent unsaved changes alert
            this.initialFormValues = this.selfAwarenessForm.value;
            // redirect to overview
            this.onCancel();
            return;
          }

          this.selfAwarenessForm.patchValue({
            selfAwarenessTrainerId:
              this.currentSelfAwarenessEntry.selfAwarenessTrainer.id,
            description: this.currentSelfAwarenessEntry.description,
            duration: this.currentSelfAwarenessEntry.duration,
            date: moment(this.currentSelfAwarenessEntry.date).toDate(),
          });

          this.currentSelfAwarenessEntry?.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.initialFormValues = this.selfAwarenessForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Fehler beim Laden des Selbsterfahrungseintrags.'
          );

          this.isLoading = false;
          this.onCancel();
        },
      });
  }

  /**
   * onSelfAwarenessTrainerSelected
   * @param selfAwarenessTrainer The self awareness trainer
   * @returns void
   */
  public onSelfAwarenessTrainerSelected(selfAwarenessTrainer: UserModel): void {
    if (
      this.selfAwarenessForm.get('selfAwarenessTrainerId').value ===
      selfAwarenessTrainer.id
    ) {
      // remove selected self awareness trainer
      this.selfAwarenessForm.get('selfAwarenessTrainerId').setValue(null);
    } else {
      // add self awareness trainer
      this.selfAwarenessForm
        .get('selfAwarenessTrainerId')
        .setValue(selfAwarenessTrainer.id);
    }
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the self awareness entry will be created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.selfAwarenessForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.selfAwarenessForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode
      ? this.updateSelfAwarenessEntry()
      : this.createSelfAwarenessEntry();
  }

  /**
   * Calls the create method to create a self awareness entry
   * @returns Promise<void>
   */
  private async createSelfAwarenessEntry(): Promise<void> {
    const files: FileModel[] = this.selfAwarenessForm.value.documents
      ? this.existingFiles.concat(this.selfAwarenessForm.value.documents)
      : this.existingFiles;

    const selfAwarenessEntryCreateModel: SelfAwarenessEntryCreateModel = {
      selfAwarenessTrainerId: this.selfAwarenessForm.get(
        'selfAwarenessTrainerId'
      ).value,
      description: this.selfAwarenessForm.get('description').value,
      duration: this.selfAwarenessForm.get('duration').value,
      date: moment(this.selfAwarenessForm.get('date').value).format(
        'YYYY-MM-DD'
      ),
      files: files,
    };

    const createSelfAwarenessObservable =
      await this.selfAwarenessService.createSelfAwarenessEntry(
        selfAwarenessEntryCreateModel
      );

    createSelfAwarenessObservable.pipe(first()).subscribe({
      next: result => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Selbsterfahrungseintrag erfolgreich erstellt.`
        );

        this.initialFormValues = this.selfAwarenessForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Fehler beim Erstellen des Selbsterfahrungseintrags.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * Calls the update method to update a self awareness entry
   * @returns Promise<void>
   */
  private async updateSelfAwarenessEntry(): Promise<void> {
    const files: FileModel[] = this.selfAwarenessForm.value.documents
      ? this.existingFiles.concat(this.selfAwarenessForm.value.documents)
      : this.existingFiles;

    const selfAwarenessEntryUpdateModel: SelfAwarenessEntryCreateModel = {
      selfAwarenessTrainerId: this.selfAwarenessForm.get(
        'selfAwarenessTrainerId'
      ).value,
      description: this.selfAwarenessForm.get('description').value,
      duration: this.selfAwarenessForm.get('duration').value,
      date: moment(this.selfAwarenessForm.get('date').value).format(
        'YYYY-MM-DD'
      ),
      files: files,
    };

    const updateSelfAwarenessObservable =
      await this.selfAwarenessService.updateSelfAwarenessEntry(
        this.currentSelfAwarenessEntry.id,
        selfAwarenessEntryUpdateModel
      );

    updateSelfAwarenessObservable.pipe(first()).subscribe({
      next: result => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Selbsterfahrungseintrag erfolgreich aktualisiert.`
        );

        this.initialFormValues = this.selfAwarenessForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Fehler beim Aktualisieren des Selbsterfahrungseintrags.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * onCancel
   */
  public onCancel() {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.selfAwarenessService.openFile(
      this.currentSelfAwarenessEntry?.id,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.selfAwarenessService.downloadFile(
      this.currentSelfAwarenessEntry?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   */
  public onDeleteExistingFile(file: FileModel) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.selfAwarenessForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.selfAwarenessForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   * Cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
